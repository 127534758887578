/* eslint-disable */
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  DateTime: { input: number; output: number; }
  Long: { input: number; output: number; }
  Void: { input: any; output: any; }
};

export type AccessConstraint = {
  partyRole?: InputMaybe<PartyRole>;
};

/** Report Options */
export enum AccountNumbersDisplay {
  Auto = 'Auto',
  Hidden = 'Hidden'
}

export type AccountReconciliation = {
  category: EntityCategory;
  categoryId: Scalars['String']['output'];
  chartOfAccountsId: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  /** creator and updater are nullable when system opened the reconciliation */
  creator?: Maybe<EntityUser>;
  endDate: Date;
  reconciliationId: Scalars['String']['output'];
  startDate: Date;
  status: AccountReconciliationStatus;
  updatedAt: Scalars['DateTime']['output'];
  updater?: Maybe<EntityUser>;
};

export enum AccountReconciliationStatus {
  Draft = 'Draft',
  Finalized = 'Finalized',
  ManualReopened = 'ManualReopened',
  SystemReopened = 'SystemReopened',
  Unknown = 'Unknown'
}

export type AccountReconciliations = {
  account: EntityCategory;
  reconciliations: Array<AccountReconciliation>;
};

export enum AccountingBasis {
  AccrualBasis = 'AccrualBasis',
  CashBasis = 'CashBasis',
  UnknownAccountingBasis = 'UnknownAccountingBasis'
}

export type ActionItem = {
  author?: Maybe<EntityUser>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  title: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  value: ActionItemValue;
};

export enum ActionItemActionType {
  Approve = 'Approve',
  Pay = 'Pay',
  Review = 'Review',
  Submit = 'Submit',
  UnknownActionType = 'UnknownActionType'
}

export type ActionItemBillApproval = {
  bill: EntityBill;
};

export type ActionItemFilter = {
  assignedToOrganizationId?: InputMaybe<Scalars['String']['input']>;
  assignedToUserId?: InputMaybe<Scalars['String']['input']>;
  excludeFanouts?: InputMaybe<Scalars['Boolean']['input']>;
  excludeObjectKinds?: InputMaybe<Array<ObjectKind>>;
  includeMode?: InputMaybe<Array<ActionItemTargetMode>>;
  includeSuperseded?: InputMaybe<Scalars['Boolean']['input']>;
  status: Array<ActionItemStatus>;
};

export enum ActionItemKind {
  Bill = 'Bill',
  Comment = 'Comment',
  Onboarding = 'Onboarding',
  Report = 'Report',
  TransactionReview = 'TransactionReview',
  UnknownActionItemKind = 'UnknownActionItemKind',
  WorkItem = 'WorkItem'
}

export type ActionItemPublishedReport = {
  package: EntityReportPackage;
};

export enum ActionItemStatus {
  Closed = 'Closed',
  Completed = 'Completed',
  Created = 'Created',
  Unknown = 'Unknown'
}

export enum ActionItemTargetMode {
  Assign = 'Assign',
  UnknownTargetMode = 'UnknownTargetMode',
  Watch = 'Watch'
}

export type ActionItemThread = {
  targetEntity: ObjectEntity;
  threadId: Scalars['String']['output'];
};

export type ActionItemTransaction = {
  transaction: EntityTransaction;
};

export type ActionItemValue = ActionItemBillApproval | ActionItemPublishedReport | ActionItemThread | ActionItemTransaction;

/**
 * ActivityEvent represents one event in an ActivityFeed.
 * Note: The objects represented by subject, target and actor_user_id
 *       will be available externally to this message type.
 */
export type ActivityEvent = {
  computedEvent?: Maybe<ComputedEvent>;
  discreteEvent?: Maybe<DiscreteEvent>;
  id: Scalars['String']['output'];
  legalEntityId: Scalars['String']['output'];
  occurredAt: Scalars['DateTime']['output'];
};

export enum ActivityEventInterval {
  AllTime = 'AllTime',
  Day = 'Day',
  Hour = 'Hour',
  Minute = 'Minute',
  Month = 'Month',
  None = 'None',
  Week = 'Week',
  Year = 'Year'
}

/**
 * Activity Feed
 * ActivityFeed contains a sequence of ActivityEvents
 * and a separate structure describing the hydrated form
 * of the object entities referenced by the author/subject/target
 * of the feed's events.
 */
export type ActivityFeed = {
  entities: ObjectEntities;
  events: Array<ActivityEvent>;
};

export type ActivityObjectIdentifier = {
  context?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  kind: ObjectKind;
};

export type AddedFeature = {
  featureId: Scalars['String']['output'];
  invalid: Scalars['Boolean']['output'];
};

export type Address = {
  /** city is used for the city/town/village of the address */
  city?: Maybe<Scalars['String']['output']>;
  /** ISO country code */
  countryCode?: Maybe<Scalars['String']['output']>;
  /** Latitude */
  lat?: Maybe<Scalars['Float']['output']>;
  /** Longitude */
  long?: Maybe<Scalars['Float']['output']>;
  /** postal identifier for the address */
  postalCode?: Maybe<Scalars['String']['output']>;
  /** region is the state, region, or any other subdivision of the country */
  region?: Maybe<Scalars['String']['output']>;
  /** street contains the housing number and street name */
  street?: Maybe<Scalars['String']['output']>;
  /** street_additional is used for any other additional street identifying information */
  streetAdditional?: Maybe<Scalars['String']['output']>;
};

export type AddressFeatureValue = {
  addressValue: Address;
};

export type AddressInput = {
  city?: InputMaybe<Scalars['String']['input']>;
  countryCode?: InputMaybe<Scalars['String']['input']>;
  lat?: InputMaybe<Scalars['Float']['input']>;
  long?: InputMaybe<Scalars['Float']['input']>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  region?: InputMaybe<Scalars['String']['input']>;
  street?: InputMaybe<Scalars['String']['input']>;
  streetAdditional?: InputMaybe<Scalars['String']['input']>;
};

export type AdjacentObjectPermission = {
  legalEntity: EntityLegalEntity;
  object: ObjectIdentifier;
  organizationPreview: OrganizationPreview;
  viewType: ViewType;
};

export type AdminAllFactsDeleted = {
  allDeleted: Scalars['Boolean']['output'];
};

export type AdminApprovalEligibility = {
  legalEntityId: Scalars['String']['output'];
  statuses: Array<ApprovalEligibilityStatus>;
};

export type AdminCategory = {
  category: Category;
  chartOfAccountsDisplayNumber?: Maybe<Scalars['String']['output']>;
  externalAccountSubtype?: Maybe<Scalars['String']['output']>;
  externalAccountType?: Maybe<Scalars['String']['output']>;
  externalId?: Maybe<Scalars['String']['output']>;
  subtype?: Maybe<CategorySubtype>;
  synthesized: Scalars['Boolean']['output'];
};

export type AdminCategoryClassification = {
  category: Category;
  classification: AdminClassification;
  entryType: Scalars['String']['output'];
  transactionFactId: Scalars['String']['output'];
};

export type AdminChartOfAccounts = {
  chart: ChartOfAccounts;
  createdAt: Scalars['DateTime']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type AdminChartOfAccountsFilter = {
  accountingBasis?: InputMaybe<AccountingBasis>;
  global?: InputMaybe<Scalars['Boolean']['input']>;
  legalEntityId?: InputMaybe<Scalars['String']['input']>;
};

export type AdminClassification = {
  createdAt: Scalars['DateTime']['output'];
  legalEntityId: Scalars['String']['output'];
  rule?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  version: Scalars['String']['output'];
};

/** Connect */
export type AdminConnection = {
  connectorClassName?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  dataSourceId: Scalars['String']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  externalId?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  legalEntityId: Scalars['String']['output'];
  sourceName: Scalars['String']['output'];
  status: ConnectionStatus;
  sync?: Maybe<ConnectionSyncState>;
};

export type AdminConnectionEvent = {
  affectedCount?: Maybe<Scalars['Long']['output']>;
  connectionEventRef?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  dataStatus?: Maybe<Scalars['String']['output']>;
  dataUpdatedAt?: Maybe<Scalars['DateTime']['output']>;
  failureReason?: Maybe<ConnectionEventFailureReason>;
  id: Scalars['String']['output'];
  message?: Maybe<Scalars['String']['output']>;
  newestSeen?: Maybe<Scalars['Long']['output']>;
  oldestSeen?: Maybe<Scalars['Long']['output']>;
  source: ConnectionEventSource;
  status: ConnectionEventStatus;
  superUserId?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

export type AdminConnectionList = {
  connections: Array<AdminConnection>;
};

export type AdminDoppelgangerPermit = {
  authorizer: Superuser;
  doppelgangerPermit: DoppelgangerPermit;
  justification?: Maybe<Scalars['String']['output']>;
  sessionEndedAt?: Maybe<Scalars['DateTime']['output']>;
  sessionStartedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type AdminEmployee = {
  createdAt: Scalars['DateTime']['output'];
  employee: Employee;
  externalId?: Maybe<Scalars['String']['output']>;
  origin: EmployeeOrigin;
  provider?: Maybe<OAuthService>;
};

export type AdminGlobalParty = {
  aliases?: Maybe<Array<Scalars['String']['output']>>;
  lastReviewedAt?: Maybe<Scalars['DateTime']['output']>;
  party: Party;
  prefixes?: Maybe<Array<Scalars['String']['output']>>;
  transactionsCount: Scalars['Long']['output'];
};

export type AdminGlobalVanityStats = {
  activeLegalEntitiesCount: Scalars['Int']['output'];
  activeLegalEntitiesWithDataIssuesCount: Scalars['Int']['output'];
  activeLegalEntitiesWithPlaidConnectionIssuesCount: Scalars['Int']['output'];
  activeLegalEntitiesWithQuickBooksConnectionIssuesCount: Scalars['Int']['output'];
  activeUsersCount: Scalars['Int']['output'];
  appliedSignupsCount: Scalars['Int']['output'];
  approvedSignupsLast30Count: Scalars['Int']['output'];
  averageSignupAppliedAt?: Maybe<Scalars['DateTime']['output']>;
  averageSignupInvitedAt?: Maybe<Scalars['DateTime']['output']>;
  averageSignupPrimedAt?: Maybe<Scalars['DateTime']['output']>;
  averageSignupQualifiedAt?: Maybe<Scalars['DateTime']['output']>;
  dailyDesignationsCreatedLast30Count: Scalars['Int']['output'];
  dailyDesignationsModeratedLast30: Scalars['Int']['output'];
  dailyHydrationsCreatedLast30: Scalars['Int']['output'];
  dailyHydrationsModeratedLast30: Scalars['Int']['output'];
  dailyUnlinkedPartiesLast30: Scalars['Int']['output'];
  dayOfInterest?: Maybe<Scalars['DateTime']['output']>;
  globalPartiesCount: Scalars['Int']['output'];
  globalPartiesLast30Count: Scalars['Int']['output'];
  globalPartiesViaDesignationLast30Count: Scalars['Int']['output'];
  globalPartyTransactionsCount: Scalars['Int']['output'];
  hydratableUnlinkedPartiesCount: Scalars['Int']['output'];
  hydratableUnlinkedRepeatPartiesCount: Scalars['Int']['output'];
  invitedSignupsCount: Scalars['Int']['output'];
  lastSignupApprovedAt?: Maybe<Scalars['DateTime']['output']>;
  legalEntitiesWithDataIssuesCount: Scalars['Int']['output'];
  legalEntitiesWithEngineeringRequiredCount: Scalars['Int']['output'];
  legalEntitiesWithQuickBooksConnectionIssuesCount: Scalars['Int']['output'];
  /** Data Quality */
  legalEntitiesWithValidQuickBooksCount: Scalars['Int']['output'];
  linkedPartiesCount: Scalars['Int']['output'];
  linkedPartiesLast30Count: Scalars['Int']['output'];
  pendingLegalEntitiesCount: Scalars['Int']['output'];
  primedSignupsCount: Scalars['Int']['output'];
  qualifiedSignupsCount: Scalars['Int']['output'];
  /** Report Validation */
  reportValidations: Array<ReportValidationGlobalPeriodSummary>;
  /** Superseding Rate */
  supersedingRate: Scalars['Int']['output'];
  /** Profile Hydration Throughput */
  totalHydrationsProcessedCount: Scalars['Int']['output'];
  /** Legal Entities */
  totalLegalEntitiesCount: Scalars['Int']['output'];
  /** Parties */
  totalPartiesCount: Scalars['Int']['output'];
  /** Signups */
  totalSignupsCount: Scalars['Int']['output'];
  totalSignupsLast30Count: Scalars['Int']['output'];
  /** Transactions */
  totalTransactionsCount: Scalars['Int']['output'];
  totalTransactionsValue: MonetaryValue;
  totalUnmoderatedDesignationsCount: Scalars['Int']['output'];
  totalUnmoderatedHydrationsCount: Scalars['Int']['output'];
  /** Users */
  totalUsersCount: Scalars['Int']['output'];
  unlinkedPartiesCount: Scalars['Int']['output'];
  /** Designation Throughput */
  unlinkedPartiesLast30Count: Scalars['Int']['output'];
  unlinkedRepeatPartiesCount: Scalars['Int']['output'];
};

/** Institutions */
export type AdminInstitution = {
  id: Scalars['String']['output'];
  institution: Institution;
  party?: Maybe<Party>;
  products: Array<InstitutionProduct>;
};

/** Invitations */
export type AdminInvitation = {
  emailStatus?: Maybe<EmailStatus>;
  invitation: Invitation;
  senderSuperuser?: Maybe<Superuser>;
};

export type AdminLayout = {
  layout: Layout;
  layoutId: Scalars['String']['output'];
  layoutJson: Scalars['String']['output'];
  viewKey: ViewIdentifier;
};

export type AdminLayoutVersion = {
  authorId: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  layoutId: Scalars['String']['output'];
  versionId: Scalars['String']['output'];
  viewKey: ViewIdentifier;
};

/** Layouts */
export type AdminLayoutVersions = {
  layoutVersions: Array<AdminLayoutVersion>;
};

export type AdminLegalEntity = {
  allDataSourcesHaveTransactions: Scalars['Boolean']['output'];
  allDataSourcesLinked: Scalars['Boolean']['output'];
  anyDataSourceRequiresEngineering: Scalars['Boolean']['output'];
  approvalEligibilityStatuses: Array<ApprovalEligibilityStatus>;
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  hasPlaidDataSource: Scalars['Boolean']['output'];
  legalEntity: LegalEntity;
  reportValidationHistory: Array<ReportKindValidationPeriodHistory>;
  userCount: Scalars['Long']['output'];
  validationStatus: ValidationHealthStatus;
};

export type AdminOrganization = {
  affiliateLegalEntities: Array<AdminLegalEntity>;
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  legalEntities: Array<AdminLegalEntity>;
  organization: Organization;
  userCount: Scalars['Long']['output'];
};

/** Parties */
export type AdminParty = {
  lastTransactionOccurredAt?: Maybe<Scalars['DateTime']['output']>;
  party: Party;
  transactionsCount: Scalars['Long']['output'];
};

export type AdminPartyAndRoleBalanceFact = {
  balance: MonetaryValue;
  balanceAt: Scalars['DateTime']['output'];
  createdAt: Scalars['DateTime']['output'];
  legalEntityId: Scalars['String']['output'];
  partyId: Scalars['String']['output'];
  partyRole: PartyRole;
  userId: Scalars['String']['output'];
};

export type AdminPartyClassification = {
  classification: AdminClassification;
  overlayName?: Maybe<Scalars['String']['output']>;
  party: Party;
  prefix?: Maybe<Scalars['String']['output']>;
  prefixDescription?: Maybe<Scalars['String']['output']>;
  role: PartyRole;
  viewPartyName?: Maybe<Scalars['String']['output']>;
  viewPartyReason?: Maybe<Scalars['String']['output']>;
};

export type AdminPartyExtraction = {
  legalEntityId: Scalars['String']['output'];
  party?: Maybe<Party>;
  partyExtractionId: Scalars['String']['output'];
  partyExtractionKey: Scalars['String']['output'];
  transactionFactId?: Maybe<Scalars['String']['output']>;
};

export type AdminReportPackageDocumentMetadata = {
  documentObjectId: Scalars['String']['output'];
  documentObjectVersionId: Scalars['String']['output'];
  generationHistory?: Maybe<ReportGenerationHistory>;
  id: Scalars['String']['output'];
  position: Scalars['Int']['output'];
  reportFile?: Maybe<ReportFileMetadata>;
  status: ReportPackageDocumentStatus;
  title: Scalars['String']['output'];
  type: ReportPackageDocumentType;
  validationHistories: Array<ReportValidationHistory>;
};

export type AdminReportPackageMetadata = {
  author?: Maybe<Contact>;
  coverUrl?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  documents: Array<AdminReportPackageDocumentMetadata>;
  endedAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  interval: Interval;
  publishedAsDraft: Scalars['Boolean']['output'];
  startedAt: Scalars['DateTime']['output'];
  status: ReportPackageStatus;
  title: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  versionId: Scalars['String']['output'];
  viewKey: ViewIdentifier;
};

/** Signups */
export type AdminSignup = {
  createdAt: Scalars['DateTime']['output'];
  signup: Signup;
  timezone?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type AdminTransactionEntityExtraction = {
  classificationVersionId: Scalars['String']['output'];
  creditCard?: Maybe<Scalars['String']['output']>;
  creditCardScore?: Maybe<Scalars['Long']['output']>;
  date?: Maybe<Scalars['String']['output']>;
  dateScore?: Maybe<Scalars['Long']['output']>;
  institution?: Maybe<Scalars['String']['output']>;
  institutionScore?: Maybe<Scalars['Long']['output']>;
  location?: Maybe<Scalars['String']['output']>;
  locationScore?: Maybe<Scalars['Long']['output']>;
  party: Scalars['String']['output'];
  partyScore: Scalars['Long']['output'];
  person?: Maybe<Scalars['String']['output']>;
  personScore?: Maybe<Scalars['Long']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  phoneScore?: Maybe<Scalars['Long']['output']>;
  proxy?: Maybe<Scalars['String']['output']>;
  proxyScore?: Maybe<Scalars['Long']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  url?: Maybe<Scalars['String']['output']>;
  urlScore?: Maybe<Scalars['Long']['output']>;
};

export type AdminTransactionFact = {
  createdAt: Scalars['DateTime']['output'];
  dataSource: EnrichedDataSource;
  externalCreatedAt?: Maybe<Scalars['DateTime']['output']>;
  externalDescription?: Maybe<Scalars['String']['output']>;
  externalId: Scalars['String']['output'];
  externalModifiedAt?: Maybe<Scalars['DateTime']['output']>;
  externalName: Scalars['String']['output'];
  externalTimestamp: Scalars['DateTime']['output'];
  legalEntityId: Scalars['String']['output'];
  pending: Scalars['Boolean']['output'];
  sourceAccount?: Maybe<SourceAccount>;
  transactionFactId: Scalars['String']['output'];
  transactionType: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type AdminTransactionPair = {
  ledgerTransactionId: Scalars['String']['output'];
  transactionFactId: Scalars['String']['output'];
};

export type AdminUnmatchedSourceTransaction = {
  id: Scalars['String']['output'];
  rawTransactionDescription: Scalars['String']['output'];
  transaction: Transaction;
  transactionDetails: Array<TransactionViewDetail>;
};

export type AdminUser = {
  accountLocked: Scalars['Boolean']['output'];
  affiliations?: Maybe<Array<AdminUserAffiliation>>;
  employments?: Maybe<Array<AdminUserEmployment>>;
  invitationId?: Maybe<Scalars['String']['output']>;
  timezone?: Maybe<Scalars['String']['output']>;
  user: User;
};

export type AdminUserAffiliation = {
  affiliatedTo?: Maybe<LegalEntity>;
  role: Role;
  userOrganization?: Maybe<Organization>;
};

export type AdminUserEmployment = {
  activatedAt?: Maybe<Scalars['DateTime']['output']>;
  employeeId: Scalars['String']['output'];
  organization: Organization;
  role: Role;
  status: EmployeeStatus;
};

/** Vanity */
export type AdminVanityStats = {
  categoriesCount: Scalars['Int']['output'];
  linkedPartiesCount: Scalars['Int']['output'];
  partiesCount: Scalars['Int']['output'];
  totalCalculations: Scalars['Int']['output'];
  totalValue: MonetaryValue;
  transactionsCount: Scalars['Int']['output'];
  usersCount: Scalars['Int']['output'];
};

export type AdminWorkItem = {
  actionable: Scalars['Boolean']['output'];
  createdAt: Scalars['DateTime']['output'];
  defaultState: WorkItemState;
  generatedQuestions: Array<WorkItemGeneratedQuestion>;
  itemState: WorkItemState;
  itemType: WorkItemType;
  ledgerType: LedgerType;
  legalEntityId: Scalars['String']['output'];
  pushes: Array<WorkItemPush>;
  time: Scalars['DateTime']['output'];
  /** Admin-specific fields. */
  transactionFactId?: Maybe<Scalars['String']['output']>;
  transactionGcsUrl?: Maybe<Scalars['String']['output']>;
  transactions: Array<AdminWorkItemTransaction>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** TODO: Hydrate user. */
  updatedBy?: Maybe<Scalars['String']['output']>;
  userState?: Maybe<WorkItemState>;
  workItemId: Scalars['String']['output'];
};

/** NOTE: This type is not allowed to return amounts because it is used in sensitive areas. */
export type AdminWorkItemTransaction = {
  creditCategory?: Maybe<EntityCategory>;
  dataSourceId?: Maybe<Scalars['String']['output']>;
  debitCategory?: Maybe<EntityCategory>;
  description?: Maybe<Scalars['String']['output']>;
  displayCategory?: Maybe<EntityCategory>;
  /** NOTE: This type is not allowed to return amounts because it is used in sensitive areas. */
  displayEntry: DisplayEntry;
  externalTransactionId: Scalars['String']['output'];
  externalTransactionLineId?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  occurredAt?: Maybe<Scalars['DateTime']['output']>;
  party?: Maybe<EntityParty>;
  quickbooksAppName?: Maybe<Scalars['String']['output']>;
  supportType: WorkItemSupportType;
};

export type AffiliateOrganization = {
  affiliatedUsers: Array<AffiliateUser>;
  organization: Organization;
  type: AffiliateType;
};

export type AffiliateOrganizations = {
  inviteProposals: Array<InvitationProposal>;
  organizations: Array<AffiliateOrganization>;
};

export enum AffiliateType {
  Accounting = 'Accounting',
  Investment = 'Investment'
}

export type AffiliateUser = {
  role: Role;
  user: User;
};

export type Affiliation = {
  legalEntity: EntityLegalEntity;
  organization: EntityOrganization;
  role: Role;
};

export type AgentEvent = {
  message?: Maybe<Scalars['String']['output']>;
  runStatus: AgentEventStatus;
};

export enum AgentEventStatus {
  Completed = 'Completed',
  InProgress = 'InProgress',
  Queued = 'Queued',
  RequiresAction = 'RequiresAction',
  UnknownRunStatus = 'UnknownRunStatus'
}

export type AmountRuleFilter = {
  amount: MonetaryValue;
  amountRuleFilterId: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  operator: ComparisonOperator;
};

export type ApprovalEligibility = {
  legalEntityId: Scalars['String']['output'];
  status: ApprovalEligibilityStatus;
};

export enum ApprovalEligibilityStatus {
  BelowRequiredTransactionCount = 'BelowRequiredTransactionCount',
  DataValidationHealthNotComputed = 'DataValidationHealthNotComputed',
  DataValidationUnhealthy = 'DataValidationUnhealthy',
  /** Error statuses */
  Error = 'Error',
  InvalidQBOLink = 'InvalidQBOLink',
  LedgerViewError = 'LedgerViewError',
  /** Pending i.e. still computing */
  LedgerViewNotComputed = 'LedgerViewNotComputed',
  NoTransactionCount = 'NoTransactionCount',
  Ready = 'Ready',
  ReportValidationNotComputed = 'ReportValidationNotComputed',
  ReportValidationUnhealthy = 'ReportValidationUnhealthy',
  Unknown = 'Unknown'
}

/**
 * TYPES
 *
 */
export type ArchiveIntervalOrigin = {
  index: Scalars['Long']['output'];
  interval: Interval;
  intervalCount?: Maybe<Scalars['Long']['output']>;
  year: Scalars['Long']['output'];
};

export type ArchivedComponent = {
  data: ComponentData;
  viewId: ViewIdentifier;
};

export enum ArrivalContext {
  ReportsBuilder = 'ReportsBuilder',
  TransactionReview = 'TransactionReview'
}

export type Aspect = {
  code: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  defaultStatus: AspectStatusValue;
  description: Scalars['String']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type AspectDataHealth = {
  closedViewDataStatus: HealthStatus;
  importedDataStatus: HealthStatus;
  overallDataStatus: HealthStatus;
};

export type AspectRequest = {
  aspect: Aspect;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  legalEntityId: Scalars['String']['output'];
  legalEntityName: Scalars['String']['output'];
};

export type AspectRequestMeta = {
  createdAt: Scalars['DateTime']['output'];
  requestor: EntityUser;
};

export type AspectStatus = {
  aspect: Aspect;
  status: AspectStatusValue;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export enum AspectStatusValue {
  Disabled = 'Disabled',
  Enabled = 'Enabled'
}

export type Assignee = {
  organization?: InputMaybe<OrganizationAssignee>;
  user?: InputMaybe<UserAssignee>;
};

export type AssistantEvent = {
  comment: Comment;
  kind: AssistantEventKind;
  layout?: Maybe<Layout>;
  suggestions?: Maybe<Array<Scalars['String']['output']>>;
  threadId?: Maybe<Scalars['String']['output']>;
};

export enum AssistantEventKind {
  Complete = 'Complete',
  Error = 'Error',
  Unknown = 'Unknown',
  Update = 'Update'
}

export enum AssociationSource {
  Onboarding = 'Onboarding',
  UnknownSource = 'UnknownSource'
}

export enum AsyncProcessingStatus {
  AsyncProcessingDone = 'AsyncProcessingDone',
  AsyncProcessingError = 'AsyncProcessingError',
  AsyncProcessingStarted = 'AsyncProcessingStarted',
  UnknownAsyncProcessingStatus = 'UnknownAsyncProcessingStatus'
}

export type AuditRule = {
  action: AuditRuleAction;
  amountFilters?: Maybe<Array<AmountRuleFilter>>;
  auditRuleId: Scalars['String']['output'];
  categoryFilters?: Maybe<Array<CategoryRuleFilter>>;
  description?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  partyFilters?: Maybe<Array<PartyRuleFilter>>;
  ruleState: AuditRuleState;
  type: AuditRuleType;
};

export enum AuditRuleAction {
  CreateBoost = 'CreateBoost',
  SendClientEmail = 'SendClientEmail',
  SendSelfEmail = 'SendSelfEmail',
  UnknownAction = 'UnknownAction'
}

export enum AuditRuleState {
  Disabled = 'Disabled',
  Enabled = 'Enabled',
  UnknownState = 'UnknownState'
}

export type AuditRuleStatus = {
  recentlyUpdated: Scalars['Boolean']['output'];
  type: AuditRuleType;
  updaterType: AuditRuleUpdaterType;
};

export enum AuditRuleType {
  CategorySuggestion = 'CategorySuggestion',
  InParentCategory = 'InParentCategory',
  NewTransaction = 'NewTransaction',
  SensitiveDataSuggestion = 'SensitiveDataSuggestion',
  UnassignedCategorySuggestion = 'UnassignedCategorySuggestion',
  UnassignedVendorSuggestion = 'UnassignedVendorSuggestion',
  UnknownType = 'UnknownType',
  VendorSuggestion = 'VendorSuggestion'
}

export enum AuditRuleUpdaterType {
  System = 'System',
  UnknownUpdaterType = 'UnknownUpdaterType',
  User = 'User'
}

export type BadgeCount = {
  count: Scalars['Long']['output'];
};

export type BalanceSummary = {
  period: Period;
  total: PeriodMonetaryValue;
};

export type BankAccount = {
  accountIdMask: Scalars['String']['output'];
  iconObjectName?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  institutionName: Scalars['String']['output'];
  isAutoPayout: Scalars['Boolean']['output'];
  isDefault: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  nickname: Scalars['String']['output'];
};

export type BankFeedEntry = {
  amount: InputMonetaryValue;
  memo: Scalars['String']['input'];
  timestamp: Scalars['DateTime']['input'];
};

export type BillAccess = {
  objectId: Scalars['String']['output'];
  objectKind: BillAccessKind;
  role: BillAccessRole;
};

export enum BillAccessKind {
  UnknownBillAccessKind = 'UnknownBillAccessKind',
  User = 'User'
}

export enum BillAccessRole {
  Approver = 'Approver',
  Payer = 'Payer',
  UnknownBillAccessRole = 'UnknownBillAccessRole',
  Uploader = 'Uploader'
}

export enum BillStatus {
  Approved = 'Approved',
  Assigned = 'Assigned',
  New = 'New',
  PaymentCreated = 'PaymentCreated',
  Rejected = 'Rejected',
  UnknownBillStatus = 'UnknownBillStatus'
}

export type BillingCardDetails = {
  brand: Scalars['String']['output'];
  country: Scalars['String']['output'];
  expMonth: Scalars['Int']['output'];
  expYear: Scalars['Int']['output'];
  last4: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type BillingCustomerProfile = {
  cardDetails: BillingCardDetails;
  subscription: BillingSubscription;
};

export type BillingLegalEntityLimit = {
  id: Scalars['String']['output'];
  maxLegalEntityLimit?: Maybe<Scalars['Int']['output']>;
  minLegalEntityLimit: Scalars['Int']['output'];
};

export enum BillingPaymentIntentStatus {
  Cancelled = 'Cancelled',
  Failed = 'Failed',
  Paid = 'Paid',
  UnknownStatus = 'UnknownStatus',
  Upcoming = 'Upcoming'
}

export type BillingPlan = {
  legalEntityLimit?: Maybe<BillingLegalEntityLimit>;
  price: BillingPrice;
  product: BillingProduct;
};

export type BillingPrice = {
  amount: MonetaryValue;
  id: Scalars['String']['output'];
  interval: Interval;
  intervalCount?: Maybe<Scalars['Int']['output']>;
};

export type BillingProduct = {
  description?: Maybe<Scalars['String']['output']>;
  displayName: Scalars['String']['output'];
  id: Scalars['String']['output'];
  name: BillingProductName;
  trialPeriodDays?: Maybe<Scalars['Int']['output']>;
};

export enum BillingProductName {
  AIBookkeeping = 'AIBookkeeping',
  FounderFinance = 'FounderFinance',
  Free = 'Free',
  FreeForever = 'FreeForever',
  Growth = 'Growth',
  Premium = 'Premium',
  UnknownName = 'UnknownName'
}

export type BillingSubscription = {
  id: Scalars['String']['output'];
  periodEnd: Scalars['DateTime']['output'];
  periodStart: Scalars['DateTime']['output'];
  status: Scalars['String']['output'];
};

export type BillingSubscriptionHistory = {
  amount: MonetaryValue;
  createdAt: Scalars['DateTime']['output'];
  interval: Scalars['String']['output'];
  paymentIntentStatus: BillingPaymentIntentStatus;
  productName: BillingProductName;
};

export type BillingSubscriptionWithoutStatus = {
  id: Scalars['String']['output'];
  periodEnd: Scalars['DateTime']['output'];
  periodStart: Scalars['DateTime']['output'];
};

export type BillingTrial = {
  end: Scalars['DateTime']['output'];
  start: Scalars['DateTime']['output'];
};

export type BooksClosedDate = {
  latestBooksClosedDateFact?: Maybe<Scalars['DateTime']['output']>;
  legalEntityId: Scalars['String']['output'];
  user?: Maybe<EntityUser>;
  viewBooksClosedDate?: Maybe<Scalars['DateTime']['output']>;
};

export type BooksClosedFact = {
  booksClosedDate: Scalars['DateTime']['output'];
  createdAt: Scalars['DateTime']['output'];
  dataSourceId?: Maybe<Scalars['String']['output']>;
  legalEntityId: Scalars['String']['output'];
  superUserId?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

export type BrokenConnection = {
  connection: AdminConnection;
  dataUpdatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export enum CanonicalCategoryType {
  Cash = 'Cash',
  CashNoClearing = 'CashNoClearing',
  Unknown = 'Unknown'
}

export type CashSummary = {
  cashChange: DimensionSummary;
  cashTotal: DimensionSummary;
};

export type CashSummaryChartConfig = {
  origin: ArchiveIntervalOrigin;
};

export type CashSummaryChartConfigInput = {
  origin: IntervalOrigin;
};

/** Categories */
export type Category = {
  active: Scalars['Boolean']['output'];
  ancestors?: Maybe<Array<EntityCategory>>;
  categoryState: CategoryState;
  description: Scalars['String']['output'];
  displayKey: Scalars['String']['output'];
  displayNumber?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  parentCategoryId?: Maybe<Scalars['String']['output']>;
  productArea?: Maybe<ProductArea>;
  subtype: CategorySubtype;
  synthesized?: Maybe<Scalars['Boolean']['output']>;
  type: CategoryType;
};

/** Balances */
export type CategoryBalanceFilter = {
  categoryId?: InputMaybe<Scalars['String']['input']>;
  categoryType?: InputMaybe<CategoryType>;
  includeCategoryChildren?: InputMaybe<Scalars['Boolean']['input']>;
  legalEntityId: Scalars['String']['input'];
  productArea?: InputMaybe<ProductArea>;
  viewVersion?: InputMaybe<Scalars['String']['input']>;
};

export type CategoryChartConfig = {
  asPermanent?: Maybe<Scalars['Boolean']['output']>;
  categoryId: Scalars['String']['output'];
  origin: ArchiveIntervalOrigin;
  withoutCategoryAncestors?: Maybe<Scalars['Boolean']['output']>;
};

export type CategoryChartConfigInput = {
  asPermanent?: InputMaybe<Scalars['Boolean']['input']>;
  categoryId: Scalars['String']['input'];
  origin: IntervalOrigin;
  withoutCategoryAncestors?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CategoryDimension = {
  by: DimensionSummary;
  categoryId: Scalars['String']['output'];
  categoryObject: EntityCategory;
  summary: TransactionSummary;
};

export type CategoryHover = {
  balance?: Maybe<Scalars['Boolean']['output']>;
  context?: Maybe<DimensionSummary>;
  entity: EntityCategory;
  history: DimensionSummary;
  topTransactions?: Maybe<Array<EntityTransaction>>;
};

/** Inputs */
export type CategoryLabelInput = {
  categoryId: Scalars['String']['input'];
  label: ConnectLabel;
};

/** Category Mappings */
export type CategoryMapping = {
  category: EntityCategory;
  mappingType: CategoryToLabelMappingType;
  mappingValue: Scalars['String']['output'];
  product: CategoryToLabelMappingProduct;
};

export type CategoryParams = {
  categoryIcon?: InputMaybe<Scalars['String']['input']>;
  categoryId: Scalars['String']['input'];
  categoryName: Scalars['String']['input'];
};

export type CategoryRef = {
  id: Scalars['String']['output'];
};

export type CategoryRuleFilter = {
  categories: Array<CategoryRuleFilterCategory>;
  categoryRuleFilterId: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  operation: Operation;
};

export type CategoryRuleFilterCategory = {
  categoryId: Scalars['String']['output'];
  categoryName: Scalars['String']['output'];
};

export enum CategoryState {
  CategoryActive = 'CategoryActive',
  CategoryDisabled = 'CategoryDisabled',
  CategoryInactive = 'CategoryInactive',
  UnknownCategoryState = 'UnknownCategoryState'
}

export enum CategorySubtype {
  AccountsPayable = 'AccountsPayable',
  AccountsReceivable = 'AccountsReceivable',
  AccruedExpenses = 'AccruedExpenses',
  AccruedRevenue = 'AccruedRevenue',
  AdditionalPaidInCapital = 'AdditionalPaidInCapital',
  AssetClearing = 'AssetClearing',
  BadDebtExpense = 'BadDebtExpense',
  BankAccounts = 'BankAccounts',
  BusinessApplicationsAndSoftware = 'BusinessApplicationsAndSoftware',
  CommonStock = 'CommonStock',
  ConvertibleNotes = 'ConvertibleNotes',
  CostOfGoods = 'CostOfGoods',
  CostOfLabor = 'CostOfLabor',
  CreditCardRewards = 'CreditCardRewards',
  CreditCards = 'CreditCards',
  DeferredRevenue = 'DeferredRevenue',
  DepreciationAndAmortization = 'DepreciationAndAmortization',
  Facilities = 'Facilities',
  FixedAssets = 'FixedAssets',
  GeneralOperations = 'GeneralOperations',
  Goodwill = 'Goodwill',
  HostingFees = 'HostingFees',
  IntangibleAssets = 'IntangibleAssets',
  InterestAndDividends = 'InterestAndDividends',
  InterestExpense = 'InterestExpense',
  Inventory = 'Inventory',
  Investments = 'Investments',
  LiabilityClearing = 'LiabilityClearing',
  LongTermDebt = 'LongTermDebt',
  None = 'None',
  Other = 'Other',
  OtherComprehensiveIncome = 'OtherComprehensiveIncome',
  OtherCostOfRevenue = 'OtherCostOfRevenue',
  OtherCurrentAssets = 'OtherCurrentAssets',
  OtherCurrentLiabilities = 'OtherCurrentLiabilities',
  OtherLongTermAssets = 'OtherLongTermAssets',
  OtherLongTermLiabilities = 'OtherLongTermLiabilities',
  OtherMiscellaneousExpenses = 'OtherMiscellaneousExpenses',
  OtherMiscellaneousIncome = 'OtherMiscellaneousIncome',
  OwnersEquity = 'OwnersEquity',
  PartnerRevenue = 'PartnerRevenue',
  PaymentProcessingFees = 'PaymentProcessingFees',
  Payroll = 'Payroll',
  PayrollExpenses = 'PayrollExpenses',
  Pending = 'Pending',
  PreferredStock = 'PreferredStock',
  PrepaidExpenses = 'PrepaidExpenses',
  ProfessionalFees = 'ProfessionalFees',
  RefundsAndDiscounts = 'RefundsAndDiscounts',
  RetainedEarnings = 'RetainedEarnings',
  Safes = 'Safes',
  SalesAndMarketing = 'SalesAndMarketing',
  SalesRevenue = 'SalesRevenue',
  ShareholderLoans = 'ShareholderLoans',
  ShortTermDebt = 'ShortTermDebt',
  SubscriptionRevenue = 'SubscriptionRevenue',
  SuppliesAndMaterials = 'SuppliesAndMaterials',
  Taxes = 'Taxes',
  TransactionRevenue = 'TransactionRevenue',
  TravelAndEntertainment = 'TravelAndEntertainment',
  TreasuryStock = 'TreasuryStock',
  UncategorizedAssets = 'UncategorizedAssets',
  UncategorizedEquity = 'UncategorizedEquity',
  UncategorizedExpense = 'UncategorizedExpense',
  UncategorizedIncome = 'UncategorizedIncome',
  /** Current values */
  UnknownSubtype = 'UnknownSubtype'
}

/** Category Suggestions */
export type CategorySuggestion = {
  categoryId: Scalars['String']['output'];
  classificationVersionId: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  dataSourceId: Scalars['String']['output'];
  modelConfidence?: Maybe<Scalars['Long']['output']>;
  modelVersionId?: Maybe<Scalars['String']['output']>;
  priority: Scalars['Long']['output'];
  transactionFactId: Scalars['String']['output'];
  type: EntryType;
  updatedAt: Scalars['DateTime']['output'];
};

export enum CategoryToLabelMappingLabel {
  AccountsPayable = 'AccountsPayable',
  AccountsReceivable = 'AccountsReceivable',
  CashAccount = 'CashAccount',
  ClearingAccount = 'ClearingAccount',
  ContraRevenue = 'ContraRevenue',
  Fees = 'Fees',
  SalesRevenue = 'SalesRevenue',
  UncategorizedExpenses = 'UncategorizedExpenses',
  UnknownLabel = 'UnknownLabel'
}

export enum CategoryToLabelMappingProduct {
  DigitsPayAP = 'DigitsPayAP',
  DigitsPayAR = 'DigitsPayAR',
  UnknownProduct = 'UnknownProduct'
}

export enum CategoryToLabelMappingType {
  CategoryLabel = 'CategoryLabel',
  PaymentInstrument = 'PaymentInstrument',
  UnknownType = 'UnknownType'
}

export enum CategoryType {
  Assets = 'Assets',
  CostOfGoodsSold = 'CostOfGoodsSold',
  Equity = 'Equity',
  Expenses = 'Expenses',
  Income = 'Income',
  Liabilities = 'Liabilities',
  OtherExpenses = 'OtherExpenses',
  OtherIncome = 'OtherIncome',
  UnknownType = 'UnknownType'
}

export type CategoryTypeDimension = {
  by: DimensionSummary;
  summary: TransactionSummary;
};

export type Chart = {
  entity: ObjectEntity;
  summary: DimensionSummary;
};

/** Chart of Accounts */
export type ChartOfAccounts = {
  accountingBasis: AccountingBasis;
  categories: Array<Category>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type ChartOfAccountsListEntry = {
  accountingBasis: AccountingBasis;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  legalEntityId?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
};

export type CheckBillingPlan = {
  billingPlan?: Maybe<BillingPlan>;
  exists: Scalars['Boolean']['output'];
  trial?: Maybe<BillingTrial>;
};

export enum ClassificationSource {
  Digits = 'Digits',
  Manual = 'Manual',
  None = 'None',
  QuickBooks = 'QuickBooks'
}

export type ClassificationValidation = {
  creditCategoryName?: Maybe<Scalars['String']['output']>;
  debitCategoryName?: Maybe<Scalars['String']['output']>;
  employeeOwnerName?: Maybe<Scalars['String']['output']>;
  expectationId?: Maybe<Scalars['String']['output']>;
  facts: Array<ClassificationValidationExpectationFact>;
  legalEntityId: Scalars['String']['output'];
  partyIconUrl?: Maybe<Scalars['String']['output']>;
  partyName?: Maybe<Scalars['String']['output']>;
  partyRole?: Maybe<PartyRole>;
  status: ExpectationStatus;
};

export type ClassificationValidationExpectationFact = {
  dataSourceId: Scalars['String']['output'];
  transactionFactId: Scalars['String']['output'];
};

export type ClassificationValidationFailure = {
  expectation: ClassificationValidation;
  failureDetails: Array<ClassificationValidationFailureDetail>;
};

export type ClassificationValidationFailureDetail = {
  actualValue?: Maybe<Scalars['String']['output']>;
  expectationType?: Maybe<ExpectationType>;
  expectedValue?: Maybe<Scalars['String']['output']>;
};

export type ClassificationValidationSummary = {
  numberOfLegalEntities?: Maybe<Scalars['Long']['output']>;
  passingValidations?: Maybe<Scalars['Long']['output']>;
  totalValidations?: Maybe<Scalars['Long']['output']>;
};

export type ClosedWorkItem = {
  ignoredFields: Array<WorkItemPushFieldType>;
  workItemId: Scalars['String']['input'];
};

export type ClusteredPrefix = {
  prefix: Scalars['String']['output'];
  transactionCount: Scalars['Long']['output'];
};

export type CollapsedSections = {
  sections: Array<Scalars['String']['output']>;
};

export type CollapsedSectionsInput = {
  sections: Array<Scalars['String']['input']>;
};

/**
 * Comment represents a single thread comment, with additional
 * properties resolved by the backend.
 */
export type Comment = {
  authorId: Scalars['String']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  editedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['String']['output'];
  layoutAttachment?: Maybe<LayoutAttachment>;
  reactions?: Maybe<Array<CommentReaction>>;
  text: Scalars['String']['output'];
  timestamp: Scalars['DateTime']['output'];
};

export enum CommentReaction {
  Dislike = 'Dislike',
  Like = 'Like',
  UnknownReaction = 'UnknownReaction'
}

export type CommentWithEntities = {
  comment: Comment;
  entities?: Maybe<ObjectEntities>;
};

export type CompanyDetails = {
  address?: Maybe<Address>;
  categories?: Maybe<Array<Scalars['String']['output']>>;
  companyName: Scalars['String']['output'];
  companySize?: Maybe<Scalars['String']['output']>;
  entityType?: Maybe<Scalars['String']['output']>;
  foreignSubsidiaryCount?: Maybe<Scalars['String']['output']>;
  incorporationState?: Maybe<Scalars['String']['output']>;
  incorporationYear?: Maybe<Scalars['String']['output']>;
  lastTaxFiling?: Maybe<Scalars['String']['output']>;
  subsidiaryCount?: Maybe<Scalars['String']['output']>;
  websiteURL?: Maybe<Scalars['String']['output']>;
};

export type CompanyDetailsInput = {
  address?: InputMaybe<AddressInput>;
  categories?: InputMaybe<Array<Scalars['String']['input']>>;
  companyName: Scalars['String']['input'];
  companySize?: InputMaybe<Scalars['String']['input']>;
  entityType?: InputMaybe<Scalars['String']['input']>;
  foreignSubsidiaryCount?: InputMaybe<Scalars['String']['input']>;
  incorporationState?: InputMaybe<Scalars['String']['input']>;
  incorporationYear?: InputMaybe<Scalars['String']['input']>;
  lastTaxFiling?: InputMaybe<Scalars['String']['input']>;
  subsidiaryCount?: InputMaybe<Scalars['String']['input']>;
  websiteURL?: InputMaybe<Scalars['String']['input']>;
};

export type CompanyFinancialValue = {
  institutionId?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
};

export type CompanyFinancialValueInput = {
  institutionId?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

export type CompanyFinancials = {
  product: InstitutionProduct;
  values: Array<CompanyFinancialValue>;
};

export type CompanyFinancialsInput = {
  product: InstitutionProduct;
  values: Array<CompanyFinancialValueInput>;
};

export enum ComparisonOperator {
  EqualTo = 'EqualTo',
  GreaterThan = 'GreaterThan',
  LessThan = 'LessThan',
  UnknownComparisonOperator = 'UnknownComparisonOperator'
}

export enum CompileSource {
  SpeedLayer = 'SpeedLayer',
  UnknownCompileSource = 'UnknownCompileSource',
  ViewGeneration = 'ViewGeneration',
  ViewGenerationIncomplete = 'ViewGenerationIncomplete'
}

export type ComponentData = CashSummary | Chart | DimensionSummary | EntitiesList | EntityHovers | InsightComponent | LocationSummary | Runway | Statement | TopEntities | TopTransactions;

/**
 * ComputedEvent represents batches of activity feed events
 * that are counted over a given interval
 */
export type ComputedEvent = {
  eventInterval: ActivityEventInterval;
  kind: ComputedEventKind;
  priority?: Maybe<Scalars['Float']['output']>;
  value: Scalars['Float']['output'];
};

export enum ComputedEventKind {
  AssetInsights = 'AssetInsights',
  ClassifiedCategory = 'ClassifiedCategory',
  ClassifiedCustomer = 'ClassifiedCustomer',
  ClassifiedSupplier = 'ClassifiedSupplier',
  ClassifiedVendor = 'ClassifiedVendor',
  EquityInsights = 'EquityInsights',
  ExpenseInsights = 'ExpenseInsights',
  LiabilityInsights = 'LiabilityInsights',
  MatchedTransactions = 'MatchedTransactions',
  NewRecurringTransactions = 'NewRecurringTransactions',
  NewTransactions = 'NewTransactions',
  NewVendors = 'NewVendors',
  PerformedCalculations = 'PerformedCalculations',
  RevenueInsights = 'RevenueInsights',
  UpdatedVendors = 'UpdatedVendors',
  VendorTransactions = 'VendorTransactions'
}

export enum ConnectLabel {
  Adjustment = 'Adjustment',
  /** Account types */
  Bank = 'Bank',
  BenefitsExpense = 'BenefitsExpense',
  BenefitsPayable = 'BenefitsPayable',
  Clearing = 'Clearing',
  CreditCard = 'CreditCard',
  Deduction = 'Deduction',
  DigitsAI = 'DigitsAI',
  Fees = 'Fees',
  Investment = 'Investment',
  Loan = 'Loan',
  Reimbursement = 'Reimbursement',
  Sales = 'Sales',
  Settlement = 'Settlement',
  TaxExpense = 'TaxExpense',
  UncategorizedExpense = 'UncategorizedExpense',
  Unspecified = 'Unspecified',
  Wages = 'Wages'
}

export type Connection = {
  accounts?: Maybe<Array<ConnectionAccount>>;
  connector?: Maybe<Connector>;
  connectorClassName?: Maybe<Scalars['String']['output']>;
  dataSourceId: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  sourceIconURL?: Maybe<Scalars['String']['output']>;
  sourceName: Scalars['String']['output'];
  /** stripe, plaid */
  status: ConnectionStatus;
  sync?: Maybe<ConnectionSyncState>;
};

export type ConnectionAccount = {
  connectionId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  mask?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  settings?: Maybe<ConnectionAccountSettings>;
  sourceAccountId: Scalars['String']['output'];
};

export type ConnectionAccountInput = {
  connectionAccountId: Scalars['String']['input'];
  settings: ConnectionAccountSettingsInput;
};

export type ConnectionAccountSettings = {
  cutoverDate?: Maybe<Scalars['DateTime']['output']>;
  enabled: Scalars['Boolean']['output'];
  labelCategories?: Maybe<Array<LabelCategory>>;
};

export type ConnectionAccountSettingsInput = {
  categoryLabels?: InputMaybe<Array<CategoryLabelInput>>;
  cutoverDate?: InputMaybe<Scalars['DateTime']['input']>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ConnectionAccountSummary = {
  connectionId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  mask?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  sourceAccountId: Scalars['String']['output'];
};

export type ConnectionBadgeCountFilter = {
  connectionStatuses?: InputMaybe<Array<ConnectionStatus>>;
  legalEntityIds: Array<Scalars['String']['input']>;
  syncStatuses?: InputMaybe<Array<ConnectionSyncStatus>>;
};

export enum ConnectionEventFailureReason {
  AuthenticationError = 'AuthenticationError',
  InternalError = 'InternalError',
  RemoteError = 'RemoteError'
}

export enum ConnectionEventSource {
  API = 'API',
  Admin = 'Admin',
  Runner = 'Runner',
  Scheduler = 'Scheduler',
  User = 'User'
}

export enum ConnectionEventStatus {
  Authenticated = 'Authenticated',
  Configured = 'Configured',
  Created = 'Created',
  Deleted = 'Deleted',
  Disabled = 'Disabled',
  Enabled = 'Enabled',
  Failed = 'Failed',
  Progress = 'Progress',
  Reset = 'Reset',
  Succeeded = 'Succeeded',
  SyncNow = 'SyncNow',
  SystemDisabled = 'SystemDisabled',
  Triggered = 'Triggered'
}

export type ConnectionSettingsInput = {
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum ConnectionStatus {
  ActivationPending = 'ActivationPending',
  Archived = 'Archived',
  ConfigurationPending = 'ConfigurationPending',
  Disabled = 'Disabled',
  Enabled = 'Enabled',
  SystemDisabled = 'SystemDisabled',
  Unknown = 'Unknown'
}

export type ConnectionSummary = {
  accounts?: Maybe<Array<ConnectionAccountSummary>>;
  connector?: Maybe<Connector>;
  connectorClassName?: Maybe<Scalars['String']['output']>;
  dataSourceId: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  sourceIconURL?: Maybe<Scalars['String']['output']>;
  sourceName: Scalars['String']['output'];
  /** stripe, plaid */
  status: ConnectionStatus;
  sync?: Maybe<ConnectionSyncState>;
};

export type ConnectionSyncState = {
  lastEventAt?: Maybe<Scalars['DateTime']['output']>;
  lastSuccessAt?: Maybe<Scalars['DateTime']['output']>;
  status?: Maybe<ConnectionSyncStatus>;
};

export enum ConnectionSyncStatus {
  Configured = 'Configured',
  Delayed = 'Delayed',
  Failed = 'Failed',
  LoginRequired = 'LoginRequired',
  Success = 'Success'
}

export type ConnectionTemplate = {
  connector: Connector;
  institution: ProductInstitution;
};

export type Connector = {
  category: ConnectorCategory;
  className?: Maybe<Scalars['String']['output']>;
  connectorProperties?: Maybe<ConnectorProperties>;
  description?: Maybe<Scalars['String']['output']>;
  iconURL?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export enum ConnectorCategory {
  BanksAndCards = 'BanksAndCards',
  CategoryUnspecified = 'CategoryUnspecified',
  Ecommerce = 'Ecommerce',
  ExpenseManagement = 'ExpenseManagement',
  Payment = 'Payment',
  Payroll = 'Payroll'
}

export type ConnectorProperties = EmptyConnectorProperties | GustoConnectorProperties | PlaidConnectorProperties | RampConnectorProperties | StripeConnectorProperties;

export type Contact = {
  avatarUrl?: Maybe<Scalars['String']['output']>;
  emailAddress: Scalars['String']['output'];
  familyName?: Maybe<Scalars['String']['output']>;
  givenName?: Maybe<Scalars['String']['output']>;
};

export type ContactInput = {
  emailAddress: Scalars['String']['input'];
  familyName?: InputMaybe<Scalars['String']['input']>;
  givenName?: InputMaybe<Scalars['String']['input']>;
};

export type ContractLineItemInput = {
  amount: InputMonetaryValue;
  recurrence: ProductRecurrence;
  stripeProductId: Scalars['String']['input'];
};

export enum CoverLogoSource {
  AccountingFirm = 'AccountingFirm',
  Client = 'Client'
}

export type CreateCategoryResponse = {
  categoryId: Scalars['String']['output'];
};

export type CreateConnectionResponse = {
  connection: Connection;
};

export type CreateTransactionResponse = {
  transactionFactIds: Array<Scalars['String']['output']>;
};

export type CreatedLegalEntity = {
  legalEntity?: Maybe<LegalEntity>;
  quickBooksCompany?: Maybe<QuickBooksCompany>;
};

export type CreatedQuickBooksDataSource = {
  dataSource?: Maybe<EnrichedDataSource>;
  quickBooksCompany?: Maybe<QuickBooksCompany>;
};

export enum CreationMethod {
  Auto = 'Auto',
  Manual = 'Manual',
  Unknown = 'Unknown',
  Validation = 'Validation'
}

export type CreditCard = {
  id: Scalars['String']['output'];
  isDefault: Scalars['Boolean']['output'];
  nickname: Scalars['String']['output'];
};

export type CsvExportField = {
  fieldName: CsvExportFieldName;
  label?: InputMaybe<Scalars['String']['input']>;
};

export enum CsvExportFieldName {
  CategoryName = 'CategoryName',
  CategoryNumber = 'CategoryNumber',
  Memo = 'Memo',
  Party1099Status = 'Party1099Status',
  PartyEntityType = 'PartyEntityType',
  PartyName = 'PartyName',
  PartyW9Status = 'PartyW9Status',
  SplitCategoryName = 'SplitCategoryName',
  SplitCategoryNumber = 'SplitCategoryNumber',
  TaxStatus = 'TaxStatus',
  TransactionAmount = 'TransactionAmount',
  TransactionCreditAmount = 'TransactionCreditAmount',
  TransactionDate = 'TransactionDate',
  TransactionDebitAmount = 'TransactionDebitAmount'
}

export type CsvImport = {
  createdAt: Scalars['DateTime']['output'];
  csvImportId: Scalars['String']['output'];
  dataSourceId: Scalars['String']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  fileName: Scalars['String']['output'];
  source: Scalars['String']['output'];
  transactionCount: Scalars['Long']['output'];
};

export type CsvImportSort = {
  direction?: InputMaybe<SortDirection>;
  field?: InputMaybe<CsvImportSortOrderBy>;
};

export enum CsvImportSortOrderBy {
  CreatedAt = 'CreatedAt',
  FileName = 'FileName',
  TransactionCount = 'TransactionCount'
}

export type Customer = InvoiceCustomerInterface & {
  address: CustomerAddress;
  email: Scalars['String']['output'];
  icon?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  providerType: ProviderType;
};

export type CustomerAddress = {
  address: Address;
  id: Scalars['String']['output'];
};

/** Customer Events (Logs) */
export type CustomerEvent = {
  eventId: Scalars['String']['input'];
  legalEntityId?: InputMaybe<Scalars['String']['input']>;
  occurredAt: Scalars['DateTime']['input'];
  payload: CustomerEventPayload;
};

export type CustomerEventPayload = {
  marketingEvent?: InputMaybe<MarketingEvent>;
  pageView?: InputMaybe<PageView>;
  reportAction?: InputMaybe<ReportActionEvent>;
  signUpEvent?: InputMaybe<SignUpEvent>;
};

export type CustomerSummary = InvoiceCustomerSummaryInterface & {
  email: Scalars['String']['output'];
  icon?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type DataHealthStatus = {
  allDataSourcesHaveTransactions: Scalars['Boolean']['output'];
  allDataSourcesLinked: Scalars['Boolean']['output'];
  anyDataSourceRequiresEngineering: Scalars['Boolean']['output'];
  hasPlaidDataSource: Scalars['Boolean']['output'];
  validationStatus: ValidationHealthStatus;
};

export type DataSource = {
  accountAlias?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  institution: Institution;
  legalEntityId: Scalars['String']['output'];
  providerType: ProviderType;
  status: DataSourceStatus;
  statusReason?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type DataSourceFetchCountSummary = {
  institutionName: Scalars['String']['output'];
  statTimeBuckets?: Maybe<StatisticByTimeBuckets>;
  success: Scalars['Boolean']['output'];
};

export type DataSourceFetchStats = {
  summaries?: Maybe<Array<DataSourceFetchCountSummary>>;
};

export enum DataSourceJobType {
  QBO_AppendMemo = 'QBO_AppendMemo',
  QBO_AssignVendor = 'QBO_AssignVendor',
  QBO_ChangeCategory = 'QBO_ChangeCategory',
  QBO_RenameVendor = 'QBO_RenameVendor',
  QBO_UpdateDepartment = 'QBO_UpdateDepartment',
  QBO_UpdateLineClass = 'QBO_UpdateLineClass',
  QBO_UpdateTransactionDescription = 'QBO_UpdateTransactionDescription',
  UnknownType = 'UnknownType'
}

export type DataSourcePreference = {
  accountingBasis: AccountingBasis;
  dataSourceId: Scalars['String']['output'];
  departmentTerminology?: Maybe<Scalars['String']['output']>;
  providerType: ProviderType;
  taxForm: TaxForm;
};

export enum DataSourceStatus {
  Archived = 'Archived',
  Deleted = 'Deleted',
  EngineeringRequired = 'EngineeringRequired',
  Linked = 'Linked',
  LogInRequired = 'LogInRequired',
  Unknown = 'Unknown',
  Unsupported = 'Unsupported'
}

export type DataSources = {
  legalEntityId: Scalars['String']['output'];
  sources: Array<EnrichedDataSource>;
};

export type Date = {
  day: Scalars['Int']['output'];
  month: Scalars['Int']['output'];
  year: Scalars['Int']['output'];
};

export type DateFeatureValue = {
  dateValue: Date;
};

export type DateInput = {
  day?: InputMaybe<Scalars['Int']['input']>;
  month?: InputMaybe<Scalars['Int']['input']>;
  year?: InputMaybe<Scalars['Int']['input']>;
};

/** DayOfMonth */
export enum DayOfMonth {
  Fifteenth = 'Fifteenth',
  First = 'First',
  Last = 'Last'
}

/** DayOfWeek */
export enum DayOfWeek {
  Friday = 'Friday',
  Monday = 'Monday',
  Thursday = 'Thursday',
  Tuesday = 'Tuesday',
  Wednesday = 'Wednesday'
}

export type DeletedAmountFilter = {
  amountRuleFilterId: Scalars['String']['input'];
  operator: ComparisonOperator;
};

export type DeletedCategory = {
  categoryId: Scalars['String']['input'];
  operation: Operation;
};

export type DeletedCategoryFilter = {
  categoryRuleFilterId: Scalars['String']['input'];
  operation: Operation;
};

export type DenormalizedViewStatus = {
  activeViewCreatedAt?: Maybe<Scalars['DateTime']['output']>;
  legalEntityId: Scalars['String']['output'];
  ongoingViewStartedAt?: Maybe<Scalars['DateTime']['output']>;
  viewType: ViewType;
};

export type DenormalizedViewStatusSummary = {
  entries: Array<DenormalizedViewStatus>;
};

export type Department = {
  active: Scalars['Boolean']['output'];
  ancestors: Array<EntityDepartment>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  /** @deprecated Use parentId instead */
  parentDepartmentId?: Maybe<Scalars['String']['output']>;
  parentId?: Maybe<Scalars['String']['output']>;
};

export type DepartmentChartConfig = {
  departmentId: Scalars['String']['output'];
  origin: ArchiveIntervalOrigin;
};

export type DepartmentChartConfigInput = {
  departmentId: Scalars['String']['input'];
  origin: IntervalOrigin;
};

export type DepartmentDimension = {
  by: DimensionSummary;
  departmentId: Scalars['String']['output'];
  departmentObject: EntityDepartment;
  summary: TransactionSummary;
};

export type DigitsWorkItemPushParams = {
  categoryParams?: InputMaybe<CategoryParams>;
  partyParams?: InputMaybe<PartyParams>;
  skipClose?: InputMaybe<Scalars['Boolean']['input']>;
  workItemId: Scalars['String']['input'];
};

export enum DimensionSortMode {
  Active = 'Active',
  Growing = 'Growing',
  Shrinking = 'Shrinking',
  Total = 'Total'
}

export type DimensionSummary = {
  category: Array<CategoryDimension>;
  categoryType: Array<CategoryTypeDimension>;
  department: Array<DepartmentDimension>;
  location: Array<LocationDimension>;
  partialData: Scalars['Boolean']['output'];
  party: Array<PartyDimension>;
  summary: TransactionSummary;
  time: Array<TimeDimension>;
};


export type DimensionSummaryCategoryArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<DimensionSortMode>;
};


export type DimensionSummaryCategoryTypeArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<DimensionSortMode>;
};


export type DimensionSummaryDepartmentArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<DimensionSortMode>;
};


export type DimensionSummaryLocationArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<DimensionSortMode>;
};


export type DimensionSummaryPartyArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<DimensionSortMode>;
};


export type DimensionSummaryTimeArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<DimensionSortMode>;
};

export enum DirectionFromOrigin {
  Future = 'Future',
  Past = 'Past'
}

/**
 * DiscreteEvent represents one-time activity feed events
 * that are created via our go-services APIs
 */
export type DiscreteEvent = {
  actorUserId: Scalars['String']['output'];
  kind: DiscreteEventKind;
  subject: ActivityObjectIdentifier;
  target: ActivityObjectIdentifier;
  targetEmailAddress?: Maybe<Scalars['String']['output']>;
};

export enum DiscreteEventKind {
  CreatedGrant = 'CreatedGrant',
  CreatedIntent = 'CreatedIntent',
  /**
   * DisabledLink
   * RejectedRequest
   */
  CreatedReportPackage = 'CreatedReportPackage',
  /** RegeneratedReportPackage */
  DeletedReportPackage = 'DeletedReportPackage',
  IntegrationFixed = 'IntegrationFixed',
  NewComment = 'NewComment',
  NewIntegration = 'NewIntegration',
  NewReport = 'NewReport',
  NewUser = 'NewUser',
  /**
   * CreatedReport
   * ViewedReport
   * UpdatedReport
   * RegeneratedReport
   */
  PublishedDraftReportPackage = 'PublishedDraftReportPackage',
  PublishedFinalReportPackage = 'PublishedFinalReportPackage',
  /**
   * FixedIntegration
   * CreatedIntegration
   * CreatedUser
   * CreatedThread
   * CreatedComment
   */
  ResolvedThread = 'ResolvedThread',
  RevokedGrant = 'RevokedGrant',
  /**
   * UpdatedLink
   * AcceptedRequest
   */
  RevokedIntent = 'RevokedIntent',
  UpdatedGrant = 'UpdatedGrant',
  /**
   * CreatedLink
   * CreatedRequest
   */
  UpdatedIntent = 'UpdatedIntent',
  UpdatedReportPackage = 'UpdatedReportPackage',
  VendorEdited = 'VendorEdited',
  ViewedReportPackage = 'ViewedReportPackage'
}

export enum DisplayEntry {
  Credit = 'Credit',
  Debit = 'Debit',
  TransferCredit = 'TransferCredit',
  TransferDebit = 'TransferDebit',
  Unknown = 'Unknown'
}

export type DocumentCollectionConfig = {
  collectionId: Scalars['String']['output'];
};

export type DocumentCollectionConfigInput = {
  collectionId: Scalars['String']['input'];
};

export type DocumentDownload = {
  url: Scalars['String']['output'];
};

export type DocumentFilter = {
  creatorId?: InputMaybe<Scalars['String']['input']>;
  documentCollectionId?: InputMaybe<Scalars['String']['input']>;
  documentTypes?: InputMaybe<Array<DocumentType>>;
  featureKinds?: InputMaybe<Array<FeatureKind>>;
  mimeTypes?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type DocumentInitialSegment = {
  collectionId: Scalars['String']['output'];
  documentId: Scalars['String']['output'];
  downloadUrl?: Maybe<Scalars['String']['output']>;
};

export type DocumentLineItem = {
  amount: MonetaryValue;
  categoryId?: Maybe<Scalars['String']['output']>;
  description: Scalars['String']['output'];
  position: Scalars['Long']['output'];
  quantity: Scalars['Long']['output'];
  rate: MonetaryValue;
};

export type DocumentSort = {
  direction?: InputMaybe<SortDirection>;
  field: DocumentSortBy;
};

export enum DocumentSortBy {
  CreatedAt = 'CreatedAt',
  DocumentType = 'DocumentType',
  FileName = 'FileName',
  FileSize = 'FileSize',
  Title = 'Title'
}

export enum DocumentStatus {
  Done = 'Done',
  New = 'New',
  Processing = 'Processing',
  UnknownStatus = 'UnknownStatus'
}

export enum DocumentType {
  Bill = 'Bill',
  CheckingAccount = 'CheckingAccount',
  Compliance = 'Compliance',
  Contract = 'Contract',
  CreditCard = 'CreditCard',
  Equity = 'Equity',
  Form1099 = 'Form1099',
  FormW9 = 'FormW9',
  Invoice = 'Invoice',
  K1 = 'K1',
  LoanAgreement = 'LoanAgreement',
  Payroll = 'Payroll',
  Receipt = 'Receipt',
  SavingsAccount = 'SavingsAccount',
  Schedule = 'Schedule',
  TaxID = 'TaxID',
  TaxReturn = 'TaxReturn',
  UnknownType = 'UnknownType',
  Valuation = 'Valuation'
}

export type DocumentsSummary = {
  count: Scalars['Long']['output'];
};

export type DoppelgangerPermit = {
  createdAt: Scalars['DateTime']['output'];
  expiresAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  user: User;
};

export type DoubleEntry = {
  firstEntryCategoryId: Scalars['String']['input'];
  secondEntryCategoryId: Scalars['String']['input'];
  withoutFirstEntryAncestors?: InputMaybe<Scalars['Boolean']['input']>;
  withoutSecondEntryAncestors?: InputMaybe<Scalars['Boolean']['input']>;
};

export type DraftCustomer = InvoiceCustomerInterface & {
  address: CustomerAddress;
  email: Scalars['String']['output'];
  icon?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  providerType: ProviderType;
};

export type DraftCustomerSummary = InvoiceCustomerSummaryInterface & {
  email: Scalars['String']['output'];
  icon?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type DraftInvoicingProfile = InvoicingProfileInterface & {
  backgroundColor?: Maybe<Scalars['String']['output']>;
  companyAddress: InvoicingAddress;
  companyEmail: Scalars['String']['output'];
  companyIcon?: Maybe<Scalars['String']['output']>;
  companyName: Scalars['String']['output'];
  coverURL?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  payoutInstrumentID: Scalars['String']['output'];
};

export type EmailStatus = {
  rawStatus: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type EmbeddingResponse = {
  deployedIndexId: Scalars['String']['output'];
  embeddingValue?: Maybe<Array<Scalars['Float']['output']>>;
};

export type Employee = {
  avatarUrl?: Maybe<Scalars['String']['output']>;
  emailAddress?: Maybe<Scalars['String']['output']>;
  familyName?: Maybe<Scalars['String']['output']>;
  givenName?: Maybe<Scalars['String']['output']>;
  hiddenAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['String']['output'];
  invitation?: Maybe<Invitation>;
  jobTitle?: Maybe<Scalars['String']['output']>;
  organizationId: Scalars['String']['output'];
  role?: Maybe<Role>;
  status: EmployeeStatus;
  user?: Maybe<User>;
};

export type EmployeeAffiliations = {
  affiliations: Array<Affiliation>;
  employee: Employee;
};

export type EmployeeFilter = {
  employeeIds?: InputMaybe<Array<Scalars['String']['input']>>;
  legalEntityId?: InputMaybe<Scalars['String']['input']>;
  organizationId?: InputMaybe<Scalars['String']['input']>;
  searchTerm?: InputMaybe<Scalars['String']['input']>;
  statuses?: InputMaybe<Array<EmployeeStatus>>;
  visibility?: InputMaybe<EmployeeVisibility>;
};

export type EmployeeIdentifier = {
  employeeId: Scalars['String']['input'];
  organizationId: Scalars['String']['input'];
};

export enum EmployeeOrigin {
  Bootstrapped = 'Bootstrapped',
  External = 'External',
  Invitation = 'Invitation',
  Manual = 'Manual',
  Synthesized = 'Synthesized',
  UnknownOrigin = 'UnknownOrigin'
}

export type EmployeeSort = {
  direction?: InputMaybe<SortDirection>;
  field?: InputMaybe<EmployeeSortOrderBy>;
};

export enum EmployeeSortOrderBy {
  Name = 'Name',
  Status = 'Status'
}

export enum EmployeeStatus {
  Activated = 'Activated',
  Deactivated = 'Deactivated',
  Initialized = 'Initialized',
  UnknownStatus = 'UnknownStatus'
}

export enum EmployeeVisibility {
  Active = 'Active',
  All = 'All',
  Hidden = 'Hidden'
}

export type EmptyConnectorProperties = {
  empty?: Maybe<Scalars['Boolean']['output']>;
};

export type EnrichedDataSource = {
  accountAlias?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  hasTransactions: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
  institution: Party;
  lastActivityAt?: Maybe<Scalars['DateTime']['output']>;
  legalEntityId: Scalars['String']['output'];
  plaidConfig?: Maybe<PlaidConfig>;
  providerType: ProviderType;
  quickBooksConfig?: Maybe<QuickBooksConfig>;
  sourceAccounts?: Maybe<Array<SourceAccount>>;
  status: DataSourceStatus;
  statusReason?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type EntitiesList = {
  entities: ObjectEntities;
};

export type EntitiesListConfig = {
  entities: ObjectEntities;
  origin: ArchiveIntervalOrigin;
};

export type EntityBill = {
  approvalAccess?: Maybe<BillAccess>;
  billDescription: Scalars['String']['output'];
  billStatus: BillStatus;
  dueDate?: Maybe<Date>;
  format: Format;
  id: Scalars['String']['output'];
  totalAmount: EntityMonetaryValue;
  vendorName: Scalars['String']['output'];
};

export type EntityCategory = {
  active: Scalars['Boolean']['output'];
  chartOfAccountsDisplayNumber?: Maybe<Scalars['String']['output']>;
  displayKey: Scalars['String']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  parentCategoryId?: Maybe<Scalars['String']['output']>;
  productArea?: Maybe<ProductArea>;
  subtype: CategorySubtype;
  type: CategoryType;
};

/** Comments */
export type EntityComment = {
  authorId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  text: Scalars['String']['output'];
  timestamp: Scalars['DateTime']['output'];
};

export type EntityDataSource = {
  iconUrl?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type EntityDepartment = {
  active: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  /** @deprecated Use parentId */
  parentDepartmentId?: Maybe<Scalars['String']['output']>;
  parentId?: Maybe<Scalars['String']['output']>;
};

/** Employees */
export type EntityEmployee = {
  avatarUrl?: Maybe<Scalars['String']['output']>;
  familyName?: Maybe<Scalars['String']['output']>;
  givenName?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  organizationId: Scalars['String']['output'];
};

export type EntityHovers = {
  hovers: Array<Hover>;
};

export type EntityIdentityVerification = {
  legalEntityId: Scalars['String']['output'];
  status: IdentityVerificationStatus;
};

/** Invoices */
export type EntityInvoice = {
  amount: EntityMonetaryValue;
  createdAt: Scalars['DateTime']['output'];
  customer: EntityInvoiceCustomer;
  dueAt?: Maybe<Scalars['DateTime']['output']>;
  externalDisplayId: Scalars['String']['output'];
  id: Scalars['String']['output'];
};

export type EntityInvoiceCustomer = {
  email?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  party: EntityParty;
  phone?: Maybe<Scalars['String']['output']>;
};

export type EntityLedgerTransaction = {
  factIds?: Maybe<Array<Scalars['String']['output']>>;
  id: Scalars['String']['output'];
  timestamp: Scalars['DateTime']['output'];
};

export type EntityLegalEntity = {
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  slug: Scalars['String']['output'];
};

export type EntityLocation = {
  active: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  parentId?: Maybe<Scalars['String']['output']>;
};

export type EntityMonetaryValue = {
  amount: Scalars['Long']['output'];
  currencyMultiplier: Scalars['Long']['output'];
  iso4217CurrencyCode: Scalars['String']['output'];
};

export type EntityOrganization = {
  iconUrl?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  slug: Scalars['String']['output'];
};

/** Parties */
export type EntityParty = {
  designation: PartyDesignation;
  iconUrl?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  roles?: Maybe<Array<PartyRole>>;
  shortDescription?: Maybe<Scalars['String']['output']>;
};

/** Reports */
export type EntityReport = {
  authorId: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  mimeType: Scalars['String']['output'];
  reportFor: Date;
  title: Scalars['String']['output'];
};

export type EntityReportPackage = {
  authorId: Scalars['String']['output'];
  coverUrl?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  documents: Array<SharedReportDocumentMetadata>;
  id: Scalars['String']['output'];
  interval: Interval;
  periodEndedAt: Scalars['DateTime']['output'];
  periodStartedAt: Scalars['DateTime']['output'];
  title: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type EntityThread = {
  id: Scalars['String']['output'];
  resolvedAt: Scalars['DateTime']['output'];
};

/**
 * ObjectEntities
 * Note: some definitions exist in shared/common/
 * Transactions
 */
export type EntityTransaction = {
  description?: Maybe<Scalars['String']['output']>;
  displayCategory: EntityCategory;
  displayEntry: DisplayEntry;
  /** @deprecated Use moneyFlow */
  displayValue?: Maybe<EntityMonetaryValue>;
  externalId?: Maybe<Scalars['String']['output']>;
  factId?: Maybe<Scalars['String']['output']>;
  moneyFlow: MoneyFlow;
  name?: Maybe<Scalars['String']['output']>;
  party?: Maybe<EntityParty>;
  splitCategory?: Maybe<EntityCategory>;
  timestamp: Scalars['DateTime']['output'];
  viewId: Scalars['String']['output'];
};

export type EntityUser = {
  avatarUrl?: Maybe<Scalars['String']['output']>;
  emailAddress: Scalars['String']['output'];
  familyName: Scalars['String']['output'];
  givenName: Scalars['String']['output'];
  id: Scalars['String']['output'];
};

/** Work Items */
export type EntityWorkItem = {
  id: Scalars['String']['output'];
  ledgerType: LedgerType;
  objectTransaction: EntityTransaction;
  reviewCreatedById: Scalars['String']['output'];
  reviewState?: Maybe<WorkItemReviewState>;
  time: Scalars['DateTime']['output'];
  workItemState: WorkItemState;
  workItemType: WorkItemType;
};

export enum EntryType {
  Credit = 'Credit',
  Debit = 'Debit',
  Unknown = 'Unknown'
}

export enum EventType {
  /** Invitations */
  AffiliateLegalEntityInvitation = 'AffiliateLegalEntityInvitation',
  AffiliationsCreated = 'AffiliationsCreated',
  AffiliationsInvitationCreated = 'AffiliationsInvitationCreated',
  ApplicationApproved = 'ApplicationApproved',
  ApplicationSubmitted = 'ApplicationSubmitted',
  /** Signups */
  ApplicationSubmittedByInvitee = 'ApplicationSubmittedByInvitee',
  BoostSummary = 'BoostSummary',
  CashBalanceAlert = 'CashBalanceAlert',
  ConfirmEmail = 'ConfirmEmail',
  ConfirmPasswordReset = 'ConfirmPasswordReset',
  /** Password Management */
  CreatePasswordReset = 'CreatePasswordReset',
  InitialInvitation = 'InitialInvitation',
  InstructionsAffiliateInvitation = 'InstructionsAffiliateInvitation',
  InstructionsInvitation = 'InstructionsInvitation',
  LedgerTransactionComment = 'LedgerTransactionComment',
  LedgerTransactionMention = 'LedgerTransactionMention',
  ObjectAccessAccepted = 'ObjectAccessAccepted',
  ObjectAccessApproved = 'ObjectAccessApproved',
  ObjectAccessRequested = 'ObjectAccessRequested',
  ObjectComment = 'ObjectComment',
  /** Sharing */
  ObjectGrantCreated = 'ObjectGrantCreated',
  ObjectMention = 'ObjectMention',
  PlanInvitation = 'PlanInvitation',
  ReportComment = 'ReportComment',
  ReportCommentThreadResolved = 'ReportCommentThreadResolved',
  ReportMention = 'ReportMention',
  ReportsCreated = 'ReportsCreated',
  SystemReportCreated = 'SystemReportCreated',
  TransactionMention = 'TransactionMention',
  TransactionQuestionsAsked = 'TransactionQuestionsAsked',
  TransactionQuestionsReminder = 'TransactionQuestionsReminder',
  UnknownEvent = 'UnknownEvent',
  UserInvitation = 'UserInvitation',
  UserLockedOut = 'UserLockedOut',
  WorkItemComment = 'WorkItemComment'
}

export type ExistingReportAttachment = {
  position: Scalars['Int']['input'];
  reportId: Scalars['String']['input'];
  title: Scalars['String']['input'];
  versionId: Scalars['String']['input'];
};

export enum ExpectationStatus {
  Active = 'Active',
  AutomaticallyUpdated = 'AutomaticallyUpdated',
  Unknown = 'Unknown'
}

export enum ExpectationType {
  CreditCategory = 'CreditCategory',
  DebitCategory = 'DebitCategory',
  MatchSet = 'MatchSet',
  Owner = 'Owner',
  PartyIcon = 'PartyIcon',
  PartyName = 'PartyName',
  PartyRole = 'PartyRole',
  Unknown = 'Unknown'
}

export type Feature = {
  featurePage: Scalars['Long']['output'];
  height: Scalars['Float']['output'];
  id: Scalars['String']['output'];
  invalid: Scalars['Boolean']['output'];
  kind: FeatureKind;
  originX: Scalars['Float']['output'];
  originY: Scalars['Float']['output'];
  value: FeatureValue;
  width: Scalars['Float']['output'];
};

export enum FeatureKind {
  AccountNumber = 'AccountNumber',
  BillCategory = 'BillCategory',
  BusinessAddress = 'BusinessAddress',
  /** W9 feature kinds */
  BusinessName = 'BusinessName',
  CheckInfo = 'CheckInfo',
  DocumentCustomer = 'DocumentCustomer',
  DocumentDate = 'DocumentDate',
  DocumentKeyword = 'DocumentKeyword',
  DocumentParty = 'DocumentParty',
  /** generic document feature kinds */
  DocumentRawText = 'DocumentRawText',
  DocumentReference = 'DocumentReference',
  DocumentSummary = 'DocumentSummary',
  DocumentType = 'DocumentType',
  DocumentVendor = 'DocumentVendor',
  DueDate = 'DueDate',
  EndingBalance = 'EndingBalance',
  EndingDate = 'EndingDate',
  InstitutionAccountNumber = 'InstitutionAccountNumber',
  /** bank statement feature kinds */
  InstitutionName = 'InstitutionName',
  InvoiceDate = 'InvoiceDate',
  InvoiceId = 'InvoiceId',
  LineItem = 'LineItem',
  Memo = 'Memo',
  Other = 'Other',
  OtherPaymentInfo = 'OtherPaymentInfo',
  PayerAddress = 'PayerAddress',
  PayerCompanyName = 'PayerCompanyName',
  PayerEmail = 'PayerEmail',
  PayerName = 'PayerName',
  PayerPhoneNumber = 'PayerPhoneNumber',
  PaymentInstructions = 'PaymentInstructions',
  RemittanceAddress = 'RemittanceAddress',
  RemittanceInformation = 'RemittanceInformation',
  /** contract feature kinds */
  Schedule = 'Schedule',
  StartingBalance = 'StartingBalance',
  StartingDate = 'StartingDate',
  TaxNumber = 'TaxNumber',
  Terms = 'Terms',
  Title = 'Title',
  TotalAmount = 'TotalAmount',
  TotalCredits = 'TotalCredits',
  TotalDebits = 'TotalDebits',
  TotalDeposits = 'TotalDeposits',
  TotalFees = 'TotalFees',
  TotalWithdrawals = 'TotalWithdrawals',
  UnknownKind = 'UnknownKind',
  VendorAddress = 'VendorAddress',
  VendorEmail = 'VendorEmail',
  VendorName = 'VendorName',
  VendorPhoneNumber = 'VendorPhoneNumber',
  VendorWebsite = 'VendorWebsite',
  WireInfo = 'WireInfo'
}

export type FeatureValue = AddressFeatureValue | DateFeatureValue | LineItemFeatureValue | MonetaryFeatureValue | NumericFeatureValue | RemittanceFeatureValue | TextFeatureValue;

export enum FetchType {
  Backfill = 'Backfill',
  ChangeDataCapture = 'ChangeDataCapture',
  Onboard = 'Onboard',
  Refresh = 'Refresh'
}

export type FirstTransactionOccurrence = {
  firstTransactionOccurredAt: Scalars['DateTime']['output'];
};

export enum Flow {
  Inbound = 'Inbound',
  Outbound = 'Outbound',
  UnknownFlow = 'UnknownFlow'
}

export type Format = {
  formatClass?: Maybe<FormatClass>;
  id: Scalars['String']['output'];
  mimeType?: Maybe<Scalars['String']['output']>;
  segments: Array<Segment>;
};

export enum FormatClass {
  AnnotatedTableJson = 'AnnotatedTableJson',
  CSV = 'CSV',
  Email = 'Email',
  ImageDocument = 'ImageDocument',
  ImageLarge = 'ImageLarge',
  ImageSmall = 'ImageSmall',
  ImageThumbnail = 'ImageThumbnail',
  LabelJson = 'LabelJson',
  OcrJson = 'OcrJson',
  OcrProto = 'OcrProto',
  PDF = 'PDF',
  RawMIME = 'RawMIME'
}

export enum GenerationStatus {
  Failed = 'Failed',
  InProgress = 'InProgress',
  Success = 'Success',
  UnknownStatus = 'UnknownStatus'
}

export type GlobalPartyAlias = {
  alias: Scalars['String']['output'];
  partyId: Scalars['String']['output'];
};

export type GlobalPartyFilter = {
  name?: InputMaybe<Scalars['String']['input']>;
};

export type GlobalPartySort = {
  direction?: InputMaybe<SortDirection>;
  field?: InputMaybe<GlobalPartySortOrderBy>;
};

export enum GlobalPartySortOrderBy {
  CreatedAt = 'CreatedAt',
  Name = 'Name',
  NameLength = 'NameLength',
  TransactionCount = 'TransactionCount',
  UpdatedAt = 'UpdatedAt'
}

export enum GrantPolicy {
  Allow = 'Allow',
  Deny = 'Deny'
}

export type GustoConnectorProperties = {
  authorizationURL: Scalars['String']['output'];
};

export enum HandleNone {
  Both = 'Both',
  Either = 'Either',
  Ignore = 'Ignore'
}

export enum HealthStatus {
  Healthy = 'Healthy',
  NotYetComputed = 'NotYetComputed',
  Unhealthy = 'Unhealthy'
}

export type HighlightedToken = {
  score: Scalars['Long']['output'];
  token: Scalars['String']['output'];
};

export type HighlightedTokens = {
  description?: Maybe<Array<HighlightedToken>>;
  name?: Maybe<Array<HighlightedToken>>;
};

/**
 * Highlight describes the positional offsets of a search term
 * within the result document, which will allow clients to highlight
 * that term during presentation of the result.
 */
export type HitHighlight = {
  end: Scalars['Long']['output'];
  field: Scalars['String']['output'];
  start: Scalars['Long']['output'];
  term: Scalars['String']['output'];
};

export type Hover = CategoryHover | PartyHover | SummaryLineItemHover;

export type HttpResponse = {
  metadata?: Maybe<HttpResponseMetadata>;
  rawBody: Scalars['String']['output'];
};

export type HttpResponseArchive = {
  responses: Array<HttpResponse>;
};

export type HttpResponseMetadata = {
  request_id?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  status_code?: Maybe<Scalars['Int']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type HydratedParty = {
  agentEvent?: Maybe<AgentEvent>;
};

/** IdentifiersByKind represents a kind, legalEntityId and list of object ids */
export type IdentifiersByKind = {
  ids: Array<Scalars['String']['input']>;
  kind: ObjectKind;
  legalEntityId: Scalars['String']['input'];
};

export type Identity = {
  domain?: Maybe<Scalars['String']['output']>;
  identifier: Scalars['String']['output'];
  provider: OAuthService;
};

export enum IdentityVerificationStatus {
  ApplicationReview = 'ApplicationReview',
  Approved = 'Approved',
  Denied = 'Denied',
  IdentityVerificationStatusUnknown = 'IdentityVerificationStatusUnknown',
  Ineligible = 'Ineligible',
  Pending = 'Pending'
}

export type IdentityVerificationStatusResult = {
  expiresAt?: Maybe<Scalars['DateTime']['output']>;
  status: IdentityVerificationStatus;
};

export type ImportedEmployees = {
  employees: Array<Employee>;
  newEmployeeCount: Scalars['Int']['output'];
};

export enum IngestSource {
  BankStatementUpload = 'BankStatementUpload',
  BillPayEmail = 'BillPayEmail',
  BillPayUpload = 'BillPayUpload',
  Report = 'Report',
  Vault = 'Vault'
}

export type InputAccountReconciliation = {
  abnormalTotal?: InputMaybe<InputMonetaryValue>;
  categoryId: Scalars['String']['input'];
  closingBalance: InputMonetaryValue;
  endDate: DateInput;
  normalTotal?: InputMaybe<InputMonetaryValue>;
};

export type InputAccountReconciliationUpdate = {
  abnormalTotal?: InputMaybe<InputReconciliationAmount>;
  closingBalance?: InputMaybe<InputMonetaryValue>;
  /** fields used for reconfiguring a reconciliation */
  endDate?: InputMaybe<DateInput>;
  /** fields used for reconfiguring or updating a reconciliation */
  normalTotal?: InputMaybe<InputReconciliationAmount>;
};

export type InputAccountReconciliationsFilter = {
  direction: DirectionFromOrigin;
  hasActivity: Scalars['Boolean']['input'];
  origin: IntervalOrigin;
};

export type InputAmountRuleFilter = {
  amount: InputMonetaryValue;
  amountRuleFilterId?: InputMaybe<Scalars['String']['input']>;
  operator: ComparisonOperator;
};

export type InputCategoryRuleFilter = {
  categories: Array<InputCategoryRuleFilterCategory>;
  categoryRuleFilterId?: InputMaybe<Scalars['String']['input']>;
  operation: Operation;
};

export type InputCategoryRuleFilterCategory = {
  categoryId: Scalars['String']['input'];
  categoryName: Scalars['String']['input'];
};

export type InputMonetaryValue = {
  amount: Scalars['Long']['input'];
  currencyMultiplier: Scalars['Long']['input'];
  iso4217CurrencyCode: Scalars['String']['input'];
};

/**
 * ObjectIdentifier is a generic identifier with an enumerated kind.
 * It can be used to identify different Digits' core objects.
 */
export type InputObjectIdentifier = {
  id: Scalars['String']['input'];
  kind: ObjectKind;
  legalEntityId: Scalars['String']['input'];
};

export type InputPartyRuleFilter = {
  operation: Operation;
  parties: Array<InputPartyRuleFilterParty>;
  partyRuleFilterId?: InputMaybe<Scalars['String']['input']>;
};

export type InputPartyRuleFilterParty = {
  partyId: Scalars['String']['input'];
  partyName: Scalars['String']['input'];
};

export type InputReconciliationAmount = {
  amount?: InputMaybe<InputMonetaryValue>;
  delete: Scalars['Boolean']['input'];
};

export type InputStatusUpdate = {
  categoryId: Scalars['String']['input'];
  reconciliationId: Scalars['String']['input'];
  status: TransactionReconciliationStatus;
  transactionFactId: Scalars['String']['input'];
};

/** # input types */
export type InputTransaction = {
  externalId?: InputMaybe<Scalars['String']['input']>;
  lines: Array<InputTransactionLine>;
  memo: Scalars['String']['input'];
  referenceNumber?: InputMaybe<Scalars['String']['input']>;
  timestamp: Scalars['DateTime']['input'];
  type: TransactionType;
};

export type InputTransactionLine = {
  amount: InputMonetaryValue;
  categoryId: Scalars['String']['input'];
  description: Scalars['String']['input'];
  entryType: EntryType;
  id: Scalars['String']['input'];
  lineItemId: Scalars['String']['input'];
  /**
   * newParty is optional
   * if provided (and partyId is blank) a new party will be created and associated with the transaction line
   */
  newParty?: InputMaybe<Scalars['String']['input']>;
  /** party classification is optional */
  partyId?: InputMaybe<Scalars['String']['input']>;
};

export type InputUpdateFields = {
  categoryId?: InputMaybe<Scalars['String']['input']>;
  digitsTransactionType: TransactionType;
  entryType: EntryType;
  /** optional fields to update */
  partyId?: InputMaybe<Scalars['String']['input']>;
  taxStatus?: InputMaybe<TaxStatus>;
  transactionFactId: Scalars['String']['input'];
};

export type InputUpdatePartyFields = {
  entityType?: InputMaybe<PartyEntityType>;
  partyId: Scalars['String']['input'];
  /** optional fields to update */
  taxpayerIdentification?: InputMaybe<TaxpayerIdentification>;
};

export type Insight = {
  absoluteDelta?: Maybe<MonetaryValue>;
  comparisonIntervalOffset: Scalars['Long']['output'];
  comparisonPeriodValue?: Maybe<MonetaryValue>;
  designation?: Maybe<PartyDesignation>;
  globalScore?: Maybe<Scalars['Float']['output']>;
  periodValue?: Maybe<MonetaryValue>;
  productArea?: Maybe<ProductArea>;
  ruleType: InsightRuleType;
  score: Scalars['Long']['output'];
  sentence?: Maybe<Scalars['String']['output']>;
  subjectDisplayKey: Scalars['String']['output'];
  subjectIconUrl?: Maybe<Scalars['String']['output']>;
  subjectId: Scalars['String']['output'];
  subjectType: InsightSubjectType;
  type: InsightType;
};

export type InsightComponent = {
  hovers: Array<Hover>;
  sentence: Scalars['String']['output'];
};

export type InsightComponentConfig = {
  lookback: ArchiveIntervalOrigin;
  objectId: ObjectIdentifier;
  options?: Maybe<InsightComponentConfigOptions>;
  origin: ArchiveIntervalOrigin;
};

export type InsightComponentConfigOptions = {
  partyRole?: Maybe<PartyRole>;
};

export type InsightConfigInput = {
  lookback: IntervalOrigin;
  objectId: InputObjectIdentifier;
  origin: IntervalOrigin;
  partyRole?: InputMaybe<PartyRole>;
};

export type InsightFilter = {
  categoryTypes?: InputMaybe<Array<CategoryType>>;
  insightTypes?: InputMaybe<Array<InsightType>>;
  insightVisibility?: InputMaybe<InsightVisibility>;
  objectId?: InputMaybe<InputObjectIdentifier>;
  partyRole?: InputMaybe<PartyRole>;
  productArea?: InputMaybe<ProductArea>;
  scoreType?: InputMaybe<InsightScoreType>;
  subjectId?: InputMaybe<Scalars['String']['input']>;
  subjectTypes?: InputMaybe<Array<InsightSubjectType>>;
  topLevelOnly?: InputMaybe<Scalars['Boolean']['input']>;
  viewKey: ViewKey;
};

export enum InsightRuleType {
  Delta = 'Delta',
  Growth = 'Growth',
  Inconsistent = 'Inconsistent',
  New = 'New',
  Outlier = 'Outlier',
  Regression = 'Regression',
  UnspecifiedRuleType = 'UnspecifiedRuleType'
}

export enum InsightScoreType {
  Global = 'Global',
  Local = 'Local',
  UnknownScoreType = 'UnknownScoreType'
}

export enum InsightSubjectType {
  Category = 'Category',
  Department = 'Department',
  LegalEntity = 'LegalEntity',
  Location = 'Location',
  Party = 'Party',
  Recurring = 'Recurring',
  TransactionFact = 'TransactionFact',
  UnspecifiedSubjectType = 'UnspecifiedSubjectType'
}

export enum InsightType {
  AssetsPrepaid = 'AssetsPrepaid',
  AssetsReceivable = 'AssetsReceivable',
  Cash = 'Cash',
  CostOfGoodsSold = 'CostOfGoodsSold',
  Equity = 'Equity',
  Expenses = 'Expenses',
  Income = 'Income',
  LiabilitiesLongTerm = 'LiabilitiesLongTerm',
  LiabilitiesPayable = 'LiabilitiesPayable',
  UnspecifiedInsightType = 'UnspecifiedInsightType'
}

export enum InsightVisibility {
  DuplicateVisibility = 'DuplicateVisibility',
  FullVisibility = 'FullVisibility',
  UnspecifiedInsightVisibility = 'UnspecifiedInsightVisibility'
}

export type Institution = {
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  iconUrl?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  partyId?: Maybe<Scalars['String']['output']>;
  routingNumber?: Maybe<Scalars['String']['output']>;
  url: Scalars['String']['output'];
};

export enum InstitutionKind {
  Bank = 'Bank',
  Expense = 'Expense',
  Ledger = 'Ledger',
  Payment = 'Payment',
  Payroll = 'Payroll'
}

export enum InstitutionProduct {
  BankAccount = 'BankAccount',
  BillPay = 'BillPay',
  CapTable = 'CapTable',
  CreditDebitCard = 'CreditDebitCard',
  Invoicing = 'Invoicing',
  PayrollProvider = 'PayrollProvider',
  Revenue = 'Revenue',
  UnknownProduct = 'UnknownProduct'
}

export enum Interval {
  Biweek = 'Biweek',
  Day = 'Day',
  Hour = 'Hour',
  IntervalNone = 'IntervalNone',
  Minute = 'Minute',
  Month = 'Month',
  Quarter = 'Quarter',
  Week = 'Week',
  Year = 'Year'
}

/** Intervals */
export type IntervalOrigin = {
  index: Scalars['Int']['input'];
  interval: Interval;
  intervalCount?: InputMaybe<Scalars['Int']['input']>;
  year: Scalars['Int']['input'];
};

export type IntervalPartition = {
  origin: IntervalPartitionOrigin;
  step: IntervalPartitionStep;
};

export type IntervalPartitionOrigin = {
  index: Scalars['Int']['input'];
  interval: Interval;
  intervalCount: Scalars['Int']['input'];
};

export type IntervalPartitionStep = {
  interval: Interval;
};

export type Invitation = {
  acceptedAt?: Maybe<Scalars['DateTime']['output']>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  invitationType: InvitationType;
  notifiedAt?: Maybe<Scalars['DateTime']['output']>;
  organization?: Maybe<Organization>;
  organizationTemplateId?: Maybe<Scalars['String']['output']>;
  recipient: Contact;
  role?: Maybe<Role>;
  sender?: Maybe<Contact>;
  service?: Maybe<OAuthService>;
  signupId?: Maybe<Scalars['String']['output']>;
  status: InvitationStatus;
  updatedAt: Scalars['DateTime']['output'];
};

export type InvitationProposal = {
  createdAt: Scalars['DateTime']['output'];
  invitationId: Scalars['String']['output'];
  legalEntity?: Maybe<EntityLegalEntity>;
  organization?: Maybe<EntityOrganization>;
  organizationTemplateId?: Maybe<Scalars['String']['output']>;
  proposalId: Scalars['String']['output'];
  proposalType: ProposalType;
  recipient?: Maybe<Contact>;
  sender?: Maybe<EntityUser>;
  status: InvitationProposalStatus;
};

export enum InvitationProposalStatus {
  Accepted = 'Accepted',
  Cancelled = 'Cancelled',
  Completed = 'Completed',
  Rejected = 'Rejected',
  Sent = 'Sent',
  Unknown = 'Unknown'
}

export enum InvitationStatus {
  Accepted = 'Accepted',
  Active = 'Active',
  Cancelled = 'Cancelled',
  Unknown = 'Unknown'
}

export type InvitationToken = {
  token: Scalars['String']['output'];
};

export enum InvitationType {
  Accountant = 'Accountant',
  Operator = 'Operator',
  Passwordless = 'Passwordless',
  UnknownType = 'UnknownType'
}

export type Invoice = {
  customer?: Maybe<Customer>;
  discounts?: Maybe<Array<InvoiceDiscount>>;
  dueDate?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['String']['output'];
  invoiceDate?: Maybe<Scalars['DateTime']['output']>;
  invoiceNumber?: Maybe<Scalars['String']['output']>;
  invoicingProfile?: Maybe<InvoicingProfile>;
  legalEntityId: Scalars['String']['output'];
  lineItems: Array<InvoiceLineItem>;
  memo?: Maybe<Scalars['String']['output']>;
  status: InvoiceStatus;
  statusSummary?: Maybe<InvoiceStatusSummary>;
  subtotal?: Maybe<MonetaryValue>;
  taxPercent?: Maybe<Scalars['Float']['output']>;
  totalAmount?: Maybe<MonetaryValue>;
  totalTax?: Maybe<MonetaryValue>;
};

export type InvoiceCustomerInterface = {
  address: CustomerAddress;
  email: Scalars['String']['output'];
  icon?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  providerType: ProviderType;
};

export type InvoiceCustomerSummaryInterface = {
  email: Scalars['String']['output'];
  icon?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type InvoiceDiscount = {
  discountAmount?: Maybe<MonetaryValue>;
  fixedDiscountAmount?: Maybe<MonetaryValue>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  providerType: ProviderType;
  variablePercentage?: Maybe<Scalars['Float']['output']>;
};

export type InvoiceLineItem = {
  description?: Maybe<Scalars['String']['output']>;
  discounts?: Maybe<Array<InvoiceDiscount>>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  position: Scalars['Long']['output'];
  productId?: Maybe<Scalars['String']['output']>;
  quantity: Scalars['Long']['output'];
  taxable: Scalars['Boolean']['output'];
  unitPrice: MonetaryValue;
};

export type InvoicePaymentDetails = {
  paymentAmount: MonetaryValue;
  paymentConfirmationId: Scalars['String']['output'];
  paymentDate: Scalars['DateTime']['output'];
  paymentInstrument: PaymentInstrument;
};

export enum InvoiceStatus {
  Cancelled = 'Cancelled',
  Draft = 'Draft',
  Failed = 'Failed',
  MarkedAsPaid = 'MarkedAsPaid',
  OnHold = 'OnHold',
  Paid = 'Paid',
  PaymentCanceled = 'PaymentCanceled',
  PaymentProcessing = 'PaymentProcessing',
  PaymentRejected = 'PaymentRejected',
  PaymentRequiresAttention = 'PaymentRequiresAttention',
  PaymentReturned = 'PaymentReturned',
  Pending = 'Pending',
  Refunded = 'Refunded',
  Sent = 'Sent',
  Settled = 'Settled',
  Submitted = 'Submitted',
  Unknown = 'Unknown',
  Voided = 'Voided'
}

export type InvoiceStatusSummary = {
  internalNoteForStatus?: Maybe<Scalars['String']['output']>;
  invoiceSentDate: Scalars['DateTime']['output'];
  status: InvoiceStatus;
  statusAt: Scalars['DateTime']['output'];
  userId?: Maybe<Scalars['String']['output']>;
};

export type InvoicingAddress = {
  address: Address;
  id: Scalars['String']['output'];
};

export type InvoicingProfile = InvoicingProfileInterface & {
  backgroundColor?: Maybe<Scalars['String']['output']>;
  companyAddress: InvoicingAddress;
  companyEmail: Scalars['String']['output'];
  companyIcon?: Maybe<Scalars['String']['output']>;
  companyName: Scalars['String']['output'];
  coverURL?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  payoutInstrumentID: Scalars['String']['output'];
};

export type InvoicingProfileInterface = {
  backgroundColor?: Maybe<Scalars['String']['output']>;
  companyAddress: InvoicingAddress;
  companyEmail: Scalars['String']['output'];
  companyIcon?: Maybe<Scalars['String']['output']>;
  companyName: Scalars['String']['output'];
  coverURL?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  payoutInstrumentID: Scalars['String']['output'];
};

export type JobError = {
  createdAt: Scalars['DateTime']['output'];
  errorMessage: Scalars['String']['output'];
  jobMetadata: JobMetadata;
  jobOwner: JobOwner;
  jobType: DataSourceJobType;
};

export type JobMetadata = {
  consumer: Scalars['String']['output'];
  requestObjectId: Scalars['String']['output'];
  userId: Scalars['String']['output'];
};

export type JobOwner = {
  dataSourceId: Scalars['String']['output'];
  legalEntityId: Scalars['String']['output'];
};

export type LabelCategory = {
  category?: Maybe<EntityCategory>;
  /**
   * The default category type for this label
   * This can be used to find the uncategorized category for the given
   * accounting category root type
   */
  defaultCategoryType: CategoryType;
  displayName: Scalars['String']['output'];
  label: ConnectLabel;
  required: Scalars['Boolean']['output'];
  tooltip?: Maybe<Scalars['String']['output']>;
  /**
   * This contains a exhaustive list of all of the valid category types
   * that can be assigned to this label for the given connector. This will
   * include the default category type as well as any other category types
   * that are valid
   */
  validCategoryTypes: Array<CategoryType>;
};

export type Layout = {
  layoutId: Scalars['String']['output'];
  layoutVersionId?: Maybe<Scalars['String']['output']>;
  sections?: Maybe<Array<LayoutSection>>;
  viewKey: ViewIdentifier;
};

export type LayoutAttachment = {
  layoutId: Scalars['String']['output'];
  legalEntityId: Scalars['String']['output'];
};

export type LayoutComponent = {
  componentId: Scalars['String']['output'];
  config: LayoutComponentConfig;
  dataId?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type LayoutComponentConfig = {
  cashSummaryChart?: Maybe<CashSummaryChartConfig>;
  categoryChart?: Maybe<CategoryChartConfig>;
  departmentChart?: Maybe<DepartmentChartConfig>;
  documentCollection?: Maybe<DocumentCollectionConfig>;
  entitiesList?: Maybe<EntitiesListConfig>;
  insight?: Maybe<InsightComponentConfig>;
  locationChart?: Maybe<LocationChartConfig>;
  metricChart?: Maybe<MetricChartConfig>;
  partyChart?: Maybe<PartyChartConfig>;
  portalMetric?: Maybe<PortalMetricConfig>;
  runwayChart?: Maybe<RunwayChartConfig>;
  statement?: Maybe<StatementConfig>;
  staticMetric?: Maybe<StaticMetricConfig>;
  summaryLineItem?: Maybe<SummaryLineItemConfig>;
  text?: Maybe<TextComponentConfig>;
  topEntities?: Maybe<TopEntitiesConfig>;
  topTransactions?: Maybe<TopTransactionsConfig>;
  type: LayoutComponentType;
};

export type LayoutComponentConfigInput = {
  cashSummaryChart?: InputMaybe<CashSummaryChartConfigInput>;
  categoryChart?: InputMaybe<CategoryChartConfigInput>;
  departmentChart?: InputMaybe<DepartmentChartConfigInput>;
  documentCollection?: InputMaybe<DocumentCollectionConfigInput>;
  insight?: InputMaybe<InsightConfigInput>;
  locationChart?: InputMaybe<LocationChartConfigInput>;
  metricChart?: InputMaybe<MetricChartConfigInput>;
  partyChart?: InputMaybe<PartyChartConfigInput>;
  portalMetric?: InputMaybe<PortalMetricConfigInput>;
  runwayChart?: InputMaybe<RunwayChartConfigInput>;
  statement?: InputMaybe<StatementConfigInput>;
  staticMetric?: InputMaybe<StaticMetricConfigInput>;
  summaryLineItem?: InputMaybe<SummaryLineItemConfigInput>;
  text?: InputMaybe<TextComponentConfigInput>;
  topEntities?: InputMaybe<TopEntitiesConfigInput>;
  topTransactions?: InputMaybe<TopTransactionsConfigInput>;
  type: LayoutComponentType;
};

export type LayoutComponentInput = {
  componentId: Scalars['String']['input'];
  config: LayoutComponentConfigInput;
  dataId?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export enum LayoutComponentType {
  CashSummaryChart = 'CashSummaryChart',
  CategoryBalanceSummaryChart = 'CategoryBalanceSummaryChart',
  CategoryTransactionSummaryChart = 'CategoryTransactionSummaryChart',
  DepartmentChart = 'DepartmentChart',
  DocumentCollection = 'DocumentCollection',
  EntitiesList = 'EntitiesList',
  Insight = 'Insight',
  LocationChart = 'LocationChart',
  MetricSummaryChart = 'MetricSummaryChart',
  PartyRoleBalanceSummaryChart = 'PartyRoleBalanceSummaryChart',
  PartyTransactionSummaryChart = 'PartyTransactionSummaryChart',
  PortalMetric = 'PortalMetric',
  RunwayChart = 'RunwayChart',
  Statement = 'Statement',
  StaticMetric = 'StaticMetric',
  SummaryLineItemChart = 'SummaryLineItemChart',
  Text = 'Text',
  TopEntities = 'TopEntities',
  TopTransactions = 'TopTransactions'
}

export type LayoutDocument = {
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  layoutId: Scalars['String']['output'];
  status: ReportPackageDocumentStatus;
  title: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type LayoutInput = {
  layoutId: Scalars['String']['input'];
  sections?: InputMaybe<Array<LayoutSectionInput>>;
};

export type LayoutRow = {
  components?: Maybe<Array<LayoutComponent>>;
  rowId: Scalars['String']['output'];
  title?: Maybe<Scalars['String']['output']>;
};

export type LayoutRowInput = {
  components?: InputMaybe<Array<LayoutComponentInput>>;
  rowId: Scalars['String']['input'];
  title?: InputMaybe<Scalars['String']['input']>;
};

export type LayoutSection = {
  rows?: Maybe<Array<LayoutRow>>;
  sectionId: Scalars['String']['output'];
  title?: Maybe<Scalars['String']['output']>;
};

export type LayoutSectionInput = {
  rows?: InputMaybe<Array<LayoutRowInput>>;
  sectionId: Scalars['String']['input'];
  title?: InputMaybe<Scalars['String']['input']>;
};

export type LedgerAccountDifferences = {
  creditDifference?: Maybe<MoneyFlow>;
  debitDifference?: Maybe<MoneyFlow>;
  unreconciledDifference: MoneyFlow;
};

export type LedgerBalances = {
  closingBalance: MoneyFlow;
  creditTotal: MoneyFlow;
  debitTotal: MoneyFlow;
  netTotal: MoneyFlow;
  unsettledTotal: MoneyFlow;
};

export type LedgerCategoryInput = {
  canonicalLabel: Scalars['String']['input'];
  categoryName: Scalars['String']['input'];
  categoryType: Scalars['String']['input'];
  description: Scalars['String']['input'];
  nonNegativeBalanceInvariant?: InputMaybe<Scalars['Boolean']['input']>;
};

/**
 * # output types
 * LedgerTransaction is a transaction with multiple lines that are in balance
 */
export type LedgerTransaction = {
  compileSource?: Maybe<CompileSource>;
  externalId: Scalars['String']['output'];
  isMetadataEditable: Scalars['Boolean']['output'];
  ledgerTransactionId: Scalars['String']['output'];
  lines: Array<TransactionLine>;
  memo: Scalars['String']['output'];
  referenceNumber?: Maybe<Scalars['String']['output']>;
  sources: Array<TransactionSource>;
  timestamp: Scalars['DateTime']['output'];
  type: TransactionType;
};

export type LedgerTransactionStale = {
  newViewAvailable: Scalars['Boolean']['output'];
  stale: Scalars['Boolean']['output'];
};

export enum LedgerType {
  Digits = 'Digits',
  QuickBooks = 'QuickBooks',
  Unspecified = 'Unspecified'
}

export type LegalEntity = {
  address: Address;
  fiscalYearNumberOffset: Scalars['Long']['output'];
  fiscalYearStartMonth: Scalars['Long']['output'];
  id: Scalars['String']['output'];
  incorporatedOn?: Maybe<Date>;
  industry?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  organizationId: Scalars['String']['output'];
  sensitive: Scalars['Boolean']['output'];
  slug: Scalars['String']['output'];
  status: LegalEntityStatus;
  timezone?: Maybe<Scalars['String']['output']>;
  websiteUrl?: Maybe<Scalars['String']['output']>;
};

export type LegalEntityAspectStatus = {
  legalEntityId: Scalars['String']['output'];
  legalEntityName: Scalars['String']['output'];
  statuses: Array<AspectStatus>;
};

export type LegalEntityBadgeCount = {
  count: Scalars['Long']['output'];
  legalEntityId: Scalars['String']['output'];
};

export type LegalEntityPreferences = {
  bookkeeperAiCutoverAt?: Maybe<Scalars['DateTime']['output']>;
  isCogsInExpenses: Scalars['Boolean']['output'];
  legalEntityId: Scalars['String']['output'];
  lineClassDisplayName: Scalars['String']['output'];
  lineClassPluralDisplayName: Scalars['String']['output'];
};

export type LegalEntityRole = {
  legalEntityId: Scalars['String']['input'];
  role: Scalars['String']['input'];
};

export enum LegalEntityStatus {
  Active = 'Active',
  Approved = 'Approved',
  New = 'New',
  Pending = 'Pending',
  PendingHold = 'PendingHold',
  Unknown = 'Unknown'
}

export type LegalEntityUserInfo = {
  legalEntity: LegalEntity;
  userInfo: UserInfo;
};

export type LineItemFeatureValue = {
  lineItemValue: DocumentLineItem;
};

export enum ListSignupRequestStatus {
  AllSignups = 'AllSignups',
  Applied = 'Applied',
  Ignored = 'Ignored',
  InviteAccepted = 'InviteAccepted',
  Invited = 'Invited',
  PrimerSent = 'PrimerSent',
  Qualified = 'Qualified',
  Unknown = 'Unknown',
  Unqualified = 'Unqualified',
  Waitlisted = 'Waitlisted'
}

export type Location = {
  active: Scalars['Boolean']['output'];
  ancestors: Array<EntityLocation>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  parentId?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use parentId instead */
  parentLocationId?: Maybe<Scalars['String']['output']>;
};

export type LocationChartConfig = {
  locationId: Scalars['String']['output'];
  origin: ArchiveIntervalOrigin;
};

export type LocationChartConfigInput = {
  locationId: Scalars['String']['input'];
  origin: IntervalOrigin;
};

export type LocationDimension = {
  by: DimensionSummary;
  locationId: Scalars['String']['output'];
  locationObject: EntityLocation;
  summary: TransactionSummary;
};

export type LocationSummary = {
  cogsChange: DimensionSummary;
  expensesChange: DimensionSummary;
  incomeChange: DimensionSummary;
  location: EntityLocation;
  otherExpensesChange: DimensionSummary;
  otherIncomeChange: DimensionSummary;
  total: DimensionSummary;
};

export enum ManualMatchType {
  Match = 'Match',
  Unmatch = 'Unmatch'
}

export type MappedCategory = {
  fromCategory?: Maybe<AdminCategory>;
  toCategory?: Maybe<AdminCategory>;
};

export type MappedChartOfAccounts = {
  accountingBasis: AccountingBasis;
  categories: Array<MappedCategory>;
  description?: Maybe<Scalars['String']['output']>;
  fromUUID: Scalars['String']['output'];
  legalEntityId: Scalars['String']['output'];
  name?: Maybe<Scalars['String']['output']>;
  toUUID: Scalars['String']['output'];
};

export enum MappingValidity {
  Invalid = 'Invalid',
  PartiallyValid = 'PartiallyValid',
  Valid = 'Valid'
}

export type MarketingEvent = {
  attributes?: InputMaybe<MarketingEventAttributes>;
  legalEntityId?: InputMaybe<Scalars['String']['input']>;
  organizationId?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  type: MarketingEventType;
};

export type MarketingEventAttributes = {
  accountState?: InputMaybe<Scalars['String']['input']>;
  companyName?: InputMaybe<Scalars['String']['input']>;
  createAt?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  formerProvider?: InputMaybe<Scalars['String']['input']>;
  industry?: InputMaybe<Scalars['String']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  ledger?: InputMaybe<Scalars['String']['input']>;
  planName?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  website?: InputMaybe<Scalars['String']['input']>;
};

export enum MarketingEventType {
  OnboardingAccountDetailsComplete = 'OnboardingAccountDetailsComplete',
  OnboardingAccountSetupComplete = 'OnboardingAccountSetupComplete',
  OnboardingAddCC = 'OnboardingAddCC',
  OnboardingConnectQBO = 'OnboardingConnectQBO',
  OnboardingConnectionAdded = 'OnboardingConnectionAdded',
  OnboardingFormerProvider = 'OnboardingFormerProvider',
  OnboardingSelectBank = 'OnboardingSelectBank',
  OnboardingSelectFinanceTools = 'OnboardingSelectFinanceTools',
  OnboardingSignIn = 'OnboardingSignIn',
  OnboardingSignUp = 'OnboardingSignUp',
  PlanManagementAddUser = 'PlanManagementAddUser',
  PlanManagementPaymentComplete = 'PlanManagementPaymentComplete',
  PlanManagementPlanCancelled = 'PlanManagementPlanCancelled',
  PlanManagementPlanDowngrade = 'PlanManagementPlanDowngrade',
  PlanManagementPlanUpgrade = 'PlanManagementPlanUpgrade',
  PlanManagementTrialCancel = 'PlanManagementTrialCancel',
  TrialEnd = 'TrialEnd',
  TrialStart = 'TrialStart',
  UnknownType = 'UnknownType',
  UsageActivateLegalEntity = 'UsageActivateLegalEntity',
  UsageAddUser = 'UsageAddUser',
  UsageBillPayed = 'UsageBillPayed',
  UsageCreateReport = 'UsageCreateReport',
  UsageRecategorizeTransaction = 'UsageRecategorizeTransaction',
  UsageSendInvoice = 'UsageSendInvoice',
  UsageShareReport = 'UsageShareReport',
  UsageSignIn = 'UsageSignIn'
}

export type MatchResponse = {
  neighbor: Array<Neighbor>;
};

export type MatchSetEntry = {
  createdAt: Scalars['DateTime']['output'];
  dataSourceId: Scalars['String']['output'];
  entryType: MatchedSetRowType;
  id: Scalars['String']['output'];
  legalEntityId: Scalars['String']['output'];
  matchSetId: Scalars['String']['output'];
  transactionFactId: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export enum MatchType {
  Heuristic = 'Heuristic',
  Manual = 'Manual',
  SourceLinked = 'SourceLinked',
  Unknown = 'Unknown'
}

export enum MatchedSetRowType {
  JournalEntry = 'JournalEntry',
  Recorded = 'Recorded',
  SourceCredit = 'SourceCredit',
  SourceDebit = 'SourceDebit',
  Unknown = 'Unknown'
}

export enum Matcher {
  Category = 'Category',
  CategoryType = 'CategoryType',
  PartyRole = 'PartyRole',
  ProductArea = 'ProductArea'
}

export enum MergeableStatus {
  Behind = 'Behind',
  Blocked = 'Blocked',
  Clean = 'Clean',
  Dirty = 'Dirty',
  Draft = 'Draft',
  HasHooks = 'HasHooks',
  UnknownMergeableStatus = 'UnknownMergeableStatus',
  Unstable = 'Unstable'
}

export type MetricCategoriesOperand = {
  categories: Array<EntityCategory>;
  defaultCategoryType: CategoryType;
  isBalance: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  naturalFlow: Flow;
  period?: Maybe<Period>;
};

export type MetricChartConfig = {
  item: MetricSummaryItem;
  origin: ArchiveIntervalOrigin;
};

export type MetricChartConfigInput = {
  item: MetricSummaryItem;
  origin: IntervalOrigin;
};

export type MetricEquation = {
  terms: Array<MetricTerm>;
};

export type MetricOperation = {
  symbol: MetricSymbol;
};

export enum MetricSummaryItem {
  GrossBurn = 'GrossBurn',
  GrossIncome = 'GrossIncome',
  NetBurn = 'NetBurn',
  UnknownMetricType = 'UnknownMetricType'
}

export type MetricSummaryTypeOperand = {
  defaultCategoryType: CategoryType;
  isBalance: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  naturalFlow: Flow;
  period?: Maybe<Period>;
  type: MetricSummaryItem;
};

export enum MetricSymbol {
  Add = 'Add',
  Divide = 'Divide',
  Subtract = 'Subtract'
}

export type MetricTerm = MetricCategoriesOperand | MetricOperation | MetricSummaryTypeOperand;

export enum MetricType {
  COGS = 'COGS',
  Expenses = 'Expenses',
  GrossProfit = 'GrossProfit',
  Income = 'Income',
  NetIncome = 'NetIncome',
  NetOperatingIncome = 'NetOperatingIncome',
  ProfitPercentage = 'ProfitPercentage'
}

export enum MetricValidationType {
  GrossBurn = 'GrossBurn',
  GrossIncome = 'GrossIncome',
  NetBurn = 'NetBurn',
  Runway = 'Runway'
}

export type MigrationFileSummary = {
  file: PullRequestFile;
  hasRun: Scalars['Boolean']['output'];
};

export type MigrationPullRequest = {
  fileSummaries: Array<MigrationFileSummary>;
  pullRequestSummary: PullRequestSummary;
};

export type MinMaxAmount = {
  maxAmount: MonetaryValue;
  minAmount: MonetaryValue;
};

export type MismatchedQuickbooksBalanceSheetCategory = {
  categoryName: Scalars['String']['output'];
  categoryType: Scalars['String']['output'];
  digitsEndingBalance: Scalars['Long']['output'];
  digitsStartingBalance: Scalars['Long']['output'];
  endingDelta: Scalars['Long']['output'];
  externalEndingBalance: Scalars['Long']['output'];
  externalStartingBalance: Scalars['Long']['output'];
  period: Period;
  startingDelta: Scalars['Long']['output'];
};

export type MismatchedQuickbooksCategory = {
  categoryName: Scalars['String']['output'];
  categoryType: Scalars['String']['output'];
  difference: Scalars['Long']['output'];
  digitsValue: Scalars['Long']['output'];
  externalValue: Scalars['Long']['output'];
  period: Period;
};

export enum MissingParty {
  Exclude = 'Exclude',
  Include = 'Include',
  Unknown = 'Unknown'
}

export type MonetaryFeatureValue = {
  monetaryValue: MonetaryValue;
};

/** MonetaryValue */
export type MonetaryValue = {
  amount: Scalars['Long']['output'];
  currencyMultiplier: Scalars['Long']['output'];
  iso4217CurrencyCode: Scalars['String']['output'];
};

export type MoneyFlow = {
  businessFlow: Flow;
  isNormal: Scalars['Boolean']['output'];
  value: MonetaryValue;
};

export type MoneyFlowInput = {
  businessFlow: Flow;
  isNormal: Scalars['Boolean']['input'];
  value: InputMonetaryValue;
};

export type Mutation = {
  addReportValidationMetric?: Maybe<Scalars['Void']['output']>;
  addSuperuserRole: Superuser;
  /** Aspects */
  approveRequest?: Maybe<Scalars['Void']['output']>;
  backfillPlaidConnection?: Maybe<Scalars['Void']['output']>;
  backfillRecorderEncryptionKeys?: Maybe<Scalars['Void']['output']>;
  cancelInvitation?: Maybe<Scalars['Void']['output']>;
  claimFundsForLE?: Maybe<Scalars['Void']['output']>;
  createAlias: GlobalPartyAlias;
  createClassificationValidationExpectation?: Maybe<ClassificationValidation>;
  createConnectionTemplate?: Maybe<Scalars['Void']['output']>;
  /** Doppelganger */
  createDoppelgangerPermit: AdminDoppelgangerPermit;
  createGlobalParty: AdminParty;
  /** Institutions */
  createInstitution: AdminInstitution;
  createMatchConfiguration: Scalars['String']['output'];
  createPrefixMapping: PrefixMapping;
  /** Report Packages */
  createReportPackage: ReportPackageMetadata;
  /** Superusers */
  createSuperuser: Superuser;
  /** Data Sources */
  deleteAdminDataSource?: Maybe<Scalars['Void']['output']>;
  deleteAlias?: Maybe<Scalars['Void']['output']>;
  /** Connect */
  deleteConnection?: Maybe<Scalars['Void']['output']>;
  deleteConnectionTemplate?: Maybe<Scalars['Void']['output']>;
  deleteCsvImportTransactions?: Maybe<Scalars['Void']['output']>;
  deleteGlobalParty?: Maybe<Scalars['Void']['output']>;
  deletePartyExtraction?: Maybe<Scalars['Void']['output']>;
  /** Prefixes */
  deletePrefixMapping?: Maybe<Scalars['Void']['output']>;
  deleteReportPackage?: Maybe<Scalars['Void']['output']>;
  deleteReportValidationMetric?: Maybe<Scalars['Void']['output']>;
  deleteSuperuser?: Maybe<Scalars['Void']['output']>;
  /** Parties */
  designateParty?: Maybe<Scalars['Void']['output']>;
  disableConnection?: Maybe<Scalars['Void']['output']>;
  disconnectPlaidItem?: Maybe<Scalars['Void']['output']>;
  enableConnection?: Maybe<Scalars['Void']['output']>;
  enqueuePaymentCapture?: Maybe<Scalars['Void']['output']>;
  ignorePrefix?: Maybe<Scalars['Void']['output']>;
  ledgerBackfillCategories?: Maybe<Scalars['Void']['output']>;
  ledgerCreateCategory: Scalars['String']['output'];
  ledgerCreateJournalEntry: Scalars['String']['output'];
  mergeMigrationPullRequest?: Maybe<Scalars['Void']['output']>;
  /** Migrations */
  migrateEmployeeToAffiliate?: Maybe<Scalars['Void']['output']>;
  onboard?: Maybe<Scalars['Void']['output']>;
  removeSuperuserRole?: Maybe<Scalars['Void']['output']>;
  /** Action Item */
  replayObjectEventActionItemDLQ?: Maybe<Scalars['Void']['output']>;
  republishPubSubMessages?: Maybe<Scalars['Void']['output']>;
  resendActivationEmail?: Maybe<Scalars['Void']['output']>;
  resendInvitation?: Maybe<Scalars['Void']['output']>;
  resetConnection?: Maybe<Scalars['Void']['output']>;
  resetWorkItem?: Maybe<Scalars['Void']['output']>;
  runMigrationPullRequest?: Maybe<Scalars['Void']['output']>;
  /** Users and Invitations */
  sendInvitation?: Maybe<Scalars['Void']['output']>;
  sendManagedOperatorInvitation?: Maybe<Scalars['Void']['output']>;
  sendUserLegalEntityApproval?: Maybe<Scalars['Void']['output']>;
  setFailoverSetting?: Maybe<Scalars['Void']['output']>;
  softDeleteLegalEntity?: Maybe<Scalars['Void']['output']>;
  /** Soft Delete */
  softDeleteOrganization?: Maybe<Scalars['Void']['output']>;
  syncConnection?: Maybe<Scalars['Void']['output']>;
  triggerCustomLedgerCorrection?: Maybe<Scalars['Void']['output']>;
  triggerDataSourceClassification?: Maybe<Scalars['Void']['output']>;
  triggerDebugTransactionExport: EntityLegalEntity;
  /** Supports developer CLI tool for debugging data exports */
  triggerDebugViewExport: EntityLegalEntity;
  /** Data Processing */
  triggerDenormalizedViewGeneration?: Maybe<Scalars['Void']['output']>;
  /** Transaction Review */
  triggerDigitsLedgerReview?: Maybe<Scalars['Void']['output']>;
  /** Transaction Facts */
  triggerSourceDataClassification?: Maybe<Scalars['Void']['output']>;
  /** Work Items */
  triggerTransactionAuditCheck?: Maybe<Scalars['Void']['output']>;
  unignorePrefix?: Maybe<Scalars['Void']['output']>;
  unlockUser?: Maybe<Scalars['Void']['output']>;
  updateAspectStatus?: Maybe<Scalars['Void']['output']>;
  updateClassificationValidationExpectation?: Maybe<ClassificationValidation>;
  /** Employees */
  updateEmployeeHidden?: Maybe<Scalars['Void']['output']>;
  updateEmployeeStatus: AdminEmployee;
  updateGlobalParty?: Maybe<Scalars['Void']['output']>;
  updateInstitution?: Maybe<Scalars['Void']['output']>;
  updateInstitutionParty?: Maybe<Scalars['Void']['output']>;
  updateInstitutionProducts?: Maybe<Scalars['Void']['output']>;
  /** Legal Entities */
  updateLegalEntityStatus?: Maybe<Scalars['Void']['output']>;
  updateParty?: Maybe<Scalars['Void']['output']>;
  /** Signups */
  updateSignupStatus?: Maybe<Scalars['Void']['output']>;
};


export type MutationAddReportValidationMetricArgs = {
  cashOutDate?: InputMaybe<Scalars['DateTime']['input']>;
  interval: Interval;
  legalEntityId: Scalars['String']['input'];
  moneyFlow: MoneyFlowInput;
  period: PeriodInput;
  type: MetricValidationType;
};


export type MutationAddSuperuserRoleArgs = {
  roleIdentifier: Scalars['String']['input'];
  superuserId: Scalars['String']['input'];
};


export type MutationApproveRequestArgs = {
  id: Scalars['String']['input'];
};


export type MutationBackfillPlaidConnectionArgs = {
  id: Scalars['String']['input'];
};


export type MutationBackfillRecorderEncryptionKeysArgs = {
  connectionIds: Array<Scalars['String']['input']>;
};


export type MutationCancelInvitationArgs = {
  token: Scalars['String']['input'];
};


export type MutationClaimFundsForLeArgs = {
  amount: InputMonetaryValue;
  billPaymentId: Scalars['String']['input'];
  legalEntityId: Scalars['String']['input'];
  payerCompanyName: Scalars['String']['input'];
  recipientEmailAddress: Scalars['String']['input'];
};


export type MutationCreateAliasArgs = {
  alias: Scalars['String']['input'];
  partyId: Scalars['String']['input'];
};


export type MutationCreateClassificationValidationExpectationArgs = {
  creditCategoryName?: InputMaybe<Scalars['String']['input']>;
  debitCategoryName?: InputMaybe<Scalars['String']['input']>;
  employeeOwnerName?: InputMaybe<Scalars['String']['input']>;
  facts: Array<NewClassificationValidationExpectationFact>;
  legalEntityId: Scalars['String']['input'];
  partyIconObjectName?: InputMaybe<Scalars['String']['input']>;
  partyName?: InputMaybe<Scalars['String']['input']>;
  partyRole?: InputMaybe<Scalars['String']['input']>;
};


export type MutationCreateConnectionTemplateArgs = {
  connectorId: Scalars['String']['input'];
  institutionId: Scalars['String']['input'];
};


export type MutationCreateDoppelgangerPermitArgs = {
  justification?: InputMaybe<Scalars['String']['input']>;
  targetUserId: Scalars['String']['input'];
};


export type MutationCreateGlobalPartyArgs = {
  aliases: Array<Scalars['String']['input']>;
  fields: NewParty;
  prefixes: Array<Scalars['String']['input']>;
};


export type MutationCreateInstitutionArgs = {
  kind: InstitutionKind;
  name: Scalars['String']['input'];
  partyId: Scalars['String']['input'];
  routingNumber: Scalars['String']['input'];
  url: Scalars['String']['input'];
};


export type MutationCreateMatchConfigurationArgs = {
  ledgerTransactionIds: Array<Scalars['String']['input']>;
  manualMatchType: ManualMatchType;
};


export type MutationCreatePrefixMappingArgs = {
  partyId: Scalars['String']['input'];
  prefix: Scalars['String']['input'];
};


export type MutationCreateReportPackageArgs = {
  attachments: NewReportAttachments;
  interval: Interval;
  periodEndAt: DateInput;
  periodStartAt: DateInput;
  title: Scalars['String']['input'];
  viewKey: ViewKey;
};


export type MutationCreateSuperuserArgs = {
  emailAddress: Scalars['String']['input'];
  familyName?: InputMaybe<Scalars['String']['input']>;
  givenName?: InputMaybe<Scalars['String']['input']>;
  roleIdentifiers: Array<Scalars['String']['input']>;
};


export type MutationDeleteAdminDataSourceArgs = {
  dataSourceId: Scalars['String']['input'];
  legalEntityId: Scalars['String']['input'];
  providerType: ProviderType;
};


export type MutationDeleteAliasArgs = {
  alias: Scalars['String']['input'];
  partyId: Scalars['String']['input'];
};


export type MutationDeleteConnectionArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteConnectionTemplateArgs = {
  connectorId: Scalars['String']['input'];
  institutionId: Scalars['String']['input'];
};


export type MutationDeleteCsvImportTransactionsArgs = {
  csvImportId: Scalars['String']['input'];
  legalEntityId: Scalars['String']['input'];
};


export type MutationDeleteGlobalPartyArgs = {
  partyId: Scalars['String']['input'];
};


export type MutationDeletePartyExtractionArgs = {
  legalEntityId: Scalars['String']['input'];
  partyExtractionId: Scalars['String']['input'];
};


export type MutationDeletePrefixMappingArgs = {
  partyId: Scalars['String']['input'];
  prefix: Scalars['String']['input'];
};


export type MutationDeleteReportPackageArgs = {
  legalEntityId: Scalars['String']['input'];
  packageId: Scalars['String']['input'];
};


export type MutationDeleteReportValidationMetricArgs = {
  interval: Interval;
  legalEntityId: Scalars['String']['input'];
  period: PeriodInput;
  type: MetricValidationType;
};


export type MutationDeleteSuperuserArgs = {
  id: Scalars['String']['input'];
};


export type MutationDesignatePartyArgs = {
  designation: PartyDesignation;
  legalEntityId: Scalars['String']['input'];
  partyId: Scalars['String']['input'];
};


export type MutationDisableConnectionArgs = {
  id: Scalars['String']['input'];
};


export type MutationDisconnectPlaidItemArgs = {
  accessToken: Scalars['String']['input'];
};


export type MutationEnableConnectionArgs = {
  id: Scalars['String']['input'];
};


export type MutationEnqueuePaymentCaptureArgs = {
  paymentIntentId: Scalars['String']['input'];
  topic: Scalars['String']['input'];
};


export type MutationIgnorePrefixArgs = {
  prefix: Scalars['String']['input'];
};


export type MutationLedgerBackfillCategoriesArgs = {
  category: LedgerCategoryInput;
  chartOfAccountsID: Scalars['String']['input'];
};


export type MutationLedgerCreateCategoryArgs = {
  category: LedgerCategoryInput;
  legalEntityId: Scalars['String']['input'];
};


export type MutationLedgerCreateJournalEntryArgs = {
  amount: Scalars['Long']['input'];
  creditCanonicalCategoryLabel: Scalars['String']['input'];
  debitCanonicalCategoryLabel: Scalars['String']['input'];
  legalEntityId: Scalars['String']['input'];
};


export type MutationMergeMigrationPullRequestArgs = {
  prNumber: Scalars['Int']['input'];
  repo: Scalars['String']['input'];
};


export type MutationMigrateEmployeeToAffiliateArgs = {
  legalEntityId: Scalars['String']['input'];
  organizationId?: InputMaybe<Scalars['String']['input']>;
  organizationName?: InputMaybe<Scalars['String']['input']>;
  userId: Scalars['String']['input'];
};


export type MutationOnboardArgs = {
  legalEntityId: Scalars['String']['input'];
};


export type MutationRemoveSuperuserRoleArgs = {
  roleIdentifier: Scalars['String']['input'];
  superuserId: Scalars['String']['input'];
};


export type MutationRepublishPubSubMessagesArgs = {
  destinationTopic: Scalars['String']['input'];
  projectId: Scalars['String']['input'];
  sourceSubscription: Scalars['String']['input'];
};


export type MutationResendActivationEmailArgs = {
  legalEntityId: Scalars['String']['input'];
  recipientId: Scalars['String']['input'];
};


export type MutationResendInvitationArgs = {
  token: Scalars['String']['input'];
};


export type MutationResetConnectionArgs = {
  id: Scalars['String']['input'];
};


export type MutationResetWorkItemArgs = {
  workItemId: Scalars['String']['input'];
};


export type MutationRunMigrationPullRequestArgs = {
  filename: Scalars['String']['input'];
  isPartitionableDML: Scalars['Boolean']['input'];
  prNumber: Scalars['Int']['input'];
  repo: Scalars['String']['input'];
};


export type MutationSendInvitationArgs = {
  destinationOrganizationId?: InputMaybe<Scalars['String']['input']>;
  destinationOrganizationRole?: InputMaybe<Scalars['String']['input']>;
  invitationType: Scalars['String']['input'];
  legalEntityId?: InputMaybe<Scalars['String']['input']>;
  newOrganizationIcon?: InputMaybe<Scalars['String']['input']>;
  newOrganizationName?: InputMaybe<Scalars['String']['input']>;
  newOrganizationWebsiteUrl?: InputMaybe<Scalars['String']['input']>;
  proposalType?: InputMaybe<Scalars['String']['input']>;
  recipient: ContactInput;
  signupId?: InputMaybe<Scalars['String']['input']>;
};


export type MutationSendManagedOperatorInvitationArgs = {
  affiliateOrganizationId: Scalars['String']['input'];
  affiliateUserId: Scalars['String']['input'];
  legalEntityId: Scalars['String']['input'];
  message?: InputMaybe<Scalars['String']['input']>;
  organizationId: Scalars['String']['input'];
  recipient: ContactInput;
  redirectPath?: InputMaybe<Scalars['String']['input']>;
};


export type MutationSendUserLegalEntityApprovalArgs = {
  legalEntityId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};


export type MutationSetFailoverSettingArgs = {
  enabled: Scalars['Boolean']['input'];
};


export type MutationSoftDeleteLegalEntityArgs = {
  id: Scalars['String']['input'];
};


export type MutationSoftDeleteOrganizationArgs = {
  deleteUsers: Scalars['Boolean']['input'];
  id: Scalars['String']['input'];
};


export type MutationSyncConnectionArgs = {
  id: Scalars['String']['input'];
};


export type MutationTriggerDataSourceClassificationArgs = {
  dataSourceId: Scalars['String']['input'];
  legalEntityId: Scalars['String']['input'];
};


export type MutationTriggerDebugTransactionExportArgs = {
  dstLegalEntityId: Scalars['String']['input'];
  secret: Scalars['String']['input'];
  secretId: Scalars['String']['input'];
  srcLegalEntityId: Scalars['String']['input'];
  srcTransactionFactId: Scalars['String']['input'];
};


export type MutationTriggerDebugViewExportArgs = {
  dstLegalEntityId: Scalars['String']['input'];
  dstViewVersion: Scalars['String']['input'];
  secret: Scalars['String']['input'];
  secretId: Scalars['String']['input'];
  srcLegalEntityId: Scalars['String']['input'];
  srcViewVersion?: InputMaybe<Scalars['String']['input']>;
};


export type MutationTriggerDenormalizedViewGenerationArgs = {
  legalEntityId: Scalars['String']['input'];
};


export type MutationTriggerDigitsLedgerReviewArgs = {
  ledgerType: LedgerType;
  legalEntityId: Scalars['String']['input'];
};


export type MutationTriggerSourceDataClassificationArgs = {
  dataSourceId: Scalars['String']['input'];
  externalId: Scalars['String']['input'];
  legalEntityId: Scalars['String']['input'];
};


export type MutationTriggerTransactionAuditCheckArgs = {
  transactionFactId: Scalars['String']['input'];
};


export type MutationUnignorePrefixArgs = {
  prefix: Scalars['String']['input'];
};


export type MutationUnlockUserArgs = {
  id: Scalars['String']['input'];
};


export type MutationUpdateAspectStatusArgs = {
  aspectId: Scalars['String']['input'];
  legalEntityId: Scalars['String']['input'];
  status: AspectStatusValue;
};


export type MutationUpdateClassificationValidationExpectationArgs = {
  creditCategoryName?: InputMaybe<Scalars['String']['input']>;
  debitCategoryName?: InputMaybe<Scalars['String']['input']>;
  employeeOwnerName?: InputMaybe<Scalars['String']['input']>;
  expectationId: Scalars['String']['input'];
  facts: Array<NewClassificationValidationExpectationFact>;
  legalEntityId: Scalars['String']['input'];
  partyIconObjectName?: InputMaybe<Scalars['String']['input']>;
  partyName?: InputMaybe<Scalars['String']['input']>;
  partyRole?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateEmployeeHiddenArgs = {
  employeeId: Scalars['String']['input'];
  isHidden: Scalars['Boolean']['input'];
  organizationId: Scalars['String']['input'];
};


export type MutationUpdateEmployeeStatusArgs = {
  employeeId: Scalars['String']['input'];
  organizationId: Scalars['String']['input'];
  status: EmployeeStatus;
};


export type MutationUpdateGlobalPartyArgs = {
  fields: NewParty;
  partyId: Scalars['String']['input'];
};


export type MutationUpdateInstitutionArgs = {
  institution_id: Scalars['String']['input'];
  name: Scalars['String']['input'];
  routingNumber: Scalars['String']['input'];
  url: Scalars['String']['input'];
};


export type MutationUpdateInstitutionPartyArgs = {
  institutionId: Scalars['String']['input'];
  partyId: Scalars['String']['input'];
};


export type MutationUpdateInstitutionProductsArgs = {
  institutionId: Scalars['String']['input'];
  products: Array<InstitutionProduct>;
};


export type MutationUpdateLegalEntityStatusArgs = {
  id: Scalars['String']['input'];
  status: LegalEntityStatus;
};


export type MutationUpdatePartyArgs = {
  details: NewParty;
  legalEntityId: Scalars['String']['input'];
  partyId: Scalars['String']['input'];
};


export type MutationUpdateSignupStatusArgs = {
  id: Scalars['String']['input'];
  status: SignupStatus;
  statusReason?: InputMaybe<Scalars['String']['input']>;
};

export type Namespace = {
  allowTokens?: InputMaybe<Array<Scalars['String']['input']>>;
  denyTokens?: InputMaybe<Array<Scalars['String']['input']>>;
  name: Scalars['String']['input'];
};

export type Neighbor = {
  distance: Scalars['Float']['output'];
  id: Scalars['String']['output'];
};

export type NewAiBookkeeperLegalEntity = {
  legalEntity: LegalEntity;
  quotePackage: QuotePackage;
  userInfo: UserInfo;
};

export type NewBillingSubscription = {
  customerId: Scalars['String']['output'];
  stripeClientSecret: Scalars['String']['output'];
};

export type NewClassificationValidationExpectationFact = {
  dataSourceId: Scalars['String']['input'];
  transactionFactId: Scalars['String']['input'];
};

/** NewComment represents an input comment. */
export type NewComment = {
  text: Scalars['String']['input'];
};

export type NewDocumentFeature = {
  kind: FeatureKind;
  value: NewDocumentFeatureValue;
};

export type NewDocumentFeatureValue = {
  textValue?: InputMaybe<Scalars['String']['input']>;
};

export type NewLayoutDocument = {
  initialText?: InputMaybe<Scalars['String']['input']>;
  position: Scalars['Int']['input'];
  tags?: InputMaybe<Array<TextComponentConfigTagInput>>;
  title: Scalars['String']['input'];
};

export type NewObjectGrant = {
  object: InputObjectIdentifier;
  permission?: InputMaybe<Permission>;
  policy?: InputMaybe<GrantPolicy>;
};

export type NewParty = {
  designation?: InputMaybe<PartyDesignation>;
  facebookUrl?: InputMaybe<Scalars['String']['input']>;
  iconUrl: Scalars['String']['input'];
  instagramUrl?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  shortDescription: Scalars['String']['input'];
  supportEmail?: InputMaybe<Scalars['String']['input']>;
  supportPhone?: InputMaybe<Scalars['String']['input']>;
  supportUrl?: InputMaybe<Scalars['String']['input']>;
  twitterUrl?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
  yelpUrl?: InputMaybe<Scalars['String']['input']>;
};

export type NewReportAttachments = {
  existing: Array<ExistingReportAttachment>;
  insightObjectIds?: InputMaybe<Array<InputObjectIdentifier>>;
  layout: Array<NewLayoutDocument>;
  statementKinds: Array<ReportKind>;
  text: Array<NewTextDocument>;
};

export type NewReportUpload = {
  fileId: Scalars['String']['input'];
  fileName: Scalars['String']['input'];
  fileSize: Scalars['Float']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  interval: Interval;
  mimeType: Scalars['String']['input'];
  position: Scalars['Int']['input'];
  reportFor: DateInput;
  title: Scalars['String']['input'];
};

export type NewTextDocument = {
  position: Scalars['Int']['input'];
  summary: Scalars['String']['input'];
  title: Scalars['String']['input'];
};

export type NewTrialBillingSubscription = {
  customerId: Scalars['String']['output'];
  productId: Scalars['String']['output'];
};

export type NewUserGrant = {
  emailAddress: Scalars['String']['input'];
  permission: Permission;
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type NotificationSetting = {
  displayKey: Scalars['String']['output'];
  enabled: Scalars['Boolean']['output'];
  eventType: EventType;
  legalEntityId: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type NumericFeatureValue = {
  numericValue: Scalars['Long']['output'];
};

export type OAuthCredentialFields = {
  code: Scalars['String']['input'];
  organizationId: Scalars['String']['input'];
  service: OAuthService;
};

export enum OAuthService {
  Google = 'Google',
  LinkedIn = 'LinkedIn',
  QuickBooks = 'QuickBooks',
  Unknown = 'Unknown'
}

export type ObjectAcl = {
  entities: ObjectEntities;
  grants: Array<UserGrant>;
  intents: Array<UserIntent>;
  link?: Maybe<Permission>;
  object: ObjectIdentifier;
  requests: Array<UserRequest>;
};

/**
 * ObjectEntities is a structure to thread through populated objects
 * indicated by ObjectIdentifiers to clients.
 */
export type ObjectEntities = {
  bills?: Maybe<Array<EntityBill>>;
  categories?: Maybe<Array<EntityCategory>>;
  comments?: Maybe<Array<EntityComment>>;
  datasources?: Maybe<Array<EntityDataSource>>;
  departments?: Maybe<Array<EntityDepartment>>;
  identityVerifications?: Maybe<Array<EntityIdentityVerification>>;
  invoices?: Maybe<Array<EntityInvoice>>;
  legalEntities?: Maybe<Array<EntityLegalEntity>>;
  locations?: Maybe<Array<EntityLocation>>;
  parties?: Maybe<Array<EntityParty>>;
  reportPackages?: Maybe<Array<EntityReportPackage>>;
  reports?: Maybe<Array<EntityReport>>;
  threads?: Maybe<Array<EntityThread>>;
  transactions?: Maybe<Array<EntityTransaction>>;
  users?: Maybe<Array<EntityUser>>;
  workItems?: Maybe<Array<EntityWorkItem>>;
};

export type ObjectEntity = EntityBill | EntityCategory | EntityComment | EntityDataSource | EntityDepartment | EntityIdentityVerification | EntityInvoice | EntityLedgerTransaction | EntityLegalEntity | EntityLocation | EntityParty | EntityReport | EntityReportPackage | EntityThread | EntityTransaction | EntityUser | EntityWorkItem;

export type ObjectGrant = {
  createdAt: Scalars['DateTime']['output'];
  grantId: Scalars['String']['output'];
  object: ObjectIdentifier;
  permission: Permission;
  viewType?: Maybe<ViewType>;
};

/**
 * ObjectIdentifier is a generic identifier with an enumerated kind.
 * It can be used to identify different Digits' core objects.
 */
export type ObjectIdentifier = {
  id: Scalars['String']['output'];
  kind: ObjectKind;
  legalEntityId: Scalars['String']['output'];
};

export type ObjectIntent = {
  creatorUserId: Scalars['String']['output'];
  intentId: Scalars['String']['output'];
  object: ObjectIdentifier;
  permission: Permission;
};

export enum ObjectKind {
  Bill = 'Bill',
  Category = 'Category',
  Comment = 'Comment',
  DataSource = 'DataSource',
  Department = 'Department',
  Invoice = 'Invoice',
  LedgerTransaction = 'LedgerTransaction',
  LegalEntity = 'LegalEntity',
  Location = 'Location',
  Party = 'Party',
  Portal = 'Portal',
  Report = 'Report',
  ReportPackage = 'ReportPackage',
  Thread = 'Thread',
  Transaction = 'Transaction',
  User = 'User',
  WorkItem = 'WorkItem'
}

export type ObjectKindFacet = {
  count: Scalars['Long']['output'];
  kind: ObjectKind;
};

export type ObjectMappingHierarchies = {
  departmentHierarchy: Array<ObjectMappingHierarchyNode>;
  lineClassHierarchy: Array<ObjectMappingHierarchyNode>;
};

export type ObjectMappingHierarchyNode = {
  active: Scalars['Boolean']['output'];
  depth: Scalars['Long']['output'];
  factId: Scalars['String']['output'];
  fullyQualifiedName: Scalars['String']['output'];
  mappedTo?: Maybe<ObjectKind>;
  name: Scalars['String']['output'];
  parentId?: Maybe<Scalars['String']['output']>;
  transactionCount: Scalars['Long']['output'];
};

export type ObjectPermission = {
  action: ShareAction;
  legalEntity: EntityLegalEntity;
  object: ObjectIdentifier;
  organizationPreview: OrganizationPreview;
  permission?: Maybe<Permission>;
  viewType: ViewType;
};

export type ObjectRequest = {
  object: ObjectIdentifier;
  requestId: Scalars['String']['output'];
};

export enum Operation {
  Contains = 'Contains',
  Excludes = 'Excludes',
  UnknownOperation = 'UnknownOperation'
}

export type Organization = {
  iconUrl?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  slug: Scalars['String']['output'];
  websiteUrl?: Maybe<Scalars['String']['output']>;
};

export type OrganizationAffiliation = {
  legalEntity: EntityLegalEntity;
  organization: EntityOrganization;
};

export type OrganizationAssignee = {
  organizationId: Scalars['String']['input'];
};

export type OrganizationBillingPlan = {
  plan: BillingPlan;
  trial?: Maybe<BillingTrial>;
};

export type OrganizationEmployment = {
  employeeId: Scalars['String']['output'];
  organization: Organization;
  role: Role;
};

export type OrganizationPreferences = {
  anonymizeEmployees: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
};

export type OrganizationPreview = {
  iconUrl?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  slug: Scalars['String']['output'];
};

export type OrganizationTemplate = {
  iconUrl?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  websiteUrl?: Maybe<Scalars['String']['output']>;
};

export type OwnedDataSource = {
  dataSource: EnrichedDataSource;
  dataSourcePreference?: Maybe<DataSourcePreference>;
  ownedSourceAccounts?: Maybe<Array<OwnedSourceAccount>>;
};

export type OwnedSourceAccount = {
  owner?: Maybe<Employee>;
  sourceAccount: SourceAccount;
};

export type PackageCount = {
  interval: Interval;
  total: Scalars['Long']['output'];
};

export type PackageCounts = {
  counts: Array<PackageCount>;
  total: Scalars['Long']['output'];
};

export type PackagesFilter = {
  hideDrafts?: InputMaybe<Scalars['Boolean']['input']>;
  interval?: InputMaybe<Interval>;
};

export type PackagesSort = {
  direction?: InputMaybe<SortDirection>;
  orderBy?: InputMaybe<PackagesSortOrderBy>;
};

export enum PackagesSortOrderBy {
  UpdatedAt = 'UpdatedAt'
}

export type PageView = {
  url: Scalars['String']['input'];
};

export type Pagination = {
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
};

/** Parties */
export type Party = {
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  designation: PartyDesignation;
  facebookUrl?: Maybe<Scalars['String']['output']>;
  iconUrl?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  instagramUrl?: Maybe<Scalars['String']['output']>;
  legalEntityId: Scalars['String']['output'];
  name: Scalars['String']['output'];
  shortDescription?: Maybe<Scalars['String']['output']>;
  supportEmail?: Maybe<Scalars['String']['output']>;
  supportPhone?: Maybe<Scalars['String']['output']>;
  supportUrl?: Maybe<Scalars['String']['output']>;
  twitterUrl?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  url?: Maybe<Scalars['String']['output']>;
  yelpUrl?: Maybe<Scalars['String']['output']>;
};

export type PartyAndRoleBalanceFilter = {
  legalEntityId: Scalars['String']['input'];
  partyId: Scalars['String']['input'];
  partyRole: PartyRole;
  viewVersion?: InputMaybe<Scalars['String']['input']>;
};

export type PartyChartConfig = {
  asPermanent?: Maybe<Scalars['Boolean']['output']>;
  origin: ArchiveIntervalOrigin;
  partyId: Scalars['String']['output'];
  partyRole?: Maybe<PartyRole>;
};

export type PartyChartConfigInput = {
  asPermanent?: InputMaybe<Scalars['Boolean']['input']>;
  origin: IntervalOrigin;
  partyId: Scalars['String']['input'];
  partyRole?: InputMaybe<PartyRole>;
};

export enum PartyDesignation {
  BusinessDesignation = 'BusinessDesignation',
  IgnoredDesignation = 'IgnoredDesignation',
  InvalidDesignation = 'InvalidDesignation',
  PeopleDesignation = 'PeopleDesignation',
  SMBMealsDesignation = 'SMBMealsDesignation',
  SMBOtherDesignation = 'SMBOtherDesignation',
  UnknownDesignation = 'UnknownDesignation'
}

export type PartyDimension = {
  by: DimensionSummary;
  partyId: Scalars['String']['output'];
  partyObject: EntityParty;
  summary: TransactionSummary;
};

export enum PartyEntityType {
  Corporation = 'Corporation',
  ForeignEntities = 'ForeignEntities',
  Individual = 'Individual',
  LimitedLiabilityCompany = 'LimitedLiabilityCompany',
  Other = 'Other',
  Partnerships = 'Partnerships',
  SoleProprietorship = 'SoleProprietorship',
  TrustsAndEstates = 'TrustsAndEstates',
  Undetermined = 'Undetermined',
  UnknownEntityType = 'UnknownEntityType'
}

export type PartyHistory = {
  entityTypeClassification: PartyHistoryEntry;
  partyId: Scalars['String']['output'];
  taxpayerIdentificationClassification: PartyHistoryEntry;
};

export type PartyHistoryEntry = {
  iconUrl?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

export type PartyHover = {
  context?: Maybe<DimensionSummary>;
  entity: EntityParty;
  history: DimensionSummary;
  topTransactions?: Maybe<Array<EntityTransaction>>;
};

export type PartyIconMetadata = {
  iconUrl: Scalars['String']['output'];
};

export type PartyParams = {
  action: PushAction;
  partyID?: InputMaybe<Scalars['String']['input']>;
  partyIconUrl?: InputMaybe<Scalars['String']['input']>;
  partyName: Scalars['String']['input'];
};

export type PartyRef = {
  id: Scalars['String']['output'];
};

export enum PartyRole {
  EntityAssetRole = 'EntityAssetRole',
  EntityAssetSupplierRole = 'EntityAssetSupplierRole',
  EntityAssetVendorRole = 'EntityAssetVendorRole',
  EntityCustomerRole = 'EntityCustomerRole',
  EntityLenderRole = 'EntityLenderRole',
  EntityLiabilitiesRole = 'EntityLiabilitiesRole',
  EntityOtherExpensesRole = 'EntityOtherExpensesRole',
  EntityOtherIncomeRole = 'EntityOtherIncomeRole',
  EntityOwedVendorRole = 'EntityOwedVendorRole',
  EntityPrepaidVendorRole = 'EntityPrepaidVendorRole',
  EntityShareholderRole = 'EntityShareholderRole',
  EntitySupplierRole = 'EntitySupplierRole',
  EntityUnpaidCustomerRole = 'EntityUnpaidCustomerRole',
  EntityUnspecifiedRole = 'EntityUnspecifiedRole',
  EntityVendorRole = 'EntityVendorRole',
  FacilitatorRole = 'FacilitatorRole',
  InstitutionCreditRole = 'InstitutionCreditRole',
  InstitutionDebitRole = 'InstitutionDebitRole',
  OwnerRole = 'OwnerRole',
  PaymentProcessorRole = 'PaymentProcessorRole',
  UnknownRole = 'UnknownRole'
}

export type PartyRuleFilter = {
  createdAt: Scalars['DateTime']['output'];
  operation: Operation;
  parties: Array<PartyRuleFilterParty>;
  partyRuleFilterId: Scalars['String']['output'];
};

export type PartyRuleFilterParty = {
  partyId: Scalars['String']['output'];
  partyName: Scalars['String']['output'];
};

export enum PartyType {
  Global = 'Global',
  Implied = 'Implied',
  Imported = 'Imported',
  Synthesized = 'Synthesized',
  Unknown = 'Unknown',
  UserCreated = 'UserCreated',
  WireImplied = 'WireImplied'
}

export type PayInvoiceSummary = {
  invoicePaymentDetails: InvoicePaymentDetails;
  status: InvoiceStatus;
};

export type PaymentInstrument = BankAccount | CreditCard;

export enum PaymentStatus {
  Blocked = 'Blocked',
  Canceled = 'Canceled',
  Created = 'Created',
  Deposited = 'Deposited',
  Mailed = 'Mailed',
  OnHold = 'OnHold',
  Paid = 'Paid',
  PendingIncreaseReview = 'PendingIncreaseReview',
  PendingMailing = 'PendingMailing',
  Refunded = 'Refunded',
  Rejected = 'Rejected',
  RequiresAttention = 'RequiresAttention',
  Returned = 'Returned',
  Settled = 'Settled',
  Stopped = 'Stopped',
  Submitted = 'Submitted',
  Unknown = 'Unknown'
}

export type PayrollProvider = {
  legalEntityId: Scalars['String']['output'];
  partyId: Scalars['String']['output'];
  partyName: Scalars['String']['output'];
  payrollProviderType: PayrollProviderType;
  rangeEndedAt?: Maybe<Scalars['DateTime']['output']>;
  rangeStartedAt: Scalars['DateTime']['output'];
};

export enum PayrollProviderType {
  Primary = 'Primary',
  UnknownPayrollProvider = 'UnknownPayrollProvider'
}

export type Period = {
  endedAt: Scalars['DateTime']['output'];
  interval: Interval;
  name: Scalars['String']['output'];
  startedAt: Scalars['DateTime']['output'];
};

export type PeriodInput = {
  endedAt: Scalars['DateTime']['input'];
  interval: Interval;
  name: Scalars['String']['input'];
  startedAt: Scalars['DateTime']['input'];
};

/** Insights */
export type PeriodInsights = {
  entities?: Maybe<ObjectEntities>;
  insights: Array<Insight>;
  period: Period;
};

/** Periods */
export type PeriodMonetaryValue = {
  current?: Maybe<SummaryTotal>;
  deltaPrevious?: Maybe<Scalars['Float']['output']>;
  /** @deprecated unpopulated */
  deltaYearAgo?: Maybe<Scalars['Float']['output']>;
  /** @deprecated unpopulated */
  isFirstOccurrencePeriod?: Maybe<Scalars['Boolean']['output']>;
  moneyFlow?: Maybe<MoneyFlow>;
  prior?: Maybe<SummaryTotal>;
  transactionsCount: Scalars['Long']['output'];
  value: MonetaryValue;
};

export type PeriodReport = {
  author?: Maybe<Contact>;
  createdAt: Scalars['DateTime']['output'];
  generationHistory?: Maybe<ReportGenerationHistory>;
  id: Scalars['String']['output'];
  interval: Interval;
  reportFile: ReportFileMetadata;
  reportFor?: Maybe<Date>;
  status: ReportPackageDocumentStatus;
  threadDetails?: Maybe<Array<ThreadDetails>>;
  title: Scalars['String']['output'];
};

export enum Permission {
  CommentRead = 'CommentRead',
  CommentShare = 'CommentShare',
  FullAccess = 'FullAccess',
  None = 'None',
  ReadOnly = 'ReadOnly',
  SensitiveFullAccess = 'SensitiveFullAccess'
}

export enum Persona {
  AccountingLarge = 'AccountingLarge',
  AccountingMedium = 'AccountingMedium',
  AccountingSmall = 'AccountingSmall',
  Business = 'Business',
  BusinessAndSubsidiaries = 'BusinessAndSubsidiaries',
  InvestmentMedium = 'InvestmentMedium',
  InvestmentSmall = 'InvestmentSmall',
  MailingList = 'MailingList',
  UnknownPersona = 'UnknownPersona'
}

export type PlaidAccount = {
  accountId: Scalars['String']['output'];
  balances?: Maybe<PlaidAccountBalances>;
  mask?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  officialName?: Maybe<Scalars['String']['output']>;
  persistentAccountId?: Maybe<Scalars['String']['output']>;
  subtype?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type PlaidAccountBalances = {
  available?: Maybe<Scalars['Float']['output']>;
  current?: Maybe<Scalars['Float']['output']>;
  isoCurrencyCode?: Maybe<Scalars['String']['output']>;
};

export type PlaidConfig = {
  createdAt: Scalars['DateTime']['output'];
  externalInstitutionId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  itemId: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type PlaidConnection = {
  accounts?: Maybe<Array<PlaidAccount>>;
  connectionId: Scalars['String']['output'];
  item?: Maybe<PlaidConnectionItem>;
  transactionStatus?: Maybe<PlaidStatus>;
};

export type PlaidConnectionAuth = {
  connectionId: Scalars['String']['output'];
  response: Scalars['String']['output'];
};

export type PlaidConnectionItem = {
  availableProducts?: Maybe<Array<Scalars['String']['output']>>;
  billedProducts?: Maybe<Array<Scalars['String']['output']>>;
  consentedProducts?: Maybe<Array<Scalars['String']['output']>>;
  error?: Maybe<PlaidItemError>;
  institutionId?: Maybe<Scalars['String']['output']>;
  itemId: Scalars['String']['output'];
  products?: Maybe<Array<Scalars['String']['output']>>;
  updateType?: Maybe<Scalars['String']['output']>;
  webhook?: Maybe<Scalars['String']['output']>;
};

export type PlaidConnectorProperties = {
  /**
   * For the initial implementation, we wont have anything special here
   * however in the future we may use this to add things like filters
   * for specific account types, icons for different banks, etc
   */
  empty?: Maybe<Scalars['Boolean']['output']>;
};

export type PlaidItem = {
  institutionId: Scalars['String']['output'];
  itemId: Scalars['String']['output'];
};

export type PlaidItemError = {
  causes?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  displayMessage?: Maybe<Scalars['String']['output']>;
  documentationUrl?: Maybe<Scalars['String']['output']>;
  errorCode?: Maybe<Scalars['String']['output']>;
  errorCodeReason?: Maybe<Scalars['String']['output']>;
  errorMessage?: Maybe<Scalars['String']['output']>;
  errorType?: Maybe<Scalars['String']['output']>;
  requestId?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['Int']['output']>;
  suggestedAction?: Maybe<Scalars['String']['output']>;
};

export type PlaidLinkToken = {
  token: Scalars['String']['output'];
};

export type PlaidStatus = {
  lastFailedUpdate?: Maybe<Scalars['DateTime']['output']>;
  lastSuccessfulUpdate?: Maybe<Scalars['DateTime']['output']>;
};

export type Portal = {
  backgroundUrl?: Maybe<Scalars['String']['output']>;
  layoutId: Scalars['String']['output'];
  legalEntityId: Scalars['String']['output'];
  portalId: Scalars['String']['output'];
};

export type PortalMetricConfig = {
  metricType: MetricType;
  origin: ArchiveIntervalOrigin;
};

export type PortalMetricConfigInput = {
  metricType: MetricType;
  origin: IntervalOrigin;
};

export type PredictedDataSource = {
  institution: Party;
  providerType: ProviderType;
  type: PredictedDataSourceType;
};

export enum PredictedDataSourceType {
  BankAccount = 'BankAccount',
  BankAccountAndCreditCard = 'BankAccountAndCreditCard',
  CreditCard = 'CreditCard',
  UnknownType = 'UnknownType'
}

export type PrefixFilter = {
  legalEntityId?: InputMaybe<Scalars['String']['input']>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type PrefixMapping = {
  partyId: Scalars['String']['output'];
  prefix: Scalars['String']['output'];
};

export type PrefixMappingSummary = {
  party: Party;
  prefix: Scalars['String']['output'];
  transactionCount: Scalars['Long']['output'];
};

export type Product = {
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export enum ProductArea {
  All = 'All',
  Cash = 'Cash',
  Expenses = 'Expenses',
  None = 'None',
  Revenue = 'Revenue'
}

export type ProductInstitution = {
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  party?: Maybe<Party>;
  products?: Maybe<Array<InstitutionProduct>>;
  routingNumber?: Maybe<Scalars['String']['output']>;
};

export enum ProductRecurrence {
  Monthly = 'Monthly',
  OneTime = 'OneTime',
  UnknownRecurrence = 'UnknownRecurrence',
  Yearly = 'Yearly'
}

export enum ProposalType {
  AffiliateInviteManagedOperator = 'AffiliateInviteManagedOperator',
  AffiliationAssistClient = 'AffiliationAssistClient',
  AffiliationCreateClient = 'AffiliationCreateClient',
  AffiliationJoinClient = 'AffiliationJoinClient',
  CreateOrganization = 'CreateOrganization',
  EmploymentAssistOperator = 'EmploymentAssistOperator',
  EmploymentJoinOrg = 'EmploymentJoinOrg',
  OperatorAssistAffiliate = 'OperatorAssistAffiliate',
  UnknownProposalType = 'UnknownProposalType'
}

export type ProposedGlobalParty = {
  designation: PartyDesignation;
  iconUrl: Scalars['String']['output'];
  name: Scalars['String']['output'];
  shortDescription: Scalars['String']['output'];
  url?: Maybe<Scalars['String']['output']>;
};

export type Provider = {
  imageUrl?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
};

export enum ProviderType {
  CSVImport = 'CSVImport',
  Connect = 'Connect',
  DigitsInvoicing = 'DigitsInvoicing',
  DigitsPay = 'DigitsPay',
  DigitsPaymentsLedger = 'DigitsPaymentsLedger',
  Manual = 'Manual',
  Plaid = 'Plaid',
  QuickBooks = 'QuickBooks',
  UnknownProvider = 'UnknownProvider',
  Xero = 'Xero'
}

export type PublicInvoice = {
  customer?: Maybe<Customer>;
  discounts?: Maybe<Array<InvoiceDiscount>>;
  dueDate?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['String']['output'];
  invoiceDate?: Maybe<Scalars['DateTime']['output']>;
  invoiceNumber?: Maybe<Scalars['String']['output']>;
  invoicingProfile?: Maybe<InvoicingProfile>;
  legalEntityId: Scalars['String']['output'];
  lineItems: Array<InvoiceLineItem>;
  memo?: Maybe<Scalars['String']['output']>;
  status: InvoiceStatus;
  subtotal?: Maybe<MonetaryValue>;
  taxPercent?: Maybe<Scalars['Float']['output']>;
  totalAmount?: Maybe<MonetaryValue>;
  totalTax?: Maybe<MonetaryValue>;
};

export type PullRequest = {
  author: PullRequestAuthor;
  branch: Scalars['String']['output'];
  labels?: Maybe<Array<PullRequestLabel>>;
  number: Scalars['Int']['output'];
  prState: PullRequestState;
  repo: Scalars['String']['output'];
  title: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type PullRequestAuthor = {
  avatarUrl?: Maybe<Scalars['String']['output']>;
  id: Scalars['Long']['output'];
  login: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type PullRequestFile = {
  contents: Scalars['String']['output'];
  name: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export enum PullRequestFilterState {
  All = 'All',
  Closed = 'Closed',
  Open = 'Open'
}

export type PullRequestLabel = {
  hexColor: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export enum PullRequestState {
  Closed = 'Closed',
  Open = 'Open'
}

export type PullRequestSummary = {
  mergeableStatus: MergeableStatus;
  merged: Scalars['Boolean']['output'];
  pullRequest: PullRequest;
  reviewStatus: ReviewStatus;
};

export enum PushAction {
  Reassign = 'Reassign',
  Rename = 'Rename',
  UnknownPushAction = 'UnknownPushAction'
}

export type Query = {
  brokenConnections: Array<BrokenConnection>;
  checkAllCsvImportFactsDeleted: AdminAllFactsDeleted;
  connectionEvents: Array<AdminConnectionEvent>;
  fetchPlaidConnectionAuth: PlaidConnectionAuth;
  getEmbedding: EmbeddingResponse;
  getInstitution: AdminInstitution;
  getNeighbors: MatchResponse;
  highlightWorkItem: HighlightedTokens;
  listAdminBooksClosedFacts: Array<BooksClosedFact>;
  /** Data Sources */
  listAdminOwnedDataSources: Array<OwnedDataSource>;
  listAdminPredictedDataSources: Array<PredictedDataSource>;
  listAffiliatedOrganizations: Array<AdminOrganization>;
  listAgentParties: Array<Party>;
  listAllDataProcessingStatuses: DenormalizedViewStatusSummary;
  /** Legal Entity Approval */
  listApprovalEligibility: Array<AdminApprovalEligibility>;
  listApprovalPriorityOrganizations: Array<AdminOrganization>;
  listAspectRequests: Array<AspectRequest>;
  /** Aspects */
  listAspectStatuses: Array<LegalEntityAspectStatus>;
  listAssociatedTransactionFacts: Array<AdminTransactionFact>;
  listAvailableSuperuserRoles: Array<SuperuserRole>;
  /** Charts of Accounts */
  listChartsOfAccounts: Array<ChartOfAccountsListEntry>;
  listClassificationValidationExpectations: Array<ClassificationValidation>;
  /** Prefixes */
  listClusteredPrefixes: Array<ClusteredPrefix>;
  /** Connect */
  listConnections: AdminConnectionList;
  listConnectors: Array<Connector>;
  listCsvImportTransactionFacts: Array<Scalars['String']['output']>;
  /** CSV Import */
  listCsvImports: Array<CsvImport>;
  listDataSourceJobErrors?: Maybe<Array<JobError>>;
  listDepartmentFactHierarchy: Array<ObjectMappingHierarchyNode>;
  /** Doppelganger */
  listDoppelgangerPermits: Array<Maybe<AdminDoppelgangerPermit>>;
  listEmployedUsers?: Maybe<Array<User>>;
  /** Employees */
  listEmployees: Array<AdminEmployee>;
  listFailingClassificationValidations: Array<ClassificationValidationFailure>;
  /** Parties */
  listGlobalParties: Array<AdminGlobalParty>;
  listGlobalPartiesWithSummaries: Array<AdminGlobalParty>;
  listIgnoredPrefixes: Array<Scalars['String']['output']>;
  /** Institutions */
  listInstitutions: Array<AdminInstitution>;
  /** Invites */
  listInvitations: Array<AdminInvitation>;
  /** Layouts */
  listLayoutVersions: AdminLayoutVersions;
  /** Legal Entities */
  listLegalEntities: Array<AdminLegalEntity>;
  listLegalEntityUsers: Array<AdminUser>;
  /** Object Mapping */
  listLineClassFactHierarchy: Array<ObjectMappingHierarchyNode>;
  /** Migrations */
  listMigrationPullRequests: Array<PullRequest>;
  listMismatchedQuickbooksBalanceSheetCategories: Array<MismatchedQuickbooksBalanceSheetCategory>;
  listMismatchedQuickbooksCategories: Array<MismatchedQuickbooksCategory>;
  listOrganizationUsers: Array<AdminUser>;
  listOrganizations: Array<AdminOrganization>;
  listParties: Array<AdminParty>;
  listPartyExtractions: Array<AdminPartyExtraction>;
  listPayrollParties: Array<PayrollProvider>;
  listPrefixMappings: Array<PrefixMappingSummary>;
  /** Report Packages */
  listReportPackages: Array<AdminReportPackageMetadata>;
  /** Report Package Validation */
  listReportValidationHistories: Array<ReportLegalEntityValidationHistory>;
  listSanitizedDescriptions: Array<SanitizedDescription>;
  /** Signups */
  listSignups: Array<AdminSignup>;
  /** Superusers */
  listSuperusers: Array<Superuser>;
  listTransactionFactCategoryClassifications: Array<AdminCategoryClassification>;
  listTransactionFactLedgerPartyClassifications: Array<AdminPartyClassification>;
  listTransactionFactPartyClassifications: Array<AdminPartyClassification>;
  listUnsupportedTransactions: UnsupportedTransactions;
  listUserLegalEntities?: Maybe<Array<LegalEntity>>;
  /** Users */
  listUsers: Array<AdminUser>;
  plaidTriggerRefresh?: Maybe<Scalars['Void']['output']>;
  readAdminBooksClosedDate: BooksClosedDate;
  readAssociatedThreads: ThreadListWithEntities;
  readBillingSubscriptionHistory: Array<BillingSubscriptionHistory>;
  readChartOfAccounts: AdminChartOfAccounts;
  readComponentData: ArchivedComponent;
  readComponentDataJson: Scalars['String']['output'];
  readConnectRecording: HttpResponseArchive;
  readConnection: AdminConnection;
  readDataHealth: DataHealthStatus;
  /** Notifications */
  readEmailHTML?: Maybe<Scalars['String']['output']>;
  /** Data Processing */
  readEntityDataProcessingStatuses: DenormalizedViewStatusSummary;
  readEntityExtractions: Array<AdminTransactionEntityExtraction>;
  /** Vanity */
  readGlobalVanityStats: AdminGlobalVanityStats;
  readInvitation: AdminInvitation;
  readInvitationBySignup: AdminInvitation;
  readLayoutVersion: AdminLayout;
  readLedgerTransactionIds: Array<AdminTransactionPair>;
  readLegalEntity: AdminLegalEntity;
  readLegalEntityPreferences: LegalEntityPreferences;
  readMappedChartOfAccounts: MappedChartOfAccounts;
  readMigrationPullRequest: MigrationPullRequest;
  /** Organizations */
  readOrganization: AdminOrganization;
  /** Billing */
  readOrganizationBillingPlan: BillingPlan;
  readPlaidConnection: PlaidConnection;
  readPlaidItem: PlaidItem;
  readReportValidationArchive?: Maybe<ReportValidationArchive>;
  readSignup: AdminSignup;
  readSourceRecordedMatchSummary: SourceRecordedMatchSummary;
  /** Threads */
  readThread: ThreadWithEntities;
  /** Transactions */
  readTransactionFact: AdminTransactionFact;
  readUser: AdminUser;
  readUserBySignup: AdminUser;
  readValidationHistory?: Maybe<ValidationHistory>;
  /** Validation */
  readValidationSummary: ValidationSummary;
  readVanityStats: AdminVanityStats;
  /** Work Items */
  readWorkItem: AdminWorkItem;
  readWorkItemValidation: WorkItemValidation;
  reprocessDocument?: Maybe<Scalars['Void']['output']>;
  /** Documents */
  segmentURL: Scalars['String']['output'];
  summarizeClassificationValidations: ClassificationValidationSummary;
  summarizeDataSourceFetches: DataSourceFetchStats;
  summarizeViewGeneration: ViewGenerationSummary;
};


export type QueryCheckAllCsvImportFactsDeletedArgs = {
  csvImportId: Scalars['String']['input'];
  legalEntityId: Scalars['String']['input'];
};


export type QueryConnectionEventsArgs = {
  id: Scalars['String']['input'];
};


export type QueryFetchPlaidConnectionAuthArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetEmbeddingArgs = {
  partyPrefix?: InputMaybe<Scalars['String']['input']>;
  transactionFactId: Scalars['String']['input'];
};


export type QueryGetInstitutionArgs = {
  institutionId: Scalars['String']['input'];
};


export type QueryGetNeighborsArgs = {
  approxNumNeighbors?: InputMaybe<Scalars['Int']['input']>;
  deployedIndexId: Scalars['String']['input'];
  floatVal: Array<Scalars['Float']['input']>;
  leafNodesToSearchPercentOverride?: InputMaybe<Scalars['Int']['input']>;
  numNeighbors?: InputMaybe<Scalars['Int']['input']>;
  perCrowdingAttributeNumNeighbors?: InputMaybe<Scalars['Int']['input']>;
  restricts?: InputMaybe<Array<Namespace>>;
};


export type QueryHighlightWorkItemArgs = {
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  similarityType: SimilarityType;
};


export type QueryListAdminBooksClosedFactsArgs = {
  legalEntityId: Scalars['String']['input'];
};


export type QueryListAdminOwnedDataSourcesArgs = {
  legalEntityId: Scalars['String']['input'];
};


export type QueryListAdminPredictedDataSourcesArgs = {
  legalEntityId: Scalars['String']['input'];
};


export type QueryListAffiliatedOrganizationsArgs = {
  searchTerm?: InputMaybe<Scalars['String']['input']>;
};


export type QueryListAgentPartiesArgs = {
  legalEntityId: Scalars['String']['input'];
  pagination: Pagination;
};


export type QueryListApprovalEligibilityArgs = {
  legalEntityIds: Array<Scalars['String']['input']>;
};


export type QueryListApprovalPriorityOrganizationsArgs = {
  pagination?: InputMaybe<Pagination>;
};


export type QueryListAspectRequestsArgs = {
  legalEntityId: Scalars['String']['input'];
};


export type QueryListAspectStatusesArgs = {
  legalEntityId: Scalars['String']['input'];
};


export type QueryListAssociatedTransactionFactsArgs = {
  dataSourceId: Scalars['String']['input'];
  externalId: Scalars['String']['input'];
};


export type QueryListChartsOfAccountsArgs = {
  filter?: InputMaybe<AdminChartOfAccountsFilter>;
};


export type QueryListClassificationValidationExpectationsArgs = {
  factIds?: InputMaybe<Array<Scalars['String']['input']>>;
  legalEntityId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryListClusteredPrefixesArgs = {
  legalEntityId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryListConnectionsArgs = {
  legalEntityId: Scalars['String']['input'];
};


export type QueryListConnectorsArgs = {
  institutionId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryListCsvImportTransactionFactsArgs = {
  csvImportId: Scalars['String']['input'];
  legalEntityId: Scalars['String']['input'];
  pagination?: InputMaybe<Pagination>;
};


export type QueryListCsvImportsArgs = {
  legalEntityId: Scalars['String']['input'];
  order?: InputMaybe<CsvImportSort>;
  pagination?: InputMaybe<Pagination>;
};


export type QueryListDataSourceJobErrorsArgs = {
  legalEntityId: Scalars['String']['input'];
  requestObjectId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryListDepartmentFactHierarchyArgs = {
  legalEntityId: Scalars['String']['input'];
};


export type QueryListEmployeesArgs = {
  filter: EmployeeFilter;
  pagination: Pagination;
};


export type QueryListGlobalPartiesArgs = {
  filter?: InputMaybe<GlobalPartyFilter>;
  order?: InputMaybe<GlobalPartySort>;
  pagination: Pagination;
};


export type QueryListGlobalPartiesWithSummariesArgs = {
  filter?: InputMaybe<GlobalPartyFilter>;
  order?: InputMaybe<GlobalPartySort>;
  pagination: Pagination;
};


export type QueryListInstitutionsArgs = {
  pagination: Pagination;
};


export type QueryListLayoutVersionsArgs = {
  layoutId: Scalars['String']['input'];
  legalEntityId: Scalars['String']['input'];
};


export type QueryListLegalEntitiesArgs = {
  excludedStatuses?: InputMaybe<Array<LegalEntityStatus>>;
  includedStatuses?: InputMaybe<Array<LegalEntityStatus>>;
  legalEntityIds?: InputMaybe<Array<Scalars['String']['input']>>;
  pagination: Pagination;
  searchTerm?: InputMaybe<Scalars['String']['input']>;
};


export type QueryListLegalEntityUsersArgs = {
  id: Scalars['String']['input'];
};


export type QueryListLineClassFactHierarchyArgs = {
  legalEntityId: Scalars['String']['input'];
};


export type QueryListMigrationPullRequestsArgs = {
  anyLabel?: InputMaybe<Scalars['Boolean']['input']>;
  prState?: InputMaybe<PullRequestFilterState>;
  repo: Scalars['String']['input'];
};


export type QueryListMismatchedQuickbooksBalanceSheetCategoriesArgs = {
  legalEntityId: Scalars['String']['input'];
};


export type QueryListMismatchedQuickbooksCategoriesArgs = {
  legalEntityId: Scalars['String']['input'];
};


export type QueryListOrganizationUsersArgs = {
  id: Scalars['String']['input'];
};


export type QueryListOrganizationsArgs = {
  affiliateType?: InputMaybe<AffiliateType>;
  onlyWithEmployees?: InputMaybe<Scalars['Boolean']['input']>;
  organizationIds?: InputMaybe<Array<Scalars['String']['input']>>;
  pagination?: InputMaybe<Pagination>;
  searchTerm?: InputMaybe<Scalars['String']['input']>;
};


export type QueryListPartiesArgs = {
  designation?: InputMaybe<PartyDesignation>;
  legalEntityId: Scalars['String']['input'];
  pagination: Pagination;
  partyType?: InputMaybe<PartyType>;
  viewType: ViewType;
};


export type QueryListPartyExtractionsArgs = {
  legalEntityId: Scalars['String']['input'];
  pagination: Pagination;
};


export type QueryListPayrollPartiesArgs = {
  legalEntityId: Scalars['String']['input'];
};


export type QueryListPrefixMappingsArgs = {
  filter: PrefixFilter;
};


export type QueryListReportPackagesArgs = {
  legalEntityId: Scalars['String']['input'];
  origin: IntervalOrigin;
  pagination: Pagination;
};


export type QueryListReportValidationHistoriesArgs = {
  legalEntityIds: Array<Scalars['String']['input']>;
};


export type QueryListSanitizedDescriptionsArgs = {
  prefix: Scalars['String']['input'];
};


export type QueryListSignupsArgs = {
  pagination: Pagination;
  searchTerm?: InputMaybe<Scalars['String']['input']>;
  status: ListSignupRequestStatus;
};


export type QueryListTransactionFactCategoryClassificationsArgs = {
  transactionFactId: Scalars['String']['input'];
};


export type QueryListTransactionFactLedgerPartyClassificationsArgs = {
  transactionFactId: Scalars['String']['input'];
};


export type QueryListTransactionFactPartyClassificationsArgs = {
  transactionFactId: Scalars['String']['input'];
};


export type QueryListUnsupportedTransactionsArgs = {
  legalEntityId: Scalars['String']['input'];
};


export type QueryListUserLegalEntitiesArgs = {
  userId: Scalars['String']['input'];
};


export type QueryListUsersArgs = {
  ids?: InputMaybe<Array<Scalars['String']['input']>>;
  pagination: Pagination;
  searchTerm?: InputMaybe<Scalars['String']['input']>;
};


export type QueryPlaidTriggerRefreshArgs = {
  id: Scalars['String']['input'];
};


export type QueryReadAdminBooksClosedDateArgs = {
  legalEntityId: Scalars['String']['input'];
};


export type QueryReadAssociatedThreadsArgs = {
  allowResolved?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['String']['input'];
  kind: ObjectKind;
  legalEntityId: Scalars['String']['input'];
};


export type QueryReadBillingSubscriptionHistoryArgs = {
  organizationId: Scalars['String']['input'];
};


export type QueryReadChartOfAccountsArgs = {
  id: Scalars['String']['input'];
};


export type QueryReadComponentDataArgs = {
  dataId: Scalars['String']['input'];
  layoutId: Scalars['String']['input'];
  legalEntityId: Scalars['String']['input'];
};


export type QueryReadComponentDataJsonArgs = {
  dataId: Scalars['String']['input'];
  layoutId: Scalars['String']['input'];
  legalEntityId: Scalars['String']['input'];
};


export type QueryReadConnectRecordingArgs = {
  connectionId: Scalars['String']['input'];
  eventId: Scalars['String']['input'];
};


export type QueryReadConnectionArgs = {
  id: Scalars['String']['input'];
};


export type QueryReadDataHealthArgs = {
  legalEntityId: Scalars['String']['input'];
};


export type QueryReadEmailHtmlArgs = {
  eventType: EventType;
};


export type QueryReadEntityDataProcessingStatusesArgs = {
  legalEntityId: Scalars['String']['input'];
};


export type QueryReadEntityExtractionsArgs = {
  transactionFactId: Scalars['String']['input'];
};


export type QueryReadInvitationArgs = {
  token: Scalars['String']['input'];
};


export type QueryReadInvitationBySignupArgs = {
  signupId: Scalars['String']['input'];
};


export type QueryReadLayoutVersionArgs = {
  layoutId: Scalars['String']['input'];
  legalEntityId: Scalars['String']['input'];
  versionId: Scalars['String']['input'];
};


export type QueryReadLedgerTransactionIdsArgs = {
  transactionFactIds: Array<Scalars['String']['input']>;
};


export type QueryReadLegalEntityArgs = {
  id: Scalars['String']['input'];
};


export type QueryReadLegalEntityPreferencesArgs = {
  legalEntityId: Scalars['String']['input'];
};


export type QueryReadMappedChartOfAccountsArgs = {
  fromId: Scalars['String']['input'];
  toId: Scalars['String']['input'];
  viewType?: InputMaybe<ViewType>;
};


export type QueryReadMigrationPullRequestArgs = {
  prNumber: Scalars['Int']['input'];
  repo: Scalars['String']['input'];
};


export type QueryReadOrganizationArgs = {
  id: Scalars['String']['input'];
};


export type QueryReadOrganizationBillingPlanArgs = {
  organizationId: Scalars['String']['input'];
};


export type QueryReadPlaidConnectionArgs = {
  id: Scalars['String']['input'];
};


export type QueryReadPlaidItemArgs = {
  accessToken: Scalars['String']['input'];
};


export type QueryReadReportValidationArchiveArgs = {
  legalEntityId: Scalars['String']['input'];
  reportId: Scalars['String']['input'];
  versionId: Scalars['String']['input'];
};


export type QueryReadSignupArgs = {
  id: Scalars['String']['input'];
};


export type QueryReadSourceRecordedMatchSummaryArgs = {
  legalEntityId: Scalars['String']['input'];
};


export type QueryReadThreadArgs = {
  legalEntityId: Scalars['String']['input'];
  threadId: Scalars['String']['input'];
};


export type QueryReadTransactionFactArgs = {
  transactionFactId: Scalars['String']['input'];
};


export type QueryReadUserArgs = {
  id: Scalars['String']['input'];
};


export type QueryReadUserBySignupArgs = {
  signupId: Scalars['String']['input'];
};


export type QueryReadValidationHistoryArgs = {
  legalEntityId: Scalars['String']['input'];
};


export type QueryReadValidationSummaryArgs = {
  legalEntityId: Scalars['String']['input'];
};


export type QueryReadVanityStatsArgs = {
  legalEntityId: Scalars['String']['input'];
};


export type QueryReadWorkItemArgs = {
  workItemId: Scalars['String']['input'];
};


export type QueryReadWorkItemValidationArgs = {
  legalEntityId: Scalars['String']['input'];
};


export type QueryReprocessDocumentArgs = {
  documentId: Scalars['String']['input'];
  legalEntityId: Scalars['String']['input'];
};


export type QuerySegmentUrlArgs = {
  segmentId: Scalars['String']['input'];
};

export type QuickBooksAccountingInfoPreferences = {
  bookCloseDate?: Maybe<Date>;
};

export type QuickBooksCompany = {
  preferences?: Maybe<QuickBooksPreferences>;
};

export type QuickBooksConfig = {
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  realmId: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type QuickBooksPreferences = {
  accountingInfo?: Maybe<QuickBooksAccountingInfoPreferences>;
};

export type QuotePackage = {
  companyDetails: CompanyDetails;
  contractDocumentId?: Maybe<Scalars['String']['output']>;
  financials?: Maybe<Array<CompanyFinancials>>;
  id: Scalars['String']['output'];
  recipient?: Maybe<Contact>;
  status: QuoteStatus;
};

export type QuotePackageId = {
  id: Scalars['String']['output'];
};

export enum QuoteStatus {
  Accepted = 'Accepted',
  Created = 'Created',
  CustomerReview = 'CustomerReview',
  Deferred = 'Deferred',
  New = 'New',
  UnknownQuoteStatus = 'UnknownQuoteStatus'
}

export type RampConnectorProperties = {
  authorizationURL: Scalars['String']['output'];
};

export type RawDocument = {
  asyncProcessingStatus: AsyncProcessingStatus;
  collectionId: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  creator?: Maybe<EntityUser>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  features: Array<Feature>;
  fileName?: Maybe<Scalars['String']['output']>;
  fileSize?: Maybe<Scalars['Float']['output']>;
  formats: Array<Format>;
  id: Scalars['String']['output'];
  mimeType?: Maybe<Scalars['String']['output']>;
  sourceFormat?: Maybe<Format>;
  sourceFormatId?: Maybe<Scalars['String']['output']>;
  status: DocumentStatus;
  thumbnail?: Maybe<Segment>;
  title?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

/** ReadLedgerTransaction is the output type of readLedgerTransaction GraphQL query */
export type ReadLedgerTransaction = {
  transaction: LedgerTransaction;
};

export enum RealtimeHealthStatus {
  HasLoginRequired = 'HasLoginRequired',
  HasPredictions = 'HasPredictions',
  Healthy = 'Healthy',
  UnknownHealthStatus = 'UnknownHealthStatus'
}

export enum Reconciliation {
  Reconciled = 'Reconciled',
  Unreconciled = 'Unreconciled'
}

export type ReconciliationBalances = {
  ledgerAccountDifferences: LedgerAccountDifferences;
  ledgerBalances: LedgerBalances;
  statementBalances: StatementBalances;
};

export type ReconciliationDetails = {
  balances: ReconciliationBalances;
  normalEntryType: EntryType;
  reconciliation: AccountReconciliation;
  transactionCounts: TransactionCounts;
};

export enum ReconciliationFilter {
  FindAll = 'FindAll',
  Reconciled = 'Reconciled',
  Unreconciled = 'Unreconciled',
  Unspecified = 'Unspecified'
}

export type ReconciliationPeriodStats = {
  drafts: Scalars['Int']['output'];
  finalized: Scalars['Int']['output'];
  noActivity?: Maybe<Scalars['Int']['output']>;
  notStarted: Scalars['Int']['output'];
  totalCategories: Scalars['Int']['output'];
};

export type RefreshProgress = {
  mostRecentFetchStartTime?: Maybe<Scalars['DateTime']['output']>;
  progressPercentage?: Maybe<Scalars['Float']['output']>;
};

export type RemittanceFeatureValue = {
  remittanceValue: RemittanceItem;
};

export type RemittanceItem = {
  BankAccountName?: Maybe<Scalars['String']['output']>;
  BankAccountNumber: Scalars['String']['output'];
  BankName: Scalars['String']['output'];
  BankRoutingNumber: Scalars['String']['output'];
  RemittanceType: RemittanceType;
};

export enum RemittanceType {
  ACH = 'ACH',
  Unknown = 'Unknown',
  Wire = 'Wire'
}

export type Report = {
  author?: Maybe<Contact>;
  createdAt: Scalars['DateTime']['output'];
  generationHistory?: Maybe<ReportGenerationHistory>;
  id: Scalars['String']['output'];
  interval: Interval;
  options?: Maybe<ReportDocumentOptions>;
  reportFile: ReportFileMetadata;
  reportFor?: Maybe<Date>;
  status: ReportPackageDocumentStatus;
  title: Scalars['String']['output'];
  versionId: Scalars['String']['output'];
};

export enum ReportAction {
  Print = 'Print'
}

export type ReportActionEvent = {
  action: ReportAction;
  packageId: Scalars['String']['input'];
  url: Scalars['String']['input'];
};

/** Report automation settings */
export type ReportAutomationSettings = {
  autoCreateDraft: Scalars['Boolean']['output'];
  autoPublishDraft: Scalars['Boolean']['output'];
  autoPublishDraftDay: Scalars['Long']['output'];
  autoPublishFinal: Scalars['Boolean']['output'];
  autoPublishFinalDay: Scalars['Long']['output'];
  interval: Interval;
  legalEntityId?: Maybe<Scalars['String']['output']>;
  organizationId: Scalars['String']['output'];
};

export type ReportDocumentOptions = {
  amountPrecision?: Maybe<ReportNumberPrecision>;
  collapsedSections?: Maybe<CollapsedSections>;
  comparedToIncome?: Maybe<ReportOptionComparison>;
  comparedToTotal?: Maybe<ReportOptionComparison>;
  deltaMonthOverMonth?: Maybe<ReportOptionComparison>;
  deltaMonthOverMonthPeriods?: Maybe<Scalars['Int']['output']>;
  deltaYearOverYear?: Maybe<ReportOptionComparison>;
  deltaYearToDate?: Maybe<ReportOptionComparison>;
  sparklineLookbackPeriods?: Maybe<Scalars['Int']['output']>;
  yearToDate?: Maybe<ReportOptionComparison>;
};

export type ReportDocumentOptionsInput = {
  amountPrecision?: InputMaybe<ReportNumberPrecision>;
  collapsedSections?: InputMaybe<CollapsedSectionsInput>;
  comparedToIncome?: InputMaybe<ReportOptionComparison>;
  comparedToTotal?: InputMaybe<ReportOptionComparison>;
  deltaMonthOverMonth?: InputMaybe<ReportOptionComparison>;
  deltaMonthOverMonthPeriods?: InputMaybe<Scalars['Int']['input']>;
  deltaYearOverYear?: InputMaybe<ReportOptionComparison>;
  deltaYearToDate?: InputMaybe<ReportOptionComparison>;
  sparklineLookbackPeriods?: InputMaybe<Scalars['Int']['input']>;
  yearToDate?: InputMaybe<ReportOptionComparison>;
};

export type ReportDownload = {
  url: Scalars['String']['output'];
};

export type ReportFileMetadata = {
  fileName: Scalars['String']['output'];
  fileSize: Scalars['Float']['output'];
  id: Scalars['String']['output'];
  mimeType: Scalars['String']['output'];
  source: ReportFileSource;
};

export enum ReportFileSource {
  AVS = 'AVS',
  DataIngestion = 'DataIngestion',
  UnknownFileSource = 'UnknownFileSource',
  UserUploaded = 'UserUploaded'
}

export type ReportGenerationHistory = {
  authorId: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  error?: Maybe<Scalars['String']['output']>;
  generationEndedAt?: Maybe<Scalars['DateTime']['output']>;
  generationStartedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['String']['output'];
  kind: ReportKind;
  legalEntityId: Scalars['String']['output'];
  periodEndedAt: Scalars['DateTime']['output'];
  periodStartedAt: Scalars['DateTime']['output'];
  reportId: Scalars['String']['output'];
  requestId: Scalars['String']['output'];
  timezone: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  versionId: Scalars['String']['output'];
  viewVersion: Scalars['Float']['output'];
};

export type ReportGenerationStatus = {
  reportId: Scalars['String']['output'];
  status: GenerationStatus;
  versionId: Scalars['String']['output'];
};

export enum ReportKind {
  BalanceSheet = 'BalanceSheet',
  CashFlow = 'CashFlow',
  ExpenseSummary = 'ExpenseSummary',
  ProfitAndLoss = 'ProfitAndLoss',
  Unknown = 'Unknown'
}

export type ReportKindValidationHistory = {
  history: ReportValidationHistory;
  kind: ReportKind;
};

export type ReportKindValidationPeriodHistory = {
  histories: Array<ReportKindValidationHistory>;
  period: Period;
};

export type ReportLegalEntityValidationHistory = {
  legalEntityId: Scalars['String']['output'];
  periodHistories: Array<ReportKindValidationPeriodHistory>;
};

export enum ReportNumberPrecision {
  Decimal = 'Decimal',
  InvalidNumberPrecision = 'InvalidNumberPrecision',
  Rounded = 'Rounded'
}

export enum ReportOptionComparison {
  Amount = 'Amount',
  DeltaPercent = 'DeltaPercent',
  InvalidComparison = 'InvalidComparison',
  Percent = 'Percent',
  Total = 'Total',
  TriPeriodDeltaPercent = 'TriPeriodDeltaPercent'
}

export enum ReportOptionTimelineInterval {
  InvalidInterval = 'InvalidInterval',
  Last3Months = 'Last3Months',
  Last6Months = 'Last6Months',
  Last12Months = 'Last12Months'
}

export type ReportPackage = {
  author?: Maybe<Contact>;
  authorOrganization?: Maybe<OrganizationPreview>;
  createdAt: Scalars['DateTime']['output'];
  documents: Array<ReportPackageDocumentDetails>;
  endedAt: Scalars['DateTime']['output'];
  entities: ObjectEntities;
  id: Scalars['String']['output'];
  interval: Interval;
  legalEntityOrganization?: Maybe<OrganizationPreview>;
  packageOptions?: Maybe<ReportPackageOptions>;
  startedAt: Scalars['DateTime']['output'];
  status: ReportPackageStatus;
  title: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  version: ReportPackageVersionMetadata;
  versionId: Scalars['String']['output'];
  viewKey: ViewIdentifier;
};

export type ReportPackageDocument = LayoutDocument | Report | TextDocument;

export type ReportPackageDocumentDetails = {
  document?: Maybe<ReportPackageDocument>;
  metadata: ReportPackageDocumentMetadata;
};

export type ReportPackageDocumentMetadata = {
  documentObjectId: Scalars['String']['output'];
  documentObjectVersionId: Scalars['String']['output'];
  generationHistory?: Maybe<ReportGenerationHistory>;
  id: Scalars['String']['output'];
  position: Scalars['Int']['output'];
  reportFile?: Maybe<ReportFileMetadata>;
  status: ReportPackageDocumentStatus;
  title: Scalars['String']['output'];
  type: ReportPackageDocumentType;
};

export enum ReportPackageDocumentStatus {
  Complete = 'Complete',
  Failed = 'Failed',
  Pending = 'Pending',
  UnknownStatus = 'UnknownStatus'
}

export enum ReportPackageDocumentType {
  Layout = 'Layout',
  Report = 'Report',
  Text = 'Text'
}

export type ReportPackageMetadata = {
  author?: Maybe<Contact>;
  coverUrl?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  creationMethod: CreationMethod;
  documents: Array<ReportPackageDocumentMetadata>;
  endedAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  interval: Interval;
  publishedAsDraft: Scalars['Boolean']['output'];
  startedAt: Scalars['DateTime']['output'];
  status: ReportPackageStatus;
  title: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  versionId: Scalars['String']['output'];
  versions: Array<ReportPackageVersionMetadata>;
  viewKey: ViewIdentifier;
};


export type ReportPackageMetadataVersionsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<ReportPackageStatus>;
};

export type ReportPackageOptions = {
  accountNumbersDisplay?: Maybe<AccountNumbersDisplay>;
  coverLogoSource?: Maybe<CoverLogoSource>;
  coverUrl?: Maybe<Scalars['String']['output']>;
  customFooter?: Maybe<Scalars['String']['output']>;
  preparedBy?: Maybe<Scalars['String']['output']>;
  publishedAsDraft?: Maybe<Scalars['Boolean']['output']>;
};

export enum ReportPackageStatus {
  Draft = 'Draft',
  Published = 'Published',
  Transient = 'Transient',
  Unknown = 'Unknown'
}

/** Reports */
export type ReportPackageSummary = {
  draftCount: Scalars['Long']['output'];
  packagesCount: Scalars['Long']['output'];
  period: Period;
  publishedCount: Scalars['Long']['output'];
};

export type ReportPackageVersionMetadata = {
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  status: ReportPackageStatus;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type ReportRefreshRequest = {
  requestId: Scalars['String']['output'];
};

export enum ReportSource {
  Digits = 'Digits',
  None = 'None',
  QuickBooks = 'QuickBooks'
}

export type ReportTemplate = {
  coverUrl: Scalars['String']['output'];
  hasBalanceSheet: Scalars['Boolean']['output'];
  hasCashFlow: Scalars['Boolean']['output'];
  hasExecutiveSummary: Scalars['Boolean']['output'];
  hasProfitAndLoss: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type ReportValidationArchive = {
  avs?: Maybe<Scalars['String']['output']>;
  digits?: Maybe<Scalars['String']['output']>;
  quickbooks: Scalars['String']['output'];
};

export type ReportValidationByKind = {
  invalidCount: Scalars['Long']['output'];
  kind: ReportKind;
  validCount: Scalars['Long']['output'];
};

export type ReportValidationGlobalPeriodSummary = {
  byKind: Array<ReportValidationByKind>;
  packageInvalidCount: Scalars['Long']['output'];
  packageValidCount: Scalars['Long']['output'];
  period: Period;
};

export type ReportValidationHistory = {
  errorMessage?: Maybe<Scalars['String']['output']>;
  isValid: Scalars['Boolean']['output'];
  legalEntityId: Scalars['String']['output'];
  reportId: Scalars['String']['output'];
  source: ReportFileSource;
  validatedAt: Scalars['DateTime']['output'];
  versionId: Scalars['String']['output'];
};

export enum ReviewStatus {
  Approved = 'Approved',
  ChangesRequested = 'ChangesRequested',
  Commented = 'Commented',
  Dismissed = 'Dismissed',
  Pending = 'Pending'
}

export type RichComment = {
  comment: Comment;
  entities?: Maybe<ObjectEntities>;
  layout: Layout;
};

export type Role = {
  id: Scalars['String']['output'];
};

export type RoleIdentifier = {
  id: Scalars['String']['input'];
};

export type Runway = {
  cashOutDate?: Maybe<Scalars['DateTime']['output']>;
  current: DimensionSummary;
  future: DimensionSummary;
  previousCashOutDate?: Maybe<Scalars['DateTime']['output']>;
};

export type RunwayChartConfig = {
  origin: ArchiveIntervalOrigin;
};

export type RunwayChartConfigInput = {
  origin: IntervalOrigin;
};

export type SalesLead = {
  agentEvent?: Maybe<AgentEvent>;
};

export type SanitizedDescription = {
  exampleTransactionNameAndDescription?: Maybe<Scalars['String']['output']>;
  sanitizedDescription: Scalars['String']['output'];
  transactionCount: Scalars['Long']['output'];
};

export enum SearchEntitiesField {
  Date = 'Date',
  DisplayNumber = 'DisplayNumber',
  Name = 'Name',
  Score = 'Score',
  Type = 'Type',
  Unspecified = 'Unspecified'
}

export type SearchEntitiesSort = {
  ascending: Scalars['Boolean']['input'];
  field: SearchEntitiesField;
};

/** Hit describes a single search result within a result set */
export type SearchHit = {
  /** The search-term highlight offsets within the result contents */
  highlights: Array<HitHighlight>;
  /** The object this search hit references */
  objectId: ObjectIdentifier;
  /** The bleve-assigned score of this result. Uses TDF-IF */
  score: Scalars['Float']['output'];
};

/**
 * Search
 * Result represents a customer-facing set of search results.
 */
export type SearchResult = {
  entities: ObjectEntities;
  kindCounts: Array<ObjectKindFacet>;
  results: Array<SearchHit>;
  tookSecs: Scalars['Float']['output'];
  total: Scalars['Long']['output'];
};

export type Segment = {
  collectionId: Scalars['String']['output'];
  fileId: Scalars['String']['output'];
  fileName?: Maybe<Scalars['String']['output']>;
  fileSize?: Maybe<Scalars['Long']['output']>;
  sequence: Scalars['Long']['output'];
  token: Scalars['String']['output'];
};

export type SentInvoiceUsers = {
  invoice: Invoice;
  users?: Maybe<Array<User>>;
};

export enum ShareAction {
  CreateGrant = 'CreateGrant',
  IntentRevoked = 'IntentRevoked',
  Proceed = 'Proceed',
  RequestAccess = 'RequestAccess',
  RequestPending = 'RequestPending',
  Unknown = 'Unknown'
}

export type SharedReportDocumentMetadata = {
  documentObjectId: Scalars['String']['output'];
  documentObjectVersionId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  mimeType: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type SignUpEvent = {
  legalEntityId?: InputMaybe<Scalars['String']['input']>;
  organizationId?: InputMaybe<Scalars['String']['input']>;
  provider?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
  state: Scalars['String']['input'];
};

export type Signup = {
  companyCurrency?: Maybe<Scalars['String']['output']>;
  companyLedger?: Maybe<Scalars['String']['output']>;
  companyName?: Maybe<Scalars['String']['output']>;
  companyUrl?: Maybe<Scalars['String']['output']>;
  contact: Contact;
  id: Scalars['String']['output'];
  industry?: Maybe<Scalars['String']['output']>;
  location?: Maybe<Scalars['String']['output']>;
  oAuthEmailAddress?: Maybe<Scalars['String']['output']>;
  oAuthService?: Maybe<Scalars['String']['output']>;
  persona: Persona;
  positionTitle?: Maybe<Scalars['String']['output']>;
  profileURL?: Maybe<Scalars['String']['output']>;
  status: SignupStatus;
  statusReason?: Maybe<Scalars['String']['output']>;
};

export enum SignupStatus {
  Applied = 'Applied',
  Approved = 'Approved',
  Ignored = 'Ignored',
  InviteAccepted = 'InviteAccepted',
  Invited = 'Invited',
  PrimerSent = 'PrimerSent',
  Qualified = 'Qualified',
  Unknown = 'Unknown',
  Unqualified = 'Unqualified',
  Waitlisted = 'Waitlisted'
}

export enum SimilarityType {
  Category = 'Category',
  Party = 'Party',
  Unknown = 'Unknown'
}

export enum SortDirection {
  Ascending = 'Ascending',
  Descending = 'Descending'
}

export type SourceAccount = {
  dataSource?: Maybe<EnrichedDataSource>;
  externalId: Scalars['String']['output'];
  externalName?: Maybe<Scalars['String']['output']>;
  externalOfficialName?: Maybe<Scalars['String']['output']>;
  externalOwnerName?: Maybe<Scalars['String']['output']>;
  externalSubtype?: Maybe<Scalars['String']['output']>;
  externalType?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  mask?: Maybe<Scalars['String']['output']>;
  ownerEmployeeId?: Maybe<Scalars['String']['output']>;
  status?: Maybe<SourceAccountStatus>;
};

export enum SourceAccountStatus {
  Disabled = 'Disabled',
  Enabled = 'Enabled',
  UnknownStatus = 'UnknownStatus'
}

export type SourceRecordedMatchSummary = {
  entries: Array<SourceRecordedMatchSummaryEntry>;
};

export type SourceRecordedMatchSummaryEntry = {
  institution: Institution;
  matched: Scalars['Long']['output'];
  period: Period;
  total: Scalars['Long']['output'];
  unmatched: Scalars['Long']['output'];
  unmatchedAndUncategorized: Scalars['Long']['output'];
};

export type Statement = {
  kind: ReportKind;
  rows: Array<StatementRow>;
};

export type StatementBalances = {
  closingBalance: MoneyFlow;
  creditTotal?: Maybe<MoneyFlow>;
  debitTotal?: Maybe<MoneyFlow>;
  netTotal: MoneyFlow;
  openingBalance: MoneyFlow;
};

export type StatementConfig = {
  kind: ReportKind;
  options?: Maybe<ReportDocumentOptions>;
  origin: ArchiveIntervalOrigin;
};

export type StatementConfigInput = {
  kind: ReportKind;
  options?: InputMaybe<ReportDocumentOptionsInput>;
  origin: IntervalOrigin;
};

export type StatementDeltaValue = {
  moneyFlow: MoneyFlow;
  percentageOfAmount: Scalars['Float']['output'];
};

export type StatementDeltas = {
  percentageIncome?: Maybe<StatementDeltaValue>;
  percentageTotal?: Maybe<StatementDeltaValue>;
  previousPeriod?: Maybe<StatementDeltaValue>;
  previousYear?: Maybe<StatementDeltaValue>;
};

export type StatementDetails = {
  hover: Hover;
  insight?: Maybe<Insight>;
};

export type StatementRow = {
  deltas?: Maybe<StatementDeltas>;
  depth: Scalars['Int']['output'];
  details?: Maybe<StatementDetails>;
  leafCategorySummary?: Maybe<StatementRowSummary>;
  parentCategorySummary?: Maybe<StatementRowSummary>;
  parentCategoryTitle?: Maybe<StatementRowTitle>;
  rowId: Scalars['String']['output'];
  sectionSummary?: Maybe<StatementRowSummary>;
  sectionTitle?: Maybe<StatementRowTitle>;
  ytdDetails?: Maybe<StatementDetails>;
};

export type StatementRowSummary = {
  displayNumber?: Maybe<Scalars['String']['output']>;
  label: Scalars['String']['output'];
  moneyFlow: MoneyFlow;
};

export type StatementRowTitle = {
  displayNumber?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
};

export type StatementSummary = {
  summary: Scalars['String']['output'];
};

export type StaticMetricConfig = {
  name: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  value: Scalars['String']['output'];
};

export type StaticMetricConfigInput = {
  name: Scalars['String']['input'];
  updatedAt: Scalars['DateTime']['input'];
  value: Scalars['String']['input'];
};

/** ## */
export type StatisticByTimeBuckets = {
  bucketFour?: Maybe<Scalars['Long']['output']>;
  bucketOne?: Maybe<Scalars['Long']['output']>;
  bucketThree?: Maybe<Scalars['Long']['output']>;
  bucketTimeMins: Scalars['Long']['output'];
  bucketTwo?: Maybe<Scalars['Long']['output']>;
  name: Scalars['String']['output'];
  total: Scalars['Long']['output'];
};

export type StripeConnectorProperties = {
  appLinkURL: Scalars['String']['output'];
};

export type Subscription = {
  hydratePartyAgent: HydratedParty;
  researchSalesLeadAgent: SalesLead;
};


export type SubscriptionHydratePartyAgentArgs = {
  value: Scalars['String']['input'];
};


export type SubscriptionResearchSalesLeadAgentArgs = {
  value: Scalars['String']['input'];
};

export type SuggestedConnection = {
  template: ConnectionTemplate;
};

export type SuggestedConnectionsFilter = {
  institutionIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type SummarizeInput = {
  direction?: InputMaybe<DirectionFromOrigin>;
  filter: SummaryFilterInput;
  origin?: InputMaybe<IntervalOrigin>;
};

export enum SummaryDimension {
  Category = 'Category',
  CategoryType = 'CategoryType',
  Department = 'Department',
  Location = 'Location',
  Party = 'Party',
  Time = 'Time'
}

export type SummaryFilterInput = {
  categoryId?: InputMaybe<Scalars['String']['input']>;
  categoryIds?: InputMaybe<Array<Scalars['String']['input']>>;
  categoryType?: InputMaybe<CategoryType>;
  categoryTypes?: InputMaybe<Array<CategoryType>>;
  departmentIds?: InputMaybe<Array<Scalars['String']['input']>>;
  institutionIds?: InputMaybe<Array<Scalars['String']['input']>>;
  locationIds?: InputMaybe<Array<Scalars['String']['input']>>;
  maxValue?: InputMaybe<InputMonetaryValue>;
  minValue?: InputMaybe<InputMonetaryValue>;
  missingParty?: InputMaybe<MissingParty>;
  ownerEmployeeId?: InputMaybe<Scalars['String']['input']>;
  partyEntityTypes?: InputMaybe<Array<PartyEntityType>>;
  partyId?: InputMaybe<Scalars['String']['input']>;
  partyIds?: InputMaybe<Array<Scalars['String']['input']>>;
  partyName?: InputMaybe<Scalars['String']['input']>;
  partyRole?: InputMaybe<PartyRole>;
  partyRoles?: InputMaybe<Array<PartyRole>>;
  productArea?: InputMaybe<ProductArea>;
  reconciliationFilter?: InputMaybe<ReconciliationFilter>;
  taxStatuses?: InputMaybe<Array<TaxStatus>>;
  taxpayerIdentifications?: InputMaybe<Array<TaxpayerIdentification>>;
  useAbsAmount?: InputMaybe<Scalars['Boolean']['input']>;
  userIds?: InputMaybe<Array<Scalars['String']['input']>>;
  viewKey: ViewKey;
  withoutCategoryAncestors?: InputMaybe<Scalars['Boolean']['input']>;
  withoutDepartmentAncestors?: InputMaybe<Scalars['Boolean']['input']>;
  withoutLocationAncestors?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum SummaryLineItem {
  GrossProfit = 'GrossProfit',
  NetIncome = 'NetIncome',
  NetOperatingIncome = 'NetOperatingIncome',
  NetOtherIncome = 'NetOtherIncome',
  TotalCash = 'TotalCash'
}

export type SummaryLineItemConfig = {
  item: SummaryLineItem;
  origin: ArchiveIntervalOrigin;
};

export type SummaryLineItemConfigInput = {
  item: SummaryLineItem;
  origin: IntervalOrigin;
};

export type SummaryLineItemHover = {
  history: DimensionSummary;
  lineType: SummaryLineItem;
};

export type SummaryTotal = {
  count: Scalars['Long']['output'];
  total: MoneyFlow;
};

export type Superuser = {
  avatarUrl?: Maybe<Scalars['String']['output']>;
  /** Not provided in all cases */
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  emailAddress: Scalars['String']['output'];
  familyName?: Maybe<Scalars['String']['output']>;
  givenName?: Maybe<Scalars['String']['output']>;
  /** Basic fields */
  id: Scalars['String']['output'];
  lastLoginAt?: Maybe<Scalars['DateTime']['output']>;
  roles: Array<SuperuserRole>;
};

export type SuperuserRole = {
  description: Scalars['String']['output'];
  displayName: Scalars['String']['output'];
  identifier: Scalars['String']['output'];
};

export enum TaxForm {
  Corporation = 'Corporation',
  LimitedLiability = 'LimitedLiability',
  NonprofitOrganization = 'NonprofitOrganization',
  Other = 'Other',
  PartnershipOrLimitedLiability = 'PartnershipOrLimitedLiability',
  SmallBusinessCorporation = 'SmallBusinessCorporation',
  SoleProprietor = 'SoleProprietor',
  UnknownTaxForm = 'UnknownTaxForm'
}

export enum TaxStatus {
  NonTaxable = 'NonTaxable',
  Taxable = 'Taxable',
  Undetermined = 'Undetermined',
  UnspecifiedTaxStatus = 'UnspecifiedTaxStatus'
}

export enum TaxpayerIdentification {
  Available = 'Available',
  NotRequired = 'NotRequired',
  Required = 'Required',
  Undetermined = 'Undetermined',
  UnknownTaxpayerIdentification = 'UnknownTaxpayerIdentification'
}

export type TaxpayerIdentificationInput = {
  partyId: Scalars['String']['input'];
  taxpayerIdentification: TaxpayerIdentification;
};

export type Team = {
  description?: Maybe<Scalars['String']['output']>;
  iconObjectName?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  invites: Array<TeamMemberInvite>;
  members: Array<TeamMember>;
  name: Scalars['String']['output'];
};

export type TeamAcl = {
  entities: ObjectEntities;
  grants: Array<ObjectGrant>;
};

export type TeamMember = {
  role: TeamRole;
  user: EntityUser;
};

export type TeamMemberInvite = {
  emailAddress: Scalars['String']['output'];
  role: TeamRole;
};

export enum TeamRole {
  Manager = 'Manager',
  Member = 'Member'
}

export type TextComponentConfig = {
  body: Scalars['String']['output'];
  current?: Maybe<ArchiveIntervalOrigin>;
  lookback?: Maybe<ArchiveIntervalOrigin>;
  tags?: Maybe<Array<TextComponentConfigTag>>;
};

export type TextComponentConfigInput = {
  body: Scalars['String']['input'];
  current?: InputMaybe<IntervalOrigin>;
  lookback?: InputMaybe<IntervalOrigin>;
  tags?: InputMaybe<Array<TextComponentConfigTagInput>>;
};

export type TextComponentConfigTag = {
  displayText: Scalars['String']['output'];
  objectId: ObjectIdentifier;
  options?: Maybe<TextTagOptions>;
};

export type TextComponentConfigTagInput = {
  displayText: Scalars['String']['input'];
  objectId: InputObjectIdentifier;
  options?: InputMaybe<TextTagOptionsInput>;
};

export type TextDocument = {
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  status: ReportPackageDocumentStatus;
  summary: Scalars['String']['output'];
  title: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type TextFeatureValue = {
  textValue: Scalars['String']['output'];
};

export type TextTagOptions = {
  partyRole?: Maybe<PartyRole>;
};

export type TextTagOptionsInput = {
  partyRole?: InputMaybe<Scalars['String']['input']>;
};

/** Thread contains the ThreadDetails and associated Comments. */
export type Thread = {
  comments: Array<Comment>;
  details: ThreadDetails;
  id: Scalars['String']['output'];
};

/**
 * ThreadDetails represents metadata about a thread.
 * This type is intended to be read-only and resolved
 * by the backend.
 */
export type ThreadDetails = {
  authorId: Scalars['String']['output'];
  commentCount: Scalars['Long']['output'];
  commenterIds: Array<Scalars['String']['output']>;
  context?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  pending?: Maybe<Scalars['Boolean']['output']>;
  resolvedAt?: Maybe<Scalars['DateTime']['output']>;
  tags: Array<ObjectIdentifier>;
  targetObject: ObjectIdentifier;
};

export type ThreadList = {
  targetObject: ObjectIdentifier;
  threads: Array<ThreadDetails>;
};

export type ThreadListWithEntities = {
  entities?: Maybe<ObjectEntities>;
  threads: Array<Thread>;
};

export type ThreadWithEntities = {
  entities?: Maybe<ObjectEntities>;
  thread: Thread;
};

export type Threads = {
  entities?: Maybe<ObjectEntities>;
  threads: Array<ThreadList>;
};

export type TimeDimension = {
  by: DimensionSummary;
  summary: TransactionSummary;
  timestamp: Scalars['Float']['output'];
};

export type TopEntities = {
  entities: DimensionSummary;
};

export type TopEntitiesConfig = {
  categoryType?: Maybe<CategoryType>;
  filteredBy?: Maybe<ObjectIdentifier>;
  kind: ObjectKind;
  limit: Scalars['Int']['output'];
  origin: ArchiveIntervalOrigin;
  partyRole?: Maybe<PartyRole>;
  sort: DimensionSortMode;
};

export type TopEntitiesConfigInput = {
  categoryType?: InputMaybe<CategoryType>;
  filteredBy?: InputMaybe<InputObjectIdentifier>;
  kind: ObjectKind;
  limit: Scalars['Int']['input'];
  origin: IntervalOrigin;
  partyRole?: InputMaybe<PartyRole>;
  sort: DimensionSortMode;
};

export type TopTransactions = {
  partialData: Scalars['Boolean']['output'];
  transactions: Array<EntityTransaction>;
};

export type TopTransactionsConfig = {
  categoryType?: Maybe<CategoryType>;
  filteredBy?: Maybe<ObjectIdentifier>;
  limit: Scalars['Int']['output'];
  origin: ArchiveIntervalOrigin;
  partyRole?: Maybe<PartyRole>;
};

/**
 * INPUTS
 *
 */
export type TopTransactionsConfigInput = {
  categoryType?: InputMaybe<CategoryType>;
  filteredBy?: InputMaybe<InputObjectIdentifier>;
  limit: Scalars['Int']['input'];
  origin: IntervalOrigin;
  partyRole?: InputMaybe<PartyRole>;
};

export type Transaction = {
  compileSource?: Maybe<CompileSource>;
  creditSources: Array<TransactionSource>;
  debitSources: Array<TransactionSource>;
  department?: Maybe<EntityDepartment>;
  description?: Maybe<Scalars['String']['output']>;
  digitsTransactionType?: Maybe<TransactionType>;
  displayCanonicalCategoryName?: Maybe<Scalars['String']['output']>;
  displayCategory: Category;
  displayCategorySource: ClassificationSource;
  /** display* fields for use when a single field is preferred over displaying both debit and credit. */
  displayEntry: DisplayEntry;
  displayInstitution?: Maybe<TransactionParty>;
  displayProductArea?: Maybe<ProductArea>;
  displayUncategorizedReason: UncategorizedReason;
  /** @deprecated Use moneyFlow */
  displayValue?: Maybe<MonetaryValue>;
  facilitatorParty?: Maybe<TransactionParty>;
  factId?: Maybe<Scalars['String']['output']>;
  location?: Maybe<EntityLocation>;
  moneyFlow: MoneyFlow;
  name?: Maybe<Scalars['String']['output']>;
  owner?: Maybe<EntityEmployee>;
  ownerSource: ClassificationSource;
  party?: Maybe<TransactionParty>;
  partySource: ClassificationSource;
  product?: Maybe<Product>;
  providers: Array<Provider>;
  reconciliationStatus: TransactionReconciliationStatus;
  recordType: TransactionRecordType;
  recurrence?: Maybe<TransactionRecurrence>;
  referenceNumber?: Maybe<Scalars['String']['output']>;
  runningTotal?: Maybe<MoneyFlow>;
  sources: Array<TransactionSource>;
  splitCategory?: Maybe<Category>;
  splitInstitution?: Maybe<TransactionParty>;
  supersededFactIds: Array<Scalars['String']['output']>;
  taxStatus: TaxStatus;
  /** @deprecated unpopulated */
  threadDetails?: Maybe<Array<ThreadDetails>>;
  timestamp: Scalars['DateTime']['output'];
  transactionClassificationType?: Maybe<TransactionClassificationType>;
  viewId: Scalars['String']['output'];
};

export enum TransactionClassification {
  Credit = 'Credit',
  Debit = 'Debit'
}

export enum TransactionClassificationType {
  ACH = 'ACH',
  ATM = 'ATM',
  AccountsPayable = 'AccountsPayable',
  Charge = 'Charge',
  Check = 'Check',
  DebitCard = 'DebitCard',
  DirectDeposit = 'DirectDeposit',
  ExpenseReport = 'ExpenseReport',
  OnlineTransfer = 'OnlineTransfer',
  Transfer = 'Transfer',
  UnspecifiedTransactionClassificationType = 'UnspecifiedTransactionClassificationType',
  Wire = 'Wire'
}

export enum TransactionColumnName {
  AbsoluteAmount = 'AbsoluteAmount',
  Amount = 'Amount',
  Description = 'Description',
  FirstRecurredAt = 'FirstRecurredAt',
  Name = 'Name',
  OccurredAt = 'OccurredAt',
  Party = 'Party',
  ReconciliationStatus = 'ReconciliationStatus',
  RecurrenceInterval = 'RecurrenceInterval'
}

export type TransactionCounts = {
  reconciled: Scalars['Int']['output'];
  unsettled: Scalars['Int']['output'];
};

export type TransactionEntry = {
  amount: MonetaryValue;
  description: Scalars['String']['output'];
  externalLineId: Scalars['String']['output'];
  factId: Scalars['String']['output'];
};

export type TransactionEntryLine = {
  category: Category;
  description?: Maybe<Scalars['String']['output']>;
  highlighted: Scalars['Boolean']['output'];
  isCredit: Scalars['Boolean']['output'];
  lineId: Scalars['String']['output'];
  name?: Maybe<Scalars['String']['output']>;
  value: MonetaryValue;
};

export type TransactionFilter = {
  canonicalCategoryType?: InputMaybe<CanonicalCategoryType>;
  categoryId?: InputMaybe<Scalars['String']['input']>;
  categoryIds?: InputMaybe<Array<Scalars['String']['input']>>;
  categoryName?: InputMaybe<Scalars['String']['input']>;
  categoryType?: InputMaybe<CategoryType>;
  categoryTypes?: InputMaybe<Array<CategoryType>>;
  classification?: InputMaybe<TransactionClassification>;
  dataSourceId?: InputMaybe<Scalars['String']['input']>;
  departmentIds?: InputMaybe<Array<Scalars['String']['input']>>;
  doubleEntry?: InputMaybe<DoubleEntry>;
  filterTerm?: InputMaybe<Scalars['String']['input']>;
  handleNone?: InputMaybe<HandleNone>;
  institutionIds?: InputMaybe<Array<Scalars['String']['input']>>;
  invoiceId?: InputMaybe<Scalars['String']['input']>;
  kind?: InputMaybe<TransactionKind>;
  locationIds?: InputMaybe<Array<Scalars['String']['input']>>;
  maxValue?: InputMaybe<InputMonetaryValue>;
  minValue?: InputMaybe<InputMonetaryValue>;
  missingParty?: InputMaybe<MissingParty>;
  occurredAfter?: InputMaybe<Scalars['Float']['input']>;
  occurredBefore?: InputMaybe<Scalars['Float']['input']>;
  ownerEmployeeId?: InputMaybe<Scalars['String']['input']>;
  partyEntityTypes?: InputMaybe<Array<PartyEntityType>>;
  partyId?: InputMaybe<Scalars['String']['input']>;
  partyIds?: InputMaybe<Array<Scalars['String']['input']>>;
  partyName?: InputMaybe<Scalars['String']['input']>;
  partyRole?: InputMaybe<PartyRole>;
  partyRoles?: InputMaybe<Array<PartyRole>>;
  productArea?: InputMaybe<ProductArea>;
  reconciliation?: InputMaybe<Reconciliation>;
  reconciliationFilter?: InputMaybe<ReconciliationFilter>;
  recurrenceInterval?: InputMaybe<Interval>;
  recurrenceStatus?: InputMaybe<TransactionFilterRecurrenceStatus>;
  recurringThreadId?: InputMaybe<Scalars['String']['input']>;
  referenceTransactionId?: InputMaybe<Scalars['String']['input']>;
  sourceAccountId?: InputMaybe<Scalars['String']['input']>;
  taxStatuses?: InputMaybe<Array<TaxStatus>>;
  taxpayerIdentifications?: InputMaybe<Array<TaxpayerIdentification>>;
  useAbsAmount?: InputMaybe<Scalars['Boolean']['input']>;
  userIds?: InputMaybe<Array<Scalars['String']['input']>>;
  viewKey: ViewKey;
  withoutCategoryAncestors?: InputMaybe<Scalars['Boolean']['input']>;
  withoutDepartmentAncestors?: InputMaybe<Scalars['Boolean']['input']>;
  withoutLocationAncestors?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum TransactionFilterRecurrenceStatus {
  AllRecurring = 'AllRecurring',
  Occurred = 'Occurred'
}

export type TransactionGroup = {
  category: Category;
  description?: Maybe<Scalars['String']['output']>;
  groupId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  name?: Maybe<Scalars['String']['output']>;
  party?: Maybe<TransactionParty>;
  timestamp: Scalars['DateTime']['output'];
  totalAmount: MoneyFlow;
  transactions: Array<Transaction>;
};

export enum TransactionKind {
  Deposit = 'Deposit',
  Expense = 'Expense',
  Invoice = 'Invoice',
  Journal = 'Journal',
  Payment = 'Payment',
  Transfer = 'Transfer'
}

export type TransactionLine = {
  category: EntityCategory;
  entry: TransactionEntry;
  entryType: EntryType;
  party?: Maybe<EntityParty>;
};

/** Transactions */
export type TransactionOrder = {
  transactionOrderPairs: Array<TransactionOrderPair>;
};

export type TransactionOrderPair = {
  columnName: TransactionColumnName;
  direction: SortDirection;
};

export type TransactionParty = {
  designation: PartyDesignation;
  iconObjectName?: Maybe<Scalars['String']['output']>;
  iconUrl?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  roles?: Maybe<Array<PartyRole>>;
};

export type TransactionReconciliation = {
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  /** creator/created and updater/updatedAt are null when it was auto-reconciled/auto-unsettled */
  creator?: Maybe<EntityUser>;
  status: TransactionReconciliationStatus;
  transaction: Transaction;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updater?: Maybe<EntityUser>;
};

export enum TransactionReconciliationStatus {
  Reconciled = 'Reconciled',
  Unknown = 'Unknown',
  Unsettled = 'Unsettled'
}

export enum TransactionRecordType {
  ExpectedTransaction = 'ExpectedTransaction',
  Journal = 'Journal',
  MissedTransaction = 'MissedTransaction',
  Transaction = 'Transaction',
  UnknownRecordType = 'UnknownRecordType',
  UnreconciledSourceTransaction = 'UnreconciledSourceTransaction'
}

export type TransactionRecurrence = {
  firstOccurredAt: Scalars['DateTime']['output'];
  interval: Interval;
  intervalMultiplier: Scalars['Long']['output'];
  isHidden: Scalars['Boolean']['output'];
  lastOccurredAt: Scalars['DateTime']['output'];
  recurrenceThreadId: Scalars['String']['output'];
  threadIndex: Scalars['Long']['output'];
  totalThreadCount: Scalars['Long']['output'];
};

export type TransactionReviewQuestion = {
  createdAt: Scalars['DateTime']['output'];
  question: Scalars['String']['output'];
};

export type TransactionReviewReminderMessage = {
  legalEntityId: Scalars['String']['output'];
  message: Scalars['String']['output'];
};

export type TransactionReviewSchedule = {
  daysOfMonth?: Maybe<Array<DayOfMonth>>;
  daysOfWeek?: Maybe<Array<DayOfWeek>>;
  enabled: Scalars['Boolean']['output'];
  legalEntityId: Scalars['String']['output'];
};

export type TransactionSource = {
  account?: Maybe<TransactionSourceAccount>;
  externalDisplayId?: Maybe<Scalars['String']['output']>;
  iconUrl?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  timestamp: Scalars['DateTime']['output'];
  type: TransactionSourceType;
};

export type TransactionSourceAccount = {
  externalName?: Maybe<Scalars['String']['output']>;
  externalOfficialName?: Maybe<Scalars['String']['output']>;
  externalOwnerName?: Maybe<Scalars['String']['output']>;
  mask?: Maybe<Scalars['String']['output']>;
};

export enum TransactionSourceType {
  CSVImport = 'CSVImport',
  Institution = 'Institution',
  Unknown = 'Unknown',
  User = 'User'
}

export type TransactionSummary = {
  isPeriodBookClosed: Scalars['Boolean']['output'];
  period: Period;
  total: PeriodMonetaryValue;
};

/** Transaction Editing */
export enum TransactionType {
  BankTransfer = 'BankTransfer',
  JournalEntry = 'JournalEntry',
  PayIn = 'PayIn',
  PayOut = 'PayOut',
  Unknown = 'Unknown'
}

export type TransactionViewDetail = {
  creditAmount?: Maybe<MonetaryValue>;
  creditCategoryHierarchy: Array<Category>;
  debitAmount?: Maybe<MonetaryValue>;
  /** First node is the top most parent of the hierarchy */
  debitCategoryHierarchy: Array<Category>;
  description?: Maybe<Scalars['String']['output']>;
  /** First node is the top most parent of the hierarchy */
  entryLines: Array<TransactionEntryLine>;
  externalCreatedAt?: Maybe<Scalars['DateTime']['output']>;
  externalDisplayId?: Maybe<Scalars['String']['output']>;
  externalId?: Maybe<Scalars['String']['output']>;
  factId?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  institution: Institution;
  name?: Maybe<Scalars['String']['output']>;
  occurredAt: Scalars['DateTime']['output'];
  party?: Maybe<TransactionParty>;
  quickbooksAppName?: Maybe<Scalars['String']['output']>;
  recordType: TransactionRecordType;
  sourceAccount?: Maybe<SourceAccount>;
};

export type TransactionViewDetails = {
  details: Array<TransactionViewDetail>;
};

/** Transaction Details */
export type TransactionWithDetails = {
  details: Array<TransactionViewDetail>;
  isInBooksClosedPeriod?: Maybe<Scalars['Boolean']['output']>;
  transaction: Transaction;
};

export type TrialBalance = {
  rows: Array<TrialBalanceRow>;
};

export type TrialBalanceRow = {
  credits: MonetaryValue;
  debits: MonetaryValue;
  name: Scalars['String']['output'];
  rowId: Scalars['String']['output'];
};

export enum UncategorizedReason {
  New = 'New',
  None = 'None',
  Split = 'Split'
}

export type UnsupportedTransaction = {
  accountAlias: Scalars['String']['output'];
  dataType: Scalars['String']['output'];
  fetchId: Scalars['String']['output'];
  fetchType: FetchType;
  providerType: ProviderType;
  reason: Scalars['String']['output'];
  transactionCount: Scalars['Long']['output'];
};

export type UnsupportedTransactions = {
  onboardUnsupportedTransactions: Array<UnsupportedTransaction>;
  otherUnsupportedTransactions: Array<UnsupportedTransaction>;
};

export enum UpdatableEmployeeField {
  AvatarUrl = 'AvatarUrl',
  EmailAddress = 'EmailAddress',
  FamilyName = 'FamilyName',
  GivenName = 'GivenName',
  Hidden = 'Hidden',
  JobTitle = 'JobTitle',
  Role = 'Role'
}

export type UpdateAuditRule = {
  amountFilters?: InputMaybe<Array<InputAmountRuleFilter>>;
  auditRuleId: Scalars['String']['input'];
  auditRuleName: Scalars['String']['input'];
  categoryFilters?: InputMaybe<Array<InputCategoryRuleFilter>>;
  deletedAmountFilters?: InputMaybe<Array<DeletedAmountFilter>>;
  deletedCategories?: InputMaybe<Array<DeletedCategory>>;
  deletedCategoryFilters?: InputMaybe<Array<DeletedCategoryFilter>>;
  deletedPartyFilterIds?: InputMaybe<Array<Scalars['String']['input']>>;
  deletedPartyIds?: InputMaybe<Array<Scalars['String']['input']>>;
  partyFilters?: InputMaybe<Array<InputPartyRuleFilter>>;
  ruleState: AuditRuleState;
};

export type UploadBankFeedResponse = {
  transactionFactIds: Array<Scalars['String']['output']>;
};

export type UploadJournalEntriesResponse = {
  transactionFactIds: Array<Scalars['String']['output']>;
};

export type User = {
  avatarUrl?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  employments?: Maybe<Array<OrganizationEmployment>>;
  familyName?: Maybe<Scalars['String']['output']>;
  givenName?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  identities?: Maybe<Array<Identity>>;
  lastActivityAt: Scalars['DateTime']['output'];
  passwordSet: Scalars['Boolean']['output'];
  primaryEmailAddress: Scalars['String']['output'];
};

export type UserAcl = {
  entities: ObjectEntities;
  grants: Array<ObjectGrant>;
  intents: Array<ObjectIntent>;
  requests: Array<ObjectRequest>;
};

export type UserAssignee = {
  organizationId?: InputMaybe<Scalars['String']['input']>;
  userId: Scalars['String']['input'];
};

export type UserContact = {
  avatarUrl?: Maybe<Scalars['String']['output']>;
  emailAddress: Scalars['String']['output'];
  familyName?: Maybe<Scalars['String']['output']>;
  givenName?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

export type UserGrant = {
  grantId: Scalars['String']['output'];
  permission: Permission;
  userId: Scalars['String']['output'];
};

export type UserInfo = {
  emailAddress: Scalars['String']['output'];
  familyName: Scalars['String']['output'];
  givenName: Scalars['String']['output'];
};

export type UserIntent = {
  emailAddress: Scalars['String']['output'];
  intentId: Scalars['String']['output'];
  permission: Permission;
};

export type UserRequest = {
  requestId: Scalars['String']['output'];
  userId: Scalars['String']['output'];
};

export type ValidationHealthStatus = {
  balanceSheetConsistent: HealthStatus;
  cashAspectHealth: AspectDataHealth;
  expenseAspectHealth: AspectDataHealth;
  revenueAspectHealth: AspectDataHealth;
  startedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type ValidationHistory = {
  validationHistory: Array<ValidationHealthStatus>;
};

export type ValidationPeriodSummary = {
  matchedCategories: Scalars['Long']['output'];
  mismatchedCategories: Scalars['Long']['output'];
  noExternalValueMismatchedCategories: Scalars['Long']['output'];
  period: Period;
};

/** Validation */
export type ValidationSummary = {
  periodSummaries: Array<ValidationPeriodSummary>;
};

export enum VerificationRequestDestination {
  Bill = 'Bill',
  FounderFinance = 'FounderFinance',
  InSidedSSO = 'InSidedSSO',
  JoinDigits = 'JoinDigits',
  Share = 'Share',
  Unspecified = 'Unspecified'
}

export type ViewGenerationSummary = {
  bucketFour?: Maybe<Scalars['Long']['output']>;
  bucketOne?: Maybe<Scalars['Long']['output']>;
  bucketThree?: Maybe<Scalars['Long']['output']>;
  bucketTimeMins?: Maybe<Scalars['Long']['output']>;
  bucketTwo?: Maybe<Scalars['Long']['output']>;
};

export type ViewIdentifier = {
  legalEntityId: Scalars['String']['output'];
  mutationVersion?: Maybe<Scalars['String']['output']>;
  viewType: ViewType;
  viewVersion: Scalars['String']['output'];
};

export type ViewIdentifierInput = {
  legalEntityId: Scalars['String']['input'];
  mutationVersion?: InputMaybe<Scalars['String']['input']>;
  viewType: ViewType;
  viewVersion: Scalars['String']['input'];
};

export type ViewInvoiceSummary = {
  invoice: PublicInvoice;
  invoicePaymentDetails?: Maybe<InvoicePaymentDetails>;
};

export type ViewKey = {
  legalEntityId: Scalars['String']['input'];
  mutationVersion?: InputMaybe<Scalars['String']['input']>;
  viewType?: InputMaybe<ViewType>;
  viewVersion?: InputMaybe<Scalars['String']['input']>;
};

export type ViewMetadata = {
  firstTransactionTimestamp: Scalars['DateTime']['output'];
  legalEntityId: Scalars['String']['output'];
  viewType?: Maybe<ViewType>;
  viewVersion?: Maybe<Scalars['String']['output']>;
};

export enum ViewType {
  AIBookkeeper = 'AIBookkeeper',
  Ledger = 'Ledger',
  Unknown = 'Unknown'
}

/** Notifications */
export type WebNotification = {
  assetLink?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  displayKey: Scalars['String']['output'];
  eventType: EventType;
  id: Scalars['String']['output'];
  legalEntityId: Scalars['String']['output'];
  message: Scalars['String']['output'];
  name: Scalars['String']['output'];
  status: WebNotificationStatus;
  targetAction: Scalars['String']['output'];
};

export enum WebNotificationStatus {
  DelayedDelivered = 'DelayedDelivered',
  Dismissed = 'Dismissed',
  Enqueued = 'Enqueued',
  Read = 'Read',
  Received = 'Received',
  UnknownStatus = 'UnknownStatus'
}

/** ## */
export type WelcomePackageHealthStatus = {
  isHealthy: Scalars['Boolean']['output'];
};

export type WorkItem = {
  actionable: Scalars['Boolean']['output'];
  createdAt: Scalars['DateTime']['output'];
  generatedQuestions: Array<WorkItemGeneratedQuestion>;
  itemState: WorkItemState;
  itemType: WorkItemType;
  ledgerType: LedgerType;
  legalEntityId: Scalars['String']['output'];
  pushes: Array<WorkItemPush>;
  review?: Maybe<WorkItemReview>;
  reviewState?: Maybe<WorkItemReviewState>;
  time: Scalars['DateTime']['output'];
  transactions: Array<WorkItemTransaction>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** TODO: Hydrate user. */
  updatedBy?: Maybe<Scalars['String']['output']>;
  workItemId: Scalars['String']['output'];
};

export type WorkItemBadgeCountFilter = {
  itemStates?: InputMaybe<Array<WorkItemState>>;
  itemTypes?: InputMaybe<Array<WorkItemType>>;
  ledgerType?: InputMaybe<LedgerType>;
  legalEntityIds: Array<Scalars['String']['input']>;
  reviewStatesFilter?: InputMaybe<WorkItemReviewStatesFilter>;
};

export enum WorkItemErrorType {
  DuplicateVendor = 'DuplicateVendor',
  NoError = 'NoError',
  NotSupported = 'NotSupported',
  Other = 'Other',
  PeriodClosed = 'PeriodClosed'
}

export type WorkItemFilter = {
  excludedReviewStates?: InputMaybe<Array<WorkItemReviewState>>;
  itemStates?: InputMaybe<Array<WorkItemState>>;
  ledgerType?: InputMaybe<LedgerType>;
  legalEntityId: Scalars['String']['input'];
  reviewStates?: InputMaybe<Array<WorkItemReviewState>>;
  types?: InputMaybe<Array<WorkItemType>>;
};

export type WorkItemGeneratedQuestion = {
  active: Scalars['Boolean']['output'];
  contextType: WorkItemGeneratedQuestionContextType;
  question: Scalars['String']['output'];
};

export enum WorkItemGeneratedQuestionContextType {
  AccountantSpecific = 'AccountantSpecific',
  Direct = 'Direct',
  Friendly = 'Friendly'
}

export type WorkItemPush = {
  errorType?: Maybe<WorkItemErrorType>;
  field: WorkItemPushFieldType;
  pushState: WorkItemPushState;
  updatedAt: Scalars['DateTime']['output'];
};

export enum WorkItemPushFieldType {
  Category = 'Category',
  LineDescription = 'LineDescription',
  Memo = 'Memo',
  Party = 'Party',
  UnknownFieldType = 'UnknownFieldType'
}

export type WorkItemPushParams = {
  appendCommentsToQBO?: InputMaybe<Scalars['Boolean']['input']>;
  categoryParams?: InputMaybe<CategoryParams>;
  partyParams?: InputMaybe<PartyParams>;
  skipClose?: InputMaybe<Scalars['Boolean']['input']>;
  workItemId: Scalars['String']['input'];
};

export enum WorkItemPushState {
  Error = 'Error',
  Ignored = 'Ignored',
  Pushed = 'Pushed',
  UnknownState = 'UnknownState'
}

export type WorkItemResponse = {
  responseText?: InputMaybe<Scalars['String']['input']>;
  workItemId: Scalars['String']['input'];
};

export type WorkItemReview = {
  createdAt: Scalars['DateTime']['output'];
  creator: EntityUser;
  subject: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export enum WorkItemReviewState {
  ClientResponded = 'ClientResponded',
  PendingClientResponse = 'PendingClientResponse'
}

export type WorkItemReviewStatesFilter = {
  includeNullReviewState?: InputMaybe<Scalars['Boolean']['input']>;
  reviewStates?: InputMaybe<Array<WorkItemReviewState>>;
};

export enum WorkItemState {
  Accepted = 'Accepted',
  Active = 'Active',
  Completed = 'Completed',
  Invalidated = 'Invalidated',
  UnknownState = 'UnknownState'
}

export type WorkItemSubject = {
  saveAsTemplate: Scalars['Boolean']['input'];
  subject?: InputMaybe<Scalars['String']['input']>;
  workItemId: Scalars['String']['input'];
};

export type WorkItemSummary = {
  count: Scalars['Long']['output'];
  period: Period;
};

export enum WorkItemSupportType {
  Context = 'Context',
  From = 'From',
  Object = 'Object',
  ObjectHighlight = 'ObjectHighlight',
  ResponseBasedPrediction = 'ResponseBasedPrediction',
  To = 'To',
  UnknownSupportType = 'UnknownSupportType',
  UserProvided = 'UserProvided'
}

export type WorkItemTransaction = {
  creditValue?: Maybe<MonetaryValue>;
  dataSourceId?: Maybe<Scalars['String']['output']>;
  debitValue?: Maybe<MonetaryValue>;
  description?: Maybe<Scalars['String']['output']>;
  displayCategory?: Maybe<EntityCategory>;
  displayEntry: DisplayEntry;
  displayPartyRole: PartyRole;
  /** @deprecated Use moneyFlow instead. */
  displayValue?: Maybe<MonetaryValue>;
  externalTransactionId: Scalars['String']['output'];
  externalTransactionLineId?: Maybe<Scalars['String']['output']>;
  ledgerTransactionId?: Maybe<Scalars['String']['output']>;
  moneyFlow?: Maybe<MoneyFlow>;
  name?: Maybe<Scalars['String']['output']>;
  occurredAt?: Maybe<Scalars['DateTime']['output']>;
  party?: Maybe<EntityParty>;
  quickbooksAppName?: Maybe<Scalars['String']['output']>;
  splitCategory?: Maybe<EntityCategory>;
  supportType: WorkItemSupportType;
};

export enum WorkItemType {
  HighConfidenceCategory = 'HighConfidenceCategory',
  HighConfidenceVendor = 'HighConfidenceVendor',
  InParentCategory = 'InParentCategory',
  PII = 'PII',
  UnassignedCategory = 'UnassignedCategory',
  UnassignedVendor = 'UnassignedVendor',
  UnknownType = 'UnknownType'
}

export type WorkItemValidation = {
  status: WorkItemValidationStatus;
  workItemCount: Scalars['Long']['output'];
};

export enum WorkItemValidationStatus {
  Pending = 'Pending',
  Unknown = 'Unknown',
  Valid = 'Valid'
}

export type JobOwnerFieldsFragment = Pick<JobOwner, 'dataSourceId' | 'legalEntityId'>;

export type JobMetadataFieldsFragment = Pick<JobMetadata, 'consumer' | 'requestObjectId' | 'userId'>;

export type TriggerDataSourceClassificationMutationVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
  dataSourceId: Scalars['String']['input'];
}>;


export type TriggerDataSourceClassificationMutation = Pick<Mutation, 'triggerDataSourceClassification'>;

export type DeleteAdminDataSourceMutationVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
  dataSourceId: Scalars['String']['input'];
  providerType: ProviderType;
}>;


export type DeleteAdminDataSourceMutation = Pick<Mutation, 'deleteAdminDataSource'>;

export type ListAdminBooksClosedFactsQueryVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
}>;


export type ListAdminBooksClosedFactsQuery = { listAdminBooksClosedFacts: Array<Pick<BooksClosedFact, 'legalEntityId' | 'dataSourceId' | 'userId' | 'booksClosedDate' | 'createdAt'>> };

export type ListAdminOwnedDataSourcesQueryVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
}>;


export type ListAdminOwnedDataSourcesQuery = { listAdminOwnedDataSources: Array<{ dataSource: (
      Pick<EnrichedDataSource, 'id' | 'legalEntityId' | 'status' | 'statusReason' | 'providerType' | 'accountAlias' | 'createdAt' | 'updatedAt' | 'lastActivityAt' | 'hasTransactions'>
      & { institution: Pick<Party, 'id' | 'legalEntityId' | 'name' | 'url' | 'supportUrl' | 'supportEmail' | 'supportPhone' | 'twitterUrl' | 'facebookUrl' | 'instagramUrl' | 'yelpUrl' | 'iconUrl' | 'shortDescription' | 'designation'>, quickBooksConfig?: Maybe<Pick<QuickBooksConfig, 'id' | 'realmId' | 'createdAt' | 'updatedAt'>>, plaidConfig?: Maybe<Pick<PlaidConfig, 'id' | 'itemId' | 'externalInstitutionId' | 'createdAt' | 'updatedAt'>>, sourceAccounts?: Maybe<Array<Pick<SourceAccount, 'id' | 'externalId' | 'externalName' | 'externalOwnerName' | 'externalOfficialName' | 'externalType' | 'externalSubtype' | 'mask' | 'status' | 'ownerEmployeeId'>>> }
    ), ownedSourceAccounts?: Maybe<Array<{ sourceAccount: Pick<SourceAccount, 'id' | 'externalId' | 'externalName' | 'externalOwnerName' | 'externalOfficialName' | 'externalType' | 'externalSubtype' | 'mask' | 'status' | 'ownerEmployeeId'>, owner?: Maybe<(
        Pick<Employee, 'id' | 'organizationId' | 'givenName' | 'familyName' | 'avatarUrl' | 'emailAddress' | 'jobTitle' | 'status' | 'hiddenAt'>
        & { role?: Maybe<Pick<Role, 'id'>>, user?: Maybe<(
          Pick<User, 'id' | 'givenName' | 'familyName' | 'primaryEmailAddress' | 'passwordSet' | 'avatarUrl' | 'createdAt' | 'lastActivityAt'>
          & { employments?: Maybe<Array<(
            Pick<OrganizationEmployment, 'employeeId'>
            & { role: Pick<Role, 'id'>, organization: Pick<Organization, 'id' | 'slug' | 'name' | 'iconUrl' | 'websiteUrl'> }
          )>>, identities?: Maybe<Array<Pick<Identity, 'identifier' | 'provider' | 'domain'>>> }
        )>, invitation?: Maybe<(
          Pick<Invitation, 'id' | 'invitationType' | 'status' | 'service' | 'organizationTemplateId' | 'createdAt' | 'updatedAt' | 'notifiedAt'>
          & { recipient: Pick<Contact, 'givenName' | 'familyName' | 'emailAddress' | 'avatarUrl'>, sender?: Maybe<Pick<Contact, 'givenName' | 'familyName' | 'emailAddress' | 'avatarUrl'>>, organization?: Maybe<Pick<Organization, 'id' | 'slug' | 'name' | 'iconUrl' | 'websiteUrl'>>, role?: Maybe<Pick<Role, 'id'>> }
        )> }
      )> }>>, dataSourcePreference?: Maybe<Pick<DataSourcePreference, 'dataSourceId' | 'accountingBasis' | 'taxForm' | 'providerType' | 'departmentTerminology'>> }>, listAdminPredictedDataSources: Array<(
    Pick<PredictedDataSource, 'providerType' | 'type'>
    & { institution: Pick<Party, 'id' | 'legalEntityId' | 'name' | 'url' | 'supportUrl' | 'supportEmail' | 'supportPhone' | 'twitterUrl' | 'facebookUrl' | 'instagramUrl' | 'yelpUrl' | 'iconUrl' | 'shortDescription' | 'designation'> }
  )> };

export type ReadAdminBooksClosedDateQueryVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
}>;


export type ReadAdminBooksClosedDateQuery = { readAdminBooksClosedDate: (
    Pick<BooksClosedDate, 'legalEntityId' | 'latestBooksClosedDateFact' | 'viewBooksClosedDate'>
    & { user?: Maybe<Pick<EntityUser, 'id' | 'givenName' | 'familyName' | 'emailAddress' | 'avatarUrl'>> }
  ) };

export type ListDataSourceJobErrorsQueryVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
  requestObjectId?: InputMaybe<Scalars['String']['input']>;
}>;


export type ListDataSourceJobErrorsQuery = { listDataSourceJobErrors?: Maybe<Array<(
    Pick<JobError, 'jobType' | 'errorMessage' | 'createdAt'>
    & { jobOwner: Pick<JobOwner, 'dataSourceId' | 'legalEntityId'>, jobMetadata: Pick<JobMetadata, 'consumer' | 'requestObjectId' | 'userId'> }
  )>> };

export type UpdateInstitutionMutationVariables = Exact<{
  institution_id: Scalars['String']['input'];
  name: Scalars['String']['input'];
  url: Scalars['String']['input'];
  routingNumber: Scalars['String']['input'];
}>;


export type UpdateInstitutionMutation = Pick<Mutation, 'updateInstitution'>;

export type CreateInstitutionMutationVariables = Exact<{
  name: Scalars['String']['input'];
  url: Scalars['String']['input'];
  routingNumber: Scalars['String']['input'];
  partyId: Scalars['String']['input'];
  kind: InstitutionKind;
}>;


export type CreateInstitutionMutation = { createInstitution: (
    Pick<AdminInstitution, 'id' | 'products'>
    & { institution: Pick<Institution, 'id' | 'name' | 'url' | 'routingNumber' | 'iconUrl' | 'partyId' | 'createdAt'>, party?: Maybe<Pick<Party, 'id' | 'legalEntityId' | 'name' | 'url' | 'supportUrl' | 'supportEmail' | 'supportPhone' | 'twitterUrl' | 'facebookUrl' | 'instagramUrl' | 'yelpUrl' | 'iconUrl' | 'shortDescription' | 'designation'>> }
  ) };

export type UpdateInstitutionProductsMutationVariables = Exact<{
  institutionId: Scalars['String']['input'];
  products: Array<InstitutionProduct> | InstitutionProduct;
}>;


export type UpdateInstitutionProductsMutation = Pick<Mutation, 'updateInstitutionProducts'>;

export type UpdateInstitutionPartyMutationVariables = Exact<{
  institutionId: Scalars['String']['input'];
  partyId: Scalars['String']['input'];
}>;


export type UpdateInstitutionPartyMutation = Pick<Mutation, 'updateInstitutionParty'>;

export type AdminInstitutionFieldsFragment = (
  Pick<AdminInstitution, 'id' | 'products'>
  & { institution: Pick<Institution, 'id' | 'name' | 'url' | 'routingNumber' | 'iconUrl' | 'partyId' | 'createdAt'>, party?: Maybe<Pick<Party, 'id' | 'legalEntityId' | 'name' | 'url' | 'supportUrl' | 'supportEmail' | 'supportPhone' | 'twitterUrl' | 'facebookUrl' | 'instagramUrl' | 'yelpUrl' | 'iconUrl' | 'shortDescription' | 'designation'>> }
);

export type ListInstitutionsQueryVariables = Exact<{
  pagination: Pagination;
}>;


export type ListInstitutionsQuery = { listInstitutions: Array<(
    Pick<AdminInstitution, 'id' | 'products'>
    & { institution: Pick<Institution, 'id' | 'name' | 'url' | 'routingNumber' | 'iconUrl' | 'partyId' | 'createdAt'>, party?: Maybe<Pick<Party, 'id' | 'legalEntityId' | 'name' | 'url' | 'supportUrl' | 'supportEmail' | 'supportPhone' | 'twitterUrl' | 'facebookUrl' | 'instagramUrl' | 'yelpUrl' | 'iconUrl' | 'shortDescription' | 'designation'>> }
  )> };

export type GetInstitutionQueryVariables = Exact<{
  institutionId: Scalars['String']['input'];
}>;


export type GetInstitutionQuery = { getInstitution: (
    Pick<AdminInstitution, 'id' | 'products'>
    & { institution: Pick<Institution, 'id' | 'name' | 'url' | 'routingNumber' | 'iconUrl' | 'partyId' | 'createdAt'>, party?: Maybe<Pick<Party, 'id' | 'legalEntityId' | 'name' | 'url' | 'supportUrl' | 'supportEmail' | 'supportPhone' | 'twitterUrl' | 'facebookUrl' | 'instagramUrl' | 'yelpUrl' | 'iconUrl' | 'shortDescription' | 'designation'>> }
  ) };

export type ListOrganizationsQueryVariables = Exact<{
  pagination?: InputMaybe<Pagination>;
  searchTerm?: InputMaybe<Scalars['String']['input']>;
  affiliateType?: InputMaybe<AffiliateType>;
  organizationIds?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
  onlyWithEmployees?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type ListOrganizationsQuery = { listOrganizations: Array<(
    Pick<AdminOrganization, 'createdAt' | 'deletedAt' | 'userCount'>
    & { organization: Pick<Organization, 'id' | 'slug' | 'name' | 'iconUrl' | 'websiteUrl'>, legalEntities: Array<(
      Pick<AdminLegalEntity, 'createdAt' | 'userCount' | 'approvalEligibilityStatuses' | 'hasPlaidDataSource' | 'allDataSourcesLinked' | 'anyDataSourceRequiresEngineering' | 'allDataSourcesHaveTransactions' | 'deletedAt'>
      & { legalEntity: (
        Pick<LegalEntity, 'id' | 'slug' | 'name' | 'websiteUrl' | 'industry' | 'timezone' | 'fiscalYearStartMonth' | 'fiscalYearNumberOffset' | 'status' | 'organizationId' | 'sensitive'>
        & { address: Pick<Address, 'street' | 'streetAdditional' | 'city' | 'region' | 'postalCode' | 'countryCode' | 'lat' | 'long'>, incorporatedOn?: Maybe<Pick<Date, 'year' | 'month' | 'day'>> }
      ), validationStatus: (
        Pick<ValidationHealthStatus, 'balanceSheetConsistent' | 'startedAt'>
        & { cashAspectHealth: Pick<AspectDataHealth, 'importedDataStatus' | 'closedViewDataStatus' | 'overallDataStatus'>, revenueAspectHealth: Pick<AspectDataHealth, 'importedDataStatus' | 'closedViewDataStatus' | 'overallDataStatus'>, expenseAspectHealth: Pick<AspectDataHealth, 'importedDataStatus' | 'closedViewDataStatus' | 'overallDataStatus'> }
      ), reportValidationHistory: Array<{ period: Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'>, histories: Array<(
          Pick<ReportKindValidationHistory, 'kind'>
          & { history: Pick<ReportValidationHistory, 'reportId' | 'versionId' | 'legalEntityId' | 'validatedAt' | 'isValid' | 'errorMessage' | 'source'> }
        )> }> }
    )>, affiliateLegalEntities: Array<(
      Pick<AdminLegalEntity, 'createdAt' | 'userCount' | 'approvalEligibilityStatuses' | 'hasPlaidDataSource' | 'allDataSourcesLinked' | 'anyDataSourceRequiresEngineering' | 'allDataSourcesHaveTransactions' | 'deletedAt'>
      & { legalEntity: (
        Pick<LegalEntity, 'id' | 'slug' | 'name' | 'websiteUrl' | 'industry' | 'timezone' | 'fiscalYearStartMonth' | 'fiscalYearNumberOffset' | 'status' | 'organizationId' | 'sensitive'>
        & { address: Pick<Address, 'street' | 'streetAdditional' | 'city' | 'region' | 'postalCode' | 'countryCode' | 'lat' | 'long'>, incorporatedOn?: Maybe<Pick<Date, 'year' | 'month' | 'day'>> }
      ), validationStatus: (
        Pick<ValidationHealthStatus, 'balanceSheetConsistent' | 'startedAt'>
        & { cashAspectHealth: Pick<AspectDataHealth, 'importedDataStatus' | 'closedViewDataStatus' | 'overallDataStatus'>, revenueAspectHealth: Pick<AspectDataHealth, 'importedDataStatus' | 'closedViewDataStatus' | 'overallDataStatus'>, expenseAspectHealth: Pick<AspectDataHealth, 'importedDataStatus' | 'closedViewDataStatus' | 'overallDataStatus'> }
      ), reportValidationHistory: Array<{ period: Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'>, histories: Array<(
          Pick<ReportKindValidationHistory, 'kind'>
          & { history: Pick<ReportValidationHistory, 'reportId' | 'versionId' | 'legalEntityId' | 'validatedAt' | 'isValid' | 'errorMessage' | 'source'> }
        )> }> }
    )> }
  )> };

export type ListAffiliatedOrganizationsQueryVariables = Exact<{
  searchTerm?: InputMaybe<Scalars['String']['input']>;
}>;


export type ListAffiliatedOrganizationsQuery = { listAffiliatedOrganizations: Array<(
    Pick<AdminOrganization, 'createdAt' | 'deletedAt' | 'userCount'>
    & { organization: Pick<Organization, 'id' | 'slug' | 'name' | 'iconUrl' | 'websiteUrl'>, legalEntities: Array<(
      Pick<AdminLegalEntity, 'createdAt' | 'userCount' | 'approvalEligibilityStatuses' | 'hasPlaidDataSource' | 'allDataSourcesLinked' | 'anyDataSourceRequiresEngineering' | 'allDataSourcesHaveTransactions' | 'deletedAt'>
      & { legalEntity: (
        Pick<LegalEntity, 'id' | 'slug' | 'name' | 'websiteUrl' | 'industry' | 'timezone' | 'fiscalYearStartMonth' | 'fiscalYearNumberOffset' | 'status' | 'organizationId' | 'sensitive'>
        & { address: Pick<Address, 'street' | 'streetAdditional' | 'city' | 'region' | 'postalCode' | 'countryCode' | 'lat' | 'long'>, incorporatedOn?: Maybe<Pick<Date, 'year' | 'month' | 'day'>> }
      ), validationStatus: (
        Pick<ValidationHealthStatus, 'balanceSheetConsistent' | 'startedAt'>
        & { cashAspectHealth: Pick<AspectDataHealth, 'importedDataStatus' | 'closedViewDataStatus' | 'overallDataStatus'>, revenueAspectHealth: Pick<AspectDataHealth, 'importedDataStatus' | 'closedViewDataStatus' | 'overallDataStatus'>, expenseAspectHealth: Pick<AspectDataHealth, 'importedDataStatus' | 'closedViewDataStatus' | 'overallDataStatus'> }
      ), reportValidationHistory: Array<{ period: Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'>, histories: Array<(
          Pick<ReportKindValidationHistory, 'kind'>
          & { history: Pick<ReportValidationHistory, 'reportId' | 'versionId' | 'legalEntityId' | 'validatedAt' | 'isValid' | 'errorMessage' | 'source'> }
        )> }> }
    )>, affiliateLegalEntities: Array<(
      Pick<AdminLegalEntity, 'createdAt' | 'userCount' | 'approvalEligibilityStatuses' | 'hasPlaidDataSource' | 'allDataSourcesLinked' | 'anyDataSourceRequiresEngineering' | 'allDataSourcesHaveTransactions' | 'deletedAt'>
      & { legalEntity: (
        Pick<LegalEntity, 'id' | 'slug' | 'name' | 'websiteUrl' | 'industry' | 'timezone' | 'fiscalYearStartMonth' | 'fiscalYearNumberOffset' | 'status' | 'organizationId' | 'sensitive'>
        & { address: Pick<Address, 'street' | 'streetAdditional' | 'city' | 'region' | 'postalCode' | 'countryCode' | 'lat' | 'long'>, incorporatedOn?: Maybe<Pick<Date, 'year' | 'month' | 'day'>> }
      ), validationStatus: (
        Pick<ValidationHealthStatus, 'balanceSheetConsistent' | 'startedAt'>
        & { cashAspectHealth: Pick<AspectDataHealth, 'importedDataStatus' | 'closedViewDataStatus' | 'overallDataStatus'>, revenueAspectHealth: Pick<AspectDataHealth, 'importedDataStatus' | 'closedViewDataStatus' | 'overallDataStatus'>, expenseAspectHealth: Pick<AspectDataHealth, 'importedDataStatus' | 'closedViewDataStatus' | 'overallDataStatus'> }
      ), reportValidationHistory: Array<{ period: Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'>, histories: Array<(
          Pick<ReportKindValidationHistory, 'kind'>
          & { history: Pick<ReportValidationHistory, 'reportId' | 'versionId' | 'legalEntityId' | 'validatedAt' | 'isValid' | 'errorMessage' | 'source'> }
        )> }> }
    )> }
  )> };

export type ListApprovalPriorityOrganizationsQueryVariables = Exact<{
  pagination: Pagination;
}>;


export type ListApprovalPriorityOrganizationsQuery = { listApprovalPriorityOrganizations: Array<(
    Pick<AdminOrganization, 'createdAt' | 'deletedAt' | 'userCount'>
    & { organization: Pick<Organization, 'id' | 'slug' | 'name' | 'iconUrl' | 'websiteUrl'>, legalEntities: Array<(
      Pick<AdminLegalEntity, 'createdAt' | 'userCount' | 'approvalEligibilityStatuses' | 'hasPlaidDataSource' | 'allDataSourcesLinked' | 'anyDataSourceRequiresEngineering' | 'allDataSourcesHaveTransactions' | 'deletedAt'>
      & { legalEntity: (
        Pick<LegalEntity, 'id' | 'slug' | 'name' | 'websiteUrl' | 'industry' | 'timezone' | 'fiscalYearStartMonth' | 'fiscalYearNumberOffset' | 'status' | 'organizationId' | 'sensitive'>
        & { address: Pick<Address, 'street' | 'streetAdditional' | 'city' | 'region' | 'postalCode' | 'countryCode' | 'lat' | 'long'>, incorporatedOn?: Maybe<Pick<Date, 'year' | 'month' | 'day'>> }
      ), validationStatus: (
        Pick<ValidationHealthStatus, 'balanceSheetConsistent' | 'startedAt'>
        & { cashAspectHealth: Pick<AspectDataHealth, 'importedDataStatus' | 'closedViewDataStatus' | 'overallDataStatus'>, revenueAspectHealth: Pick<AspectDataHealth, 'importedDataStatus' | 'closedViewDataStatus' | 'overallDataStatus'>, expenseAspectHealth: Pick<AspectDataHealth, 'importedDataStatus' | 'closedViewDataStatus' | 'overallDataStatus'> }
      ), reportValidationHistory: Array<{ period: Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'>, histories: Array<(
          Pick<ReportKindValidationHistory, 'kind'>
          & { history: Pick<ReportValidationHistory, 'reportId' | 'versionId' | 'legalEntityId' | 'validatedAt' | 'isValid' | 'errorMessage' | 'source'> }
        )> }> }
    )>, affiliateLegalEntities: Array<(
      Pick<AdminLegalEntity, 'createdAt' | 'userCount' | 'approvalEligibilityStatuses' | 'hasPlaidDataSource' | 'allDataSourcesLinked' | 'anyDataSourceRequiresEngineering' | 'allDataSourcesHaveTransactions' | 'deletedAt'>
      & { legalEntity: (
        Pick<LegalEntity, 'id' | 'slug' | 'name' | 'websiteUrl' | 'industry' | 'timezone' | 'fiscalYearStartMonth' | 'fiscalYearNumberOffset' | 'status' | 'organizationId' | 'sensitive'>
        & { address: Pick<Address, 'street' | 'streetAdditional' | 'city' | 'region' | 'postalCode' | 'countryCode' | 'lat' | 'long'>, incorporatedOn?: Maybe<Pick<Date, 'year' | 'month' | 'day'>> }
      ), validationStatus: (
        Pick<ValidationHealthStatus, 'balanceSheetConsistent' | 'startedAt'>
        & { cashAspectHealth: Pick<AspectDataHealth, 'importedDataStatus' | 'closedViewDataStatus' | 'overallDataStatus'>, revenueAspectHealth: Pick<AspectDataHealth, 'importedDataStatus' | 'closedViewDataStatus' | 'overallDataStatus'>, expenseAspectHealth: Pick<AspectDataHealth, 'importedDataStatus' | 'closedViewDataStatus' | 'overallDataStatus'> }
      ), reportValidationHistory: Array<{ period: Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'>, histories: Array<(
          Pick<ReportKindValidationHistory, 'kind'>
          & { history: Pick<ReportValidationHistory, 'reportId' | 'versionId' | 'legalEntityId' | 'validatedAt' | 'isValid' | 'errorMessage' | 'source'> }
        )> }> }
    )> }
  )> };

export type ReadOrganizationQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type ReadOrganizationQuery = { readOrganization: (
    Pick<AdminOrganization, 'createdAt' | 'deletedAt' | 'userCount'>
    & { organization: Pick<Organization, 'id' | 'slug' | 'name' | 'iconUrl' | 'websiteUrl'>, legalEntities: Array<(
      Pick<AdminLegalEntity, 'createdAt' | 'userCount' | 'approvalEligibilityStatuses' | 'hasPlaidDataSource' | 'allDataSourcesLinked' | 'anyDataSourceRequiresEngineering' | 'allDataSourcesHaveTransactions' | 'deletedAt'>
      & { legalEntity: (
        Pick<LegalEntity, 'id' | 'slug' | 'name' | 'websiteUrl' | 'industry' | 'timezone' | 'fiscalYearStartMonth' | 'fiscalYearNumberOffset' | 'status' | 'organizationId' | 'sensitive'>
        & { address: Pick<Address, 'street' | 'streetAdditional' | 'city' | 'region' | 'postalCode' | 'countryCode' | 'lat' | 'long'>, incorporatedOn?: Maybe<Pick<Date, 'year' | 'month' | 'day'>> }
      ), validationStatus: (
        Pick<ValidationHealthStatus, 'balanceSheetConsistent' | 'startedAt'>
        & { cashAspectHealth: Pick<AspectDataHealth, 'importedDataStatus' | 'closedViewDataStatus' | 'overallDataStatus'>, revenueAspectHealth: Pick<AspectDataHealth, 'importedDataStatus' | 'closedViewDataStatus' | 'overallDataStatus'>, expenseAspectHealth: Pick<AspectDataHealth, 'importedDataStatus' | 'closedViewDataStatus' | 'overallDataStatus'> }
      ), reportValidationHistory: Array<{ period: Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'>, histories: Array<(
          Pick<ReportKindValidationHistory, 'kind'>
          & { history: Pick<ReportValidationHistory, 'reportId' | 'versionId' | 'legalEntityId' | 'validatedAt' | 'isValid' | 'errorMessage' | 'source'> }
        )> }> }
    )>, affiliateLegalEntities: Array<(
      Pick<AdminLegalEntity, 'createdAt' | 'userCount' | 'approvalEligibilityStatuses' | 'hasPlaidDataSource' | 'allDataSourcesLinked' | 'anyDataSourceRequiresEngineering' | 'allDataSourcesHaveTransactions' | 'deletedAt'>
      & { legalEntity: (
        Pick<LegalEntity, 'id' | 'slug' | 'name' | 'websiteUrl' | 'industry' | 'timezone' | 'fiscalYearStartMonth' | 'fiscalYearNumberOffset' | 'status' | 'organizationId' | 'sensitive'>
        & { address: Pick<Address, 'street' | 'streetAdditional' | 'city' | 'region' | 'postalCode' | 'countryCode' | 'lat' | 'long'>, incorporatedOn?: Maybe<Pick<Date, 'year' | 'month' | 'day'>> }
      ), validationStatus: (
        Pick<ValidationHealthStatus, 'balanceSheetConsistent' | 'startedAt'>
        & { cashAspectHealth: Pick<AspectDataHealth, 'importedDataStatus' | 'closedViewDataStatus' | 'overallDataStatus'>, revenueAspectHealth: Pick<AspectDataHealth, 'importedDataStatus' | 'closedViewDataStatus' | 'overallDataStatus'>, expenseAspectHealth: Pick<AspectDataHealth, 'importedDataStatus' | 'closedViewDataStatus' | 'overallDataStatus'> }
      ), reportValidationHistory: Array<{ period: Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'>, histories: Array<(
          Pick<ReportKindValidationHistory, 'kind'>
          & { history: Pick<ReportValidationHistory, 'reportId' | 'versionId' | 'legalEntityId' | 'validatedAt' | 'isValid' | 'errorMessage' | 'source'> }
        )> }> }
    )> }
  ) };

export type ListOrganizationUsersQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type ListOrganizationUsersQuery = { listOrganizationUsers: Array<(
    Pick<AdminUser, 'timezone' | 'invitationId' | 'accountLocked'>
    & { user: (
      Pick<User, 'id' | 'givenName' | 'familyName' | 'primaryEmailAddress' | 'passwordSet' | 'avatarUrl' | 'createdAt' | 'lastActivityAt'>
      & { employments?: Maybe<Array<(
        Pick<OrganizationEmployment, 'employeeId'>
        & { role: Pick<Role, 'id'>, organization: Pick<Organization, 'id' | 'slug' | 'name' | 'iconUrl' | 'websiteUrl'> }
      )>>, identities?: Maybe<Array<Pick<Identity, 'identifier' | 'provider' | 'domain'>>> }
    ), employments?: Maybe<Array<(
      Pick<AdminUserEmployment, 'activatedAt' | 'status' | 'employeeId'>
      & { role: Pick<Role, 'id'>, organization: Pick<Organization, 'id' | 'slug' | 'name' | 'iconUrl' | 'websiteUrl'> }
    )>>, affiliations?: Maybe<Array<{ role: Pick<Role, 'id'>, userOrganization?: Maybe<Pick<Organization, 'id' | 'slug' | 'name' | 'iconUrl' | 'websiteUrl'>>, affiliatedTo?: Maybe<(
        Pick<LegalEntity, 'id' | 'slug' | 'name' | 'websiteUrl' | 'industry' | 'timezone' | 'fiscalYearStartMonth' | 'fiscalYearNumberOffset' | 'status' | 'organizationId' | 'sensitive'>
        & { address: Pick<Address, 'street' | 'streetAdditional' | 'city' | 'region' | 'postalCode' | 'countryCode' | 'lat' | 'long'>, incorporatedOn?: Maybe<Pick<Date, 'year' | 'month' | 'day'>> }
      )> }>> }
  )> };

export type ReadAssociatedThreadsQueryVariables = Exact<{
  id: Scalars['String']['input'];
  legalEntityId: Scalars['String']['input'];
  kind: ObjectKind;
  allowResolved?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type ReadAssociatedThreadsQuery = { response: { threads: Array<(
      { __typename: 'Thread' }
      & Pick<Thread, 'id'>
      & { details: (
        Pick<ThreadDetails, 'id' | 'commentCount' | 'resolvedAt' | 'authorId' | 'commenterIds' | 'context' | 'pending'>
        & { targetObject: Pick<ObjectIdentifier, 'legalEntityId' | 'id' | 'kind'>, tags: Array<Pick<ObjectIdentifier, 'legalEntityId' | 'id' | 'kind'>> }
      ), comments: Array<(
        { __typename: 'Comment' }
        & Pick<Comment, 'id' | 'authorId' | 'text' | 'timestamp' | 'editedAt' | 'deletedAt' | 'reactions'>
        & { layoutAttachment?: Maybe<Pick<LayoutAttachment, 'layoutId' | 'legalEntityId'>> }
      )> }
    )>, entities?: Maybe<(
      { __typename: 'ObjectEntities' }
      & { users?: Maybe<Array<Pick<EntityUser, 'id' | 'givenName' | 'familyName' | 'emailAddress' | 'avatarUrl'>>>, transactions?: Maybe<Array<(
        Pick<EntityTransaction, 'viewId' | 'factId' | 'timestamp' | 'description' | 'externalId' | 'displayEntry'>
        & { moneyFlow: (
          Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
          & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
        ), party?: Maybe<Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>>, displayCategory: Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>, splitCategory?: Maybe<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>> }
      )>>, comments?: Maybe<Array<Pick<EntityComment, 'id' | 'authorId' | 'text' | 'timestamp'>>>, reports?: Maybe<Array<(
        Pick<EntityReport, 'id' | 'title' | 'authorId' | 'mimeType' | 'createdAt'>
        & { reportFor: Pick<Date, 'year' | 'month' | 'day'> }
      )>>, reportPackages?: Maybe<Array<(
        Pick<EntityReportPackage, 'id' | 'title' | 'authorId' | 'interval' | 'periodStartedAt' | 'periodEndedAt' | 'createdAt' | 'updatedAt' | 'coverUrl'>
        & { documents: Array<Pick<SharedReportDocumentMetadata, 'id' | 'documentObjectId' | 'documentObjectVersionId' | 'title' | 'mimeType'>> }
      )>>, parties?: Maybe<Array<Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>>>, datasources?: Maybe<Array<Pick<EntityDataSource, 'id' | 'name' | 'url' | 'iconUrl'>>>, categories?: Maybe<Array<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>>>, threads?: Maybe<Array<Pick<EntityThread, 'id' | 'resolvedAt'>>>, legalEntities?: Maybe<Array<Pick<EntityLegalEntity, 'id' | 'name' | 'slug'>>>, workItems?: Maybe<Array<(
        Pick<EntityWorkItem, 'id' | 'time' | 'workItemState' | 'workItemType' | 'reviewCreatedById' | 'reviewState' | 'ledgerType'>
        & { objectTransaction: (
          Pick<EntityTransaction, 'viewId' | 'factId' | 'timestamp' | 'description' | 'externalId' | 'displayEntry'>
          & { moneyFlow: (
            Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
            & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
          ), party?: Maybe<Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>>, displayCategory: Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>, splitCategory?: Maybe<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>> }
        ) }
      )>>, invoices?: Maybe<Array<(
        Pick<EntityInvoice, 'id' | 'externalDisplayId' | 'createdAt' | 'dueAt'>
        & { amount: Pick<EntityMonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, customer: (
          Pick<EntityInvoiceCustomer, 'name' | 'phone' | 'email'>
          & { party: Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'> }
        ) }
      )>>, bills?: Maybe<Array<(
        Pick<EntityBill, 'id' | 'billStatus' | 'vendorName' | 'billDescription'>
        & { totalAmount: Pick<EntityMonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, dueDate?: Maybe<Pick<Date, 'year' | 'month' | 'day'>>, format: (
          Pick<Format, 'id' | 'formatClass'>
          & { segments: Array<Pick<Segment, 'collectionId' | 'fileId' | 'sequence' | 'token'>> }
        ), approvalAccess?: Maybe<Pick<BillAccess, 'objectId' | 'objectKind' | 'role'>> }
      )>>, identityVerifications?: Maybe<Array<Pick<EntityIdentityVerification, 'legalEntityId' | 'status'>>>, departments?: Maybe<Array<(
        { __typename: 'EntityDepartment' }
        & Pick<EntityDepartment, 'id' | 'name' | 'parentId' | 'active'>
      )>>, locations?: Maybe<Array<(
        { __typename: 'EntityLocation' }
        & Pick<EntityLocation, 'id' | 'name' | 'parentId' | 'active'>
      )>> }
    )> } };

export type ReadThreadQueryVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
  id: Scalars['String']['input'];
}>;


export type ReadThreadQuery = { thread: (
    { __typename: 'ThreadWithEntities' }
    & { thread: (
      { __typename: 'Thread' }
      & Pick<Thread, 'id'>
      & { details: (
        Pick<ThreadDetails, 'id' | 'commentCount' | 'resolvedAt' | 'authorId' | 'commenterIds' | 'context' | 'pending'>
        & { targetObject: Pick<ObjectIdentifier, 'legalEntityId' | 'id' | 'kind'>, tags: Array<Pick<ObjectIdentifier, 'legalEntityId' | 'id' | 'kind'>> }
      ), comments: Array<(
        { __typename: 'Comment' }
        & Pick<Comment, 'id' | 'authorId' | 'text' | 'timestamp' | 'editedAt' | 'deletedAt' | 'reactions'>
        & { layoutAttachment?: Maybe<Pick<LayoutAttachment, 'layoutId' | 'legalEntityId'>> }
      )> }
    ), entities?: Maybe<(
      { __typename: 'ObjectEntities' }
      & { users?: Maybe<Array<Pick<EntityUser, 'id' | 'givenName' | 'familyName' | 'emailAddress' | 'avatarUrl'>>>, transactions?: Maybe<Array<(
        Pick<EntityTransaction, 'viewId' | 'factId' | 'timestamp' | 'description' | 'externalId' | 'displayEntry'>
        & { moneyFlow: (
          Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
          & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
        ), party?: Maybe<Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>>, displayCategory: Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>, splitCategory?: Maybe<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>> }
      )>>, comments?: Maybe<Array<Pick<EntityComment, 'id' | 'authorId' | 'text' | 'timestamp'>>>, reports?: Maybe<Array<(
        Pick<EntityReport, 'id' | 'title' | 'authorId' | 'mimeType' | 'createdAt'>
        & { reportFor: Pick<Date, 'year' | 'month' | 'day'> }
      )>>, reportPackages?: Maybe<Array<(
        Pick<EntityReportPackage, 'id' | 'title' | 'authorId' | 'interval' | 'periodStartedAt' | 'periodEndedAt' | 'createdAt' | 'updatedAt' | 'coverUrl'>
        & { documents: Array<Pick<SharedReportDocumentMetadata, 'id' | 'documentObjectId' | 'documentObjectVersionId' | 'title' | 'mimeType'>> }
      )>>, parties?: Maybe<Array<Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>>>, datasources?: Maybe<Array<Pick<EntityDataSource, 'id' | 'name' | 'url' | 'iconUrl'>>>, categories?: Maybe<Array<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>>>, threads?: Maybe<Array<Pick<EntityThread, 'id' | 'resolvedAt'>>>, legalEntities?: Maybe<Array<Pick<EntityLegalEntity, 'id' | 'name' | 'slug'>>>, workItems?: Maybe<Array<(
        Pick<EntityWorkItem, 'id' | 'time' | 'workItemState' | 'workItemType' | 'reviewCreatedById' | 'reviewState' | 'ledgerType'>
        & { objectTransaction: (
          Pick<EntityTransaction, 'viewId' | 'factId' | 'timestamp' | 'description' | 'externalId' | 'displayEntry'>
          & { moneyFlow: (
            Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
            & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
          ), party?: Maybe<Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>>, displayCategory: Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>, splitCategory?: Maybe<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>> }
        ) }
      )>>, invoices?: Maybe<Array<(
        Pick<EntityInvoice, 'id' | 'externalDisplayId' | 'createdAt' | 'dueAt'>
        & { amount: Pick<EntityMonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, customer: (
          Pick<EntityInvoiceCustomer, 'name' | 'phone' | 'email'>
          & { party: Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'> }
        ) }
      )>>, bills?: Maybe<Array<(
        Pick<EntityBill, 'id' | 'billStatus' | 'vendorName' | 'billDescription'>
        & { totalAmount: Pick<EntityMonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, dueDate?: Maybe<Pick<Date, 'year' | 'month' | 'day'>>, format: (
          Pick<Format, 'id' | 'formatClass'>
          & { segments: Array<Pick<Segment, 'collectionId' | 'fileId' | 'sequence' | 'token'>> }
        ), approvalAccess?: Maybe<Pick<BillAccess, 'objectId' | 'objectKind' | 'role'>> }
      )>>, identityVerifications?: Maybe<Array<Pick<EntityIdentityVerification, 'legalEntityId' | 'status'>>>, departments?: Maybe<Array<(
        { __typename: 'EntityDepartment' }
        & Pick<EntityDepartment, 'id' | 'name' | 'parentId' | 'active'>
      )>>, locations?: Maybe<Array<(
        { __typename: 'EntityLocation' }
        & Pick<EntityLocation, 'id' | 'name' | 'parentId' | 'active'>
      )>> }
    )> }
  ) };

export type RunMigrationPullRequestMutationVariables = Exact<{
  repo: Scalars['String']['input'];
  prNumber: Scalars['Int']['input'];
  filename: Scalars['String']['input'];
  isPartitionableDML: Scalars['Boolean']['input'];
}>;


export type RunMigrationPullRequestMutation = Pick<Mutation, 'runMigrationPullRequest'>;

export type MergeMigrationPullRequestMutationVariables = Exact<{
  repo: Scalars['String']['input'];
  prNumber: Scalars['Int']['input'];
}>;


export type MergeMigrationPullRequestMutation = Pick<Mutation, 'mergeMigrationPullRequest'>;

export type MigrateEmployeeToAffiliateMutationVariables = Exact<{
  userId: Scalars['String']['input'];
  legalEntityId: Scalars['String']['input'];
  organizationId?: InputMaybe<Scalars['String']['input']>;
  organizationName?: InputMaybe<Scalars['String']['input']>;
}>;


export type MigrateEmployeeToAffiliateMutation = Pick<Mutation, 'migrateEmployeeToAffiliate'>;

export type PullRequestFieldsFragment = (
  Pick<PullRequest, 'url' | 'title' | 'repo' | 'number' | 'branch' | 'prState'>
  & { labels?: Maybe<Array<Pick<PullRequestLabel, 'name' | 'hexColor'>>>, author: Pick<PullRequestAuthor, 'id' | 'name' | 'login' | 'avatarUrl'> }
);

export type ReadMigrationPullRequestQueryVariables = Exact<{
  repo: Scalars['String']['input'];
  prNumber: Scalars['Int']['input'];
}>;


export type ReadMigrationPullRequestQuery = { readMigrationPullRequest: { pullRequestSummary: (
      Pick<PullRequestSummary, 'reviewStatus' | 'mergeableStatus' | 'merged'>
      & { pullRequest: (
        Pick<PullRequest, 'url' | 'title' | 'repo' | 'number' | 'branch' | 'prState'>
        & { labels?: Maybe<Array<Pick<PullRequestLabel, 'name' | 'hexColor'>>>, author: Pick<PullRequestAuthor, 'id' | 'name' | 'login' | 'avatarUrl'> }
      ) }
    ), fileSummaries: Array<(
      Pick<MigrationFileSummary, 'hasRun'>
      & { file: Pick<PullRequestFile, 'name' | 'contents' | 'url'> }
    )> } };

export type ListMigrationPullRequestsQueryVariables = Exact<{
  repo: Scalars['String']['input'];
  anyLabel?: InputMaybe<Scalars['Boolean']['input']>;
  prState?: InputMaybe<PullRequestFilterState>;
}>;


export type ListMigrationPullRequestsQuery = { listMigrationPullRequests: Array<(
    Pick<PullRequest, 'url' | 'title' | 'repo' | 'number' | 'branch' | 'prState'>
    & { labels?: Maybe<Array<Pick<PullRequestLabel, 'name' | 'hexColor'>>>, author: Pick<PullRequestAuthor, 'id' | 'name' | 'login' | 'avatarUrl'> }
  )> };

export type DeletePrefixMappingMutationVariables = Exact<{
  prefix: Scalars['String']['input'];
  partyId: Scalars['String']['input'];
}>;


export type DeletePrefixMappingMutation = Pick<Mutation, 'deletePrefixMapping'>;

export type IgnorePrefixMutationVariables = Exact<{
  prefix: Scalars['String']['input'];
}>;


export type IgnorePrefixMutation = Pick<Mutation, 'ignorePrefix'>;

export type UnignorePrefixMutationVariables = Exact<{
  prefix: Scalars['String']['input'];
}>;


export type UnignorePrefixMutation = Pick<Mutation, 'unignorePrefix'>;

export type SanitizedDescriptionFieldsFragment = Pick<SanitizedDescription, 'sanitizedDescription' | 'transactionCount' | 'exampleTransactionNameAndDescription'>;

export type ClusteredPrefixFieldsFragment = Pick<ClusteredPrefix, 'prefix' | 'transactionCount'>;

export type PrefixMappingFieldsFragment = Pick<PrefixMapping, 'prefix' | 'partyId'>;

export type PrefixMappingSummaryFieldsFragment = (
  Pick<PrefixMappingSummary, 'prefix' | 'transactionCount'>
  & { party: Pick<Party, 'id' | 'legalEntityId' | 'name' | 'url' | 'supportUrl' | 'supportEmail' | 'supportPhone' | 'twitterUrl' | 'facebookUrl' | 'instagramUrl' | 'yelpUrl' | 'iconUrl' | 'shortDescription' | 'designation'> }
);

export type ListClusteredPrefixesQueryVariables = Exact<{
  legalEntityId?: InputMaybe<Scalars['String']['input']>;
}>;


export type ListClusteredPrefixesQuery = { listClusteredPrefixes: Array<Pick<ClusteredPrefix, 'prefix' | 'transactionCount'>> };

export type ListSanitizedDescriptionsQueryVariables = Exact<{
  prefix: Scalars['String']['input'];
}>;


export type ListSanitizedDescriptionsQuery = { listSanitizedDescriptions: Array<Pick<SanitizedDescription, 'sanitizedDescription' | 'transactionCount' | 'exampleTransactionNameAndDescription'>> };

export type ListPrefixMappingsQueryVariables = Exact<{
  filter: PrefixFilter;
}>;


export type ListPrefixMappingsQuery = { listPrefixMappings: Array<(
    Pick<PrefixMappingSummary, 'prefix' | 'transactionCount'>
    & { party: Pick<Party, 'id' | 'legalEntityId' | 'name' | 'url' | 'supportUrl' | 'supportEmail' | 'supportPhone' | 'twitterUrl' | 'facebookUrl' | 'instagramUrl' | 'yelpUrl' | 'iconUrl' | 'shortDescription' | 'designation'> }
  )> };

export type ListIgnoredPrefixesQueryVariables = Exact<{ [key: string]: never; }>;


export type ListIgnoredPrefixesQuery = Pick<Query, 'listIgnoredPrefixes'>;

export type TriggerDenormalizedViewGenerationMutationVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
}>;


export type TriggerDenormalizedViewGenerationMutation = Pick<Mutation, 'triggerDenormalizedViewGeneration'>;

export type OnboardMutationVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
}>;


export type OnboardMutation = Pick<Mutation, 'onboard'>;

export type DenormalizedViewStatusFieldsFragment = Pick<DenormalizedViewStatus, 'legalEntityId' | 'viewType' | 'activeViewCreatedAt' | 'ongoingViewStartedAt'>;

export type ReadEntityDataProcessingStatusesQueryVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
}>;


export type ReadEntityDataProcessingStatusesQuery = { readEntityDataProcessingStatuses: { entries: Array<Pick<DenormalizedViewStatus, 'legalEntityId' | 'viewType' | 'activeViewCreatedAt' | 'ongoingViewStartedAt'>> } };

export type ListAllDataProcessingStatusesQueryVariables = Exact<{ [key: string]: never; }>;


export type ListAllDataProcessingStatusesQuery = { listAllDataProcessingStatuses: { entries: Array<Pick<DenormalizedViewStatus, 'legalEntityId' | 'viewType' | 'activeViewCreatedAt' | 'ongoingViewStartedAt'>> } };

export type ListCsvImportsQueryVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
  pagination: Pagination;
  order?: InputMaybe<CsvImportSort>;
}>;


export type ListCsvImportsQuery = { listCsvImports: Array<Pick<CsvImport, 'csvImportId' | 'dataSourceId' | 'fileName' | 'transactionCount' | 'source' | 'createdAt' | 'deletedAt'>> };

export type ListCsvImportTransactionFactsQueryVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
  csvImportId: Scalars['String']['input'];
  pagination?: InputMaybe<Pagination>;
}>;


export type ListCsvImportTransactionFactsQuery = Pick<Query, 'listCsvImportTransactionFacts'>;

export type ResearchSalesLeadAgentSubscriptionVariables = Exact<{
  value: Scalars['String']['input'];
}>;


export type ResearchSalesLeadAgentSubscription = { researchSalesLeadAgent: { agentEvent?: Maybe<Pick<AgentEvent, 'runStatus' | 'message'>> } };

export type HydratePartyAgentSubscriptionVariables = Exact<{
  value: Scalars['String']['input'];
}>;


export type HydratePartyAgentSubscription = { hydratePartyAgent: { agentEvent?: Maybe<Pick<AgentEvent, 'runStatus' | 'message'>> } };

export type SuperuserRoleFieldsFragment = Pick<SuperuserRole, 'identifier' | 'displayName' | 'description'>;

export type ListAvailableSuperuserRolesQueryVariables = Exact<{ [key: string]: never; }>;


export type ListAvailableSuperuserRolesQuery = { listAvailableSuperuserRoles: Array<Pick<SuperuserRole, 'identifier' | 'displayName' | 'description'>> };

export type ObjectMappingHierarchyNodeFieldsFragment = Pick<ObjectMappingHierarchyNode, 'factId' | 'name' | 'parentId' | 'active' | 'transactionCount' | 'mappedTo' | 'depth' | 'fullyQualifiedName'>;

export type ListLineClassFactHierarchyQueryVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
}>;


export type ListLineClassFactHierarchyQuery = { listLineClassFactHierarchy: Array<Pick<ObjectMappingHierarchyNode, 'factId' | 'name' | 'parentId' | 'active' | 'transactionCount' | 'mappedTo' | 'depth' | 'fullyQualifiedName'>> };

export type ListDepartmentFactHierarchyQueryVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
}>;


export type ListDepartmentFactHierarchyQuery = { listDepartmentFactHierarchy: Array<Pick<ObjectMappingHierarchyNode, 'factId' | 'name' | 'parentId' | 'active' | 'transactionCount' | 'mappedTo' | 'depth' | 'fullyQualifiedName'>> };

export type AdminCategoryFieldsFragment = (
  Pick<AdminCategory, 'externalId' | 'subtype' | 'chartOfAccountsDisplayNumber' | 'externalAccountType' | 'externalAccountSubtype' | 'synthesized'>
  & { category: (
    Pick<Category, 'id' | 'name' | 'description' | 'displayKey' | 'type' | 'subtype' | 'parentCategoryId' | 'displayNumber' | 'active' | 'categoryState' | 'productArea' | 'synthesized'>
    & { ancestors?: Maybe<Array<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>>> }
  ) }
);

export type AdminChartOfAccountsFieldsFragment = { chart: (
    Pick<ChartOfAccounts, 'id' | 'name' | 'description' | 'accountingBasis'>
    & { categories: Array<(
      Pick<Category, 'id' | 'name' | 'description' | 'displayKey' | 'type' | 'subtype' | 'parentCategoryId' | 'displayNumber' | 'active' | 'categoryState' | 'productArea' | 'synthesized'>
      & { ancestors?: Maybe<Array<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>>> }
    )> }
  ) };

export type AdminDoppelgangerPermitFieldsFragment = (
  Pick<AdminDoppelgangerPermit, 'justification' | 'sessionStartedAt' | 'sessionEndedAt'>
  & { doppelgangerPermit: (
    Pick<DoppelgangerPermit, 'id' | 'expiresAt' | 'createdAt'>
    & { user: (
      Pick<User, 'id' | 'givenName' | 'familyName' | 'primaryEmailAddress' | 'passwordSet' | 'avatarUrl' | 'createdAt' | 'lastActivityAt'>
      & { employments?: Maybe<Array<(
        Pick<OrganizationEmployment, 'employeeId'>
        & { role: Pick<Role, 'id'>, organization: Pick<Organization, 'id' | 'slug' | 'name' | 'iconUrl' | 'websiteUrl'> }
      )>>, identities?: Maybe<Array<Pick<Identity, 'identifier' | 'provider' | 'domain'>>> }
    ) }
  ), authorizer: (
    Pick<Superuser, 'id' | 'givenName' | 'familyName' | 'emailAddress' | 'avatarUrl' | 'createdAt' | 'lastLoginAt'>
    & { roles: Array<Pick<SuperuserRole, 'identifier' | 'displayName' | 'description'>> }
  ) }
);

export type AdminLegalEntityFieldsFragment = (
  Pick<AdminLegalEntity, 'createdAt' | 'userCount' | 'approvalEligibilityStatuses' | 'hasPlaidDataSource' | 'allDataSourcesLinked' | 'anyDataSourceRequiresEngineering' | 'allDataSourcesHaveTransactions' | 'deletedAt'>
  & { legalEntity: (
    Pick<LegalEntity, 'id' | 'slug' | 'name' | 'websiteUrl' | 'industry' | 'timezone' | 'fiscalYearStartMonth' | 'fiscalYearNumberOffset' | 'status' | 'organizationId' | 'sensitive'>
    & { address: Pick<Address, 'street' | 'streetAdditional' | 'city' | 'region' | 'postalCode' | 'countryCode' | 'lat' | 'long'>, incorporatedOn?: Maybe<Pick<Date, 'year' | 'month' | 'day'>> }
  ), validationStatus: (
    Pick<ValidationHealthStatus, 'balanceSheetConsistent' | 'startedAt'>
    & { cashAspectHealth: Pick<AspectDataHealth, 'importedDataStatus' | 'closedViewDataStatus' | 'overallDataStatus'>, revenueAspectHealth: Pick<AspectDataHealth, 'importedDataStatus' | 'closedViewDataStatus' | 'overallDataStatus'>, expenseAspectHealth: Pick<AspectDataHealth, 'importedDataStatus' | 'closedViewDataStatus' | 'overallDataStatus'> }
  ), reportValidationHistory: Array<{ period: Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'>, histories: Array<(
      Pick<ReportKindValidationHistory, 'kind'>
      & { history: Pick<ReportValidationHistory, 'reportId' | 'versionId' | 'legalEntityId' | 'validatedAt' | 'isValid' | 'errorMessage' | 'source'> }
    )> }> }
);

export type EmailStatusFieldsFragment = Pick<EmailStatus, 'rawStatus' | 'updatedAt'>;

export type MappedCategoryFieldsFragment = { fromCategory?: Maybe<(
    Pick<AdminCategory, 'externalId' | 'subtype' | 'chartOfAccountsDisplayNumber' | 'externalAccountType' | 'externalAccountSubtype' | 'synthesized'>
    & { category: (
      Pick<Category, 'id' | 'name' | 'description' | 'displayKey' | 'type' | 'subtype' | 'parentCategoryId' | 'displayNumber' | 'active' | 'categoryState' | 'productArea' | 'synthesized'>
      & { ancestors?: Maybe<Array<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>>> }
    ) }
  )>, toCategory?: Maybe<(
    Pick<AdminCategory, 'externalId' | 'subtype' | 'chartOfAccountsDisplayNumber' | 'externalAccountType' | 'externalAccountSubtype' | 'synthesized'>
    & { category: (
      Pick<Category, 'id' | 'name' | 'description' | 'displayKey' | 'type' | 'subtype' | 'parentCategoryId' | 'displayNumber' | 'active' | 'categoryState' | 'productArea' | 'synthesized'>
      & { ancestors?: Maybe<Array<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>>> }
    ) }
  )> };

export type AdminSourceAccountFieldsFragment = (
  Pick<SourceAccount, 'id' | 'externalId' | 'externalName' | 'externalOwnerName' | 'externalOfficialName' | 'externalType' | 'externalSubtype' | 'mask'>
  & { dataSource?: Maybe<(
    Pick<EnrichedDataSource, 'id' | 'legalEntityId' | 'status' | 'statusReason' | 'providerType' | 'accountAlias' | 'createdAt' | 'updatedAt' | 'lastActivityAt' | 'hasTransactions'>
    & { institution: Pick<Party, 'id' | 'legalEntityId' | 'name' | 'url' | 'supportUrl' | 'supportEmail' | 'supportPhone' | 'twitterUrl' | 'facebookUrl' | 'instagramUrl' | 'yelpUrl' | 'iconUrl' | 'shortDescription' | 'designation'>, quickBooksConfig?: Maybe<Pick<QuickBooksConfig, 'id' | 'realmId' | 'createdAt' | 'updatedAt'>>, plaidConfig?: Maybe<Pick<PlaidConfig, 'id' | 'itemId' | 'externalInstitutionId' | 'createdAt' | 'updatedAt'>>, sourceAccounts?: Maybe<Array<Pick<SourceAccount, 'id' | 'externalId' | 'externalName' | 'externalOwnerName' | 'externalOfficialName' | 'externalType' | 'externalSubtype' | 'mask' | 'status' | 'ownerEmployeeId'>>> }
  )> }
);

export type MappedChartOfAccountsFieldsFragment = (
  Pick<MappedChartOfAccounts, 'fromUUID' | 'toUUID' | 'name' | 'description' | 'legalEntityId' | 'accountingBasis'>
  & { categories: Array<{ fromCategory?: Maybe<(
      Pick<AdminCategory, 'externalId' | 'subtype' | 'chartOfAccountsDisplayNumber' | 'externalAccountType' | 'externalAccountSubtype' | 'synthesized'>
      & { category: (
        Pick<Category, 'id' | 'name' | 'description' | 'displayKey' | 'type' | 'subtype' | 'parentCategoryId' | 'displayNumber' | 'active' | 'categoryState' | 'productArea' | 'synthesized'>
        & { ancestors?: Maybe<Array<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>>> }
      ) }
    )>, toCategory?: Maybe<(
      Pick<AdminCategory, 'externalId' | 'subtype' | 'chartOfAccountsDisplayNumber' | 'externalAccountType' | 'externalAccountSubtype' | 'synthesized'>
      & { category: (
        Pick<Category, 'id' | 'name' | 'description' | 'displayKey' | 'type' | 'subtype' | 'parentCategoryId' | 'displayNumber' | 'active' | 'categoryState' | 'productArea' | 'synthesized'>
        & { ancestors?: Maybe<Array<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>>> }
      ) }
    )> }> }
);

export type AdminOrganizationFieldsFragment = (
  Pick<AdminOrganization, 'createdAt' | 'deletedAt' | 'userCount'>
  & { organization: Pick<Organization, 'id' | 'slug' | 'name' | 'iconUrl' | 'websiteUrl'>, legalEntities: Array<(
    Pick<AdminLegalEntity, 'createdAt' | 'userCount' | 'approvalEligibilityStatuses' | 'hasPlaidDataSource' | 'allDataSourcesLinked' | 'anyDataSourceRequiresEngineering' | 'allDataSourcesHaveTransactions' | 'deletedAt'>
    & { legalEntity: (
      Pick<LegalEntity, 'id' | 'slug' | 'name' | 'websiteUrl' | 'industry' | 'timezone' | 'fiscalYearStartMonth' | 'fiscalYearNumberOffset' | 'status' | 'organizationId' | 'sensitive'>
      & { address: Pick<Address, 'street' | 'streetAdditional' | 'city' | 'region' | 'postalCode' | 'countryCode' | 'lat' | 'long'>, incorporatedOn?: Maybe<Pick<Date, 'year' | 'month' | 'day'>> }
    ), validationStatus: (
      Pick<ValidationHealthStatus, 'balanceSheetConsistent' | 'startedAt'>
      & { cashAspectHealth: Pick<AspectDataHealth, 'importedDataStatus' | 'closedViewDataStatus' | 'overallDataStatus'>, revenueAspectHealth: Pick<AspectDataHealth, 'importedDataStatus' | 'closedViewDataStatus' | 'overallDataStatus'>, expenseAspectHealth: Pick<AspectDataHealth, 'importedDataStatus' | 'closedViewDataStatus' | 'overallDataStatus'> }
    ), reportValidationHistory: Array<{ period: Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'>, histories: Array<(
        Pick<ReportKindValidationHistory, 'kind'>
        & { history: Pick<ReportValidationHistory, 'reportId' | 'versionId' | 'legalEntityId' | 'validatedAt' | 'isValid' | 'errorMessage' | 'source'> }
      )> }> }
  )>, affiliateLegalEntities: Array<(
    Pick<AdminLegalEntity, 'createdAt' | 'userCount' | 'approvalEligibilityStatuses' | 'hasPlaidDataSource' | 'allDataSourcesLinked' | 'anyDataSourceRequiresEngineering' | 'allDataSourcesHaveTransactions' | 'deletedAt'>
    & { legalEntity: (
      Pick<LegalEntity, 'id' | 'slug' | 'name' | 'websiteUrl' | 'industry' | 'timezone' | 'fiscalYearStartMonth' | 'fiscalYearNumberOffset' | 'status' | 'organizationId' | 'sensitive'>
      & { address: Pick<Address, 'street' | 'streetAdditional' | 'city' | 'region' | 'postalCode' | 'countryCode' | 'lat' | 'long'>, incorporatedOn?: Maybe<Pick<Date, 'year' | 'month' | 'day'>> }
    ), validationStatus: (
      Pick<ValidationHealthStatus, 'balanceSheetConsistent' | 'startedAt'>
      & { cashAspectHealth: Pick<AspectDataHealth, 'importedDataStatus' | 'closedViewDataStatus' | 'overallDataStatus'>, revenueAspectHealth: Pick<AspectDataHealth, 'importedDataStatus' | 'closedViewDataStatus' | 'overallDataStatus'>, expenseAspectHealth: Pick<AspectDataHealth, 'importedDataStatus' | 'closedViewDataStatus' | 'overallDataStatus'> }
    ), reportValidationHistory: Array<{ period: Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'>, histories: Array<(
        Pick<ReportKindValidationHistory, 'kind'>
        & { history: Pick<ReportValidationHistory, 'reportId' | 'versionId' | 'legalEntityId' | 'validatedAt' | 'isValid' | 'errorMessage' | 'source'> }
      )> }> }
  )> }
);

export type ReportKindValidationPeriodHistoryFieldsFragment = { period: Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'>, histories: Array<(
    Pick<ReportKindValidationHistory, 'kind'>
    & { history: Pick<ReportValidationHistory, 'reportId' | 'versionId' | 'legalEntityId' | 'validatedAt' | 'isValid' | 'errorMessage' | 'source'> }
  )> };

export type ChartOfAccountsListEntryFieldsFragment = Pick<ChartOfAccountsListEntry, 'id' | 'name' | 'description' | 'accountingBasis' | 'legalEntityId'>;

export type TriggerDigitsLedgerReviewMutationVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
  ledgerType: LedgerType;
}>;


export type TriggerDigitsLedgerReviewMutation = Pick<Mutation, 'triggerDigitsLedgerReview'>;

export type AdminVanityStatsFieldsFragment = (
  Pick<AdminVanityStats, 'transactionsCount' | 'usersCount' | 'partiesCount' | 'categoriesCount'>
  & { totalValue: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
);

export type AdminGlobalVanityStatsFieldsFragment = (
  Pick<AdminGlobalVanityStats, 'totalSignupsCount' | 'totalSignupsLast30Count' | 'qualifiedSignupsCount' | 'averageSignupQualifiedAt' | 'primedSignupsCount' | 'averageSignupPrimedAt' | 'invitedSignupsCount' | 'averageSignupInvitedAt' | 'appliedSignupsCount' | 'averageSignupAppliedAt' | 'approvedSignupsLast30Count' | 'lastSignupApprovedAt' | 'totalUsersCount' | 'activeUsersCount' | 'totalLegalEntitiesCount' | 'activeLegalEntitiesCount' | 'pendingLegalEntitiesCount' | 'totalPartiesCount' | 'globalPartiesCount' | 'globalPartiesLast30Count' | 'linkedPartiesCount' | 'unlinkedPartiesCount' | 'unlinkedRepeatPartiesCount' | 'hydratableUnlinkedRepeatPartiesCount' | 'hydratableUnlinkedPartiesCount' | 'totalTransactionsCount' | 'globalPartyTransactionsCount' | 'unlinkedPartiesLast30Count' | 'dailyUnlinkedPartiesLast30' | 'dailyDesignationsCreatedLast30Count' | 'totalUnmoderatedDesignationsCount' | 'dailyDesignationsModeratedLast30' | 'linkedPartiesLast30Count' | 'globalPartiesViaDesignationLast30Count' | 'totalHydrationsProcessedCount' | 'dailyHydrationsCreatedLast30' | 'totalUnmoderatedHydrationsCount' | 'dailyHydrationsModeratedLast30' | 'legalEntitiesWithValidQuickBooksCount' | 'legalEntitiesWithDataIssuesCount' | 'activeLegalEntitiesWithDataIssuesCount' | 'legalEntitiesWithQuickBooksConnectionIssuesCount' | 'activeLegalEntitiesWithQuickBooksConnectionIssuesCount' | 'activeLegalEntitiesWithPlaidConnectionIssuesCount' | 'legalEntitiesWithEngineeringRequiredCount' | 'supersedingRate' | 'dayOfInterest'>
  & { totalTransactionsValue: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, reportValidations: Array<(
    Pick<ReportValidationGlobalPeriodSummary, 'packageValidCount' | 'packageInvalidCount'>
    & { period: Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'>, byKind: Array<Pick<ReportValidationByKind, 'kind' | 'validCount' | 'invalidCount'>> }
  )> }
);

export type ReadAdminVanityStatsQueryVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
}>;


export type ReadAdminVanityStatsQuery = { readVanityStats: (
    Pick<AdminVanityStats, 'transactionsCount' | 'usersCount' | 'partiesCount' | 'categoriesCount'>
    & { totalValue: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
  ) };

export type ReadAdminGlobalVanityStatsQueryVariables = Exact<{ [key: string]: never; }>;


export type ReadAdminGlobalVanityStatsQuery = { readGlobalVanityStats: (
    Pick<AdminGlobalVanityStats, 'totalSignupsCount' | 'totalSignupsLast30Count' | 'qualifiedSignupsCount' | 'averageSignupQualifiedAt' | 'primedSignupsCount' | 'averageSignupPrimedAt' | 'invitedSignupsCount' | 'averageSignupInvitedAt' | 'appliedSignupsCount' | 'averageSignupAppliedAt' | 'approvedSignupsLast30Count' | 'lastSignupApprovedAt' | 'totalUsersCount' | 'activeUsersCount' | 'totalLegalEntitiesCount' | 'activeLegalEntitiesCount' | 'pendingLegalEntitiesCount' | 'totalPartiesCount' | 'globalPartiesCount' | 'globalPartiesLast30Count' | 'linkedPartiesCount' | 'unlinkedPartiesCount' | 'unlinkedRepeatPartiesCount' | 'hydratableUnlinkedRepeatPartiesCount' | 'hydratableUnlinkedPartiesCount' | 'totalTransactionsCount' | 'globalPartyTransactionsCount' | 'unlinkedPartiesLast30Count' | 'dailyUnlinkedPartiesLast30' | 'dailyDesignationsCreatedLast30Count' | 'totalUnmoderatedDesignationsCount' | 'dailyDesignationsModeratedLast30' | 'linkedPartiesLast30Count' | 'globalPartiesViaDesignationLast30Count' | 'totalHydrationsProcessedCount' | 'dailyHydrationsCreatedLast30' | 'totalUnmoderatedHydrationsCount' | 'dailyHydrationsModeratedLast30' | 'legalEntitiesWithValidQuickBooksCount' | 'legalEntitiesWithDataIssuesCount' | 'activeLegalEntitiesWithDataIssuesCount' | 'legalEntitiesWithQuickBooksConnectionIssuesCount' | 'activeLegalEntitiesWithQuickBooksConnectionIssuesCount' | 'activeLegalEntitiesWithPlaidConnectionIssuesCount' | 'legalEntitiesWithEngineeringRequiredCount' | 'supersedingRate' | 'dayOfInterest'>
    & { totalTransactionsValue: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, reportValidations: Array<(
      Pick<ReportValidationGlobalPeriodSummary, 'packageValidCount' | 'packageInvalidCount'>
      & { period: Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'>, byKind: Array<Pick<ReportValidationByKind, 'kind' | 'validCount' | 'invalidCount'>> }
    )> }
  ) };

export type SearchQueryVariables = Exact<{
  pagination: Pagination;
  searchTerm: Scalars['String']['input'];
}>;


export type SearchQuery = { organizations: Array<(
    Pick<AdminOrganization, 'createdAt' | 'deletedAt' | 'userCount'>
    & { organization: Pick<Organization, 'id' | 'slug' | 'name' | 'iconUrl' | 'websiteUrl'>, legalEntities: Array<(
      Pick<AdminLegalEntity, 'createdAt' | 'userCount' | 'approvalEligibilityStatuses' | 'hasPlaidDataSource' | 'allDataSourcesLinked' | 'anyDataSourceRequiresEngineering' | 'allDataSourcesHaveTransactions' | 'deletedAt'>
      & { legalEntity: (
        Pick<LegalEntity, 'id' | 'slug' | 'name' | 'websiteUrl' | 'industry' | 'timezone' | 'fiscalYearStartMonth' | 'fiscalYearNumberOffset' | 'status' | 'organizationId' | 'sensitive'>
        & { address: Pick<Address, 'street' | 'streetAdditional' | 'city' | 'region' | 'postalCode' | 'countryCode' | 'lat' | 'long'>, incorporatedOn?: Maybe<Pick<Date, 'year' | 'month' | 'day'>> }
      ), validationStatus: (
        Pick<ValidationHealthStatus, 'balanceSheetConsistent' | 'startedAt'>
        & { cashAspectHealth: Pick<AspectDataHealth, 'importedDataStatus' | 'closedViewDataStatus' | 'overallDataStatus'>, revenueAspectHealth: Pick<AspectDataHealth, 'importedDataStatus' | 'closedViewDataStatus' | 'overallDataStatus'>, expenseAspectHealth: Pick<AspectDataHealth, 'importedDataStatus' | 'closedViewDataStatus' | 'overallDataStatus'> }
      ), reportValidationHistory: Array<{ period: Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'>, histories: Array<(
          Pick<ReportKindValidationHistory, 'kind'>
          & { history: Pick<ReportValidationHistory, 'reportId' | 'versionId' | 'legalEntityId' | 'validatedAt' | 'isValid' | 'errorMessage' | 'source'> }
        )> }> }
    )>, affiliateLegalEntities: Array<(
      Pick<AdminLegalEntity, 'createdAt' | 'userCount' | 'approvalEligibilityStatuses' | 'hasPlaidDataSource' | 'allDataSourcesLinked' | 'anyDataSourceRequiresEngineering' | 'allDataSourcesHaveTransactions' | 'deletedAt'>
      & { legalEntity: (
        Pick<LegalEntity, 'id' | 'slug' | 'name' | 'websiteUrl' | 'industry' | 'timezone' | 'fiscalYearStartMonth' | 'fiscalYearNumberOffset' | 'status' | 'organizationId' | 'sensitive'>
        & { address: Pick<Address, 'street' | 'streetAdditional' | 'city' | 'region' | 'postalCode' | 'countryCode' | 'lat' | 'long'>, incorporatedOn?: Maybe<Pick<Date, 'year' | 'month' | 'day'>> }
      ), validationStatus: (
        Pick<ValidationHealthStatus, 'balanceSheetConsistent' | 'startedAt'>
        & { cashAspectHealth: Pick<AspectDataHealth, 'importedDataStatus' | 'closedViewDataStatus' | 'overallDataStatus'>, revenueAspectHealth: Pick<AspectDataHealth, 'importedDataStatus' | 'closedViewDataStatus' | 'overallDataStatus'>, expenseAspectHealth: Pick<AspectDataHealth, 'importedDataStatus' | 'closedViewDataStatus' | 'overallDataStatus'> }
      ), reportValidationHistory: Array<{ period: Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'>, histories: Array<(
          Pick<ReportKindValidationHistory, 'kind'>
          & { history: Pick<ReportValidationHistory, 'reportId' | 'versionId' | 'legalEntityId' | 'validatedAt' | 'isValid' | 'errorMessage' | 'source'> }
        )> }> }
    )> }
  )>, affiliations: Array<(
    Pick<AdminOrganization, 'createdAt' | 'deletedAt' | 'userCount'>
    & { organization: Pick<Organization, 'id' | 'slug' | 'name' | 'iconUrl' | 'websiteUrl'>, legalEntities: Array<(
      Pick<AdminLegalEntity, 'createdAt' | 'userCount' | 'approvalEligibilityStatuses' | 'hasPlaidDataSource' | 'allDataSourcesLinked' | 'anyDataSourceRequiresEngineering' | 'allDataSourcesHaveTransactions' | 'deletedAt'>
      & { legalEntity: (
        Pick<LegalEntity, 'id' | 'slug' | 'name' | 'websiteUrl' | 'industry' | 'timezone' | 'fiscalYearStartMonth' | 'fiscalYearNumberOffset' | 'status' | 'organizationId' | 'sensitive'>
        & { address: Pick<Address, 'street' | 'streetAdditional' | 'city' | 'region' | 'postalCode' | 'countryCode' | 'lat' | 'long'>, incorporatedOn?: Maybe<Pick<Date, 'year' | 'month' | 'day'>> }
      ), validationStatus: (
        Pick<ValidationHealthStatus, 'balanceSheetConsistent' | 'startedAt'>
        & { cashAspectHealth: Pick<AspectDataHealth, 'importedDataStatus' | 'closedViewDataStatus' | 'overallDataStatus'>, revenueAspectHealth: Pick<AspectDataHealth, 'importedDataStatus' | 'closedViewDataStatus' | 'overallDataStatus'>, expenseAspectHealth: Pick<AspectDataHealth, 'importedDataStatus' | 'closedViewDataStatus' | 'overallDataStatus'> }
      ), reportValidationHistory: Array<{ period: Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'>, histories: Array<(
          Pick<ReportKindValidationHistory, 'kind'>
          & { history: Pick<ReportValidationHistory, 'reportId' | 'versionId' | 'legalEntityId' | 'validatedAt' | 'isValid' | 'errorMessage' | 'source'> }
        )> }> }
    )>, affiliateLegalEntities: Array<(
      Pick<AdminLegalEntity, 'createdAt' | 'userCount' | 'approvalEligibilityStatuses' | 'hasPlaidDataSource' | 'allDataSourcesLinked' | 'anyDataSourceRequiresEngineering' | 'allDataSourcesHaveTransactions' | 'deletedAt'>
      & { legalEntity: (
        Pick<LegalEntity, 'id' | 'slug' | 'name' | 'websiteUrl' | 'industry' | 'timezone' | 'fiscalYearStartMonth' | 'fiscalYearNumberOffset' | 'status' | 'organizationId' | 'sensitive'>
        & { address: Pick<Address, 'street' | 'streetAdditional' | 'city' | 'region' | 'postalCode' | 'countryCode' | 'lat' | 'long'>, incorporatedOn?: Maybe<Pick<Date, 'year' | 'month' | 'day'>> }
      ), validationStatus: (
        Pick<ValidationHealthStatus, 'balanceSheetConsistent' | 'startedAt'>
        & { cashAspectHealth: Pick<AspectDataHealth, 'importedDataStatus' | 'closedViewDataStatus' | 'overallDataStatus'>, revenueAspectHealth: Pick<AspectDataHealth, 'importedDataStatus' | 'closedViewDataStatus' | 'overallDataStatus'>, expenseAspectHealth: Pick<AspectDataHealth, 'importedDataStatus' | 'closedViewDataStatus' | 'overallDataStatus'> }
      ), reportValidationHistory: Array<{ period: Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'>, histories: Array<(
          Pick<ReportKindValidationHistory, 'kind'>
          & { history: Pick<ReportValidationHistory, 'reportId' | 'versionId' | 'legalEntityId' | 'validatedAt' | 'isValid' | 'errorMessage' | 'source'> }
        )> }> }
    )> }
  )>, legalEntities: Array<(
    Pick<AdminLegalEntity, 'createdAt' | 'userCount' | 'approvalEligibilityStatuses' | 'hasPlaidDataSource' | 'allDataSourcesLinked' | 'anyDataSourceRequiresEngineering' | 'allDataSourcesHaveTransactions' | 'deletedAt'>
    & { legalEntity: (
      Pick<LegalEntity, 'id' | 'slug' | 'name' | 'websiteUrl' | 'industry' | 'timezone' | 'fiscalYearStartMonth' | 'fiscalYearNumberOffset' | 'status' | 'organizationId' | 'sensitive'>
      & { address: Pick<Address, 'street' | 'streetAdditional' | 'city' | 'region' | 'postalCode' | 'countryCode' | 'lat' | 'long'>, incorporatedOn?: Maybe<Pick<Date, 'year' | 'month' | 'day'>> }
    ), validationStatus: (
      Pick<ValidationHealthStatus, 'balanceSheetConsistent' | 'startedAt'>
      & { cashAspectHealth: Pick<AspectDataHealth, 'importedDataStatus' | 'closedViewDataStatus' | 'overallDataStatus'>, revenueAspectHealth: Pick<AspectDataHealth, 'importedDataStatus' | 'closedViewDataStatus' | 'overallDataStatus'>, expenseAspectHealth: Pick<AspectDataHealth, 'importedDataStatus' | 'closedViewDataStatus' | 'overallDataStatus'> }
    ), reportValidationHistory: Array<{ period: Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'>, histories: Array<(
        Pick<ReportKindValidationHistory, 'kind'>
        & { history: Pick<ReportValidationHistory, 'reportId' | 'versionId' | 'legalEntityId' | 'validatedAt' | 'isValid' | 'errorMessage' | 'source'> }
      )> }> }
  )>, users: Array<(
    Pick<AdminUser, 'timezone' | 'invitationId' | 'accountLocked'>
    & { user: (
      Pick<User, 'id' | 'givenName' | 'familyName' | 'primaryEmailAddress' | 'passwordSet' | 'avatarUrl' | 'createdAt' | 'lastActivityAt'>
      & { employments?: Maybe<Array<(
        Pick<OrganizationEmployment, 'employeeId'>
        & { role: Pick<Role, 'id'>, organization: Pick<Organization, 'id' | 'slug' | 'name' | 'iconUrl' | 'websiteUrl'> }
      )>>, identities?: Maybe<Array<Pick<Identity, 'identifier' | 'provider' | 'domain'>>> }
    ), employments?: Maybe<Array<(
      Pick<AdminUserEmployment, 'activatedAt' | 'status' | 'employeeId'>
      & { role: Pick<Role, 'id'>, organization: Pick<Organization, 'id' | 'slug' | 'name' | 'iconUrl' | 'websiteUrl'> }
    )>>, affiliations?: Maybe<Array<{ role: Pick<Role, 'id'>, userOrganization?: Maybe<Pick<Organization, 'id' | 'slug' | 'name' | 'iconUrl' | 'websiteUrl'>>, affiliatedTo?: Maybe<(
        Pick<LegalEntity, 'id' | 'slug' | 'name' | 'websiteUrl' | 'industry' | 'timezone' | 'fiscalYearStartMonth' | 'fiscalYearNumberOffset' | 'status' | 'organizationId' | 'sensitive'>
        & { address: Pick<Address, 'street' | 'streetAdditional' | 'city' | 'region' | 'postalCode' | 'countryCode' | 'lat' | 'long'>, incorporatedOn?: Maybe<Pick<Date, 'year' | 'month' | 'day'>> }
      )> }>> }
  )>, signups: Array<(
    Pick<AdminSignup, 'createdAt' | 'updatedAt' | 'timezone'>
    & { signup: (
      Pick<Signup, 'id' | 'status' | 'statusReason' | 'location' | 'industry' | 'companyName' | 'companyUrl' | 'companyLedger' | 'companyCurrency' | 'positionTitle' | 'profileURL' | 'oAuthEmailAddress' | 'oAuthService' | 'persona'>
      & { contact: Pick<Contact, 'givenName' | 'familyName' | 'emailAddress' | 'avatarUrl'> }
    ) }
  )> };

export type CreateDoppelgangerPermitMutationVariables = Exact<{
  userId: Scalars['String']['input'];
  justification?: InputMaybe<Scalars['String']['input']>;
}>;


export type CreateDoppelgangerPermitMutation = { createDoppelgangerPermit: (
    Pick<AdminDoppelgangerPermit, 'justification' | 'sessionStartedAt' | 'sessionEndedAt'>
    & { doppelgangerPermit: (
      Pick<DoppelgangerPermit, 'id' | 'expiresAt' | 'createdAt'>
      & { user: (
        Pick<User, 'id' | 'givenName' | 'familyName' | 'primaryEmailAddress' | 'passwordSet' | 'avatarUrl' | 'createdAt' | 'lastActivityAt'>
        & { employments?: Maybe<Array<(
          Pick<OrganizationEmployment, 'employeeId'>
          & { role: Pick<Role, 'id'>, organization: Pick<Organization, 'id' | 'slug' | 'name' | 'iconUrl' | 'websiteUrl'> }
        )>>, identities?: Maybe<Array<Pick<Identity, 'identifier' | 'provider' | 'domain'>>> }
      ) }
    ), authorizer: (
      Pick<Superuser, 'id' | 'givenName' | 'familyName' | 'emailAddress' | 'avatarUrl' | 'createdAt' | 'lastLoginAt'>
      & { roles: Array<Pick<SuperuserRole, 'identifier' | 'displayName' | 'description'>> }
    ) }
  ) };

export type ListDoppelgangerPermitsQueryVariables = Exact<{ [key: string]: never; }>;


export type ListDoppelgangerPermitsQuery = { listDoppelgangerPermits: Array<Maybe<(
    Pick<AdminDoppelgangerPermit, 'justification' | 'sessionStartedAt' | 'sessionEndedAt'>
    & { doppelgangerPermit: (
      Pick<DoppelgangerPermit, 'id' | 'expiresAt' | 'createdAt'>
      & { user: (
        Pick<User, 'id' | 'givenName' | 'familyName' | 'primaryEmailAddress' | 'passwordSet' | 'avatarUrl' | 'createdAt' | 'lastActivityAt'>
        & { employments?: Maybe<Array<(
          Pick<OrganizationEmployment, 'employeeId'>
          & { role: Pick<Role, 'id'>, organization: Pick<Organization, 'id' | 'slug' | 'name' | 'iconUrl' | 'websiteUrl'> }
        )>>, identities?: Maybe<Array<Pick<Identity, 'identifier' | 'provider' | 'domain'>>> }
      ) }
    ), authorizer: (
      Pick<Superuser, 'id' | 'givenName' | 'familyName' | 'emailAddress' | 'avatarUrl' | 'createdAt' | 'lastLoginAt'>
      & { roles: Array<Pick<SuperuserRole, 'identifier' | 'displayName' | 'description'>> }
    ) }
  )>> };

export type TriggerSourceDataClassificationMutationVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
  dataSourceId: Scalars['String']['input'];
  externalId: Scalars['String']['input'];
}>;


export type TriggerSourceDataClassificationMutation = Pick<Mutation, 'triggerSourceDataClassification'>;

export type DeleteCsvImportTransactionsMutationVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
  csvImportId: Scalars['String']['input'];
}>;


export type DeleteCsvImportTransactionsMutation = Pick<Mutation, 'deleteCsvImportTransactions'>;

export type CheckAllCsvImportFactsDeletedQueryVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
  csvImportId: Scalars['String']['input'];
}>;


export type CheckAllCsvImportFactsDeletedQuery = { checkAllCsvImportFactsDeleted: Pick<AdminAllFactsDeleted, 'allDeleted'> };

export type ListAssociatedTransactionFactsQueryVariables = Exact<{
  dataSourceId: Scalars['String']['input'];
  externalId: Scalars['String']['input'];
}>;


export type ListAssociatedTransactionFactsQuery = { listAssociatedTransactionFacts: Array<(
    Pick<AdminTransactionFact, 'transactionFactId' | 'legalEntityId' | 'externalId' | 'externalName' | 'externalDescription' | 'pending' | 'transactionType' | 'externalTimestamp' | 'externalCreatedAt' | 'externalModifiedAt' | 'createdAt' | 'updatedAt'>
    & { dataSource: (
      Pick<EnrichedDataSource, 'id' | 'legalEntityId' | 'status' | 'statusReason' | 'providerType' | 'accountAlias' | 'createdAt' | 'updatedAt' | 'lastActivityAt' | 'hasTransactions'>
      & { institution: Pick<Party, 'id' | 'legalEntityId' | 'name' | 'url' | 'supportUrl' | 'supportEmail' | 'supportPhone' | 'twitterUrl' | 'facebookUrl' | 'instagramUrl' | 'yelpUrl' | 'iconUrl' | 'shortDescription' | 'designation'>, quickBooksConfig?: Maybe<Pick<QuickBooksConfig, 'id' | 'realmId' | 'createdAt' | 'updatedAt'>>, plaidConfig?: Maybe<Pick<PlaidConfig, 'id' | 'itemId' | 'externalInstitutionId' | 'createdAt' | 'updatedAt'>>, sourceAccounts?: Maybe<Array<Pick<SourceAccount, 'id' | 'externalId' | 'externalName' | 'externalOwnerName' | 'externalOfficialName' | 'externalType' | 'externalSubtype' | 'mask' | 'status' | 'ownerEmployeeId'>>> }
    ), sourceAccount?: Maybe<Pick<SourceAccount, 'id' | 'externalId' | 'externalName' | 'externalOwnerName' | 'externalOfficialName' | 'externalType' | 'externalSubtype' | 'mask' | 'status' | 'ownerEmployeeId'>> }
  )> };

export type ReadAdminTransactionFactQueryVariables = Exact<{
  transactionFactId: Scalars['String']['input'];
}>;


export type ReadAdminTransactionFactQuery = { readTransactionFact: (
    Pick<AdminTransactionFact, 'transactionFactId' | 'legalEntityId' | 'externalId' | 'externalName' | 'externalDescription' | 'pending' | 'transactionType' | 'externalTimestamp' | 'externalCreatedAt' | 'externalModifiedAt' | 'createdAt' | 'updatedAt'>
    & { dataSource: (
      Pick<EnrichedDataSource, 'id' | 'legalEntityId' | 'status' | 'statusReason' | 'providerType' | 'accountAlias' | 'createdAt' | 'updatedAt' | 'lastActivityAt' | 'hasTransactions'>
      & { institution: Pick<Party, 'id' | 'legalEntityId' | 'name' | 'url' | 'supportUrl' | 'supportEmail' | 'supportPhone' | 'twitterUrl' | 'facebookUrl' | 'instagramUrl' | 'yelpUrl' | 'iconUrl' | 'shortDescription' | 'designation'>, quickBooksConfig?: Maybe<Pick<QuickBooksConfig, 'id' | 'realmId' | 'createdAt' | 'updatedAt'>>, plaidConfig?: Maybe<Pick<PlaidConfig, 'id' | 'itemId' | 'externalInstitutionId' | 'createdAt' | 'updatedAt'>>, sourceAccounts?: Maybe<Array<Pick<SourceAccount, 'id' | 'externalId' | 'externalName' | 'externalOwnerName' | 'externalOfficialName' | 'externalType' | 'externalSubtype' | 'mask' | 'status' | 'ownerEmployeeId'>>> }
    ), sourceAccount?: Maybe<Pick<SourceAccount, 'id' | 'externalId' | 'externalName' | 'externalOwnerName' | 'externalOfficialName' | 'externalType' | 'externalSubtype' | 'mask' | 'status' | 'ownerEmployeeId'>> }
  ) };

export type AllTransactionFactMetadataQueryVariables = Exact<{
  transactionFactId: Scalars['String']['input'];
}>;


export type AllTransactionFactMetadataQuery = { readTransactionFact: (
    Pick<AdminTransactionFact, 'transactionFactId' | 'legalEntityId' | 'externalId' | 'externalName' | 'externalDescription' | 'pending' | 'transactionType' | 'externalTimestamp' | 'externalCreatedAt' | 'externalModifiedAt' | 'createdAt' | 'updatedAt'>
    & { dataSource: (
      Pick<EnrichedDataSource, 'id' | 'legalEntityId' | 'status' | 'statusReason' | 'providerType' | 'accountAlias' | 'createdAt' | 'updatedAt' | 'lastActivityAt' | 'hasTransactions'>
      & { institution: Pick<Party, 'id' | 'legalEntityId' | 'name' | 'url' | 'supportUrl' | 'supportEmail' | 'supportPhone' | 'twitterUrl' | 'facebookUrl' | 'instagramUrl' | 'yelpUrl' | 'iconUrl' | 'shortDescription' | 'designation'>, quickBooksConfig?: Maybe<Pick<QuickBooksConfig, 'id' | 'realmId' | 'createdAt' | 'updatedAt'>>, plaidConfig?: Maybe<Pick<PlaidConfig, 'id' | 'itemId' | 'externalInstitutionId' | 'createdAt' | 'updatedAt'>>, sourceAccounts?: Maybe<Array<Pick<SourceAccount, 'id' | 'externalId' | 'externalName' | 'externalOwnerName' | 'externalOfficialName' | 'externalType' | 'externalSubtype' | 'mask' | 'status' | 'ownerEmployeeId'>>> }
    ), sourceAccount?: Maybe<Pick<SourceAccount, 'id' | 'externalId' | 'externalName' | 'externalOwnerName' | 'externalOfficialName' | 'externalType' | 'externalSubtype' | 'mask' | 'status' | 'ownerEmployeeId'>> }
  ), readEntityExtractions: Array<Pick<AdminTransactionEntityExtraction, 'classificationVersionId' | 'party' | 'partyScore' | 'phone' | 'phoneScore' | 'location' | 'locationScore' | 'proxy' | 'proxyScore' | 'person' | 'personScore' | 'url' | 'urlScore' | 'institution' | 'institutionScore' | 'date' | 'dateScore' | 'creditCard' | 'creditCardScore' | 'updatedAt'>>, listTransactionFactPartyClassifications: Array<(
    Pick<AdminPartyClassification, 'prefix' | 'prefixDescription' | 'role' | 'overlayName' | 'viewPartyName' | 'viewPartyReason'>
    & { party: Pick<Party, 'id' | 'legalEntityId' | 'name' | 'url' | 'supportUrl' | 'supportEmail' | 'supportPhone' | 'twitterUrl' | 'facebookUrl' | 'instagramUrl' | 'yelpUrl' | 'iconUrl' | 'shortDescription' | 'designation'>, classification: Pick<AdminClassification, 'version' | 'legalEntityId' | 'rule' | 'createdAt' | 'updatedAt'> }
  )>, listTransactionFactCategoryClassifications: Array<(
    Pick<AdminCategoryClassification, 'entryType' | 'transactionFactId'>
    & { category: (
      Pick<Category, 'id' | 'name' | 'description' | 'displayKey' | 'type' | 'subtype' | 'parentCategoryId' | 'displayNumber' | 'active' | 'categoryState' | 'productArea' | 'synthesized'>
      & { ancestors?: Maybe<Array<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>>> }
    ), classification: Pick<AdminClassification, 'version' | 'legalEntityId' | 'rule' | 'createdAt' | 'updatedAt'> }
  )>, listTransactionFactLedgerPartyClassifications: Array<(
    Pick<AdminPartyClassification, 'prefix' | 'prefixDescription' | 'role' | 'overlayName' | 'viewPartyName' | 'viewPartyReason'>
    & { party: Pick<Party, 'id' | 'legalEntityId' | 'name' | 'url' | 'supportUrl' | 'supportEmail' | 'supportPhone' | 'twitterUrl' | 'facebookUrl' | 'instagramUrl' | 'yelpUrl' | 'iconUrl' | 'shortDescription' | 'designation'>, classification: Pick<AdminClassification, 'version' | 'legalEntityId' | 'rule' | 'createdAt' | 'updatedAt'> }
  )> };

export type AdminTransactionFactFieldsFragment = (
  Pick<AdminTransactionFact, 'transactionFactId' | 'legalEntityId' | 'externalId' | 'externalName' | 'externalDescription' | 'pending' | 'transactionType' | 'externalTimestamp' | 'externalCreatedAt' | 'externalModifiedAt' | 'createdAt' | 'updatedAt'>
  & { dataSource: (
    Pick<EnrichedDataSource, 'id' | 'legalEntityId' | 'status' | 'statusReason' | 'providerType' | 'accountAlias' | 'createdAt' | 'updatedAt' | 'lastActivityAt' | 'hasTransactions'>
    & { institution: Pick<Party, 'id' | 'legalEntityId' | 'name' | 'url' | 'supportUrl' | 'supportEmail' | 'supportPhone' | 'twitterUrl' | 'facebookUrl' | 'instagramUrl' | 'yelpUrl' | 'iconUrl' | 'shortDescription' | 'designation'>, quickBooksConfig?: Maybe<Pick<QuickBooksConfig, 'id' | 'realmId' | 'createdAt' | 'updatedAt'>>, plaidConfig?: Maybe<Pick<PlaidConfig, 'id' | 'itemId' | 'externalInstitutionId' | 'createdAt' | 'updatedAt'>>, sourceAccounts?: Maybe<Array<Pick<SourceAccount, 'id' | 'externalId' | 'externalName' | 'externalOwnerName' | 'externalOfficialName' | 'externalType' | 'externalSubtype' | 'mask' | 'status' | 'ownerEmployeeId'>>> }
  ), sourceAccount?: Maybe<Pick<SourceAccount, 'id' | 'externalId' | 'externalName' | 'externalOwnerName' | 'externalOfficialName' | 'externalType' | 'externalSubtype' | 'mask' | 'status' | 'ownerEmployeeId'>> }
);

export type AdminClassificationFieldsFragment = Pick<AdminClassification, 'version' | 'legalEntityId' | 'rule' | 'createdAt' | 'updatedAt'>;

export type AdminTransactionEntityExtractionFieldsFragment = Pick<AdminTransactionEntityExtraction, 'classificationVersionId' | 'party' | 'partyScore' | 'phone' | 'phoneScore' | 'location' | 'locationScore' | 'proxy' | 'proxyScore' | 'person' | 'personScore' | 'url' | 'urlScore' | 'institution' | 'institutionScore' | 'date' | 'dateScore' | 'creditCard' | 'creditCardScore' | 'updatedAt'>;

export type ListAdminChartsOfAccountsQueryVariables = Exact<{
  global?: InputMaybe<Scalars['Boolean']['input']>;
  legalEntityId?: InputMaybe<Scalars['String']['input']>;
  accountingBasis?: InputMaybe<AccountingBasis>;
}>;


export type ListAdminChartsOfAccountsQuery = { listChartsOfAccounts: Array<Pick<ChartOfAccountsListEntry, 'id' | 'name' | 'description' | 'accountingBasis' | 'legalEntityId'>> };

export type ReadAdminChartOfAccountsWithMappingQueryVariables = Exact<{
  fromId: Scalars['String']['input'];
  toId: Scalars['String']['input'];
  viewType: ViewType;
}>;


export type ReadAdminChartOfAccountsWithMappingQuery = { readMappedChartOfAccounts: (
    Pick<MappedChartOfAccounts, 'fromUUID' | 'toUUID' | 'name' | 'description' | 'legalEntityId' | 'accountingBasis'>
    & { categories: Array<{ fromCategory?: Maybe<(
        Pick<AdminCategory, 'externalId' | 'subtype' | 'chartOfAccountsDisplayNumber' | 'externalAccountType' | 'externalAccountSubtype' | 'synthesized'>
        & { category: (
          Pick<Category, 'id' | 'name' | 'description' | 'displayKey' | 'type' | 'subtype' | 'parentCategoryId' | 'displayNumber' | 'active' | 'categoryState' | 'productArea' | 'synthesized'>
          & { ancestors?: Maybe<Array<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>>> }
        ) }
      )>, toCategory?: Maybe<(
        Pick<AdminCategory, 'externalId' | 'subtype' | 'chartOfAccountsDisplayNumber' | 'externalAccountType' | 'externalAccountSubtype' | 'synthesized'>
        & { category: (
          Pick<Category, 'id' | 'name' | 'description' | 'displayKey' | 'type' | 'subtype' | 'parentCategoryId' | 'displayNumber' | 'active' | 'categoryState' | 'productArea' | 'synthesized'>
          & { ancestors?: Maybe<Array<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>>> }
        ) }
      )> }> }
  ) };

export type ReadAdminChartOfAccountsQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type ReadAdminChartOfAccountsQuery = { readChartOfAccounts: { chart: (
      Pick<ChartOfAccounts, 'id' | 'name' | 'description' | 'accountingBasis'>
      & { categories: Array<(
        Pick<Category, 'id' | 'name' | 'description' | 'displayKey' | 'type' | 'subtype' | 'parentCategoryId' | 'displayNumber' | 'active' | 'categoryState' | 'productArea' | 'synthesized'>
        & { ancestors?: Maybe<Array<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>>> }
      )> }
    ) } };

export type RemoveSuperuserRoleMutationVariables = Exact<{
  superuserId: Scalars['String']['input'];
  roleIdentifier: Scalars['String']['input'];
}>;


export type RemoveSuperuserRoleMutation = Pick<Mutation, 'removeSuperuserRole'>;

export type SendUserLegalEntityApprovalMutationVariables = Exact<{
  userId: Scalars['String']['input'];
  legalEntityId: Scalars['String']['input'];
}>;


export type SendUserLegalEntityApprovalMutation = Pick<Mutation, 'sendUserLegalEntityApproval'>;

export type AddSuperuserRoleMutationVariables = Exact<{
  superuserId: Scalars['String']['input'];
  roleIdentifier: Scalars['String']['input'];
}>;


export type AddSuperuserRoleMutation = { addSuperuserRole: (
    Pick<Superuser, 'id' | 'givenName' | 'familyName' | 'emailAddress' | 'avatarUrl' | 'createdAt' | 'lastLoginAt'>
    & { roles: Array<Pick<SuperuserRole, 'identifier' | 'displayName' | 'description'>> }
  ) };

export type DeleteSuperuserMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type DeleteSuperuserMutation = Pick<Mutation, 'deleteSuperuser'>;

export type UnlockUserMutationVariables = Exact<{
  userId: Scalars['String']['input'];
}>;


export type UnlockUserMutation = Pick<Mutation, 'unlockUser'>;

export type CreateSuperuserMutationVariables = Exact<{
  emailAddress: Scalars['String']['input'];
  roleIdentifiers: Array<Scalars['String']['input']> | Scalars['String']['input'];
  givenName: Scalars['String']['input'];
  familyName: Scalars['String']['input'];
}>;


export type CreateSuperuserMutation = { createSuperuser: (
    Pick<Superuser, 'id' | 'givenName' | 'familyName' | 'emailAddress' | 'avatarUrl' | 'createdAt' | 'lastLoginAt'>
    & { roles: Array<Pick<SuperuserRole, 'identifier' | 'displayName' | 'description'>> }
  ) };

export type AdminUserFieldsFragment = (
  Pick<AdminUser, 'timezone' | 'invitationId' | 'accountLocked'>
  & { user: (
    Pick<User, 'id' | 'givenName' | 'familyName' | 'primaryEmailAddress' | 'passwordSet' | 'avatarUrl' | 'createdAt' | 'lastActivityAt'>
    & { employments?: Maybe<Array<(
      Pick<OrganizationEmployment, 'employeeId'>
      & { role: Pick<Role, 'id'>, organization: Pick<Organization, 'id' | 'slug' | 'name' | 'iconUrl' | 'websiteUrl'> }
    )>>, identities?: Maybe<Array<Pick<Identity, 'identifier' | 'provider' | 'domain'>>> }
  ), employments?: Maybe<Array<(
    Pick<AdminUserEmployment, 'activatedAt' | 'status' | 'employeeId'>
    & { role: Pick<Role, 'id'>, organization: Pick<Organization, 'id' | 'slug' | 'name' | 'iconUrl' | 'websiteUrl'> }
  )>>, affiliations?: Maybe<Array<{ role: Pick<Role, 'id'>, userOrganization?: Maybe<Pick<Organization, 'id' | 'slug' | 'name' | 'iconUrl' | 'websiteUrl'>>, affiliatedTo?: Maybe<(
      Pick<LegalEntity, 'id' | 'slug' | 'name' | 'websiteUrl' | 'industry' | 'timezone' | 'fiscalYearStartMonth' | 'fiscalYearNumberOffset' | 'status' | 'organizationId' | 'sensitive'>
      & { address: Pick<Address, 'street' | 'streetAdditional' | 'city' | 'region' | 'postalCode' | 'countryCode' | 'lat' | 'long'>, incorporatedOn?: Maybe<Pick<Date, 'year' | 'month' | 'day'>> }
    )> }>> }
);

export type SuperuserFieldsFragment = (
  Pick<Superuser, 'id' | 'givenName' | 'familyName' | 'emailAddress' | 'avatarUrl' | 'createdAt' | 'lastLoginAt'>
  & { roles: Array<Pick<SuperuserRole, 'identifier' | 'displayName' | 'description'>> }
);

export type ListSuperusersQueryVariables = Exact<{ [key: string]: never; }>;


export type ListSuperusersQuery = { listSuperusers: Array<(
    Pick<Superuser, 'id' | 'givenName' | 'familyName' | 'emailAddress' | 'avatarUrl' | 'createdAt' | 'lastLoginAt'>
    & { roles: Array<Pick<SuperuserRole, 'identifier' | 'displayName' | 'description'>> }
  )> };

export type ListUsersQueryVariables = Exact<{
  pagination: Pagination;
  searchTerm?: InputMaybe<Scalars['String']['input']>;
  ids?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
}>;


export type ListUsersQuery = { listUsers: Array<(
    Pick<AdminUser, 'timezone' | 'invitationId' | 'accountLocked'>
    & { user: (
      Pick<User, 'id' | 'givenName' | 'familyName' | 'primaryEmailAddress' | 'passwordSet' | 'avatarUrl' | 'createdAt' | 'lastActivityAt'>
      & { employments?: Maybe<Array<(
        Pick<OrganizationEmployment, 'employeeId'>
        & { role: Pick<Role, 'id'>, organization: Pick<Organization, 'id' | 'slug' | 'name' | 'iconUrl' | 'websiteUrl'> }
      )>>, identities?: Maybe<Array<Pick<Identity, 'identifier' | 'provider' | 'domain'>>> }
    ), employments?: Maybe<Array<(
      Pick<AdminUserEmployment, 'activatedAt' | 'status' | 'employeeId'>
      & { role: Pick<Role, 'id'>, organization: Pick<Organization, 'id' | 'slug' | 'name' | 'iconUrl' | 'websiteUrl'> }
    )>>, affiliations?: Maybe<Array<{ role: Pick<Role, 'id'>, userOrganization?: Maybe<Pick<Organization, 'id' | 'slug' | 'name' | 'iconUrl' | 'websiteUrl'>>, affiliatedTo?: Maybe<(
        Pick<LegalEntity, 'id' | 'slug' | 'name' | 'websiteUrl' | 'industry' | 'timezone' | 'fiscalYearStartMonth' | 'fiscalYearNumberOffset' | 'status' | 'organizationId' | 'sensitive'>
        & { address: Pick<Address, 'street' | 'streetAdditional' | 'city' | 'region' | 'postalCode' | 'countryCode' | 'lat' | 'long'>, incorporatedOn?: Maybe<Pick<Date, 'year' | 'month' | 'day'>> }
      )> }>> }
  )> };

export type ReadUserQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type ReadUserQuery = { readUser: (
    Pick<AdminUser, 'timezone' | 'invitationId' | 'accountLocked'>
    & { user: (
      Pick<User, 'id' | 'givenName' | 'familyName' | 'primaryEmailAddress' | 'passwordSet' | 'avatarUrl' | 'createdAt' | 'lastActivityAt'>
      & { employments?: Maybe<Array<(
        Pick<OrganizationEmployment, 'employeeId'>
        & { role: Pick<Role, 'id'>, organization: Pick<Organization, 'id' | 'slug' | 'name' | 'iconUrl' | 'websiteUrl'> }
      )>>, identities?: Maybe<Array<Pick<Identity, 'identifier' | 'provider' | 'domain'>>> }
    ), employments?: Maybe<Array<(
      Pick<AdminUserEmployment, 'activatedAt' | 'status' | 'employeeId'>
      & { role: Pick<Role, 'id'>, organization: Pick<Organization, 'id' | 'slug' | 'name' | 'iconUrl' | 'websiteUrl'> }
    )>>, affiliations?: Maybe<Array<{ role: Pick<Role, 'id'>, userOrganization?: Maybe<Pick<Organization, 'id' | 'slug' | 'name' | 'iconUrl' | 'websiteUrl'>>, affiliatedTo?: Maybe<(
        Pick<LegalEntity, 'id' | 'slug' | 'name' | 'websiteUrl' | 'industry' | 'timezone' | 'fiscalYearStartMonth' | 'fiscalYearNumberOffset' | 'status' | 'organizationId' | 'sensitive'>
        & { address: Pick<Address, 'street' | 'streetAdditional' | 'city' | 'region' | 'postalCode' | 'countryCode' | 'lat' | 'long'>, incorporatedOn?: Maybe<Pick<Date, 'year' | 'month' | 'day'>> }
      )> }>> }
  ) };

export type ListEmployedUsersQueryVariables = Exact<{ [key: string]: never; }>;


export type ListEmployedUsersQuery = { listEmployedUsers?: Maybe<Array<(
    Pick<User, 'id' | 'givenName' | 'familyName' | 'primaryEmailAddress' | 'passwordSet' | 'avatarUrl' | 'createdAt' | 'lastActivityAt'>
    & { employments?: Maybe<Array<(
      Pick<OrganizationEmployment, 'employeeId'>
      & { role: Pick<Role, 'id'>, organization: Pick<Organization, 'id' | 'slug' | 'name' | 'iconUrl' | 'websiteUrl'> }
    )>>, identities?: Maybe<Array<Pick<Identity, 'identifier' | 'provider' | 'domain'>>> }
  )>> };

export type UpdateSignupStatusMutationVariables = Exact<{
  id: Scalars['String']['input'];
  status: SignupStatus;
  statusReason?: InputMaybe<Scalars['String']['input']>;
}>;


export type UpdateSignupStatusMutation = Pick<Mutation, 'updateSignupStatus'>;

export type AdminSignupFieldsFragment = (
  Pick<AdminSignup, 'createdAt' | 'updatedAt' | 'timezone'>
  & { signup: (
    Pick<Signup, 'id' | 'status' | 'statusReason' | 'location' | 'industry' | 'companyName' | 'companyUrl' | 'companyLedger' | 'companyCurrency' | 'positionTitle' | 'profileURL' | 'oAuthEmailAddress' | 'oAuthService' | 'persona'>
    & { contact: Pick<Contact, 'givenName' | 'familyName' | 'emailAddress' | 'avatarUrl'> }
  ) }
);

export type ReadSignupQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type ReadSignupQuery = { readSignup: (
    Pick<AdminSignup, 'createdAt' | 'updatedAt' | 'timezone'>
    & { signup: (
      Pick<Signup, 'id' | 'status' | 'statusReason' | 'location' | 'industry' | 'companyName' | 'companyUrl' | 'companyLedger' | 'companyCurrency' | 'positionTitle' | 'profileURL' | 'oAuthEmailAddress' | 'oAuthService' | 'persona'>
      & { contact: Pick<Contact, 'givenName' | 'familyName' | 'emailAddress' | 'avatarUrl'> }
    ) }
  ) };

export type ListSignupsQueryVariables = Exact<{
  status: ListSignupRequestStatus;
  pagination: Pagination;
}>;


export type ListSignupsQuery = { listSignups: Array<(
    Pick<AdminSignup, 'createdAt' | 'updatedAt' | 'timezone'>
    & { signup: (
      Pick<Signup, 'id' | 'status' | 'statusReason' | 'location' | 'industry' | 'companyName' | 'companyUrl' | 'companyLedger' | 'companyCurrency' | 'positionTitle' | 'profileURL' | 'oAuthEmailAddress' | 'oAuthService' | 'persona'>
      & { contact: Pick<Contact, 'givenName' | 'familyName' | 'emailAddress' | 'avatarUrl'> }
    ) }
  )> };

export type ResendActivationEmailMutationVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
  recipientId: Scalars['String']['input'];
}>;


export type ResendActivationEmailMutation = Pick<Mutation, 'resendActivationEmail'>;

export type UpdateLegalEntityStatusMutationVariables = Exact<{
  id: Scalars['String']['input'];
  status: LegalEntityStatus;
}>;


export type UpdateLegalEntityStatusMutation = Pick<Mutation, 'updateLegalEntityStatus'>;

export type ListLegalEntitiesQueryVariables = Exact<{
  pagination: Pagination;
  searchTerm?: InputMaybe<Scalars['String']['input']>;
  includedStatuses?: InputMaybe<Array<LegalEntityStatus> | LegalEntityStatus>;
  excludedStatuses?: InputMaybe<Array<LegalEntityStatus> | LegalEntityStatus>;
  legalEntityIds?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
}>;


export type ListLegalEntitiesQuery = { listLegalEntities: Array<(
    Pick<AdminLegalEntity, 'createdAt' | 'userCount' | 'approvalEligibilityStatuses' | 'hasPlaidDataSource' | 'allDataSourcesLinked' | 'anyDataSourceRequiresEngineering' | 'allDataSourcesHaveTransactions' | 'deletedAt'>
    & { legalEntity: (
      Pick<LegalEntity, 'id' | 'slug' | 'name' | 'websiteUrl' | 'industry' | 'timezone' | 'fiscalYearStartMonth' | 'fiscalYearNumberOffset' | 'status' | 'organizationId' | 'sensitive'>
      & { address: Pick<Address, 'street' | 'streetAdditional' | 'city' | 'region' | 'postalCode' | 'countryCode' | 'lat' | 'long'>, incorporatedOn?: Maybe<Pick<Date, 'year' | 'month' | 'day'>> }
    ), validationStatus: (
      Pick<ValidationHealthStatus, 'balanceSheetConsistent' | 'startedAt'>
      & { cashAspectHealth: Pick<AspectDataHealth, 'importedDataStatus' | 'closedViewDataStatus' | 'overallDataStatus'>, revenueAspectHealth: Pick<AspectDataHealth, 'importedDataStatus' | 'closedViewDataStatus' | 'overallDataStatus'>, expenseAspectHealth: Pick<AspectDataHealth, 'importedDataStatus' | 'closedViewDataStatus' | 'overallDataStatus'> }
    ), reportValidationHistory: Array<{ period: Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'>, histories: Array<(
        Pick<ReportKindValidationHistory, 'kind'>
        & { history: Pick<ReportValidationHistory, 'reportId' | 'versionId' | 'legalEntityId' | 'validatedAt' | 'isValid' | 'errorMessage' | 'source'> }
      )> }> }
  )> };

export type ListLegalEntityUsersQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type ListLegalEntityUsersQuery = { listLegalEntityUsers: Array<(
    Pick<AdminUser, 'timezone' | 'invitationId' | 'accountLocked'>
    & { user: (
      Pick<User, 'id' | 'givenName' | 'familyName' | 'primaryEmailAddress' | 'passwordSet' | 'avatarUrl' | 'createdAt' | 'lastActivityAt'>
      & { employments?: Maybe<Array<(
        Pick<OrganizationEmployment, 'employeeId'>
        & { role: Pick<Role, 'id'>, organization: Pick<Organization, 'id' | 'slug' | 'name' | 'iconUrl' | 'websiteUrl'> }
      )>>, identities?: Maybe<Array<Pick<Identity, 'identifier' | 'provider' | 'domain'>>> }
    ), employments?: Maybe<Array<(
      Pick<AdminUserEmployment, 'activatedAt' | 'status' | 'employeeId'>
      & { role: Pick<Role, 'id'>, organization: Pick<Organization, 'id' | 'slug' | 'name' | 'iconUrl' | 'websiteUrl'> }
    )>>, affiliations?: Maybe<Array<{ role: Pick<Role, 'id'>, userOrganization?: Maybe<Pick<Organization, 'id' | 'slug' | 'name' | 'iconUrl' | 'websiteUrl'>>, affiliatedTo?: Maybe<(
        Pick<LegalEntity, 'id' | 'slug' | 'name' | 'websiteUrl' | 'industry' | 'timezone' | 'fiscalYearStartMonth' | 'fiscalYearNumberOffset' | 'status' | 'organizationId' | 'sensitive'>
        & { address: Pick<Address, 'street' | 'streetAdditional' | 'city' | 'region' | 'postalCode' | 'countryCode' | 'lat' | 'long'>, incorporatedOn?: Maybe<Pick<Date, 'year' | 'month' | 'day'>> }
      )> }>> }
  )> };

export type ReadLegalEntityQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type ReadLegalEntityQuery = { readLegalEntity: (
    Pick<AdminLegalEntity, 'createdAt' | 'userCount' | 'approvalEligibilityStatuses' | 'hasPlaidDataSource' | 'allDataSourcesLinked' | 'anyDataSourceRequiresEngineering' | 'allDataSourcesHaveTransactions' | 'deletedAt'>
    & { legalEntity: (
      Pick<LegalEntity, 'id' | 'slug' | 'name' | 'websiteUrl' | 'industry' | 'timezone' | 'fiscalYearStartMonth' | 'fiscalYearNumberOffset' | 'status' | 'organizationId' | 'sensitive'>
      & { address: Pick<Address, 'street' | 'streetAdditional' | 'city' | 'region' | 'postalCode' | 'countryCode' | 'lat' | 'long'>, incorporatedOn?: Maybe<Pick<Date, 'year' | 'month' | 'day'>> }
    ), validationStatus: (
      Pick<ValidationHealthStatus, 'balanceSheetConsistent' | 'startedAt'>
      & { cashAspectHealth: Pick<AspectDataHealth, 'importedDataStatus' | 'closedViewDataStatus' | 'overallDataStatus'>, revenueAspectHealth: Pick<AspectDataHealth, 'importedDataStatus' | 'closedViewDataStatus' | 'overallDataStatus'>, expenseAspectHealth: Pick<AspectDataHealth, 'importedDataStatus' | 'closedViewDataStatus' | 'overallDataStatus'> }
    ), reportValidationHistory: Array<{ period: Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'>, histories: Array<(
        Pick<ReportKindValidationHistory, 'kind'>
        & { history: Pick<ReportValidationHistory, 'reportId' | 'versionId' | 'legalEntityId' | 'validatedAt' | 'isValid' | 'errorMessage' | 'source'> }
      )> }> }
  ) };

export type ListUserLegalEntitiesQueryVariables = Exact<{
  userId: Scalars['String']['input'];
}>;


export type ListUserLegalEntitiesQuery = { listUserLegalEntities?: Maybe<Array<(
    Pick<LegalEntity, 'id' | 'slug' | 'name' | 'websiteUrl' | 'industry' | 'timezone' | 'fiscalYearStartMonth' | 'fiscalYearNumberOffset' | 'status' | 'organizationId' | 'sensitive'>
    & { address: Pick<Address, 'street' | 'streetAdditional' | 'city' | 'region' | 'postalCode' | 'countryCode' | 'lat' | 'long'>, incorporatedOn?: Maybe<Pick<Date, 'year' | 'month' | 'day'>> }
  )>> };

export type ListApprovalEligibilityQueryVariables = Exact<{
  legalEntityIds: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;


export type ListApprovalEligibilityQuery = { listApprovalEligibility: Array<Pick<AdminApprovalEligibility, 'legalEntityId' | 'statuses'>> };

export type ReadLegalEntityPreferencesQueryVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
}>;


export type ReadLegalEntityPreferencesQuery = { readLegalEntityPreferences: Pick<LegalEntityPreferences, 'legalEntityId' | 'isCogsInExpenses' | 'lineClassDisplayName' | 'lineClassPluralDisplayName' | 'bookkeeperAiCutoverAt'> };

export type CreateAliasMutationVariables = Exact<{
  partyId: Scalars['String']['input'];
  alias: Scalars['String']['input'];
}>;


export type CreateAliasMutation = { createAlias: Pick<GlobalPartyAlias, 'partyId' | 'alias'> };

export type DeleteGlobalPartyMutationVariables = Exact<{
  partyId: Scalars['String']['input'];
}>;


export type DeleteGlobalPartyMutation = Pick<Mutation, 'deleteGlobalParty'>;

export type CreateGlobalPartyMutationVariables = Exact<{
  fields: NewParty;
  aliases: Array<Scalars['String']['input']> | Scalars['String']['input'];
  prefixes: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;


export type CreateGlobalPartyMutation = { createGlobalParty: (
    Pick<AdminParty, 'transactionsCount' | 'lastTransactionOccurredAt'>
    & { party: Pick<Party, 'id' | 'legalEntityId' | 'name' | 'url' | 'supportUrl' | 'supportEmail' | 'supportPhone' | 'twitterUrl' | 'facebookUrl' | 'instagramUrl' | 'yelpUrl' | 'iconUrl' | 'shortDescription' | 'designation'> }
  ) };

export type CreatePrefixMappingMutationVariables = Exact<{
  prefix: Scalars['String']['input'];
  partyId: Scalars['String']['input'];
}>;


export type CreatePrefixMappingMutation = { createPrefixMapping: Pick<PrefixMapping, 'prefix' | 'partyId'> };

export type UpdatePartyMutationVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
  partyId: Scalars['String']['input'];
  details: NewParty;
}>;


export type UpdatePartyMutation = Pick<Mutation, 'updateParty'>;

export type DeleteAliasMutationVariables = Exact<{
  partyId: Scalars['String']['input'];
  alias: Scalars['String']['input'];
}>;


export type DeleteAliasMutation = Pick<Mutation, 'deleteAlias'>;

export type DesignatePartyMutationVariables = Exact<{
  partyId: Scalars['String']['input'];
  legalEntityId: Scalars['String']['input'];
  designation: PartyDesignation;
}>;


export type DesignatePartyMutation = Pick<Mutation, 'designateParty'>;

export type DeletePartyExtractionMutationVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
  partyExtractionId: Scalars['String']['input'];
}>;


export type DeletePartyExtractionMutation = Pick<Mutation, 'deletePartyExtraction'>;

export type UpdateGlobalPartyMutationVariables = Exact<{
  partyId: Scalars['String']['input'];
  fields: NewParty;
}>;


export type UpdateGlobalPartyMutation = Pick<Mutation, 'updateGlobalParty'>;

export type PayrollProviderFieldsFragment = Pick<PayrollProvider, 'legalEntityId' | 'partyId' | 'partyName' | 'payrollProviderType' | 'rangeStartedAt' | 'rangeEndedAt'>;

export type AdminGlobalPartyFieldsFragment = (
  Pick<AdminGlobalParty, 'transactionsCount' | 'aliases' | 'prefixes' | 'lastReviewedAt'>
  & { party: Pick<Party, 'id' | 'legalEntityId' | 'name' | 'url' | 'supportUrl' | 'supportEmail' | 'supportPhone' | 'twitterUrl' | 'facebookUrl' | 'instagramUrl' | 'yelpUrl' | 'iconUrl' | 'shortDescription' | 'designation'> }
);

export type AdminPartyFieldsFragment = (
  Pick<AdminParty, 'transactionsCount' | 'lastTransactionOccurredAt'>
  & { party: Pick<Party, 'id' | 'legalEntityId' | 'name' | 'url' | 'supportUrl' | 'supportEmail' | 'supportPhone' | 'twitterUrl' | 'facebookUrl' | 'instagramUrl' | 'yelpUrl' | 'iconUrl' | 'shortDescription' | 'designation'> }
);

export type ListGlobalPartiesWithSummariesQueryVariables = Exact<{
  filter?: InputMaybe<GlobalPartyFilter>;
  pagination: Pagination;
  order?: InputMaybe<GlobalPartySort>;
}>;


export type ListGlobalPartiesWithSummariesQuery = { listGlobalPartiesWithSummaries: Array<(
    Pick<AdminGlobalParty, 'transactionsCount' | 'aliases' | 'prefixes' | 'lastReviewedAt'>
    & { party: Pick<Party, 'id' | 'legalEntityId' | 'name' | 'url' | 'supportUrl' | 'supportEmail' | 'supportPhone' | 'twitterUrl' | 'facebookUrl' | 'instagramUrl' | 'yelpUrl' | 'iconUrl' | 'shortDescription' | 'designation'> }
  )> };

export type ListPartyExtractionsQueryVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
  pagination: Pagination;
}>;


export type ListPartyExtractionsQuery = { listPartyExtractions: Array<(
    Pick<AdminPartyExtraction, 'legalEntityId' | 'partyExtractionId' | 'partyExtractionKey' | 'transactionFactId'>
    & { party?: Maybe<Pick<Party, 'id' | 'legalEntityId' | 'name' | 'url' | 'supportUrl' | 'supportEmail' | 'supportPhone' | 'twitterUrl' | 'facebookUrl' | 'instagramUrl' | 'yelpUrl' | 'iconUrl' | 'shortDescription' | 'designation'>> }
  )> };

export type ListAgentPartiesQueryVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
  pagination: Pagination;
}>;


export type ListAgentPartiesQuery = { listAgentParties: Array<Pick<Party, 'id' | 'legalEntityId' | 'name' | 'url' | 'supportUrl' | 'supportEmail' | 'supportPhone' | 'twitterUrl' | 'facebookUrl' | 'instagramUrl' | 'yelpUrl' | 'iconUrl' | 'shortDescription' | 'designation'>> };

export type ListPartiesQueryVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
  pagination: Pagination;
  viewType: ViewType;
  partyType?: InputMaybe<PartyType>;
  designation?: InputMaybe<PartyDesignation>;
}>;


export type ListPartiesQuery = { listParties: Array<(
    Pick<AdminParty, 'transactionsCount' | 'lastTransactionOccurredAt'>
    & { party: Pick<Party, 'id' | 'legalEntityId' | 'name' | 'url' | 'supportUrl' | 'supportEmail' | 'supportPhone' | 'twitterUrl' | 'facebookUrl' | 'instagramUrl' | 'yelpUrl' | 'iconUrl' | 'shortDescription' | 'designation'> }
  )> };

export type ListGlobalPartiesQueryVariables = Exact<{
  filter?: InputMaybe<GlobalPartyFilter>;
  pagination: Pagination;
  order?: InputMaybe<GlobalPartySort>;
}>;


export type ListGlobalPartiesQuery = { listGlobalParties: Array<(
    Pick<AdminGlobalParty, 'transactionsCount' | 'aliases' | 'prefixes' | 'lastReviewedAt'>
    & { party: Pick<Party, 'id' | 'legalEntityId' | 'name' | 'url' | 'supportUrl' | 'supportEmail' | 'supportPhone' | 'twitterUrl' | 'facebookUrl' | 'instagramUrl' | 'yelpUrl' | 'iconUrl' | 'shortDescription' | 'designation'> }
  )> };

export type ListPayrollPartiesQueryVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
}>;


export type ListPayrollPartiesQuery = { listPayrollParties: Array<Pick<PayrollProvider, 'legalEntityId' | 'partyId' | 'partyName' | 'payrollProviderType' | 'rangeStartedAt' | 'rangeEndedAt'>> };

export type SourceRecordedMatchSummaryEntryFieldsFragment = (
  Pick<SourceRecordedMatchSummaryEntry, 'total' | 'matched' | 'unmatched' | 'unmatchedAndUncategorized'>
  & { period: Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'>, institution: Pick<Institution, 'name' | 'url' | 'iconUrl'> }
);

export type SourceRecordedMatchSummaryFieldsFragment = { entries: Array<(
    Pick<SourceRecordedMatchSummaryEntry, 'total' | 'matched' | 'unmatched' | 'unmatchedAndUncategorized'>
    & { period: Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'>, institution: Pick<Institution, 'name' | 'url' | 'iconUrl'> }
  )> };

export type ReadSourceRecordedMatchSummaryQueryVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
}>;


export type ReadSourceRecordedMatchSummaryQuery = { readSourceRecordedMatchSummary: { entries: Array<(
      Pick<SourceRecordedMatchSummaryEntry, 'total' | 'matched' | 'unmatched' | 'unmatchedAndUncategorized'>
      & { period: Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'>, institution: Pick<Institution, 'name' | 'url' | 'iconUrl'> }
    )> } };

export type SoftDeleteLegalEntityMutationVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
}>;


export type SoftDeleteLegalEntityMutation = Pick<Mutation, 'softDeleteLegalEntity'>;

export type SoftDeleteOrganizationMutationVariables = Exact<{
  organizationId: Scalars['String']['input'];
  deleteUsers: Scalars['Boolean']['input'];
}>;


export type SoftDeleteOrganizationMutation = Pick<Mutation, 'softDeleteOrganization'>;

export type UpdateClassificationValidationExpectationMutationVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
  facts: Array<NewClassificationValidationExpectationFact> | NewClassificationValidationExpectationFact;
  partyName?: InputMaybe<Scalars['String']['input']>;
  partyRole?: InputMaybe<Scalars['String']['input']>;
  partyIconObjectName?: InputMaybe<Scalars['String']['input']>;
  debitCategoryName?: InputMaybe<Scalars['String']['input']>;
  creditCategoryName?: InputMaybe<Scalars['String']['input']>;
  employeeOwnerName?: InputMaybe<Scalars['String']['input']>;
  expectationId: Scalars['String']['input'];
}>;


export type UpdateClassificationValidationExpectationMutation = { updateClassificationValidationExpectation?: Maybe<(
    Pick<ClassificationValidation, 'legalEntityId' | 'partyName' | 'partyRole' | 'partyIconUrl' | 'debitCategoryName' | 'creditCategoryName' | 'employeeOwnerName' | 'expectationId' | 'status'>
    & { facts: Array<Pick<ClassificationValidationExpectationFact, 'transactionFactId' | 'dataSourceId'>> }
  )> };

export type CreateClassificationValidationExpectationMutationVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
  facts: Array<NewClassificationValidationExpectationFact> | NewClassificationValidationExpectationFact;
  partyName?: InputMaybe<Scalars['String']['input']>;
  partyRole?: InputMaybe<Scalars['String']['input']>;
  partyIconObjectName?: InputMaybe<Scalars['String']['input']>;
  debitCategoryName?: InputMaybe<Scalars['String']['input']>;
  creditCategoryName?: InputMaybe<Scalars['String']['input']>;
  employeeOwnerName?: InputMaybe<Scalars['String']['input']>;
}>;


export type CreateClassificationValidationExpectationMutation = { createClassificationValidationExpectation?: Maybe<(
    Pick<ClassificationValidation, 'legalEntityId' | 'partyName' | 'partyRole' | 'partyIconUrl' | 'debitCategoryName' | 'creditCategoryName' | 'employeeOwnerName' | 'expectationId' | 'status'>
    & { facts: Array<Pick<ClassificationValidationExpectationFact, 'transactionFactId' | 'dataSourceId'>> }
  )> };

export type ClassificationValidationFailureDetailFieldsFragment = Pick<ClassificationValidationFailureDetail, 'expectationType' | 'expectedValue' | 'actualValue'>;

export type SummarizeDataSourceFetchesFieldsFragment = { summaries?: Maybe<Array<(
    Pick<DataSourceFetchCountSummary, 'institutionName' | 'success'>
    & { statTimeBuckets?: Maybe<Pick<StatisticByTimeBuckets, 'name' | 'total' | 'bucketOne' | 'bucketTwo' | 'bucketThree' | 'bucketFour' | 'bucketTimeMins'>> }
  )>> };

export type ValidationPeriodSummaryFieldsFragment = (
  Pick<ValidationPeriodSummary, 'matchedCategories' | 'mismatchedCategories' | 'noExternalValueMismatchedCategories'>
  & { period: Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'> }
);

export type ClassificationValidationFieldsFragment = (
  Pick<ClassificationValidation, 'legalEntityId' | 'partyName' | 'partyRole' | 'partyIconUrl' | 'debitCategoryName' | 'creditCategoryName' | 'employeeOwnerName' | 'expectationId' | 'status'>
  & { facts: Array<Pick<ClassificationValidationExpectationFact, 'transactionFactId' | 'dataSourceId'>> }
);

export type MismatchedQuickbooksCategoryFieldsFragment = (
  Pick<MismatchedQuickbooksCategory, 'categoryName' | 'categoryType' | 'externalValue' | 'digitsValue' | 'difference'>
  & { period: Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'> }
);

export type MismatchedQuickbooksBalanceSheetCategoryFieldsFragment = (
  Pick<MismatchedQuickbooksBalanceSheetCategory, 'categoryName' | 'categoryType' | 'externalStartingBalance' | 'externalEndingBalance' | 'digitsStartingBalance' | 'digitsEndingBalance' | 'startingDelta' | 'endingDelta'>
  & { period: Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'> }
);

export type UnsupportedTransactionsFieldsFragment = { onboardUnsupportedTransactions: Array<Pick<UnsupportedTransaction, 'fetchId' | 'fetchType' | 'providerType' | 'accountAlias' | 'dataType' | 'transactionCount' | 'reason'>>, otherUnsupportedTransactions: Array<Pick<UnsupportedTransaction, 'fetchId' | 'fetchType' | 'providerType' | 'accountAlias' | 'dataType' | 'transactionCount' | 'reason'>> };

export type ClassificationValidationSummaryFieldsFragment = Pick<ClassificationValidationSummary, 'passingValidations' | 'totalValidations' | 'numberOfLegalEntities'>;

export type SummarizeViewGenerationFieldsFragment = Pick<ViewGenerationSummary, 'bucketOne' | 'bucketTwo' | 'bucketThree' | 'bucketFour' | 'bucketTimeMins'>;

export type ClassificationValidationExpectationFactFieldsFragment = Pick<ClassificationValidationExpectationFact, 'transactionFactId' | 'dataSourceId'>;

export type UnsupportedTransactionFieldsFragment = Pick<UnsupportedTransaction, 'fetchId' | 'fetchType' | 'providerType' | 'accountAlias' | 'dataType' | 'transactionCount' | 'reason'>;

export type ValidationSummaryFieldsFragment = { periodSummaries: Array<(
    Pick<ValidationPeriodSummary, 'matchedCategories' | 'mismatchedCategories' | 'noExternalValueMismatchedCategories'>
    & { period: Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'> }
  )> };

export type ClassificationValidationFailureFieldsFragment = { expectation: (
    Pick<ClassificationValidation, 'legalEntityId' | 'partyName' | 'partyRole' | 'partyIconUrl' | 'debitCategoryName' | 'creditCategoryName' | 'employeeOwnerName' | 'expectationId' | 'status'>
    & { facts: Array<Pick<ClassificationValidationExpectationFact, 'transactionFactId' | 'dataSourceId'>> }
  ), failureDetails: Array<Pick<ClassificationValidationFailureDetail, 'expectationType' | 'expectedValue' | 'actualValue'>> };

export type ListMismatchedQuickbooksBalanceSheetCategoriesQueryVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
}>;


export type ListMismatchedQuickbooksBalanceSheetCategoriesQuery = { listMismatchedQuickbooksBalanceSheetCategories: Array<(
    Pick<MismatchedQuickbooksBalanceSheetCategory, 'categoryName' | 'categoryType' | 'externalStartingBalance' | 'externalEndingBalance' | 'digitsStartingBalance' | 'digitsEndingBalance' | 'startingDelta' | 'endingDelta'>
    & { period: Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'> }
  )> };

export type ListClassificationValidationExpectationsQueryVariables = Exact<{
  legalEntityId?: InputMaybe<Scalars['String']['input']>;
  factIds?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
}>;


export type ListClassificationValidationExpectationsQuery = { listClassificationValidationExpectations: Array<(
    Pick<ClassificationValidation, 'legalEntityId' | 'partyName' | 'partyRole' | 'partyIconUrl' | 'debitCategoryName' | 'creditCategoryName' | 'employeeOwnerName' | 'expectationId' | 'status'>
    & { facts: Array<Pick<ClassificationValidationExpectationFact, 'transactionFactId' | 'dataSourceId'>> }
  )> };

export type ListMismatchedQuickbooksCategoriesQueryVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
}>;


export type ListMismatchedQuickbooksCategoriesQuery = { listMismatchedQuickbooksCategories: Array<(
    Pick<MismatchedQuickbooksCategory, 'categoryName' | 'categoryType' | 'externalValue' | 'digitsValue' | 'difference'>
    & { period: Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'> }
  )> };

export type SummarizeDataSourceFetchesQueryVariables = Exact<{ [key: string]: never; }>;


export type SummarizeDataSourceFetchesQuery = { summarizeDataSourceFetches: { summaries?: Maybe<Array<(
      Pick<DataSourceFetchCountSummary, 'institutionName' | 'success'>
      & { statTimeBuckets?: Maybe<Pick<StatisticByTimeBuckets, 'name' | 'total' | 'bucketOne' | 'bucketTwo' | 'bucketThree' | 'bucketFour' | 'bucketTimeMins'>> }
    )>> } };

export type ListFailingClassificationValidationsQueryVariables = Exact<{ [key: string]: never; }>;


export type ListFailingClassificationValidationsQuery = { listFailingClassificationValidations: Array<{ expectation: (
      Pick<ClassificationValidation, 'legalEntityId' | 'partyName' | 'partyRole' | 'partyIconUrl' | 'debitCategoryName' | 'creditCategoryName' | 'employeeOwnerName' | 'expectationId' | 'status'>
      & { facts: Array<Pick<ClassificationValidationExpectationFact, 'transactionFactId' | 'dataSourceId'>> }
    ), failureDetails: Array<Pick<ClassificationValidationFailureDetail, 'expectationType' | 'expectedValue' | 'actualValue'>> }> };

export type SummarizeClassificationValidationsQueryVariables = Exact<{ [key: string]: never; }>;


export type SummarizeClassificationValidationsQuery = { summarizeClassificationValidations: Pick<ClassificationValidationSummary, 'passingValidations' | 'totalValidations' | 'numberOfLegalEntities'> };

export type ReadValidationSummaryQueryVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
}>;


export type ReadValidationSummaryQuery = { readValidationSummary: { periodSummaries: Array<(
      Pick<ValidationPeriodSummary, 'matchedCategories' | 'mismatchedCategories' | 'noExternalValueMismatchedCategories'>
      & { period: Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'> }
    )> } };

export type SummarizeViewGenerationQueryVariables = Exact<{ [key: string]: never; }>;


export type SummarizeViewGenerationQuery = { summarizeViewGeneration: Pick<ViewGenerationSummary, 'bucketOne' | 'bucketTwo' | 'bucketThree' | 'bucketFour' | 'bucketTimeMins'> };

export type ListUnsupportedTransactionsQueryVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
}>;


export type ListUnsupportedTransactionsQuery = { listUnsupportedTransactions: { onboardUnsupportedTransactions: Array<Pick<UnsupportedTransaction, 'fetchId' | 'fetchType' | 'providerType' | 'accountAlias' | 'dataType' | 'transactionCount' | 'reason'>>, otherUnsupportedTransactions: Array<Pick<UnsupportedTransaction, 'fetchId' | 'fetchType' | 'providerType' | 'accountAlias' | 'dataType' | 'transactionCount' | 'reason'>> } };

export type DisableConnectionMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type DisableConnectionMutation = Pick<Mutation, 'disableConnection'>;

export type SyncConnectionMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type SyncConnectionMutation = Pick<Mutation, 'syncConnection'>;

export type DeleteConnectionMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type DeleteConnectionMutation = Pick<Mutation, 'deleteConnection'>;

export type CreateConnectionTemplateMutationVariables = Exact<{
  connectorId: Scalars['String']['input'];
  institutionId: Scalars['String']['input'];
}>;


export type CreateConnectionTemplateMutation = Pick<Mutation, 'createConnectionTemplate'>;

export type ResetConnectionMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type ResetConnectionMutation = Pick<Mutation, 'resetConnection'>;

export type EnableConnectionMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type EnableConnectionMutation = Pick<Mutation, 'enableConnection'>;

export type DeleteConnectionTemplateMutationVariables = Exact<{
  connectorId: Scalars['String']['input'];
  institutionId: Scalars['String']['input'];
}>;


export type DeleteConnectionTemplateMutation = Pick<Mutation, 'deleteConnectionTemplate'>;

export type AdminConnectionFieldsFragment = (
  Pick<AdminConnection, 'id' | 'sourceName' | 'deletedAt' | 'legalEntityId' | 'dataSourceId' | 'createdAt' | 'status' | 'connectorClassName' | 'externalId'>
  & { sync?: Maybe<Pick<ConnectionSyncState, 'lastSuccessAt' | 'lastEventAt' | 'status'>> }
);

export type BrokenConnectionsQueryVariables = Exact<{ [key: string]: never; }>;


export type BrokenConnectionsQuery = { brokenConnections: Array<(
    Pick<BrokenConnection, 'dataUpdatedAt'>
    & { connection: (
      Pick<AdminConnection, 'id' | 'sourceName' | 'deletedAt' | 'legalEntityId' | 'dataSourceId' | 'createdAt' | 'status' | 'connectorClassName' | 'externalId'>
      & { sync?: Maybe<Pick<ConnectionSyncState, 'lastSuccessAt' | 'lastEventAt' | 'status'>> }
    ) }
  )> };

export type ListConnectionsQueryVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
}>;


export type ListConnectionsQuery = { listConnections: { connections: Array<(
      Pick<AdminConnection, 'id' | 'sourceName' | 'deletedAt' | 'legalEntityId' | 'dataSourceId' | 'createdAt' | 'status' | 'connectorClassName' | 'externalId'>
      & { sync?: Maybe<Pick<ConnectionSyncState, 'lastSuccessAt' | 'lastEventAt' | 'status'>> }
    )> } };

export type AdminConnectionListFieldsFragment = { connections: Array<(
    Pick<AdminConnection, 'id' | 'sourceName' | 'deletedAt' | 'legalEntityId' | 'dataSourceId' | 'createdAt' | 'status' | 'connectorClassName' | 'externalId'>
    & { sync?: Maybe<Pick<ConnectionSyncState, 'lastSuccessAt' | 'lastEventAt' | 'status'>> }
  )> };

export type ListConnectorsQueryVariables = Exact<{
  institutionId?: InputMaybe<Scalars['String']['input']>;
}>;


export type ListConnectorsQuery = { listConnectors: Array<(
    Pick<Connector, 'id' | 'name' | 'description' | 'category' | 'iconURL' | 'className'>
    & { connectorProperties?: Maybe<(
      { __typename: 'EmptyConnectorProperties' }
      & Pick<EmptyConnectorProperties, 'empty'>
    ) | (
      { __typename: 'GustoConnectorProperties' }
      & Pick<GustoConnectorProperties, 'authorizationURL'>
    ) | (
      { __typename: 'PlaidConnectorProperties' }
      & Pick<PlaidConnectorProperties, 'empty'>
    ) | (
      { __typename: 'RampConnectorProperties' }
      & Pick<RampConnectorProperties, 'authorizationURL'>
    ) | (
      { __typename: 'StripeConnectorProperties' }
      & Pick<StripeConnectorProperties, 'appLinkURL'>
    )> }
  )> };

export type ConnectionEventsQueryVariables = Exact<{
  connectionId: Scalars['String']['input'];
}>;


export type ConnectionEventsQuery = { connectionEvents: Array<Pick<AdminConnectionEvent, 'id' | 'connectionEventRef' | 'source' | 'status' | 'message' | 'failureReason' | 'affectedCount' | 'oldestSeen' | 'newestSeen' | 'dataUpdatedAt' | 'dataStatus' | 'userId' | 'superUserId' | 'createdAt'>> };

export type AdminConnectionEventFieldsFragment = Pick<AdminConnectionEvent, 'id' | 'connectionEventRef' | 'source' | 'status' | 'message' | 'failureReason' | 'affectedCount' | 'oldestSeen' | 'newestSeen' | 'dataUpdatedAt' | 'dataStatus' | 'userId' | 'superUserId' | 'createdAt'>;

export type ReadConnectionQueryVariables = Exact<{
  connectionId: Scalars['String']['input'];
}>;


export type ReadConnectionQuery = { readConnection: (
    Pick<AdminConnection, 'id' | 'sourceName' | 'deletedAt' | 'legalEntityId' | 'dataSourceId' | 'createdAt' | 'status' | 'connectorClassName' | 'externalId'>
    & { sync?: Maybe<Pick<ConnectionSyncState, 'lastSuccessAt' | 'lastEventAt' | 'status'>> }
  ) };

export type ReadConnectRecordingQueryVariables = Exact<{
  connectionId: Scalars['String']['input'];
  eventId: Scalars['String']['input'];
}>;


export type ReadConnectRecordingQuery = { readConnectRecording: { responses: Array<(
      Pick<HttpResponse, 'rawBody'>
      & { metadata?: Maybe<Pick<HttpResponseMetadata, 'request_id' | 'url' | 'status' | 'status_code'>> }
    )> } };

export type UpdateAspectStatusMutationVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
  aspectId: Scalars['String']['input'];
  status: AspectStatusValue;
}>;


export type UpdateAspectStatusMutation = Pick<Mutation, 'updateAspectStatus'>;

export type ApproveRequestMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type ApproveRequestMutation = Pick<Mutation, 'approveRequest'>;

export type LegalEntityAspectStatusFieldsFragment = (
  Pick<LegalEntityAspectStatus, 'legalEntityId' | 'legalEntityName'>
  & { statuses: Array<(
    Pick<AspectStatus, 'status' | 'updatedAt'>
    & { aspect: Pick<Aspect, 'id' | 'code' | 'name' | 'description' | 'defaultStatus' | 'createdAt' | 'updatedAt'> }
  )> }
);

export type AspectRequestFieldsFragment = (
  Pick<AspectRequest, 'id' | 'legalEntityId' | 'legalEntityName' | 'createdAt'>
  & { aspect: Pick<Aspect, 'id' | 'code' | 'name' | 'description' | 'defaultStatus' | 'createdAt' | 'updatedAt'> }
);

export type AspectFieldsFragment = Pick<Aspect, 'id' | 'code' | 'name' | 'description' | 'defaultStatus' | 'createdAt' | 'updatedAt'>;

export type AspectStatusFieldsFragment = (
  Pick<AspectStatus, 'status' | 'updatedAt'>
  & { aspect: Pick<Aspect, 'id' | 'code' | 'name' | 'description' | 'defaultStatus' | 'createdAt' | 'updatedAt'> }
);

export type ListAspectRequestsQueryVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
}>;


export type ListAspectRequestsQuery = { listAspectRequests: Array<(
    Pick<AspectRequest, 'id' | 'legalEntityId' | 'legalEntityName' | 'createdAt'>
    & { aspect: Pick<Aspect, 'id' | 'code' | 'name' | 'description' | 'defaultStatus' | 'createdAt' | 'updatedAt'> }
  )> };

export type ListAspectStatusesQueryVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
}>;


export type ListAspectStatusesQuery = { listAspectStatuses: Array<(
    Pick<LegalEntityAspectStatus, 'legalEntityId' | 'legalEntityName'>
    & { statuses: Array<(
      Pick<AspectStatus, 'status' | 'updatedAt'>
      & { aspect: Pick<Aspect, 'id' | 'code' | 'name' | 'description' | 'defaultStatus' | 'createdAt' | 'updatedAt'> }
    )> }
  )> };

export type ReadEmailHtmlQueryVariables = Exact<{
  eventType: EventType;
}>;


export type ReadEmailHtmlQuery = Pick<Query, 'readEmailHTML'>;

export type ReadBillingSubscriptionHistoryQueryVariables = Exact<{
  organizationId: Scalars['String']['input'];
}>;


export type ReadBillingSubscriptionHistoryQuery = { readBillingSubscriptionHistory: Array<(
    Pick<BillingSubscriptionHistory, 'createdAt' | 'paymentIntentStatus' | 'interval' | 'productName'>
    & { amount: Pick<MonetaryValue, 'amount' | 'currencyMultiplier' | 'iso4217CurrencyCode'> }
  )> };

export type BillingProductFieldsFragment = Pick<BillingProduct, 'id' | 'name' | 'displayName' | 'description' | 'trialPeriodDays'>;

export type BillingPriceFieldsFragment = (
  Pick<BillingPrice, 'id' | 'interval' | 'intervalCount'>
  & { amount: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
);

export type BillingLegalEntityLimitFieldsFragment = Pick<BillingLegalEntityLimit, 'id' | 'minLegalEntityLimit' | 'maxLegalEntityLimit'>;

export type ReadOrganizationBillingPlanQueryVariables = Exact<{
  organizationId: Scalars['String']['input'];
}>;


export type ReadOrganizationBillingPlanQuery = { readOrganizationBillingPlan: { product: Pick<BillingProduct, 'id' | 'name' | 'displayName' | 'description' | 'trialPeriodDays'>, price: (
      Pick<BillingPrice, 'id' | 'interval' | 'intervalCount'>
      & { amount: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
    ), legalEntityLimit?: Maybe<Pick<BillingLegalEntityLimit, 'id' | 'minLegalEntityLimit' | 'maxLegalEntityLimit'>> } };

export type ResetWorkItemMutationVariables = Exact<{
  workItemId: Scalars['String']['input'];
}>;


export type ResetWorkItemMutation = Pick<Mutation, 'resetWorkItem'>;

export type TriggerTransactionAuditCheckMutationVariables = Exact<{
  transactionFactId: Scalars['String']['input'];
}>;


export type TriggerTransactionAuditCheckMutation = Pick<Mutation, 'triggerTransactionAuditCheck'>;

export type ReadWorkItemQueryVariables = Exact<{
  workItemId: Scalars['String']['input'];
}>;


export type ReadWorkItemQuery = { readWorkItem: (
    Pick<AdminWorkItem, 'legalEntityId' | 'workItemId' | 'time' | 'itemType' | 'itemState' | 'ledgerType' | 'actionable' | 'createdAt' | 'updatedBy' | 'updatedAt' | 'transactionFactId' | 'defaultState' | 'userState' | 'transactionGcsUrl'>
    & { transactions: Array<(
      Pick<AdminWorkItemTransaction, 'externalTransactionId' | 'externalTransactionLineId' | 'supportType' | 'description' | 'name' | 'displayEntry' | 'occurredAt' | 'dataSourceId'>
      & { creditCategory?: Maybe<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>>, debitCategory?: Maybe<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>>, party?: Maybe<Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>>, displayCategory?: Maybe<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>> }
    )>, generatedQuestions: Array<Pick<WorkItemGeneratedQuestion, 'question' | 'contextType' | 'active'>>, pushes: Array<Pick<WorkItemPush, 'field' | 'pushState' | 'errorType' | 'updatedAt'>> }
  ) };

export type AdminWorkItemFieldsFragment = (
  Pick<AdminWorkItem, 'legalEntityId' | 'workItemId' | 'time' | 'itemType' | 'itemState' | 'ledgerType' | 'actionable' | 'createdAt' | 'updatedBy' | 'updatedAt' | 'transactionFactId' | 'defaultState' | 'userState' | 'transactionGcsUrl'>
  & { transactions: Array<(
    Pick<AdminWorkItemTransaction, 'externalTransactionId' | 'externalTransactionLineId' | 'supportType' | 'description' | 'name' | 'displayEntry' | 'occurredAt' | 'dataSourceId'>
    & { creditCategory?: Maybe<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>>, debitCategory?: Maybe<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>>, party?: Maybe<Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>>, displayCategory?: Maybe<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>> }
  )>, generatedQuestions: Array<Pick<WorkItemGeneratedQuestion, 'question' | 'contextType' | 'active'>>, pushes: Array<Pick<WorkItemPush, 'field' | 'pushState' | 'errorType' | 'updatedAt'>> }
);

export type AdminWorkItemTransactionFieldsFragment = (
  Pick<AdminWorkItemTransaction, 'externalTransactionId' | 'externalTransactionLineId' | 'supportType' | 'description' | 'name' | 'displayEntry' | 'occurredAt' | 'dataSourceId'>
  & { creditCategory?: Maybe<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>>, debitCategory?: Maybe<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>>, party?: Maybe<Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>>, displayCategory?: Maybe<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>> }
);

export type GetEmbeddingQueryVariables = Exact<{
  transactionFactId: Scalars['String']['input'];
  partyPrefix?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetEmbeddingQuery = { getEmbedding: Pick<EmbeddingResponse, 'embeddingValue' | 'deployedIndexId'> };

export type HighlightWorkItemQueryVariables = Exact<{
  similarityType: SimilarityType;
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
}>;


export type HighlightWorkItemQuery = { highlightWorkItem: { description?: Maybe<Array<Pick<HighlightedToken, 'token' | 'score'>>>, name?: Maybe<Array<Pick<HighlightedToken, 'token' | 'score'>>> } };

export type GetNeighborsQueryVariables = Exact<{
  deployedIndexId: Scalars['String']['input'];
  floatVal: Array<Scalars['Float']['input']> | Scalars['Float']['input'];
  numNeighbors?: InputMaybe<Scalars['Int']['input']>;
  restricts?: InputMaybe<Array<Namespace> | Namespace>;
  perCrowdingAttributeNumNeighbors?: InputMaybe<Scalars['Int']['input']>;
  approxNumNeighbors?: InputMaybe<Scalars['Int']['input']>;
  leafNodesToSearchPercentOverride?: InputMaybe<Scalars['Int']['input']>;
}>;


export type GetNeighborsQuery = { getNeighbors: { neighbor: Array<Pick<Neighbor, 'id' | 'distance'>> } };

export type ReadWorkItemValidationQueryVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
}>;


export type ReadWorkItemValidationQuery = { readWorkItemValidation: Pick<WorkItemValidation, 'status' | 'workItemCount'> };

export type AdminReportPackageMetadataFieldsFragment = (
  Pick<AdminReportPackageMetadata, 'id' | 'versionId' | 'title' | 'status' | 'startedAt' | 'endedAt' | 'interval' | 'publishedAsDraft' | 'createdAt' | 'updatedAt'>
  & { viewKey: Pick<ViewIdentifier, 'legalEntityId' | 'viewVersion' | 'mutationVersion' | 'viewType'>, author?: Maybe<Pick<Contact, 'givenName' | 'familyName' | 'emailAddress' | 'avatarUrl'>>, documents: Array<(
    Pick<AdminReportPackageDocumentMetadata, 'id' | 'title' | 'documentObjectId' | 'documentObjectVersionId' | 'type' | 'status' | 'position'>
    & { reportFile?: Maybe<Pick<ReportFileMetadata, 'id' | 'fileName' | 'mimeType' | 'fileSize' | 'source'>>, generationHistory?: Maybe<Pick<ReportGenerationHistory, 'id' | 'requestId' | 'reportId' | 'versionId' | 'legalEntityId' | 'authorId' | 'viewVersion' | 'kind' | 'timezone' | 'periodStartedAt' | 'periodEndedAt' | 'generationStartedAt' | 'generationEndedAt' | 'error' | 'createdAt' | 'updatedAt'>>, validationHistories: Array<Pick<ReportValidationHistory, 'reportId' | 'versionId' | 'legalEntityId' | 'validatedAt' | 'isValid' | 'errorMessage' | 'source'>> }
  )> }
);

export type ReportValidationHistoryFieldsFragment = Pick<ReportValidationHistory, 'reportId' | 'versionId' | 'legalEntityId' | 'validatedAt' | 'isValid' | 'errorMessage' | 'source'>;

export type AdminReportPackageDocumentMetadataFieldsFragment = (
  Pick<AdminReportPackageDocumentMetadata, 'id' | 'title' | 'documentObjectId' | 'documentObjectVersionId' | 'type' | 'status' | 'position'>
  & { reportFile?: Maybe<Pick<ReportFileMetadata, 'id' | 'fileName' | 'mimeType' | 'fileSize' | 'source'>>, generationHistory?: Maybe<Pick<ReportGenerationHistory, 'id' | 'requestId' | 'reportId' | 'versionId' | 'legalEntityId' | 'authorId' | 'viewVersion' | 'kind' | 'timezone' | 'periodStartedAt' | 'periodEndedAt' | 'generationStartedAt' | 'generationEndedAt' | 'error' | 'createdAt' | 'updatedAt'>>, validationHistories: Array<Pick<ReportValidationHistory, 'reportId' | 'versionId' | 'legalEntityId' | 'validatedAt' | 'isValid' | 'errorMessage' | 'source'>> }
);

export type DeleteReportPackageMutationVariables = Exact<{
  packageId: Scalars['String']['input'];
  legalEntityId: Scalars['String']['input'];
}>;


export type DeleteReportPackageMutation = Pick<Mutation, 'deleteReportPackage'>;

export type CreateReportPackageMutationVariables = Exact<{
  viewKey: ViewKey;
  title: Scalars['String']['input'];
  periodStartAt: DateInput;
  periodEndAt: DateInput;
  interval: Interval;
  attachments: NewReportAttachments;
}>;


export type CreateReportPackageMutation = { createReportPackage: (
    Pick<ReportPackageMetadata, 'id' | 'versionId' | 'title' | 'status' | 'startedAt' | 'endedAt' | 'interval' | 'coverUrl' | 'publishedAsDraft' | 'createdAt' | 'updatedAt' | 'creationMethod'>
    & { viewKey: Pick<ViewIdentifier, 'legalEntityId' | 'viewVersion' | 'mutationVersion' | 'viewType'>, author?: Maybe<Pick<Contact, 'givenName' | 'familyName' | 'emailAddress' | 'avatarUrl'>>, documents: Array<(
      Pick<ReportPackageDocumentMetadata, 'id' | 'title' | 'documentObjectId' | 'documentObjectVersionId' | 'type' | 'status' | 'position'>
      & { reportFile?: Maybe<Pick<ReportFileMetadata, 'id' | 'fileName' | 'mimeType' | 'fileSize' | 'source'>>, generationHistory?: Maybe<Pick<ReportGenerationHistory, 'id' | 'requestId' | 'reportId' | 'versionId' | 'legalEntityId' | 'authorId' | 'viewVersion' | 'kind' | 'timezone' | 'periodStartedAt' | 'periodEndedAt' | 'generationStartedAt' | 'generationEndedAt' | 'error' | 'createdAt' | 'updatedAt'>> }
    )>, versions: Array<Pick<ReportPackageVersionMetadata, 'id' | 'status' | 'createdAt' | 'updatedAt'>> }
  ) };

export type ReadReportValidationArchiveQueryVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
  reportId: Scalars['String']['input'];
  versionId: Scalars['String']['input'];
}>;


export type ReadReportValidationArchiveQuery = { readReportValidationArchive?: Maybe<Pick<ReportValidationArchive, 'quickbooks' | 'digits' | 'avs'>> };

export type ListReportValidationHistoriesQueryVariables = Exact<{
  legalEntityIds: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;


export type ListReportValidationHistoriesQuery = { listReportValidationHistories: Array<(
    Pick<ReportLegalEntityValidationHistory, 'legalEntityId'>
    & { periodHistories: Array<{ period: Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'>, histories: Array<(
        Pick<ReportKindValidationHistory, 'kind'>
        & { history: Pick<ReportValidationHistory, 'reportId' | 'versionId' | 'legalEntityId' | 'validatedAt' | 'isValid' | 'errorMessage' | 'source'> }
      )> }> }
  )> };

export type ListReportPackagesQueryVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
  interval: Interval;
  intervalCount?: InputMaybe<Scalars['Int']['input']>;
  year: Scalars['Int']['input'];
  index: Scalars['Int']['input'];
  paginationLimit: Scalars['Int']['input'];
  paginationOffset: Scalars['Int']['input'];
}>;


export type ListReportPackagesQuery = { listReportPackages: Array<(
    Pick<AdminReportPackageMetadata, 'id' | 'versionId' | 'title' | 'status' | 'startedAt' | 'endedAt' | 'interval' | 'publishedAsDraft' | 'createdAt' | 'updatedAt'>
    & { viewKey: Pick<ViewIdentifier, 'legalEntityId' | 'viewVersion' | 'mutationVersion' | 'viewType'>, author?: Maybe<Pick<Contact, 'givenName' | 'familyName' | 'emailAddress' | 'avatarUrl'>>, documents: Array<(
      Pick<AdminReportPackageDocumentMetadata, 'id' | 'title' | 'documentObjectId' | 'documentObjectVersionId' | 'type' | 'status' | 'position'>
      & { reportFile?: Maybe<Pick<ReportFileMetadata, 'id' | 'fileName' | 'mimeType' | 'fileSize' | 'source'>>, generationHistory?: Maybe<Pick<ReportGenerationHistory, 'id' | 'requestId' | 'reportId' | 'versionId' | 'legalEntityId' | 'authorId' | 'viewVersion' | 'kind' | 'timezone' | 'periodStartedAt' | 'periodEndedAt' | 'generationStartedAt' | 'generationEndedAt' | 'error' | 'createdAt' | 'updatedAt'>>, validationHistories: Array<Pick<ReportValidationHistory, 'reportId' | 'versionId' | 'legalEntityId' | 'validatedAt' | 'isValid' | 'errorMessage' | 'source'>> }
    )> }
  )> };

export type ReadValidationHistoryQueryVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
}>;


export type ReadValidationHistoryQuery = { readValidationHistory?: Maybe<{ validationHistory: Array<(
      Pick<ValidationHealthStatus, 'balanceSheetConsistent' | 'startedAt'>
      & { cashAspectHealth: Pick<AspectDataHealth, 'importedDataStatus' | 'closedViewDataStatus' | 'overallDataStatus'>, revenueAspectHealth: Pick<AspectDataHealth, 'importedDataStatus' | 'closedViewDataStatus' | 'overallDataStatus'>, expenseAspectHealth: Pick<AspectDataHealth, 'importedDataStatus' | 'closedViewDataStatus' | 'overallDataStatus'> }
    )> }> };

export type ValidationHistoryFieldsFragment = { validationHistory: Array<(
    Pick<ValidationHealthStatus, 'balanceSheetConsistent' | 'startedAt'>
    & { cashAspectHealth: Pick<AspectDataHealth, 'importedDataStatus' | 'closedViewDataStatus' | 'overallDataStatus'>, revenueAspectHealth: Pick<AspectDataHealth, 'importedDataStatus' | 'closedViewDataStatus' | 'overallDataStatus'>, expenseAspectHealth: Pick<AspectDataHealth, 'importedDataStatus' | 'closedViewDataStatus' | 'overallDataStatus'> }
  )> };

export type ReadDataValidationQueryVariables = Exact<{
  legalEntityId: Scalars['String']['input'];
}>;


export type ReadDataValidationQuery = { readDataHealth: (
    Pick<DataHealthStatus, 'hasPlaidDataSource' | 'allDataSourcesLinked' | 'anyDataSourceRequiresEngineering' | 'allDataSourcesHaveTransactions'>
    & { validationStatus: (
      Pick<ValidationHealthStatus, 'balanceSheetConsistent' | 'startedAt'>
      & { cashAspectHealth: Pick<AspectDataHealth, 'importedDataStatus' | 'closedViewDataStatus' | 'overallDataStatus'>, revenueAspectHealth: Pick<AspectDataHealth, 'importedDataStatus' | 'closedViewDataStatus' | 'overallDataStatus'>, expenseAspectHealth: Pick<AspectDataHealth, 'importedDataStatus' | 'closedViewDataStatus' | 'overallDataStatus'> }
    ) }
  ) };

export type ValidationHealthStatusFieldsFragment = (
  Pick<ValidationHealthStatus, 'balanceSheetConsistent' | 'startedAt'>
  & { cashAspectHealth: Pick<AspectDataHealth, 'importedDataStatus' | 'closedViewDataStatus' | 'overallDataStatus'>, revenueAspectHealth: Pick<AspectDataHealth, 'importedDataStatus' | 'closedViewDataStatus' | 'overallDataStatus'>, expenseAspectHealth: Pick<AspectDataHealth, 'importedDataStatus' | 'closedViewDataStatus' | 'overallDataStatus'> }
);

export type AspectDataHealthFieldsFragment = Pick<AspectDataHealth, 'importedDataStatus' | 'closedViewDataStatus' | 'overallDataStatus'>;

export type DataHealthFieldsFragment = (
  Pick<DataHealthStatus, 'hasPlaidDataSource' | 'allDataSourcesLinked' | 'anyDataSourceRequiresEngineering' | 'allDataSourcesHaveTransactions'>
  & { validationStatus: (
    Pick<ValidationHealthStatus, 'balanceSheetConsistent' | 'startedAt'>
    & { cashAspectHealth: Pick<AspectDataHealth, 'importedDataStatus' | 'closedViewDataStatus' | 'overallDataStatus'>, revenueAspectHealth: Pick<AspectDataHealth, 'importedDataStatus' | 'closedViewDataStatus' | 'overallDataStatus'>, expenseAspectHealth: Pick<AspectDataHealth, 'importedDataStatus' | 'closedViewDataStatus' | 'overallDataStatus'> }
  ) }
);

export type UpdateEmployeeStatusMutationVariables = Exact<{
  organizationId: Scalars['String']['input'];
  employeeId: Scalars['String']['input'];
  status: EmployeeStatus;
}>;


export type UpdateEmployeeStatusMutation = { updateEmployeeStatus: (
    Pick<AdminEmployee, 'provider' | 'externalId' | 'origin' | 'createdAt'>
    & { employee: (
      Pick<Employee, 'id' | 'organizationId' | 'givenName' | 'familyName' | 'avatarUrl' | 'emailAddress' | 'jobTitle' | 'status' | 'hiddenAt'>
      & { role?: Maybe<Pick<Role, 'id'>>, user?: Maybe<(
        Pick<User, 'id' | 'givenName' | 'familyName' | 'primaryEmailAddress' | 'passwordSet' | 'avatarUrl' | 'createdAt' | 'lastActivityAt'>
        & { employments?: Maybe<Array<(
          Pick<OrganizationEmployment, 'employeeId'>
          & { role: Pick<Role, 'id'>, organization: Pick<Organization, 'id' | 'slug' | 'name' | 'iconUrl' | 'websiteUrl'> }
        )>>, identities?: Maybe<Array<Pick<Identity, 'identifier' | 'provider' | 'domain'>>> }
      )>, invitation?: Maybe<(
        Pick<Invitation, 'id' | 'invitationType' | 'status' | 'service' | 'organizationTemplateId' | 'createdAt' | 'updatedAt' | 'notifiedAt'>
        & { recipient: Pick<Contact, 'givenName' | 'familyName' | 'emailAddress' | 'avatarUrl'>, sender?: Maybe<Pick<Contact, 'givenName' | 'familyName' | 'emailAddress' | 'avatarUrl'>>, organization?: Maybe<Pick<Organization, 'id' | 'slug' | 'name' | 'iconUrl' | 'websiteUrl'>>, role?: Maybe<Pick<Role, 'id'>> }
      )> }
    ) }
  ) };

export type UpdateEmployeeHiddenMutationVariables = Exact<{
  organizationId: Scalars['String']['input'];
  employeeId: Scalars['String']['input'];
  isHidden: Scalars['Boolean']['input'];
}>;


export type UpdateEmployeeHiddenMutation = Pick<Mutation, 'updateEmployeeHidden'>;

export type AdminEmployeeFieldsFragment = (
  Pick<AdminEmployee, 'provider' | 'externalId' | 'origin' | 'createdAt'>
  & { employee: (
    Pick<Employee, 'id' | 'organizationId' | 'givenName' | 'familyName' | 'avatarUrl' | 'emailAddress' | 'jobTitle' | 'status' | 'hiddenAt'>
    & { role?: Maybe<Pick<Role, 'id'>>, user?: Maybe<(
      Pick<User, 'id' | 'givenName' | 'familyName' | 'primaryEmailAddress' | 'passwordSet' | 'avatarUrl' | 'createdAt' | 'lastActivityAt'>
      & { employments?: Maybe<Array<(
        Pick<OrganizationEmployment, 'employeeId'>
        & { role: Pick<Role, 'id'>, organization: Pick<Organization, 'id' | 'slug' | 'name' | 'iconUrl' | 'websiteUrl'> }
      )>>, identities?: Maybe<Array<Pick<Identity, 'identifier' | 'provider' | 'domain'>>> }
    )>, invitation?: Maybe<(
      Pick<Invitation, 'id' | 'invitationType' | 'status' | 'service' | 'organizationTemplateId' | 'createdAt' | 'updatedAt' | 'notifiedAt'>
      & { recipient: Pick<Contact, 'givenName' | 'familyName' | 'emailAddress' | 'avatarUrl'>, sender?: Maybe<Pick<Contact, 'givenName' | 'familyName' | 'emailAddress' | 'avatarUrl'>>, organization?: Maybe<Pick<Organization, 'id' | 'slug' | 'name' | 'iconUrl' | 'websiteUrl'>>, role?: Maybe<Pick<Role, 'id'>> }
    )> }
  ) }
);

export type ListEmployeesQueryVariables = Exact<{
  filter: EmployeeFilter;
  pagination: Pagination;
}>;


export type ListEmployeesQuery = { listEmployees: Array<(
    Pick<AdminEmployee, 'provider' | 'externalId' | 'origin' | 'createdAt'>
    & { employee: (
      Pick<Employee, 'id' | 'organizationId' | 'givenName' | 'familyName' | 'avatarUrl' | 'emailAddress' | 'jobTitle' | 'status' | 'hiddenAt'>
      & { role?: Maybe<Pick<Role, 'id'>>, user?: Maybe<(
        Pick<User, 'id' | 'givenName' | 'familyName' | 'primaryEmailAddress' | 'passwordSet' | 'avatarUrl' | 'createdAt' | 'lastActivityAt'>
        & { employments?: Maybe<Array<(
          Pick<OrganizationEmployment, 'employeeId'>
          & { role: Pick<Role, 'id'>, organization: Pick<Organization, 'id' | 'slug' | 'name' | 'iconUrl' | 'websiteUrl'> }
        )>>, identities?: Maybe<Array<Pick<Identity, 'identifier' | 'provider' | 'domain'>>> }
      )>, invitation?: Maybe<(
        Pick<Invitation, 'id' | 'invitationType' | 'status' | 'service' | 'organizationTemplateId' | 'createdAt' | 'updatedAt' | 'notifiedAt'>
        & { recipient: Pick<Contact, 'givenName' | 'familyName' | 'emailAddress' | 'avatarUrl'>, sender?: Maybe<Pick<Contact, 'givenName' | 'familyName' | 'emailAddress' | 'avatarUrl'>>, organization?: Maybe<Pick<Organization, 'id' | 'slug' | 'name' | 'iconUrl' | 'websiteUrl'>>, role?: Maybe<Pick<Role, 'id'>> }
      )> }
    ) }
  )> };

export type ResendInvitationMutationVariables = Exact<{
  token: Scalars['String']['input'];
}>;


export type ResendInvitationMutation = Pick<Mutation, 'resendInvitation'>;

export type SendManagedOperatorInvitationMutationVariables = Exact<{
  organizationId: Scalars['String']['input'];
  affiliateUserId: Scalars['String']['input'];
  affiliateOrganizationId: Scalars['String']['input'];
  recipient: ContactInput;
  legalEntityId: Scalars['String']['input'];
  message?: InputMaybe<Scalars['String']['input']>;
  redirectPath?: InputMaybe<Scalars['String']['input']>;
}>;


export type SendManagedOperatorInvitationMutation = Pick<Mutation, 'sendManagedOperatorInvitation'>;

export type CancelInvitationMutationVariables = Exact<{
  token: Scalars['String']['input'];
}>;


export type CancelInvitationMutation = Pick<Mutation, 'cancelInvitation'>;

export type SendInvitationMutationVariables = Exact<{
  givenName?: InputMaybe<Scalars['String']['input']>;
  familyName?: InputMaybe<Scalars['String']['input']>;
  emailAddress: Scalars['String']['input'];
  signupId?: InputMaybe<Scalars['String']['input']>;
  destinationOrganizationId?: InputMaybe<Scalars['String']['input']>;
  destinationOrganizationRole?: InputMaybe<Scalars['String']['input']>;
  legalEntityId?: InputMaybe<Scalars['String']['input']>;
  invitationType: Scalars['String']['input'];
  newOrganizationName?: InputMaybe<Scalars['String']['input']>;
  newOrganizationIcon?: InputMaybe<Scalars['String']['input']>;
  newOrganizationWebsiteUrl?: InputMaybe<Scalars['String']['input']>;
  proposalType?: InputMaybe<Scalars['String']['input']>;
}>;


export type SendInvitationMutation = Pick<Mutation, 'sendInvitation'>;

export type AdminInvitationFieldsFragment = { invitation: (
    Pick<Invitation, 'acceptedAt' | 'id' | 'invitationType' | 'status' | 'service' | 'organizationTemplateId' | 'createdAt' | 'updatedAt' | 'notifiedAt'>
    & { recipient: Pick<Contact, 'givenName' | 'familyName' | 'emailAddress' | 'avatarUrl'>, sender?: Maybe<Pick<Contact, 'givenName' | 'familyName' | 'emailAddress' | 'avatarUrl'>>, organization?: Maybe<Pick<Organization, 'id' | 'slug' | 'name' | 'iconUrl' | 'websiteUrl'>>, role?: Maybe<Pick<Role, 'id'>> }
  ), senderSuperuser?: Maybe<(
    Pick<Superuser, 'id' | 'givenName' | 'familyName' | 'emailAddress' | 'avatarUrl' | 'createdAt' | 'lastLoginAt'>
    & { roles: Array<Pick<SuperuserRole, 'identifier' | 'displayName' | 'description'>> }
  )>, emailStatus?: Maybe<Pick<EmailStatus, 'rawStatus' | 'updatedAt'>> };

export type ReadInvitationQueryVariables = Exact<{
  token: Scalars['String']['input'];
}>;


export type ReadInvitationQuery = { readInvitation: { invitation: (
      Pick<Invitation, 'acceptedAt' | 'id' | 'invitationType' | 'status' | 'service' | 'organizationTemplateId' | 'createdAt' | 'updatedAt' | 'notifiedAt'>
      & { recipient: Pick<Contact, 'givenName' | 'familyName' | 'emailAddress' | 'avatarUrl'>, sender?: Maybe<Pick<Contact, 'givenName' | 'familyName' | 'emailAddress' | 'avatarUrl'>>, organization?: Maybe<Pick<Organization, 'id' | 'slug' | 'name' | 'iconUrl' | 'websiteUrl'>>, role?: Maybe<Pick<Role, 'id'>> }
    ), senderSuperuser?: Maybe<(
      Pick<Superuser, 'id' | 'givenName' | 'familyName' | 'emailAddress' | 'avatarUrl' | 'createdAt' | 'lastLoginAt'>
      & { roles: Array<Pick<SuperuserRole, 'identifier' | 'displayName' | 'description'>> }
    )>, emailStatus?: Maybe<Pick<EmailStatus, 'rawStatus' | 'updatedAt'>> } };

export type ReadInvitationBySignupQueryVariables = Exact<{
  signupId: Scalars['String']['input'];
}>;


export type ReadInvitationBySignupQuery = { readInvitationBySignup: { invitation: (
      Pick<Invitation, 'acceptedAt' | 'id' | 'invitationType' | 'status' | 'service' | 'organizationTemplateId' | 'createdAt' | 'updatedAt' | 'notifiedAt'>
      & { recipient: Pick<Contact, 'givenName' | 'familyName' | 'emailAddress' | 'avatarUrl'>, sender?: Maybe<Pick<Contact, 'givenName' | 'familyName' | 'emailAddress' | 'avatarUrl'>>, organization?: Maybe<Pick<Organization, 'id' | 'slug' | 'name' | 'iconUrl' | 'websiteUrl'>>, role?: Maybe<Pick<Role, 'id'>> }
    ), senderSuperuser?: Maybe<(
      Pick<Superuser, 'id' | 'givenName' | 'familyName' | 'emailAddress' | 'avatarUrl' | 'createdAt' | 'lastLoginAt'>
      & { roles: Array<Pick<SuperuserRole, 'identifier' | 'displayName' | 'description'>> }
    )>, emailStatus?: Maybe<Pick<EmailStatus, 'rawStatus' | 'updatedAt'>> } };

export type ListInvitationsQueryVariables = Exact<{ [key: string]: never; }>;


export type ListInvitationsQuery = { listInvitations: Array<{ invitation: (
      Pick<Invitation, 'acceptedAt' | 'id' | 'invitationType' | 'status' | 'service' | 'organizationTemplateId' | 'createdAt' | 'updatedAt' | 'notifiedAt'>
      & { recipient: Pick<Contact, 'givenName' | 'familyName' | 'emailAddress' | 'avatarUrl'>, sender?: Maybe<Pick<Contact, 'givenName' | 'familyName' | 'emailAddress' | 'avatarUrl'>>, organization?: Maybe<Pick<Organization, 'id' | 'slug' | 'name' | 'iconUrl' | 'websiteUrl'>>, role?: Maybe<Pick<Role, 'id'>> }
    ), senderSuperuser?: Maybe<(
      Pick<Superuser, 'id' | 'givenName' | 'familyName' | 'emailAddress' | 'avatarUrl' | 'createdAt' | 'lastLoginAt'>
      & { roles: Array<Pick<SuperuserRole, 'identifier' | 'displayName' | 'description'>> }
    )>, emailStatus?: Maybe<Pick<EmailStatus, 'rawStatus' | 'updatedAt'>> }> };

export type TransactionFieldsFragment = (
  Pick<Transaction, 'viewId' | 'factId' | 'supersededFactIds' | 'timestamp' | 'recordType' | 'name' | 'description' | 'digitsTransactionType' | 'partySource' | 'displayEntry' | 'displayCategorySource' | 'displayCanonicalCategoryName' | 'displayUncategorizedReason' | 'displayProductArea' | 'ownerSource' | 'transactionClassificationType' | 'compileSource' | 'reconciliationStatus' | 'referenceNumber' | 'taxStatus'>
  & { party?: Maybe<Pick<TransactionParty, 'id' | 'name' | 'iconUrl' | 'iconObjectName' | 'designation' | 'roles'>>, facilitatorParty?: Maybe<Pick<TransactionParty, 'id' | 'name' | 'iconUrl' | 'iconObjectName' | 'designation' | 'roles'>>, product?: Maybe<Pick<Product, 'id' | 'name'>>, department?: Maybe<(
    { __typename: 'EntityDepartment' }
    & Pick<EntityDepartment, 'id' | 'name' | 'parentId' | 'active'>
  )>, location?: Maybe<(
    { __typename: 'EntityLocation' }
    & Pick<EntityLocation, 'id' | 'name' | 'parentId' | 'active'>
  )>, providers: Array<Pick<Provider, 'name'>>, recurrence?: Maybe<Pick<TransactionRecurrence, 'recurrenceThreadId' | 'interval' | 'intervalMultiplier' | 'threadIndex' | 'totalThreadCount' | 'isHidden' | 'firstOccurredAt' | 'lastOccurredAt'>>, displayInstitution?: Maybe<Pick<TransactionParty, 'id' | 'name' | 'iconUrl' | 'iconObjectName' | 'designation' | 'roles'>>, displayCategory: (
    Pick<Category, 'id' | 'name' | 'description' | 'displayKey' | 'type' | 'subtype' | 'parentCategoryId' | 'displayNumber' | 'active' | 'categoryState' | 'productArea' | 'synthesized'>
    & { ancestors?: Maybe<Array<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>>> }
  ), splitCategory?: Maybe<(
    Pick<Category, 'id' | 'name' | 'description' | 'displayKey' | 'type' | 'subtype' | 'parentCategoryId' | 'displayNumber' | 'active' | 'categoryState' | 'productArea' | 'synthesized'>
    & { ancestors?: Maybe<Array<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>>> }
  )>, splitInstitution?: Maybe<Pick<TransactionParty, 'id' | 'name' | 'iconUrl' | 'iconObjectName' | 'designation' | 'roles'>>, moneyFlow: (
    Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
    & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
  ), runningTotal?: Maybe<(
    Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
    & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
  )>, owner?: Maybe<Pick<EntityEmployee, 'id' | 'organizationId' | 'givenName' | 'familyName' | 'avatarUrl'>>, sources: Array<(
    Pick<TransactionSource, 'timestamp' | 'name' | 'iconUrl' | 'externalDisplayId' | 'type'>
    & { account?: Maybe<Pick<TransactionSourceAccount, 'externalName' | 'mask' | 'externalOwnerName' | 'externalOfficialName'>> }
  )>, creditSources: Array<(
    Pick<TransactionSource, 'timestamp' | 'name' | 'iconUrl' | 'externalDisplayId' | 'type'>
    & { account?: Maybe<Pick<TransactionSourceAccount, 'externalName' | 'mask' | 'externalOwnerName' | 'externalOfficialName'>> }
  )>, debitSources: Array<(
    Pick<TransactionSource, 'timestamp' | 'name' | 'iconUrl' | 'externalDisplayId' | 'type'>
    & { account?: Maybe<Pick<TransactionSourceAccount, 'externalName' | 'mask' | 'externalOwnerName' | 'externalOfficialName'>> }
  )> }
);

export type IdentityFieldsFragment = Pick<Identity, 'identifier' | 'provider' | 'domain'>;

export type InstitutionFieldsFragment = Pick<Institution, 'id' | 'name' | 'url' | 'routingNumber' | 'iconUrl' | 'partyId' | 'createdAt'>;

export type ReportGenerationHistoryFieldsFragment = Pick<ReportGenerationHistory, 'id' | 'requestId' | 'reportId' | 'versionId' | 'legalEntityId' | 'authorId' | 'viewVersion' | 'kind' | 'timezone' | 'periodStartedAt' | 'periodEndedAt' | 'generationStartedAt' | 'generationEndedAt' | 'error' | 'createdAt' | 'updatedAt'>;

export type TransactionLineFieldsFragment = (
  Pick<TransactionLine, 'entryType'>
  & { entry: (
    Pick<TransactionEntry, 'factId' | 'externalLineId' | 'description'>
    & { amount: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
  ), category: Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>, party?: Maybe<Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>> }
);

export type TransactionEntryFieldsFragment = (
  Pick<TransactionEntry, 'factId' | 'externalLineId' | 'description'>
  & { amount: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
);

export type MoneyFlowFieldsFragment = (
  Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
  & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
);

export type ConnectorFieldsFragment = (
  Pick<Connector, 'id' | 'name' | 'description' | 'category' | 'iconURL' | 'className'>
  & { connectorProperties?: Maybe<(
    { __typename: 'EmptyConnectorProperties' }
    & Pick<EmptyConnectorProperties, 'empty'>
  ) | (
    { __typename: 'GustoConnectorProperties' }
    & Pick<GustoConnectorProperties, 'authorizationURL'>
  ) | (
    { __typename: 'PlaidConnectorProperties' }
    & Pick<PlaidConnectorProperties, 'empty'>
  ) | (
    { __typename: 'RampConnectorProperties' }
    & Pick<RampConnectorProperties, 'authorizationURL'>
  ) | (
    { __typename: 'StripeConnectorProperties' }
    & Pick<StripeConnectorProperties, 'appLinkURL'>
  )> }
);

export type ReportPackageMetadataFieldsFragment = (
  Pick<ReportPackageMetadata, 'id' | 'versionId' | 'title' | 'status' | 'startedAt' | 'endedAt' | 'interval' | 'coverUrl' | 'publishedAsDraft' | 'createdAt' | 'updatedAt' | 'creationMethod'>
  & { viewKey: Pick<ViewIdentifier, 'legalEntityId' | 'viewVersion' | 'mutationVersion' | 'viewType'>, author?: Maybe<Pick<Contact, 'givenName' | 'familyName' | 'emailAddress' | 'avatarUrl'>>, documents: Array<(
    Pick<ReportPackageDocumentMetadata, 'id' | 'title' | 'documentObjectId' | 'documentObjectVersionId' | 'type' | 'status' | 'position'>
    & { reportFile?: Maybe<Pick<ReportFileMetadata, 'id' | 'fileName' | 'mimeType' | 'fileSize' | 'source'>>, generationHistory?: Maybe<Pick<ReportGenerationHistory, 'id' | 'requestId' | 'reportId' | 'versionId' | 'legalEntityId' | 'authorId' | 'viewVersion' | 'kind' | 'timezone' | 'periodStartedAt' | 'periodEndedAt' | 'generationStartedAt' | 'generationEndedAt' | 'error' | 'createdAt' | 'updatedAt'>> }
  )>, versions: Array<Pick<ReportPackageVersionMetadata, 'id' | 'status' | 'createdAt' | 'updatedAt'>> }
);

export type ReportPackageVersionMetadataFieldsFragment = Pick<ReportPackageVersionMetadata, 'id' | 'status' | 'createdAt' | 'updatedAt'>;

export type WorkItemPushFieldsFragment = Pick<WorkItemPush, 'field' | 'pushState' | 'errorType' | 'updatedAt'>;

export type PredictedDataSourceFieldsFragment = (
  Pick<PredictedDataSource, 'providerType' | 'type'>
  & { institution: Pick<Party, 'id' | 'legalEntityId' | 'name' | 'url' | 'supportUrl' | 'supportEmail' | 'supportPhone' | 'twitterUrl' | 'facebookUrl' | 'instagramUrl' | 'yelpUrl' | 'iconUrl' | 'shortDescription' | 'designation'> }
);

export type TransactionViewDetailFieldsFragment = (
  Pick<TransactionViewDetail, 'id' | 'factId' | 'occurredAt' | 'externalCreatedAt' | 'externalId' | 'externalDisplayId' | 'quickbooksAppName' | 'name' | 'description' | 'recordType'>
  & { creditAmount?: Maybe<Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>>, debitAmount?: Maybe<Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>>, creditCategoryHierarchy: Array<(
    Pick<Category, 'id' | 'name' | 'description' | 'displayKey' | 'type' | 'subtype' | 'parentCategoryId' | 'displayNumber' | 'active' | 'categoryState' | 'productArea' | 'synthesized'>
    & { ancestors?: Maybe<Array<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>>> }
  )>, debitCategoryHierarchy: Array<(
    Pick<Category, 'id' | 'name' | 'description' | 'displayKey' | 'type' | 'subtype' | 'parentCategoryId' | 'displayNumber' | 'active' | 'categoryState' | 'productArea' | 'synthesized'>
    & { ancestors?: Maybe<Array<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>>> }
  )>, entryLines: Array<(
    Pick<TransactionEntryLine, 'lineId' | 'name' | 'description' | 'isCredit' | 'highlighted'>
    & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, category: (
      Pick<Category, 'id' | 'name' | 'description' | 'displayKey' | 'type' | 'subtype' | 'parentCategoryId' | 'displayNumber' | 'active' | 'categoryState' | 'productArea' | 'synthesized'>
      & { ancestors?: Maybe<Array<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>>> }
    ) }
  )>, party?: Maybe<Pick<TransactionParty, 'id' | 'name' | 'iconUrl' | 'iconObjectName' | 'designation' | 'roles'>>, institution: Pick<Institution, 'id' | 'name' | 'url' | 'routingNumber' | 'iconUrl' | 'partyId' | 'createdAt'>, sourceAccount?: Maybe<Pick<SourceAccount, 'id' | 'externalId' | 'externalName' | 'externalOwnerName' | 'externalOfficialName' | 'externalType' | 'externalSubtype' | 'mask' | 'status' | 'ownerEmployeeId'>> }
);

export type WorkItemGeneratedQuestionFieldsFragment = Pick<WorkItemGeneratedQuestion, 'question' | 'contextType' | 'active'>;

export type ThreadFieldsFragment = (
  { __typename: 'Thread' }
  & Pick<Thread, 'id'>
  & { details: (
    Pick<ThreadDetails, 'id' | 'commentCount' | 'resolvedAt' | 'authorId' | 'commenterIds' | 'context' | 'pending'>
    & { targetObject: Pick<ObjectIdentifier, 'legalEntityId' | 'id' | 'kind'>, tags: Array<Pick<ObjectIdentifier, 'legalEntityId' | 'id' | 'kind'>> }
  ), comments: Array<(
    { __typename: 'Comment' }
    & Pick<Comment, 'id' | 'authorId' | 'text' | 'timestamp' | 'editedAt' | 'deletedAt' | 'reactions'>
    & { layoutAttachment?: Maybe<Pick<LayoutAttachment, 'layoutId' | 'legalEntityId'>> }
  )> }
);

export type LegalEntityFieldsFragment = (
  Pick<LegalEntity, 'id' | 'slug' | 'name' | 'websiteUrl' | 'industry' | 'timezone' | 'fiscalYearStartMonth' | 'fiscalYearNumberOffset' | 'status' | 'organizationId' | 'sensitive'>
  & { address: Pick<Address, 'street' | 'streetAdditional' | 'city' | 'region' | 'postalCode' | 'countryCode' | 'lat' | 'long'>, incorporatedOn?: Maybe<Pick<Date, 'year' | 'month' | 'day'>> }
);

export type ProductInstitutionFieldsFragment = (
  Pick<ProductInstitution, 'id' | 'name' | 'products' | 'routingNumber'>
  & { party?: Maybe<Pick<Party, 'id' | 'legalEntityId' | 'name' | 'url' | 'supportUrl' | 'supportEmail' | 'supportPhone' | 'twitterUrl' | 'facebookUrl' | 'instagramUrl' | 'yelpUrl' | 'iconUrl' | 'shortDescription' | 'designation'>> }
);

export type DepartmentFieldsFragment = (
  Pick<Department, 'id' | 'name' | 'parentId' | 'active'>
  & { ancestors: Array<(
    { __typename: 'EntityDepartment' }
    & Pick<EntityDepartment, 'id' | 'name' | 'parentId' | 'active'>
  )> }
);

export type WorkItemSummaryFieldsFragment = (
  Pick<WorkItemSummary, 'count'>
  & { period: Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'> }
);

export type InvitationProposalFieldsFragment = (
  Pick<InvitationProposal, 'proposalId' | 'proposalType' | 'status' | 'invitationId' | 'organizationTemplateId' | 'createdAt'>
  & { legalEntity?: Maybe<Pick<EntityLegalEntity, 'id' | 'name' | 'slug'>>, organization?: Maybe<Pick<EntityOrganization, 'id' | 'name' | 'slug' | 'iconUrl'>>, sender?: Maybe<Pick<EntityUser, 'id' | 'givenName' | 'familyName' | 'emailAddress' | 'avatarUrl'>>, recipient?: Maybe<Pick<Contact, 'givenName' | 'familyName' | 'emailAddress' | 'avatarUrl'>> }
);

export type EmployeeFieldsFragment = (
  Pick<Employee, 'id' | 'organizationId' | 'givenName' | 'familyName' | 'avatarUrl' | 'emailAddress' | 'jobTitle' | 'status' | 'hiddenAt'>
  & { role?: Maybe<Pick<Role, 'id'>>, user?: Maybe<(
    Pick<User, 'id' | 'givenName' | 'familyName' | 'primaryEmailAddress' | 'passwordSet' | 'avatarUrl' | 'createdAt' | 'lastActivityAt'>
    & { employments?: Maybe<Array<(
      Pick<OrganizationEmployment, 'employeeId'>
      & { role: Pick<Role, 'id'>, organization: Pick<Organization, 'id' | 'slug' | 'name' | 'iconUrl' | 'websiteUrl'> }
    )>>, identities?: Maybe<Array<Pick<Identity, 'identifier' | 'provider' | 'domain'>>> }
  )>, invitation?: Maybe<(
    Pick<Invitation, 'id' | 'invitationType' | 'status' | 'service' | 'organizationTemplateId' | 'createdAt' | 'updatedAt' | 'notifiedAt'>
    & { recipient: Pick<Contact, 'givenName' | 'familyName' | 'emailAddress' | 'avatarUrl'>, sender?: Maybe<Pick<Contact, 'givenName' | 'familyName' | 'emailAddress' | 'avatarUrl'>>, organization?: Maybe<Pick<Organization, 'id' | 'slug' | 'name' | 'iconUrl' | 'websiteUrl'>>, role?: Maybe<Pick<Role, 'id'>> }
  )> }
);

export type SourceAccountFieldsFragment = Pick<SourceAccount, 'id' | 'externalId' | 'externalName' | 'externalOwnerName' | 'externalOfficialName' | 'externalType' | 'externalSubtype' | 'mask' | 'status' | 'ownerEmployeeId'>;

export type DocumentInitialSegmentFieldsFragment = Pick<DocumentInitialSegment, 'collectionId' | 'documentId' | 'downloadUrl'>;

export type ThreadsFieldsFragment = { threads: Array<{ targetObject: Pick<ObjectIdentifier, 'legalEntityId' | 'id' | 'kind'>, threads: Array<(
      Pick<ThreadDetails, 'id' | 'commentCount' | 'resolvedAt' | 'authorId' | 'commenterIds' | 'context' | 'pending'>
      & { targetObject: Pick<ObjectIdentifier, 'legalEntityId' | 'id' | 'kind'>, tags: Array<Pick<ObjectIdentifier, 'legalEntityId' | 'id' | 'kind'>> }
    )> }>, entities?: Maybe<(
    { __typename: 'ObjectEntities' }
    & { users?: Maybe<Array<Pick<EntityUser, 'id' | 'givenName' | 'familyName' | 'emailAddress' | 'avatarUrl'>>>, transactions?: Maybe<Array<(
      Pick<EntityTransaction, 'viewId' | 'factId' | 'timestamp' | 'description' | 'externalId' | 'displayEntry'>
      & { moneyFlow: (
        Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
        & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
      ), party?: Maybe<Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>>, displayCategory: Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>, splitCategory?: Maybe<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>> }
    )>>, comments?: Maybe<Array<Pick<EntityComment, 'id' | 'authorId' | 'text' | 'timestamp'>>>, reports?: Maybe<Array<(
      Pick<EntityReport, 'id' | 'title' | 'authorId' | 'mimeType' | 'createdAt'>
      & { reportFor: Pick<Date, 'year' | 'month' | 'day'> }
    )>>, reportPackages?: Maybe<Array<(
      Pick<EntityReportPackage, 'id' | 'title' | 'authorId' | 'interval' | 'periodStartedAt' | 'periodEndedAt' | 'createdAt' | 'updatedAt' | 'coverUrl'>
      & { documents: Array<Pick<SharedReportDocumentMetadata, 'id' | 'documentObjectId' | 'documentObjectVersionId' | 'title' | 'mimeType'>> }
    )>>, parties?: Maybe<Array<Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>>>, datasources?: Maybe<Array<Pick<EntityDataSource, 'id' | 'name' | 'url' | 'iconUrl'>>>, categories?: Maybe<Array<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>>>, threads?: Maybe<Array<Pick<EntityThread, 'id' | 'resolvedAt'>>>, legalEntities?: Maybe<Array<Pick<EntityLegalEntity, 'id' | 'name' | 'slug'>>>, workItems?: Maybe<Array<(
      Pick<EntityWorkItem, 'id' | 'time' | 'workItemState' | 'workItemType' | 'reviewCreatedById' | 'reviewState' | 'ledgerType'>
      & { objectTransaction: (
        Pick<EntityTransaction, 'viewId' | 'factId' | 'timestamp' | 'description' | 'externalId' | 'displayEntry'>
        & { moneyFlow: (
          Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
          & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
        ), party?: Maybe<Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>>, displayCategory: Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>, splitCategory?: Maybe<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>> }
      ) }
    )>>, invoices?: Maybe<Array<(
      Pick<EntityInvoice, 'id' | 'externalDisplayId' | 'createdAt' | 'dueAt'>
      & { amount: Pick<EntityMonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, customer: (
        Pick<EntityInvoiceCustomer, 'name' | 'phone' | 'email'>
        & { party: Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'> }
      ) }
    )>>, bills?: Maybe<Array<(
      Pick<EntityBill, 'id' | 'billStatus' | 'vendorName' | 'billDescription'>
      & { totalAmount: Pick<EntityMonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, dueDate?: Maybe<Pick<Date, 'year' | 'month' | 'day'>>, format: (
        Pick<Format, 'id' | 'formatClass'>
        & { segments: Array<Pick<Segment, 'collectionId' | 'fileId' | 'sequence' | 'token'>> }
      ), approvalAccess?: Maybe<Pick<BillAccess, 'objectId' | 'objectKind' | 'role'>> }
    )>>, identityVerifications?: Maybe<Array<Pick<EntityIdentityVerification, 'legalEntityId' | 'status'>>>, departments?: Maybe<Array<(
      { __typename: 'EntityDepartment' }
      & Pick<EntityDepartment, 'id' | 'name' | 'parentId' | 'active'>
    )>>, locations?: Maybe<Array<(
      { __typename: 'EntityLocation' }
      & Pick<EntityLocation, 'id' | 'name' | 'parentId' | 'active'>
    )>> }
  )> };

export type SignupFieldsFragment = (
  Pick<Signup, 'id' | 'status' | 'statusReason' | 'location' | 'industry' | 'companyName' | 'companyUrl' | 'companyLedger' | 'companyCurrency' | 'positionTitle' | 'profileURL' | 'oAuthEmailAddress' | 'oAuthService' | 'persona'>
  & { contact: Pick<Contact, 'givenName' | 'familyName' | 'emailAddress' | 'avatarUrl'> }
);

export type ThreadWithEntitiesFieldsFragment = (
  { __typename: 'ThreadWithEntities' }
  & { thread: (
    { __typename: 'Thread' }
    & Pick<Thread, 'id'>
    & { details: (
      Pick<ThreadDetails, 'id' | 'commentCount' | 'resolvedAt' | 'authorId' | 'commenterIds' | 'context' | 'pending'>
      & { targetObject: Pick<ObjectIdentifier, 'legalEntityId' | 'id' | 'kind'>, tags: Array<Pick<ObjectIdentifier, 'legalEntityId' | 'id' | 'kind'>> }
    ), comments: Array<(
      { __typename: 'Comment' }
      & Pick<Comment, 'id' | 'authorId' | 'text' | 'timestamp' | 'editedAt' | 'deletedAt' | 'reactions'>
      & { layoutAttachment?: Maybe<Pick<LayoutAttachment, 'layoutId' | 'legalEntityId'>> }
    )> }
  ), entities?: Maybe<(
    { __typename: 'ObjectEntities' }
    & { users?: Maybe<Array<Pick<EntityUser, 'id' | 'givenName' | 'familyName' | 'emailAddress' | 'avatarUrl'>>>, transactions?: Maybe<Array<(
      Pick<EntityTransaction, 'viewId' | 'factId' | 'timestamp' | 'description' | 'externalId' | 'displayEntry'>
      & { moneyFlow: (
        Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
        & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
      ), party?: Maybe<Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>>, displayCategory: Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>, splitCategory?: Maybe<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>> }
    )>>, comments?: Maybe<Array<Pick<EntityComment, 'id' | 'authorId' | 'text' | 'timestamp'>>>, reports?: Maybe<Array<(
      Pick<EntityReport, 'id' | 'title' | 'authorId' | 'mimeType' | 'createdAt'>
      & { reportFor: Pick<Date, 'year' | 'month' | 'day'> }
    )>>, reportPackages?: Maybe<Array<(
      Pick<EntityReportPackage, 'id' | 'title' | 'authorId' | 'interval' | 'periodStartedAt' | 'periodEndedAt' | 'createdAt' | 'updatedAt' | 'coverUrl'>
      & { documents: Array<Pick<SharedReportDocumentMetadata, 'id' | 'documentObjectId' | 'documentObjectVersionId' | 'title' | 'mimeType'>> }
    )>>, parties?: Maybe<Array<Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>>>, datasources?: Maybe<Array<Pick<EntityDataSource, 'id' | 'name' | 'url' | 'iconUrl'>>>, categories?: Maybe<Array<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>>>, threads?: Maybe<Array<Pick<EntityThread, 'id' | 'resolvedAt'>>>, legalEntities?: Maybe<Array<Pick<EntityLegalEntity, 'id' | 'name' | 'slug'>>>, workItems?: Maybe<Array<(
      Pick<EntityWorkItem, 'id' | 'time' | 'workItemState' | 'workItemType' | 'reviewCreatedById' | 'reviewState' | 'ledgerType'>
      & { objectTransaction: (
        Pick<EntityTransaction, 'viewId' | 'factId' | 'timestamp' | 'description' | 'externalId' | 'displayEntry'>
        & { moneyFlow: (
          Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
          & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
        ), party?: Maybe<Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>>, displayCategory: Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>, splitCategory?: Maybe<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>> }
      ) }
    )>>, invoices?: Maybe<Array<(
      Pick<EntityInvoice, 'id' | 'externalDisplayId' | 'createdAt' | 'dueAt'>
      & { amount: Pick<EntityMonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, customer: (
        Pick<EntityInvoiceCustomer, 'name' | 'phone' | 'email'>
        & { party: Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'> }
      ) }
    )>>, bills?: Maybe<Array<(
      Pick<EntityBill, 'id' | 'billStatus' | 'vendorName' | 'billDescription'>
      & { totalAmount: Pick<EntityMonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, dueDate?: Maybe<Pick<Date, 'year' | 'month' | 'day'>>, format: (
        Pick<Format, 'id' | 'formatClass'>
        & { segments: Array<Pick<Segment, 'collectionId' | 'fileId' | 'sequence' | 'token'>> }
      ), approvalAccess?: Maybe<Pick<BillAccess, 'objectId' | 'objectKind' | 'role'>> }
    )>>, identityVerifications?: Maybe<Array<Pick<EntityIdentityVerification, 'legalEntityId' | 'status'>>>, departments?: Maybe<Array<(
      { __typename: 'EntityDepartment' }
      & Pick<EntityDepartment, 'id' | 'name' | 'parentId' | 'active'>
    )>>, locations?: Maybe<Array<(
      { __typename: 'EntityLocation' }
      & Pick<EntityLocation, 'id' | 'name' | 'parentId' | 'active'>
    )>> }
  )> }
);

export type InvitationFieldsFragment = (
  Pick<Invitation, 'id' | 'invitationType' | 'status' | 'service' | 'organizationTemplateId' | 'createdAt' | 'updatedAt' | 'notifiedAt'>
  & { recipient: Pick<Contact, 'givenName' | 'familyName' | 'emailAddress' | 'avatarUrl'>, sender?: Maybe<Pick<Contact, 'givenName' | 'familyName' | 'emailAddress' | 'avatarUrl'>>, organization?: Maybe<Pick<Organization, 'id' | 'slug' | 'name' | 'iconUrl' | 'websiteUrl'>>, role?: Maybe<Pick<Role, 'id'>> }
);

export type BooksClosedDateFieldsFragment = (
  Pick<BooksClosedDate, 'legalEntityId' | 'latestBooksClosedDateFact' | 'viewBooksClosedDate'>
  & { user?: Maybe<Pick<EntityUser, 'id' | 'givenName' | 'familyName' | 'emailAddress' | 'avatarUrl'>> }
);

export type LegalEntityUserInfoFieldsFragment = { legalEntity: (
    Pick<LegalEntity, 'id' | 'slug' | 'name' | 'websiteUrl' | 'industry' | 'timezone' | 'fiscalYearStartMonth' | 'fiscalYearNumberOffset' | 'status' | 'organizationId' | 'sensitive'>
    & { address: Pick<Address, 'street' | 'streetAdditional' | 'city' | 'region' | 'postalCode' | 'countryCode' | 'lat' | 'long'>, incorporatedOn?: Maybe<Pick<Date, 'year' | 'month' | 'day'>> }
  ), userInfo: Pick<UserInfo, 'givenName' | 'familyName' | 'emailAddress'> };

export type CategoryFieldsFragment = (
  Pick<Category, 'id' | 'name' | 'description' | 'displayKey' | 'type' | 'subtype' | 'parentCategoryId' | 'displayNumber' | 'active' | 'categoryState' | 'productArea' | 'synthesized'>
  & { ancestors?: Maybe<Array<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>>> }
);

export type ChartOfAccountsFieldsFragment = (
  Pick<ChartOfAccounts, 'id' | 'name' | 'description' | 'accountingBasis'>
  & { categories: Array<(
    Pick<Category, 'id' | 'name' | 'description' | 'displayKey' | 'type' | 'subtype' | 'parentCategoryId' | 'displayNumber' | 'active' | 'categoryState' | 'productArea' | 'synthesized'>
    & { ancestors?: Maybe<Array<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>>> }
  )> }
);

export type OwnedSourceAccountFieldsFragment = { sourceAccount: Pick<SourceAccount, 'id' | 'externalId' | 'externalName' | 'externalOwnerName' | 'externalOfficialName' | 'externalType' | 'externalSubtype' | 'mask' | 'status' | 'ownerEmployeeId'>, owner?: Maybe<(
    Pick<Employee, 'id' | 'organizationId' | 'givenName' | 'familyName' | 'avatarUrl' | 'emailAddress' | 'jobTitle' | 'status' | 'hiddenAt'>
    & { role?: Maybe<Pick<Role, 'id'>>, user?: Maybe<(
      Pick<User, 'id' | 'givenName' | 'familyName' | 'primaryEmailAddress' | 'passwordSet' | 'avatarUrl' | 'createdAt' | 'lastActivityAt'>
      & { employments?: Maybe<Array<(
        Pick<OrganizationEmployment, 'employeeId'>
        & { role: Pick<Role, 'id'>, organization: Pick<Organization, 'id' | 'slug' | 'name' | 'iconUrl' | 'websiteUrl'> }
      )>>, identities?: Maybe<Array<Pick<Identity, 'identifier' | 'provider' | 'domain'>>> }
    )>, invitation?: Maybe<(
      Pick<Invitation, 'id' | 'invitationType' | 'status' | 'service' | 'organizationTemplateId' | 'createdAt' | 'updatedAt' | 'notifiedAt'>
      & { recipient: Pick<Contact, 'givenName' | 'familyName' | 'emailAddress' | 'avatarUrl'>, sender?: Maybe<Pick<Contact, 'givenName' | 'familyName' | 'emailAddress' | 'avatarUrl'>>, organization?: Maybe<Pick<Organization, 'id' | 'slug' | 'name' | 'iconUrl' | 'websiteUrl'>>, role?: Maybe<Pick<Role, 'id'>> }
    )> }
  )> };

export type HighlightedTokenFieldsFragment = Pick<HighlightedToken, 'token' | 'score'>;

export type BooksClosedFactFieldsFragment = Pick<BooksClosedFact, 'legalEntityId' | 'dataSourceId' | 'userId' | 'booksClosedDate' | 'createdAt'>;

export type OrganizationPreviewFieldsFragment = Pick<OrganizationPreview, 'id' | 'slug' | 'name' | 'iconUrl'>;

export type TransactionSummaryFieldsFragment = (
  Pick<TransactionSummary, 'isPeriodBookClosed'>
  & { period: Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'>, total: (
    Pick<PeriodMonetaryValue, 'transactionsCount' | 'deltaPrevious' | 'deltaYearAgo' | 'isFirstOccurrencePeriod'>
    & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, moneyFlow?: Maybe<(
      Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
      & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
    )>, current?: Maybe<(
      Pick<SummaryTotal, 'count'>
      & { total: (
        Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
        & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
      ) }
    )>, prior?: Maybe<(
      Pick<SummaryTotal, 'count'>
      & { total: (
        Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
        & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
      ) }
    )> }
  ) }
);

export type DataSourceFieldsFragment = (
  Pick<DataSource, 'id' | 'legalEntityId' | 'status' | 'statusReason' | 'providerType' | 'accountAlias' | 'createdAt' | 'updatedAt'>
  & { institution: Pick<Institution, 'id' | 'name' | 'url' | 'routingNumber' | 'iconUrl' | 'partyId' | 'createdAt'> }
);

export type EnrichedDataSourceFieldsFragment = (
  Pick<EnrichedDataSource, 'id' | 'legalEntityId' | 'status' | 'statusReason' | 'providerType' | 'accountAlias' | 'createdAt' | 'updatedAt' | 'lastActivityAt' | 'hasTransactions'>
  & { institution: Pick<Party, 'id' | 'legalEntityId' | 'name' | 'url' | 'supportUrl' | 'supportEmail' | 'supportPhone' | 'twitterUrl' | 'facebookUrl' | 'instagramUrl' | 'yelpUrl' | 'iconUrl' | 'shortDescription' | 'designation'>, quickBooksConfig?: Maybe<Pick<QuickBooksConfig, 'id' | 'realmId' | 'createdAt' | 'updatedAt'>>, plaidConfig?: Maybe<Pick<PlaidConfig, 'id' | 'itemId' | 'externalInstitutionId' | 'createdAt' | 'updatedAt'>>, sourceAccounts?: Maybe<Array<Pick<SourceAccount, 'id' | 'externalId' | 'externalName' | 'externalOwnerName' | 'externalOfficialName' | 'externalType' | 'externalSubtype' | 'mask' | 'status' | 'ownerEmployeeId'>>> }
);

export type QuickBooksConfigFieldsFragment = Pick<QuickBooksConfig, 'id' | 'realmId' | 'createdAt' | 'updatedAt'>;

export type PlaidConfigFieldsFragment = Pick<PlaidConfig, 'id' | 'itemId' | 'externalInstitutionId' | 'createdAt' | 'updatedAt'>;

export type RefreshProgressFieldsFragment = Pick<RefreshProgress, 'mostRecentFetchStartTime' | 'progressPercentage'>;

export type EmployeeAffiliationsFieldsFragment = { employee: (
    Pick<Employee, 'id' | 'organizationId' | 'givenName' | 'familyName' | 'avatarUrl' | 'emailAddress' | 'jobTitle' | 'status' | 'hiddenAt'>
    & { role?: Maybe<Pick<Role, 'id'>>, user?: Maybe<(
      Pick<User, 'id' | 'givenName' | 'familyName' | 'primaryEmailAddress' | 'passwordSet' | 'avatarUrl' | 'createdAt' | 'lastActivityAt'>
      & { employments?: Maybe<Array<(
        Pick<OrganizationEmployment, 'employeeId'>
        & { role: Pick<Role, 'id'>, organization: Pick<Organization, 'id' | 'slug' | 'name' | 'iconUrl' | 'websiteUrl'> }
      )>>, identities?: Maybe<Array<Pick<Identity, 'identifier' | 'provider' | 'domain'>>> }
    )>, invitation?: Maybe<(
      Pick<Invitation, 'id' | 'invitationType' | 'status' | 'service' | 'organizationTemplateId' | 'createdAt' | 'updatedAt' | 'notifiedAt'>
      & { recipient: Pick<Contact, 'givenName' | 'familyName' | 'emailAddress' | 'avatarUrl'>, sender?: Maybe<Pick<Contact, 'givenName' | 'familyName' | 'emailAddress' | 'avatarUrl'>>, organization?: Maybe<Pick<Organization, 'id' | 'slug' | 'name' | 'iconUrl' | 'websiteUrl'>>, role?: Maybe<Pick<Role, 'id'>> }
    )> }
  ), affiliations: Array<{ organization: Pick<EntityOrganization, 'id' | 'name' | 'slug' | 'iconUrl'>, legalEntity: Pick<EntityLegalEntity, 'id' | 'name' | 'slug'>, role: Pick<Role, 'id'> }> };

export type ViewIdentifierFieldsFragment = Pick<ViewIdentifier, 'legalEntityId' | 'viewVersion' | 'mutationVersion' | 'viewType'>;

export type MonetaryValueFieldsFragment = Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>;

export type ProductFieldsFragment = Pick<Product, 'id' | 'name'>;

export type OrganizationFieldsFragment = Pick<Organization, 'id' | 'slug' | 'name' | 'iconUrl' | 'websiteUrl'>;

export type LocationFieldsFragment = (
  Pick<Location, 'id' | 'name' | 'parentId' | 'active'>
  & { ancestors: Array<(
    { __typename: 'EntityLocation' }
    & Pick<EntityLocation, 'id' | 'name' | 'parentId' | 'active'>
  )> }
);

export type TransactionPartyFieldsFragment = Pick<TransactionParty, 'id' | 'name' | 'iconUrl' | 'iconObjectName' | 'designation' | 'roles'>;

export type UserInfoFieldsFragment = Pick<UserInfo, 'givenName' | 'familyName' | 'emailAddress'>;

export type WorkItemFieldsFragment = (
  Pick<WorkItem, 'legalEntityId' | 'workItemId' | 'time' | 'itemType' | 'itemState' | 'ledgerType' | 'createdAt' | 'updatedBy' | 'updatedAt' | 'actionable' | 'reviewState'>
  & { transactions: Array<(
    Pick<WorkItemTransaction, 'externalTransactionId' | 'externalTransactionLineId' | 'supportType' | 'displayPartyRole' | 'displayEntry' | 'description' | 'name' | 'occurredAt' | 'dataSourceId' | 'quickbooksAppName' | 'ledgerTransactionId'>
    & { displayCategory?: Maybe<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>>, splitCategory?: Maybe<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>>, party?: Maybe<Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>>, creditValue?: Maybe<Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>>, debitValue?: Maybe<Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>>, displayValue?: Maybe<Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>>, moneyFlow?: Maybe<(
      Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
      & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
    )> }
  )>, review?: Maybe<(
    Pick<WorkItemReview, 'createdAt' | 'updatedAt' | 'subject'>
    & { creator: Pick<EntityUser, 'id' | 'givenName' | 'familyName' | 'emailAddress' | 'avatarUrl'> }
  )>, generatedQuestions: Array<Pick<WorkItemGeneratedQuestion, 'question' | 'contextType' | 'active'>>, pushes: Array<Pick<WorkItemPush, 'field' | 'pushState' | 'errorType' | 'updatedAt'>> }
);

export type AddressFieldsFragment = Pick<Address, 'street' | 'streetAdditional' | 'city' | 'region' | 'postalCode' | 'countryCode' | 'lat' | 'long'>;

export type InsightFieldsFragment = (
  Pick<Insight, 'score' | 'globalScore' | 'subjectType' | 'subjectId' | 'subjectDisplayKey' | 'comparisonIntervalOffset' | 'ruleType' | 'sentence' | 'subjectIconUrl' | 'designation' | 'type' | 'productArea'>
  & { periodValue?: Maybe<Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>>, absoluteDelta?: Maybe<Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>>, comparisonPeriodValue?: Maybe<Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>> }
);

export type UserFieldsFragment = (
  Pick<User, 'id' | 'givenName' | 'familyName' | 'primaryEmailAddress' | 'passwordSet' | 'avatarUrl' | 'createdAt' | 'lastActivityAt'>
  & { employments?: Maybe<Array<(
    Pick<OrganizationEmployment, 'employeeId'>
    & { role: Pick<Role, 'id'>, organization: Pick<Organization, 'id' | 'slug' | 'name' | 'iconUrl' | 'websiteUrl'> }
  )>>, identities?: Maybe<Array<Pick<Identity, 'identifier' | 'provider' | 'domain'>>> }
);

export type WorkItemReviewFieldsFragment = (
  Pick<WorkItemReview, 'createdAt' | 'updatedAt' | 'subject'>
  & { creator: Pick<EntityUser, 'id' | 'givenName' | 'familyName' | 'emailAddress' | 'avatarUrl'> }
);

export type ThreadDetailsFieldsFragment = (
  Pick<ThreadDetails, 'id' | 'commentCount' | 'resolvedAt' | 'authorId' | 'commenterIds' | 'context' | 'pending'>
  & { targetObject: Pick<ObjectIdentifier, 'legalEntityId' | 'id' | 'kind'>, tags: Array<Pick<ObjectIdentifier, 'legalEntityId' | 'id' | 'kind'>> }
);

export type PartyFieldsFragment = Pick<Party, 'id' | 'legalEntityId' | 'name' | 'url' | 'supportUrl' | 'supportEmail' | 'supportPhone' | 'twitterUrl' | 'facebookUrl' | 'instagramUrl' | 'yelpUrl' | 'iconUrl' | 'shortDescription' | 'designation'>;

export type DateFieldsFragment = Pick<Date, 'year' | 'month' | 'day'>;

export type ArchiveIntervalOriginFieldsFragment = Pick<ArchiveIntervalOrigin, 'interval' | 'year' | 'index' | 'intervalCount'>;

export type ThreadListFieldsFragment = { targetObject: Pick<ObjectIdentifier, 'legalEntityId' | 'id' | 'kind'>, threads: Array<(
    Pick<ThreadDetails, 'id' | 'commentCount' | 'resolvedAt' | 'authorId' | 'commenterIds' | 'context' | 'pending'>
    & { targetObject: Pick<ObjectIdentifier, 'legalEntityId' | 'id' | 'kind'>, tags: Array<Pick<ObjectIdentifier, 'legalEntityId' | 'id' | 'kind'>> }
  )> };

export type PeriodFieldsFragment = Pick<Period, 'name' | 'interval' | 'startedAt' | 'endedAt'>;

export type WorkItemTransactionFieldsFragment = (
  Pick<WorkItemTransaction, 'externalTransactionId' | 'externalTransactionLineId' | 'supportType' | 'displayPartyRole' | 'displayEntry' | 'description' | 'name' | 'occurredAt' | 'dataSourceId' | 'quickbooksAppName' | 'ledgerTransactionId'>
  & { displayCategory?: Maybe<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>>, splitCategory?: Maybe<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>>, party?: Maybe<Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>>, creditValue?: Maybe<Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>>, debitValue?: Maybe<Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>>, displayValue?: Maybe<Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>>, moneyFlow?: Maybe<(
    Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
    & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
  )> }
);

export type ReportPackageDocumentMetadataFieldsFragment = (
  Pick<ReportPackageDocumentMetadata, 'id' | 'title' | 'documentObjectId' | 'documentObjectVersionId' | 'type' | 'status' | 'position'>
  & { reportFile?: Maybe<Pick<ReportFileMetadata, 'id' | 'fileName' | 'mimeType' | 'fileSize' | 'source'>>, generationHistory?: Maybe<Pick<ReportGenerationHistory, 'id' | 'requestId' | 'reportId' | 'versionId' | 'legalEntityId' | 'authorId' | 'viewVersion' | 'kind' | 'timezone' | 'periodStartedAt' | 'periodEndedAt' | 'generationStartedAt' | 'generationEndedAt' | 'error' | 'createdAt' | 'updatedAt'>> }
);

export type PeriodMonetaryValueFieldsFragment = (
  Pick<PeriodMonetaryValue, 'transactionsCount' | 'deltaPrevious' | 'deltaYearAgo' | 'isFirstOccurrencePeriod'>
  & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, moneyFlow?: Maybe<(
    Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
    & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
  )>, current?: Maybe<(
    Pick<SummaryTotal, 'count'>
    & { total: (
      Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
      & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
    ) }
  )>, prior?: Maybe<(
    Pick<SummaryTotal, 'count'>
    & { total: (
      Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
      & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
    ) }
  )> }
);

export type CommentFieldsFragment = (
  { __typename: 'Comment' }
  & Pick<Comment, 'id' | 'authorId' | 'text' | 'timestamp' | 'editedAt' | 'deletedAt' | 'reactions'>
  & { layoutAttachment?: Maybe<Pick<LayoutAttachment, 'layoutId' | 'legalEntityId'>> }
);

export type TransactionViewDetailsFieldsFragment = { details: Array<(
    Pick<TransactionViewDetail, 'id' | 'factId' | 'occurredAt' | 'externalCreatedAt' | 'externalId' | 'externalDisplayId' | 'quickbooksAppName' | 'name' | 'description' | 'recordType'>
    & { creditAmount?: Maybe<Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>>, debitAmount?: Maybe<Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>>, creditCategoryHierarchy: Array<(
      Pick<Category, 'id' | 'name' | 'description' | 'displayKey' | 'type' | 'subtype' | 'parentCategoryId' | 'displayNumber' | 'active' | 'categoryState' | 'productArea' | 'synthesized'>
      & { ancestors?: Maybe<Array<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>>> }
    )>, debitCategoryHierarchy: Array<(
      Pick<Category, 'id' | 'name' | 'description' | 'displayKey' | 'type' | 'subtype' | 'parentCategoryId' | 'displayNumber' | 'active' | 'categoryState' | 'productArea' | 'synthesized'>
      & { ancestors?: Maybe<Array<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>>> }
    )>, entryLines: Array<(
      Pick<TransactionEntryLine, 'lineId' | 'name' | 'description' | 'isCredit' | 'highlighted'>
      & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, category: (
        Pick<Category, 'id' | 'name' | 'description' | 'displayKey' | 'type' | 'subtype' | 'parentCategoryId' | 'displayNumber' | 'active' | 'categoryState' | 'productArea' | 'synthesized'>
        & { ancestors?: Maybe<Array<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>>> }
      ) }
    )>, party?: Maybe<Pick<TransactionParty, 'id' | 'name' | 'iconUrl' | 'iconObjectName' | 'designation' | 'roles'>>, institution: Pick<Institution, 'id' | 'name' | 'url' | 'routingNumber' | 'iconUrl' | 'partyId' | 'createdAt'>, sourceAccount?: Maybe<Pick<SourceAccount, 'id' | 'externalId' | 'externalName' | 'externalOwnerName' | 'externalOfficialName' | 'externalType' | 'externalSubtype' | 'mask' | 'status' | 'ownerEmployeeId'>> }
  )> };

export type SummaryTotalFieldsFragment = (
  Pick<SummaryTotal, 'count'>
  & { total: (
    Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
    & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
  ) }
);

export type HighlightedTokensFieldsFragment = { description?: Maybe<Array<Pick<HighlightedToken, 'token' | 'score'>>>, name?: Maybe<Array<Pick<HighlightedToken, 'token' | 'score'>>> };

export type ContactFieldsFragment = Pick<Contact, 'givenName' | 'familyName' | 'emailAddress' | 'avatarUrl'>;

export type TransactionEntryLineFieldsFragment = (
  Pick<TransactionEntryLine, 'lineId' | 'name' | 'description' | 'isCredit' | 'highlighted'>
  & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, category: (
    Pick<Category, 'id' | 'name' | 'description' | 'displayKey' | 'type' | 'subtype' | 'parentCategoryId' | 'displayNumber' | 'active' | 'categoryState' | 'productArea' | 'synthesized'>
    & { ancestors?: Maybe<Array<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>>> }
  ) }
);

export type EntityLedgerTransactionFieldsFragment = Pick<EntityLedgerTransaction, 'id' | 'timestamp' | 'factIds'>;

export type EntityOrganizationFieldsFragment = Pick<EntityOrganization, 'id' | 'name' | 'slug' | 'iconUrl'>;

export type EntityReportFieldsFragment = (
  Pick<EntityReport, 'id' | 'title' | 'authorId' | 'mimeType' | 'createdAt'>
  & { reportFor: Pick<Date, 'year' | 'month' | 'day'> }
);

export type EntityTransactionFieldsFragment = (
  Pick<EntityTransaction, 'viewId' | 'factId' | 'timestamp' | 'description' | 'externalId' | 'displayEntry'>
  & { moneyFlow: (
    Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
    & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
  ), party?: Maybe<Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>>, displayCategory: Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>, splitCategory?: Maybe<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>> }
);

export type EntityLocationFieldsFragment = (
  { __typename: 'EntityLocation' }
  & Pick<EntityLocation, 'id' | 'name' | 'parentId' | 'active'>
);

export type EntityPartyFieldsFragment = Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>;

export type EntityInvoiceFieldsFragment = (
  Pick<EntityInvoice, 'id' | 'externalDisplayId' | 'createdAt' | 'dueAt'>
  & { amount: Pick<EntityMonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, customer: (
    Pick<EntityInvoiceCustomer, 'name' | 'phone' | 'email'>
    & { party: Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'> }
  ) }
);

export type EntityWorkItemFieldsFragment = (
  Pick<EntityWorkItem, 'id' | 'time' | 'workItemState' | 'workItemType' | 'reviewCreatedById' | 'reviewState' | 'ledgerType'>
  & { objectTransaction: (
    Pick<EntityTransaction, 'viewId' | 'factId' | 'timestamp' | 'description' | 'externalId' | 'displayEntry'>
    & { moneyFlow: (
      Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
      & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
    ), party?: Maybe<Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>>, displayCategory: Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>, splitCategory?: Maybe<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>> }
  ) }
);

export type EntityMonetaryValueFieldsFragment = Pick<EntityMonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>;

export type EntityLegalEntityFieldsFragment = Pick<EntityLegalEntity, 'id' | 'name' | 'slug'>;

export type BillAccessFieldsFragment = Pick<BillAccess, 'objectId' | 'objectKind' | 'role'>;

export type EntityDepartmentFieldsFragment = (
  { __typename: 'EntityDepartment' }
  & Pick<EntityDepartment, 'id' | 'name' | 'parentId' | 'active'>
);

export type EntityReportPackageFieldsFragment = (
  Pick<EntityReportPackage, 'id' | 'title' | 'authorId' | 'interval' | 'periodStartedAt' | 'periodEndedAt' | 'createdAt' | 'updatedAt' | 'coverUrl'>
  & { documents: Array<Pick<SharedReportDocumentMetadata, 'id' | 'documentObjectId' | 'documentObjectVersionId' | 'title' | 'mimeType'>> }
);

export type SharedReportDocumentMetadataFieldsFragment = Pick<SharedReportDocumentMetadata, 'id' | 'documentObjectId' | 'documentObjectVersionId' | 'title' | 'mimeType'>;

export type EntityUserFieldsFragment = Pick<EntityUser, 'id' | 'givenName' | 'familyName' | 'emailAddress' | 'avatarUrl'>;

export type ObjectIdentifierFieldsFragment = Pick<ObjectIdentifier, 'legalEntityId' | 'id' | 'kind'>;

export type ActivityObjectIdentifierFieldsFragment = Pick<ActivityObjectIdentifier, 'kind' | 'id' | 'context'>;

export type EntityInvoiceCustomerFieldsFragment = (
  Pick<EntityInvoiceCustomer, 'name' | 'phone' | 'email'>
  & { party: Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'> }
);

export type EntityEmployeeFieldsFragment = Pick<EntityEmployee, 'id' | 'organizationId' | 'givenName' | 'familyName' | 'avatarUrl'>;

export type EntityCommentFieldsFragment = Pick<EntityComment, 'id' | 'authorId' | 'text' | 'timestamp'>;

export type EntityIdentityVerificationFieldsFragment = Pick<EntityIdentityVerification, 'legalEntityId' | 'status'>;

export type SegmentFieldsFragment = Pick<Segment, 'collectionId' | 'fileId' | 'sequence' | 'token'>;

export type EntityThreadFieldsFragment = Pick<EntityThread, 'id' | 'resolvedAt'>;

export type EntityCategoryFieldsFragment = Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>;

export type EntityBillFieldsFragment = (
  Pick<EntityBill, 'id' | 'billStatus' | 'vendorName' | 'billDescription'>
  & { totalAmount: Pick<EntityMonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, dueDate?: Maybe<Pick<Date, 'year' | 'month' | 'day'>>, format: (
    Pick<Format, 'id' | 'formatClass'>
    & { segments: Array<Pick<Segment, 'collectionId' | 'fileId' | 'sequence' | 'token'>> }
  ), approvalAccess?: Maybe<Pick<BillAccess, 'objectId' | 'objectKind' | 'role'>> }
);

export type EntityDataSourceFieldsFragment = Pick<EntityDataSource, 'id' | 'name' | 'url' | 'iconUrl'>;

export type ObjectEntitiesFieldsFragment = (
  { __typename: 'ObjectEntities' }
  & { users?: Maybe<Array<Pick<EntityUser, 'id' | 'givenName' | 'familyName' | 'emailAddress' | 'avatarUrl'>>>, transactions?: Maybe<Array<(
    Pick<EntityTransaction, 'viewId' | 'factId' | 'timestamp' | 'description' | 'externalId' | 'displayEntry'>
    & { moneyFlow: (
      Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
      & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
    ), party?: Maybe<Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>>, displayCategory: Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>, splitCategory?: Maybe<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>> }
  )>>, comments?: Maybe<Array<Pick<EntityComment, 'id' | 'authorId' | 'text' | 'timestamp'>>>, reports?: Maybe<Array<(
    Pick<EntityReport, 'id' | 'title' | 'authorId' | 'mimeType' | 'createdAt'>
    & { reportFor: Pick<Date, 'year' | 'month' | 'day'> }
  )>>, reportPackages?: Maybe<Array<(
    Pick<EntityReportPackage, 'id' | 'title' | 'authorId' | 'interval' | 'periodStartedAt' | 'periodEndedAt' | 'createdAt' | 'updatedAt' | 'coverUrl'>
    & { documents: Array<Pick<SharedReportDocumentMetadata, 'id' | 'documentObjectId' | 'documentObjectVersionId' | 'title' | 'mimeType'>> }
  )>>, parties?: Maybe<Array<Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>>>, datasources?: Maybe<Array<Pick<EntityDataSource, 'id' | 'name' | 'url' | 'iconUrl'>>>, categories?: Maybe<Array<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>>>, threads?: Maybe<Array<Pick<EntityThread, 'id' | 'resolvedAt'>>>, legalEntities?: Maybe<Array<Pick<EntityLegalEntity, 'id' | 'name' | 'slug'>>>, workItems?: Maybe<Array<(
    Pick<EntityWorkItem, 'id' | 'time' | 'workItemState' | 'workItemType' | 'reviewCreatedById' | 'reviewState' | 'ledgerType'>
    & { objectTransaction: (
      Pick<EntityTransaction, 'viewId' | 'factId' | 'timestamp' | 'description' | 'externalId' | 'displayEntry'>
      & { moneyFlow: (
        Pick<MoneyFlow, 'isNormal' | 'businessFlow'>
        & { value: Pick<MonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'> }
      ), party?: Maybe<Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'>>, displayCategory: Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>, splitCategory?: Maybe<Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'>> }
    ) }
  )>>, invoices?: Maybe<Array<(
    Pick<EntityInvoice, 'id' | 'externalDisplayId' | 'createdAt' | 'dueAt'>
    & { amount: Pick<EntityMonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, customer: (
      Pick<EntityInvoiceCustomer, 'name' | 'phone' | 'email'>
      & { party: Pick<EntityParty, 'id' | 'name' | 'iconUrl' | 'shortDescription' | 'roles' | 'designation'> }
    ) }
  )>>, bills?: Maybe<Array<(
    Pick<EntityBill, 'id' | 'billStatus' | 'vendorName' | 'billDescription'>
    & { totalAmount: Pick<EntityMonetaryValue, 'iso4217CurrencyCode' | 'currencyMultiplier' | 'amount'>, dueDate?: Maybe<Pick<Date, 'year' | 'month' | 'day'>>, format: (
      Pick<Format, 'id' | 'formatClass'>
      & { segments: Array<Pick<Segment, 'collectionId' | 'fileId' | 'sequence' | 'token'>> }
    ), approvalAccess?: Maybe<Pick<BillAccess, 'objectId' | 'objectKind' | 'role'>> }
  )>>, identityVerifications?: Maybe<Array<Pick<EntityIdentityVerification, 'legalEntityId' | 'status'>>>, departments?: Maybe<Array<(
    { __typename: 'EntityDepartment' }
    & Pick<EntityDepartment, 'id' | 'name' | 'parentId' | 'active'>
  )>>, locations?: Maybe<Array<(
    { __typename: 'EntityLocation' }
    & Pick<EntityLocation, 'id' | 'name' | 'parentId' | 'active'>
  )>> }
);

export type RoleFieldsFragment = Pick<Role, 'id'>;

export type ReportFileMetadataFieldsFragment = Pick<ReportFileMetadata, 'id' | 'fileName' | 'mimeType' | 'fileSize' | 'source'>;

export type DataSourcePreferenceFieldsFragment = Pick<DataSourcePreference, 'dataSourceId' | 'accountingBasis' | 'taxForm' | 'providerType' | 'departmentTerminology'>;

export type TransactionSourceFieldsFragment = (
  Pick<TransactionSource, 'timestamp' | 'name' | 'iconUrl' | 'externalDisplayId' | 'type'>
  & { account?: Maybe<Pick<TransactionSourceAccount, 'externalName' | 'mask' | 'externalOwnerName' | 'externalOfficialName'>> }
);

export type DoppelgangerPermitFieldsFragment = (
  Pick<DoppelgangerPermit, 'id' | 'expiresAt' | 'createdAt'>
  & { user: (
    Pick<User, 'id' | 'givenName' | 'familyName' | 'primaryEmailAddress' | 'passwordSet' | 'avatarUrl' | 'createdAt' | 'lastActivityAt'>
    & { employments?: Maybe<Array<(
      Pick<OrganizationEmployment, 'employeeId'>
      & { role: Pick<Role, 'id'>, organization: Pick<Organization, 'id' | 'slug' | 'name' | 'iconUrl' | 'websiteUrl'> }
    )>>, identities?: Maybe<Array<Pick<Identity, 'identifier' | 'provider' | 'domain'>>> }
  ) }
);

export type CategoryMappingFieldsFragment = (
  Pick<CategoryMapping, 'mappingValue' | 'mappingType' | 'product'>
  & { category: Pick<EntityCategory, 'id' | 'name' | 'displayKey' | 'chartOfAccountsDisplayNumber' | 'type' | 'subtype' | 'parentCategoryId' | 'productArea' | 'active'> }
);

export const JobOwnerFieldsFragmentDoc = gql`
    fragment JobOwnerFields on JobOwner {
  dataSourceId
  legalEntityId
}
    `;
export const JobMetadataFieldsFragmentDoc = gql`
    fragment JobMetadataFields on JobMetadata {
  consumer
  requestObjectId
  userId
}
    `;
export const InstitutionFieldsFragmentDoc = gql`
    fragment InstitutionFields on Institution {
  id
  name
  url
  routingNumber
  iconUrl
  partyId
  createdAt
}
    `;
export const PartyFieldsFragmentDoc = gql`
    fragment PartyFields on Party {
  id
  legalEntityId
  name
  url
  supportUrl
  supportEmail
  supportPhone
  twitterUrl
  facebookUrl
  instagramUrl
  yelpUrl
  iconUrl
  shortDescription
  designation
}
    `;
export const AdminInstitutionFieldsFragmentDoc = gql`
    fragment AdminInstitutionFields on AdminInstitution {
  id
  institution {
    ...InstitutionFields
  }
  party {
    ...PartyFields
  }
  products
}
    ${InstitutionFieldsFragmentDoc}
${PartyFieldsFragmentDoc}`;
export const PullRequestFieldsFragmentDoc = gql`
    fragment PullRequestFields on PullRequest {
  url
  title
  repo
  number
  branch
  prState
  labels {
    name
    hexColor
  }
  author {
    id
    name
    login
    avatarUrl
  }
}
    `;
export const SanitizedDescriptionFieldsFragmentDoc = gql`
    fragment SanitizedDescriptionFields on SanitizedDescription {
  sanitizedDescription
  transactionCount
  exampleTransactionNameAndDescription
}
    `;
export const ClusteredPrefixFieldsFragmentDoc = gql`
    fragment ClusteredPrefixFields on ClusteredPrefix {
  prefix
  transactionCount
}
    `;
export const PrefixMappingFieldsFragmentDoc = gql`
    fragment PrefixMappingFields on PrefixMapping {
  prefix
  partyId
}
    `;
export const PrefixMappingSummaryFieldsFragmentDoc = gql`
    fragment PrefixMappingSummaryFields on PrefixMappingSummary {
  prefix
  transactionCount
  party {
    ...PartyFields
  }
}
    ${PartyFieldsFragmentDoc}`;
export const DenormalizedViewStatusFieldsFragmentDoc = gql`
    fragment DenormalizedViewStatusFields on DenormalizedViewStatus {
  legalEntityId
  viewType
  activeViewCreatedAt
  ongoingViewStartedAt
}
    `;
export const ObjectMappingHierarchyNodeFieldsFragmentDoc = gql`
    fragment ObjectMappingHierarchyNodeFields on ObjectMappingHierarchyNode {
  factId
  name
  parentId
  active
  transactionCount
  mappedTo
  depth
  fullyQualifiedName
}
    `;
export const EntityCategoryFieldsFragmentDoc = gql`
    fragment EntityCategoryFields on EntityCategory {
  id
  name
  displayKey
  chartOfAccountsDisplayNumber
  type
  subtype
  parentCategoryId
  productArea
  active
}
    `;
export const CategoryFieldsFragmentDoc = gql`
    fragment CategoryFields on Category {
  id
  name
  description
  displayKey
  type
  subtype
  parentCategoryId
  displayNumber
  active
  categoryState
  productArea
  synthesized
  ancestors {
    ...EntityCategoryFields
  }
}
    ${EntityCategoryFieldsFragmentDoc}`;
export const ChartOfAccountsFieldsFragmentDoc = gql`
    fragment ChartOfAccountsFields on ChartOfAccounts {
  id
  name
  description
  accountingBasis
  categories {
    ...CategoryFields
  }
}
    ${CategoryFieldsFragmentDoc}`;
export const AdminChartOfAccountsFieldsFragmentDoc = gql`
    fragment AdminChartOfAccountsFields on AdminChartOfAccounts {
  chart {
    ...ChartOfAccountsFields
  }
}
    ${ChartOfAccountsFieldsFragmentDoc}`;
export const RoleFieldsFragmentDoc = gql`
    fragment RoleFields on Role {
  id
}
    `;
export const OrganizationFieldsFragmentDoc = gql`
    fragment OrganizationFields on Organization {
  id
  slug
  name
  iconUrl
  websiteUrl
}
    `;
export const IdentityFieldsFragmentDoc = gql`
    fragment IdentityFields on Identity {
  identifier
  provider
  domain
}
    `;
export const UserFieldsFragmentDoc = gql`
    fragment UserFields on User {
  id
  givenName
  familyName
  primaryEmailAddress
  passwordSet
  avatarUrl
  createdAt
  lastActivityAt
  employments {
    employeeId
    role {
      ...RoleFields
    }
    organization {
      ...OrganizationFields
    }
  }
  identities {
    ...IdentityFields
  }
}
    ${RoleFieldsFragmentDoc}
${OrganizationFieldsFragmentDoc}
${IdentityFieldsFragmentDoc}`;
export const DoppelgangerPermitFieldsFragmentDoc = gql`
    fragment DoppelgangerPermitFields on DoppelgangerPermit {
  id
  user {
    ...UserFields
  }
  expiresAt
  createdAt
}
    ${UserFieldsFragmentDoc}`;
export const SuperuserRoleFieldsFragmentDoc = gql`
    fragment SuperuserRoleFields on SuperuserRole {
  identifier
  displayName
  description
}
    `;
export const SuperuserFieldsFragmentDoc = gql`
    fragment SuperuserFields on Superuser {
  id
  givenName
  familyName
  emailAddress
  avatarUrl
  createdAt
  lastLoginAt
  roles {
    ...SuperuserRoleFields
  }
}
    ${SuperuserRoleFieldsFragmentDoc}`;
export const AdminDoppelgangerPermitFieldsFragmentDoc = gql`
    fragment AdminDoppelgangerPermitFields on AdminDoppelgangerPermit {
  doppelgangerPermit {
    ...DoppelgangerPermitFields
  }
  authorizer {
    ...SuperuserFields
  }
  justification
  sessionStartedAt
  sessionEndedAt
}
    ${DoppelgangerPermitFieldsFragmentDoc}
${SuperuserFieldsFragmentDoc}`;
export const QuickBooksConfigFieldsFragmentDoc = gql`
    fragment QuickBooksConfigFields on QuickBooksConfig {
  id
  realmId
  createdAt
  updatedAt
}
    `;
export const PlaidConfigFieldsFragmentDoc = gql`
    fragment PlaidConfigFields on PlaidConfig {
  id
  itemId
  externalInstitutionId
  createdAt
  updatedAt
}
    `;
export const SourceAccountFieldsFragmentDoc = gql`
    fragment SourceAccountFields on SourceAccount {
  id
  externalId
  externalName
  externalOwnerName
  externalOfficialName
  externalType
  externalSubtype
  mask
  status
  ownerEmployeeId
}
    `;
export const EnrichedDataSourceFieldsFragmentDoc = gql`
    fragment EnrichedDataSourceFields on EnrichedDataSource {
  id
  legalEntityId
  status
  statusReason
  institution {
    ...PartyFields
  }
  quickBooksConfig {
    ...QuickBooksConfigFields
  }
  plaidConfig {
    ...PlaidConfigFields
  }
  providerType
  accountAlias
  createdAt
  updatedAt
  lastActivityAt
  sourceAccounts {
    ...SourceAccountFields
  }
  hasTransactions
}
    ${PartyFieldsFragmentDoc}
${QuickBooksConfigFieldsFragmentDoc}
${PlaidConfigFieldsFragmentDoc}
${SourceAccountFieldsFragmentDoc}`;
export const AdminSourceAccountFieldsFragmentDoc = gql`
    fragment AdminSourceAccountFields on SourceAccount {
  id
  externalId
  externalName
  externalOwnerName
  externalOfficialName
  externalType
  externalSubtype
  mask
  dataSource {
    ...EnrichedDataSourceFields
  }
}
    ${EnrichedDataSourceFieldsFragmentDoc}`;
export const AdminCategoryFieldsFragmentDoc = gql`
    fragment AdminCategoryFields on AdminCategory {
  category {
    ...CategoryFields
  }
  externalId
  subtype
  chartOfAccountsDisplayNumber
  externalAccountType
  externalAccountSubtype
  synthesized
}
    ${CategoryFieldsFragmentDoc}`;
export const MappedCategoryFieldsFragmentDoc = gql`
    fragment MappedCategoryFields on MappedCategory {
  fromCategory {
    ...AdminCategoryFields
  }
  toCategory {
    ...AdminCategoryFields
  }
}
    ${AdminCategoryFieldsFragmentDoc}`;
export const MappedChartOfAccountsFieldsFragmentDoc = gql`
    fragment MappedChartOfAccountsFields on MappedChartOfAccounts {
  fromUUID
  toUUID
  name
  description
  legalEntityId
  accountingBasis
  categories {
    ...MappedCategoryFields
  }
}
    ${MappedCategoryFieldsFragmentDoc}`;
export const AddressFieldsFragmentDoc = gql`
    fragment AddressFields on Address {
  street
  streetAdditional
  city
  region
  postalCode
  countryCode
  lat
  long
}
    `;
export const LegalEntityFieldsFragmentDoc = gql`
    fragment LegalEntityFields on LegalEntity {
  id
  slug
  name
  websiteUrl
  industry
  timezone
  fiscalYearStartMonth
  fiscalYearNumberOffset
  status
  organizationId
  address {
    ...AddressFields
  }
  incorporatedOn {
    year
    month
    day
  }
  sensitive
}
    ${AddressFieldsFragmentDoc}`;
export const AspectDataHealthFieldsFragmentDoc = gql`
    fragment AspectDataHealthFields on AspectDataHealth {
  importedDataStatus
  closedViewDataStatus
  overallDataStatus
}
    `;
export const ValidationHealthStatusFieldsFragmentDoc = gql`
    fragment ValidationHealthStatusFields on ValidationHealthStatus {
  cashAspectHealth {
    ...AspectDataHealthFields
  }
  revenueAspectHealth {
    ...AspectDataHealthFields
  }
  expenseAspectHealth {
    ...AspectDataHealthFields
  }
  balanceSheetConsistent
  startedAt
}
    ${AspectDataHealthFieldsFragmentDoc}`;
export const PeriodFieldsFragmentDoc = gql`
    fragment PeriodFields on Period {
  name
  interval
  startedAt
  endedAt
}
    `;
export const ReportValidationHistoryFieldsFragmentDoc = gql`
    fragment ReportValidationHistoryFields on ReportValidationHistory {
  reportId
  versionId
  legalEntityId
  validatedAt
  isValid
  errorMessage
  source
}
    `;
export const ReportKindValidationPeriodHistoryFieldsFragmentDoc = gql`
    fragment ReportKindValidationPeriodHistoryFields on ReportKindValidationPeriodHistory {
  period {
    ...PeriodFields
  }
  histories {
    kind
    history {
      ...ReportValidationHistoryFields
    }
  }
}
    ${PeriodFieldsFragmentDoc}
${ReportValidationHistoryFieldsFragmentDoc}`;
export const AdminLegalEntityFieldsFragmentDoc = gql`
    fragment AdminLegalEntityFields on AdminLegalEntity {
  legalEntity {
    ...LegalEntityFields
  }
  createdAt
  userCount
  validationStatus {
    ...ValidationHealthStatusFields
  }
  reportValidationHistory {
    ...ReportKindValidationPeriodHistoryFields
  }
  approvalEligibilityStatuses
  hasPlaidDataSource
  allDataSourcesLinked
  anyDataSourceRequiresEngineering
  allDataSourcesHaveTransactions
  deletedAt
}
    ${LegalEntityFieldsFragmentDoc}
${ValidationHealthStatusFieldsFragmentDoc}
${ReportKindValidationPeriodHistoryFieldsFragmentDoc}`;
export const AdminOrganizationFieldsFragmentDoc = gql`
    fragment AdminOrganizationFields on AdminOrganization {
  organization {
    ...OrganizationFields
  }
  createdAt
  deletedAt
  userCount
  legalEntities {
    ...AdminLegalEntityFields
  }
  affiliateLegalEntities {
    ...AdminLegalEntityFields
  }
}
    ${OrganizationFieldsFragmentDoc}
${AdminLegalEntityFieldsFragmentDoc}`;
export const ChartOfAccountsListEntryFieldsFragmentDoc = gql`
    fragment ChartOfAccountsListEntryFields on ChartOfAccountsListEntry {
  id
  name
  description
  accountingBasis
  legalEntityId
}
    `;
export const MonetaryValueFieldsFragmentDoc = gql`
    fragment MonetaryValueFields on MonetaryValue {
  iso4217CurrencyCode
  currencyMultiplier
  amount
}
    `;
export const AdminVanityStatsFieldsFragmentDoc = gql`
    fragment AdminVanityStatsFields on AdminVanityStats {
  transactionsCount
  usersCount
  partiesCount
  categoriesCount
  totalValue {
    ...MonetaryValueFields
  }
}
    ${MonetaryValueFieldsFragmentDoc}`;
export const AdminGlobalVanityStatsFieldsFragmentDoc = gql`
    fragment AdminGlobalVanityStatsFields on AdminGlobalVanityStats {
  totalSignupsCount
  totalSignupsLast30Count
  qualifiedSignupsCount
  averageSignupQualifiedAt
  primedSignupsCount
  averageSignupPrimedAt
  invitedSignupsCount
  averageSignupInvitedAt
  appliedSignupsCount
  averageSignupAppliedAt
  approvedSignupsLast30Count
  lastSignupApprovedAt
  totalUsersCount
  activeUsersCount
  totalLegalEntitiesCount
  activeLegalEntitiesCount
  pendingLegalEntitiesCount
  totalPartiesCount
  globalPartiesCount
  globalPartiesLast30Count
  linkedPartiesCount
  unlinkedPartiesCount
  unlinkedRepeatPartiesCount
  hydratableUnlinkedRepeatPartiesCount
  hydratableUnlinkedPartiesCount
  totalTransactionsCount
  totalTransactionsValue {
    ...MonetaryValueFields
  }
  globalPartyTransactionsCount
  unlinkedPartiesLast30Count
  dailyUnlinkedPartiesLast30
  dailyDesignationsCreatedLast30Count
  totalUnmoderatedDesignationsCount
  dailyDesignationsModeratedLast30
  linkedPartiesLast30Count
  globalPartiesViaDesignationLast30Count
  totalHydrationsProcessedCount
  dailyHydrationsCreatedLast30
  totalUnmoderatedHydrationsCount
  dailyHydrationsModeratedLast30
  legalEntitiesWithValidQuickBooksCount
  legalEntitiesWithDataIssuesCount
  activeLegalEntitiesWithDataIssuesCount
  legalEntitiesWithQuickBooksConnectionIssuesCount
  activeLegalEntitiesWithQuickBooksConnectionIssuesCount
  activeLegalEntitiesWithPlaidConnectionIssuesCount
  legalEntitiesWithEngineeringRequiredCount
  supersedingRate
  dayOfInterest
  reportValidations {
    period {
      ...PeriodFields
    }
    packageValidCount
    packageInvalidCount
    byKind {
      kind
      validCount
      invalidCount
    }
  }
}
    ${MonetaryValueFieldsFragmentDoc}
${PeriodFieldsFragmentDoc}`;
export const AdminTransactionFactFieldsFragmentDoc = gql`
    fragment AdminTransactionFactFields on AdminTransactionFact {
  transactionFactId
  legalEntityId
  dataSource {
    ...EnrichedDataSourceFields
  }
  sourceAccount {
    ...SourceAccountFields
  }
  externalId
  externalName
  externalDescription
  pending
  transactionType
  externalTimestamp
  externalCreatedAt
  externalModifiedAt
  createdAt
  updatedAt
}
    ${EnrichedDataSourceFieldsFragmentDoc}
${SourceAccountFieldsFragmentDoc}`;
export const AdminClassificationFieldsFragmentDoc = gql`
    fragment AdminClassificationFields on AdminClassification {
  version
  legalEntityId
  rule
  createdAt
  updatedAt
}
    `;
export const AdminTransactionEntityExtractionFieldsFragmentDoc = gql`
    fragment AdminTransactionEntityExtractionFields on AdminTransactionEntityExtraction {
  classificationVersionId
  party
  partyScore
  phone
  phoneScore
  location
  locationScore
  proxy
  proxyScore
  person
  personScore
  url
  urlScore
  institution
  institutionScore
  date
  dateScore
  creditCard
  creditCardScore
  updatedAt
}
    `;
export const AdminUserFieldsFragmentDoc = gql`
    fragment AdminUserFields on AdminUser {
  user {
    ...UserFields
  }
  timezone
  invitationId
  employments {
    role {
      id
    }
    organization {
      ...OrganizationFields
    }
    activatedAt
    status
    employeeId
  }
  affiliations {
    role {
      id
    }
    userOrganization {
      ...OrganizationFields
    }
    affiliatedTo {
      ...LegalEntityFields
    }
  }
  accountLocked
}
    ${UserFieldsFragmentDoc}
${OrganizationFieldsFragmentDoc}
${LegalEntityFieldsFragmentDoc}`;
export const ContactFieldsFragmentDoc = gql`
    fragment ContactFields on Contact {
  givenName
  familyName
  emailAddress
  avatarUrl
}
    `;
export const SignupFieldsFragmentDoc = gql`
    fragment SignupFields on Signup {
  id
  contact {
    ...ContactFields
  }
  status
  statusReason
  location
  industry
  companyName
  companyUrl
  companyLedger
  companyCurrency
  positionTitle
  profileURL
  oAuthEmailAddress
  oAuthService
  persona
}
    ${ContactFieldsFragmentDoc}`;
export const AdminSignupFieldsFragmentDoc = gql`
    fragment AdminSignupFields on AdminSignup {
  signup {
    ...SignupFields
  }
  createdAt
  updatedAt
  timezone
}
    ${SignupFieldsFragmentDoc}`;
export const PayrollProviderFieldsFragmentDoc = gql`
    fragment PayrollProviderFields on PayrollProvider {
  legalEntityId
  partyId
  partyName
  payrollProviderType
  rangeStartedAt
  rangeEndedAt
}
    `;
export const AdminGlobalPartyFieldsFragmentDoc = gql`
    fragment AdminGlobalPartyFields on AdminGlobalParty {
  party {
    ...PartyFields
  }
  transactionsCount
  aliases
  prefixes
  lastReviewedAt
}
    ${PartyFieldsFragmentDoc}`;
export const AdminPartyFieldsFragmentDoc = gql`
    fragment AdminPartyFields on AdminParty {
  party {
    ...PartyFields
  }
  transactionsCount
  lastTransactionOccurredAt
}
    ${PartyFieldsFragmentDoc}`;
export const SourceRecordedMatchSummaryEntryFieldsFragmentDoc = gql`
    fragment SourceRecordedMatchSummaryEntryFields on SourceRecordedMatchSummaryEntry {
  period {
    ...PeriodFields
  }
  institution {
    name
    url
    iconUrl
  }
  total
  matched
  unmatched
  unmatchedAndUncategorized
}
    ${PeriodFieldsFragmentDoc}`;
export const SourceRecordedMatchSummaryFieldsFragmentDoc = gql`
    fragment SourceRecordedMatchSummaryFields on SourceRecordedMatchSummary {
  entries {
    ...SourceRecordedMatchSummaryEntryFields
  }
}
    ${SourceRecordedMatchSummaryEntryFieldsFragmentDoc}`;
export const SummarizeDataSourceFetchesFieldsFragmentDoc = gql`
    fragment SummarizeDataSourceFetchesFields on DataSourceFetchStats {
  summaries {
    institutionName
    success
    statTimeBuckets {
      name
      total
      bucketOne
      bucketTwo
      bucketThree
      bucketFour
      bucketTimeMins
    }
  }
}
    `;
export const MismatchedQuickbooksCategoryFieldsFragmentDoc = gql`
    fragment MismatchedQuickbooksCategoryFields on MismatchedQuickbooksCategory {
  period {
    ...PeriodFields
  }
  categoryName
  categoryType
  externalValue
  digitsValue
  difference
}
    ${PeriodFieldsFragmentDoc}`;
export const MismatchedQuickbooksBalanceSheetCategoryFieldsFragmentDoc = gql`
    fragment MismatchedQuickbooksBalanceSheetCategoryFields on MismatchedQuickbooksBalanceSheetCategory {
  period {
    ...PeriodFields
  }
  categoryName
  categoryType
  externalStartingBalance
  externalEndingBalance
  digitsStartingBalance
  digitsEndingBalance
  startingDelta
  endingDelta
}
    ${PeriodFieldsFragmentDoc}`;
export const UnsupportedTransactionFieldsFragmentDoc = gql`
    fragment UnsupportedTransactionFields on UnsupportedTransaction {
  fetchId
  fetchType
  providerType
  accountAlias
  dataType
  transactionCount
  reason
}
    `;
export const UnsupportedTransactionsFieldsFragmentDoc = gql`
    fragment UnsupportedTransactionsFields on UnsupportedTransactions {
  onboardUnsupportedTransactions {
    ...UnsupportedTransactionFields
  }
  otherUnsupportedTransactions {
    ...UnsupportedTransactionFields
  }
}
    ${UnsupportedTransactionFieldsFragmentDoc}`;
export const ClassificationValidationSummaryFieldsFragmentDoc = gql`
    fragment ClassificationValidationSummaryFields on ClassificationValidationSummary {
  passingValidations
  totalValidations
  numberOfLegalEntities
}
    `;
export const SummarizeViewGenerationFieldsFragmentDoc = gql`
    fragment SummarizeViewGenerationFields on ViewGenerationSummary {
  bucketOne
  bucketTwo
  bucketThree
  bucketFour
  bucketTimeMins
}
    `;
export const ValidationPeriodSummaryFieldsFragmentDoc = gql`
    fragment ValidationPeriodSummaryFields on ValidationPeriodSummary {
  period {
    ...PeriodFields
  }
  matchedCategories
  mismatchedCategories
  noExternalValueMismatchedCategories
}
    ${PeriodFieldsFragmentDoc}`;
export const ValidationSummaryFieldsFragmentDoc = gql`
    fragment ValidationSummaryFields on ValidationSummary {
  periodSummaries {
    ...ValidationPeriodSummaryFields
  }
}
    ${ValidationPeriodSummaryFieldsFragmentDoc}`;
export const ClassificationValidationExpectationFactFieldsFragmentDoc = gql`
    fragment ClassificationValidationExpectationFactFields on ClassificationValidationExpectationFact {
  transactionFactId
  dataSourceId
}
    `;
export const ClassificationValidationFieldsFragmentDoc = gql`
    fragment ClassificationValidationFields on ClassificationValidation {
  legalEntityId
  facts {
    ...ClassificationValidationExpectationFactFields
  }
  partyName
  partyRole
  partyIconUrl
  debitCategoryName
  creditCategoryName
  employeeOwnerName
  expectationId
  status
}
    ${ClassificationValidationExpectationFactFieldsFragmentDoc}`;
export const ClassificationValidationFailureDetailFieldsFragmentDoc = gql`
    fragment ClassificationValidationFailureDetailFields on ClassificationValidationFailureDetail {
  expectationType
  expectedValue
  actualValue
}
    `;
export const ClassificationValidationFailureFieldsFragmentDoc = gql`
    fragment ClassificationValidationFailureFields on ClassificationValidationFailure {
  expectation {
    ...ClassificationValidationFields
  }
  failureDetails {
    ...ClassificationValidationFailureDetailFields
  }
}
    ${ClassificationValidationFieldsFragmentDoc}
${ClassificationValidationFailureDetailFieldsFragmentDoc}`;
export const AdminConnectionFieldsFragmentDoc = gql`
    fragment AdminConnectionFields on AdminConnection {
  id
  sourceName
  deletedAt
  legalEntityId
  dataSourceId
  createdAt
  status
  connectorClassName
  externalId
  sync {
    lastSuccessAt
    lastEventAt
    status
  }
}
    `;
export const AdminConnectionListFieldsFragmentDoc = gql`
    fragment AdminConnectionListFields on AdminConnectionList {
  connections {
    ...AdminConnectionFields
  }
}
    ${AdminConnectionFieldsFragmentDoc}`;
export const AdminConnectionEventFieldsFragmentDoc = gql`
    fragment AdminConnectionEventFields on AdminConnectionEvent {
  id
  connectionEventRef
  source
  status
  message
  failureReason
  affectedCount
  oldestSeen
  newestSeen
  dataUpdatedAt
  dataStatus
  userId
  superUserId
  createdAt
}
    `;
export const AspectFieldsFragmentDoc = gql`
    fragment AspectFields on Aspect {
  id
  code
  name
  description
  defaultStatus
  createdAt
  updatedAt
}
    `;
export const AspectStatusFieldsFragmentDoc = gql`
    fragment AspectStatusFields on AspectStatus {
  aspect {
    ...AspectFields
  }
  status
  updatedAt
}
    ${AspectFieldsFragmentDoc}`;
export const LegalEntityAspectStatusFieldsFragmentDoc = gql`
    fragment LegalEntityAspectStatusFields on LegalEntityAspectStatus {
  legalEntityId
  legalEntityName
  statuses {
    ...AspectStatusFields
  }
}
    ${AspectStatusFieldsFragmentDoc}`;
export const AspectRequestFieldsFragmentDoc = gql`
    fragment AspectRequestFields on AspectRequest {
  id
  legalEntityId
  legalEntityName
  createdAt
  aspect {
    ...AspectFields
  }
}
    ${AspectFieldsFragmentDoc}`;
export const BillingProductFieldsFragmentDoc = gql`
    fragment BillingProductFields on BillingProduct {
  id
  name
  displayName
  description
  trialPeriodDays
}
    `;
export const BillingPriceFieldsFragmentDoc = gql`
    fragment BillingPriceFields on BillingPrice {
  id
  amount {
    ...MonetaryValueFields
  }
  interval
  intervalCount
}
    ${MonetaryValueFieldsFragmentDoc}`;
export const BillingLegalEntityLimitFieldsFragmentDoc = gql`
    fragment BillingLegalEntityLimitFields on BillingLegalEntityLimit {
  id
  minLegalEntityLimit
  maxLegalEntityLimit
}
    `;
export const EntityPartyFieldsFragmentDoc = gql`
    fragment EntityPartyFields on EntityParty {
  id
  name
  iconUrl
  shortDescription
  roles
  designation
}
    `;
export const AdminWorkItemTransactionFieldsFragmentDoc = gql`
    fragment AdminWorkItemTransactionFields on AdminWorkItemTransaction {
  externalTransactionId
  externalTransactionLineId
  supportType
  creditCategory {
    ...EntityCategoryFields
  }
  debitCategory {
    ...EntityCategoryFields
  }
  description
  name
  party {
    ...EntityPartyFields
  }
  displayEntry
  displayCategory {
    ...EntityCategoryFields
  }
  occurredAt
  dataSourceId
}
    ${EntityCategoryFieldsFragmentDoc}
${EntityPartyFieldsFragmentDoc}`;
export const WorkItemGeneratedQuestionFieldsFragmentDoc = gql`
    fragment WorkItemGeneratedQuestionFields on WorkItemGeneratedQuestion {
  question
  contextType
  active
}
    `;
export const WorkItemPushFieldsFragmentDoc = gql`
    fragment WorkItemPushFields on WorkItemPush {
  field
  pushState
  errorType
  updatedAt
}
    `;
export const AdminWorkItemFieldsFragmentDoc = gql`
    fragment AdminWorkItemFields on AdminWorkItem {
  legalEntityId
  workItemId
  time
  itemType
  itemState
  ledgerType
  actionable
  createdAt
  updatedBy
  updatedAt
  transactions {
    ...AdminWorkItemTransactionFields
  }
  generatedQuestions {
    ...WorkItemGeneratedQuestionFields
  }
  pushes {
    ...WorkItemPushFields
  }
  transactionFactId
  defaultState
  userState
  transactionGcsUrl
}
    ${AdminWorkItemTransactionFieldsFragmentDoc}
${WorkItemGeneratedQuestionFieldsFragmentDoc}
${WorkItemPushFieldsFragmentDoc}`;
export const ViewIdentifierFieldsFragmentDoc = gql`
    fragment ViewIdentifierFields on ViewIdentifier {
  legalEntityId
  viewVersion
  mutationVersion
  viewType
}
    `;
export const ReportFileMetadataFieldsFragmentDoc = gql`
    fragment ReportFileMetadataFields on ReportFileMetadata {
  id
  fileName
  mimeType
  fileSize
  source
}
    `;
export const ReportGenerationHistoryFieldsFragmentDoc = gql`
    fragment ReportGenerationHistoryFields on ReportGenerationHistory {
  id
  requestId
  reportId
  versionId
  legalEntityId
  authorId
  viewVersion
  kind
  timezone
  periodStartedAt
  periodEndedAt
  generationStartedAt
  generationEndedAt
  error
  createdAt
  updatedAt
}
    `;
export const AdminReportPackageDocumentMetadataFieldsFragmentDoc = gql`
    fragment AdminReportPackageDocumentMetadataFields on AdminReportPackageDocumentMetadata {
  id
  title
  documentObjectId
  documentObjectVersionId
  type
  reportFile {
    ...ReportFileMetadataFields
  }
  status
  generationHistory {
    ...ReportGenerationHistoryFields
  }
  validationHistories {
    ...ReportValidationHistoryFields
  }
  position
}
    ${ReportFileMetadataFieldsFragmentDoc}
${ReportGenerationHistoryFieldsFragmentDoc}
${ReportValidationHistoryFieldsFragmentDoc}`;
export const AdminReportPackageMetadataFieldsFragmentDoc = gql`
    fragment AdminReportPackageMetadataFields on AdminReportPackageMetadata {
  id
  versionId
  title
  status
  viewKey {
    ...ViewIdentifierFields
  }
  author {
    ...ContactFields
  }
  documents {
    ...AdminReportPackageDocumentMetadataFields
  }
  startedAt
  endedAt
  interval
  status
  publishedAsDraft
  createdAt
  updatedAt
}
    ${ViewIdentifierFieldsFragmentDoc}
${ContactFieldsFragmentDoc}
${AdminReportPackageDocumentMetadataFieldsFragmentDoc}`;
export const ValidationHistoryFieldsFragmentDoc = gql`
    fragment ValidationHistoryFields on ValidationHistory {
  validationHistory {
    ...ValidationHealthStatusFields
  }
}
    ${ValidationHealthStatusFieldsFragmentDoc}`;
export const DataHealthFieldsFragmentDoc = gql`
    fragment DataHealthFields on DataHealthStatus {
  validationStatus {
    ...ValidationHealthStatusFields
  }
  hasPlaidDataSource
  allDataSourcesLinked
  anyDataSourceRequiresEngineering
  allDataSourcesHaveTransactions
}
    ${ValidationHealthStatusFieldsFragmentDoc}`;
export const InvitationFieldsFragmentDoc = gql`
    fragment InvitationFields on Invitation {
  id
  invitationType
  recipient {
    givenName
    familyName
    emailAddress
    avatarUrl
  }
  sender {
    givenName
    familyName
    emailAddress
    avatarUrl
  }
  organization {
    ...OrganizationFields
  }
  role {
    ...RoleFields
  }
  status
  service
  organizationTemplateId
  createdAt
  updatedAt
  notifiedAt
}
    ${OrganizationFieldsFragmentDoc}
${RoleFieldsFragmentDoc}`;
export const EmployeeFieldsFragmentDoc = gql`
    fragment EmployeeFields on Employee {
  id
  organizationId
  givenName
  familyName
  avatarUrl
  emailAddress
  jobTitle
  status
  role {
    ...RoleFields
  }
  user {
    ...UserFields
  }
  invitation {
    ...InvitationFields
  }
  hiddenAt
}
    ${RoleFieldsFragmentDoc}
${UserFieldsFragmentDoc}
${InvitationFieldsFragmentDoc}`;
export const AdminEmployeeFieldsFragmentDoc = gql`
    fragment AdminEmployeeFields on AdminEmployee {
  employee {
    ...EmployeeFields
  }
  provider
  externalId
  origin
  createdAt
}
    ${EmployeeFieldsFragmentDoc}`;
export const EmailStatusFieldsFragmentDoc = gql`
    fragment EmailStatusFields on EmailStatus {
  rawStatus
  updatedAt
}
    `;
export const AdminInvitationFieldsFragmentDoc = gql`
    fragment AdminInvitationFields on AdminInvitation {
  invitation {
    ...InvitationFields
    acceptedAt
  }
  senderSuperuser {
    ...SuperuserFields
  }
  emailStatus {
    ...EmailStatusFields
  }
}
    ${InvitationFieldsFragmentDoc}
${SuperuserFieldsFragmentDoc}
${EmailStatusFieldsFragmentDoc}`;
export const TransactionPartyFieldsFragmentDoc = gql`
    fragment TransactionPartyFields on TransactionParty {
  id
  name
  iconUrl
  iconObjectName
  designation
  roles
}
    `;
export const ProductFieldsFragmentDoc = gql`
    fragment ProductFields on Product {
  id
  name
}
    `;
export const EntityDepartmentFieldsFragmentDoc = gql`
    fragment EntityDepartmentFields on EntityDepartment {
  __typename
  id
  name
  parentId
  active
}
    `;
export const EntityLocationFieldsFragmentDoc = gql`
    fragment EntityLocationFields on EntityLocation {
  __typename
  id
  name
  parentId
  active
}
    `;
export const MoneyFlowFieldsFragmentDoc = gql`
    fragment MoneyFlowFields on MoneyFlow {
  value {
    ...MonetaryValueFields
  }
  isNormal
  businessFlow
}
    ${MonetaryValueFieldsFragmentDoc}`;
export const EntityEmployeeFieldsFragmentDoc = gql`
    fragment EntityEmployeeFields on EntityEmployee {
  id
  organizationId
  givenName
  familyName
  avatarUrl
}
    `;
export const TransactionSourceFieldsFragmentDoc = gql`
    fragment TransactionSourceFields on TransactionSource {
  timestamp
  name
  iconUrl
  externalDisplayId
  type
  account {
    externalName
    mask
    externalOwnerName
    externalOfficialName
  }
}
    `;
export const TransactionFieldsFragmentDoc = gql`
    fragment TransactionFields on Transaction {
  viewId
  factId
  supersededFactIds
  timestamp
  recordType
  name
  description
  digitsTransactionType
  party {
    ...TransactionPartyFields
  }
  partySource
  facilitatorParty {
    ...TransactionPartyFields
  }
  product {
    ...ProductFields
  }
  department {
    ...EntityDepartmentFields
  }
  location {
    ...EntityLocationFields
  }
  providers {
    name
  }
  recurrence {
    recurrenceThreadId
    interval
    intervalMultiplier
    threadIndex
    totalThreadCount
    isHidden
    firstOccurredAt
    lastOccurredAt
  }
  displayEntry
  displayInstitution {
    ...TransactionPartyFields
  }
  displayCategory {
    ...CategoryFields
  }
  displayCategorySource
  displayCanonicalCategoryName
  displayUncategorizedReason
  displayProductArea
  splitCategory {
    ...CategoryFields
  }
  splitInstitution {
    ...TransactionPartyFields
  }
  moneyFlow {
    ...MoneyFlowFields
  }
  runningTotal {
    ...MoneyFlowFields
  }
  owner {
    ...EntityEmployeeFields
  }
  ownerSource
  transactionClassificationType
  sources {
    ...TransactionSourceFields
  }
  creditSources {
    ...TransactionSourceFields
  }
  debitSources {
    ...TransactionSourceFields
  }
  compileSource
  reconciliationStatus
  referenceNumber
  taxStatus
}
    ${TransactionPartyFieldsFragmentDoc}
${ProductFieldsFragmentDoc}
${EntityDepartmentFieldsFragmentDoc}
${EntityLocationFieldsFragmentDoc}
${CategoryFieldsFragmentDoc}
${MoneyFlowFieldsFragmentDoc}
${EntityEmployeeFieldsFragmentDoc}
${TransactionSourceFieldsFragmentDoc}`;
export const TransactionEntryFieldsFragmentDoc = gql`
    fragment TransactionEntryFields on TransactionEntry {
  factId
  externalLineId
  description
  amount {
    ...MonetaryValueFields
  }
}
    ${MonetaryValueFieldsFragmentDoc}`;
export const TransactionLineFieldsFragmentDoc = gql`
    fragment TransactionLineFields on TransactionLine {
  entry {
    ...TransactionEntryFields
  }
  entryType
  category {
    ...EntityCategoryFields
  }
  party {
    ...EntityPartyFields
  }
}
    ${TransactionEntryFieldsFragmentDoc}
${EntityCategoryFieldsFragmentDoc}
${EntityPartyFieldsFragmentDoc}`;
export const ConnectorFieldsFragmentDoc = gql`
    fragment ConnectorFields on Connector {
  id
  name
  description
  category
  iconURL
  className
  connectorProperties {
    __typename
    ... on StripeConnectorProperties {
      __typename
      appLinkURL
    }
    ... on PlaidConnectorProperties {
      __typename
      empty
    }
    ... on RampConnectorProperties {
      __typename
      authorizationURL
    }
    ... on GustoConnectorProperties {
      __typename
      authorizationURL
    }
    ... on EmptyConnectorProperties {
      __typename
      empty
    }
  }
}
    `;
export const ReportPackageDocumentMetadataFieldsFragmentDoc = gql`
    fragment ReportPackageDocumentMetadataFields on ReportPackageDocumentMetadata {
  id
  title
  documentObjectId
  documentObjectVersionId
  type
  reportFile {
    ...ReportFileMetadataFields
  }
  status
  generationHistory {
    ...ReportGenerationHistoryFields
  }
  position
}
    ${ReportFileMetadataFieldsFragmentDoc}
${ReportGenerationHistoryFieldsFragmentDoc}`;
export const ReportPackageVersionMetadataFieldsFragmentDoc = gql`
    fragment ReportPackageVersionMetadataFields on ReportPackageVersionMetadata {
  id
  status
  createdAt
  updatedAt
}
    `;
export const ReportPackageMetadataFieldsFragmentDoc = gql`
    fragment ReportPackageMetadataFields on ReportPackageMetadata {
  id
  versionId
  viewKey {
    ...ViewIdentifierFields
  }
  title
  status
  author {
    ...ContactFields
  }
  documents {
    ...ReportPackageDocumentMetadataFields
  }
  startedAt
  endedAt
  interval
  coverUrl
  publishedAsDraft
  status
  createdAt
  updatedAt
  creationMethod
  versions {
    ...ReportPackageVersionMetadataFields
  }
}
    ${ViewIdentifierFieldsFragmentDoc}
${ContactFieldsFragmentDoc}
${ReportPackageDocumentMetadataFieldsFragmentDoc}
${ReportPackageVersionMetadataFieldsFragmentDoc}`;
export const PredictedDataSourceFieldsFragmentDoc = gql`
    fragment PredictedDataSourceFields on PredictedDataSource {
  institution {
    ...PartyFields
  }
  providerType
  type
}
    ${PartyFieldsFragmentDoc}`;
export const ProductInstitutionFieldsFragmentDoc = gql`
    fragment ProductInstitutionFields on ProductInstitution {
  id
  name
  party {
    ...PartyFields
  }
  products
  routingNumber
}
    ${PartyFieldsFragmentDoc}`;
export const DepartmentFieldsFragmentDoc = gql`
    fragment DepartmentFields on Department {
  id
  name
  parentId
  active
  ancestors {
    ...EntityDepartmentFields
  }
}
    ${EntityDepartmentFieldsFragmentDoc}`;
export const WorkItemSummaryFieldsFragmentDoc = gql`
    fragment WorkItemSummaryFields on WorkItemSummary {
  period {
    ...PeriodFields
  }
  count
}
    ${PeriodFieldsFragmentDoc}`;
export const EntityLegalEntityFieldsFragmentDoc = gql`
    fragment EntityLegalEntityFields on EntityLegalEntity {
  id
  name
  slug
}
    `;
export const EntityOrganizationFieldsFragmentDoc = gql`
    fragment EntityOrganizationFields on EntityOrganization {
  id
  name
  slug
  iconUrl
}
    `;
export const EntityUserFieldsFragmentDoc = gql`
    fragment EntityUserFields on EntityUser {
  id
  givenName
  familyName
  emailAddress
  avatarUrl
}
    `;
export const InvitationProposalFieldsFragmentDoc = gql`
    fragment InvitationProposalFields on InvitationProposal {
  proposalId
  proposalType
  status
  invitationId
  legalEntity {
    ...EntityLegalEntityFields
  }
  organization {
    ...EntityOrganizationFields
  }
  organizationTemplateId
  sender {
    ...EntityUserFields
  }
  createdAt
  recipient {
    ...ContactFields
  }
}
    ${EntityLegalEntityFieldsFragmentDoc}
${EntityOrganizationFieldsFragmentDoc}
${EntityUserFieldsFragmentDoc}
${ContactFieldsFragmentDoc}`;
export const DocumentInitialSegmentFieldsFragmentDoc = gql`
    fragment DocumentInitialSegmentFields on DocumentInitialSegment {
  collectionId
  documentId
  downloadUrl
}
    `;
export const ObjectIdentifierFieldsFragmentDoc = gql`
    fragment ObjectIdentifierFields on ObjectIdentifier {
  legalEntityId
  id
  kind
}
    `;
export const ThreadDetailsFieldsFragmentDoc = gql`
    fragment ThreadDetailsFields on ThreadDetails {
  id
  commentCount
  resolvedAt
  targetObject {
    ...ObjectIdentifierFields
  }
  authorId
  commenterIds
  tags {
    ...ObjectIdentifierFields
  }
  context
  pending @client
}
    ${ObjectIdentifierFieldsFragmentDoc}`;
export const ThreadListFieldsFragmentDoc = gql`
    fragment ThreadListFields on ThreadList {
  targetObject {
    ...ObjectIdentifierFields
  }
  threads {
    ...ThreadDetailsFields
  }
}
    ${ObjectIdentifierFieldsFragmentDoc}
${ThreadDetailsFieldsFragmentDoc}`;
export const EntityTransactionFieldsFragmentDoc = gql`
    fragment EntityTransactionFields on EntityTransaction {
  viewId
  factId
  timestamp
  description
  externalId
  moneyFlow {
    ...MoneyFlowFields
  }
  party {
    ...EntityPartyFields
  }
  displayCategory {
    ...EntityCategoryFields
  }
  splitCategory {
    ...EntityCategoryFields
  }
  displayEntry
}
    ${MoneyFlowFieldsFragmentDoc}
${EntityPartyFieldsFragmentDoc}
${EntityCategoryFieldsFragmentDoc}`;
export const EntityCommentFieldsFragmentDoc = gql`
    fragment EntityCommentFields on EntityComment {
  id
  authorId
  text
  timestamp
}
    `;
export const DateFieldsFragmentDoc = gql`
    fragment DateFields on Date {
  year
  month
  day
}
    `;
export const EntityReportFieldsFragmentDoc = gql`
    fragment EntityReportFields on EntityReport {
  id
  title
  authorId
  mimeType
  reportFor {
    ...DateFields
  }
  createdAt
}
    ${DateFieldsFragmentDoc}`;
export const SharedReportDocumentMetadataFieldsFragmentDoc = gql`
    fragment SharedReportDocumentMetadataFields on SharedReportDocumentMetadata {
  id
  documentObjectId
  documentObjectVersionId
  title
  mimeType
}
    `;
export const EntityReportPackageFieldsFragmentDoc = gql`
    fragment EntityReportPackageFields on EntityReportPackage {
  id
  title
  authorId
  interval
  periodStartedAt
  periodEndedAt
  createdAt
  updatedAt
  documents {
    ...SharedReportDocumentMetadataFields
  }
  coverUrl
}
    ${SharedReportDocumentMetadataFieldsFragmentDoc}`;
export const EntityDataSourceFieldsFragmentDoc = gql`
    fragment EntityDataSourceFields on EntityDataSource {
  id
  name
  url
  iconUrl
}
    `;
export const EntityThreadFieldsFragmentDoc = gql`
    fragment EntityThreadFields on EntityThread {
  id
  resolvedAt
}
    `;
export const EntityWorkItemFieldsFragmentDoc = gql`
    fragment EntityWorkItemFields on EntityWorkItem {
  id
  time
  workItemState
  workItemType
  reviewCreatedById
  objectTransaction {
    ...EntityTransactionFields
  }
  reviewState
  ledgerType
}
    ${EntityTransactionFieldsFragmentDoc}`;
export const EntityMonetaryValueFieldsFragmentDoc = gql`
    fragment EntityMonetaryValueFields on EntityMonetaryValue {
  iso4217CurrencyCode
  currencyMultiplier
  amount
}
    `;
export const EntityInvoiceCustomerFieldsFragmentDoc = gql`
    fragment EntityInvoiceCustomerFields on EntityInvoiceCustomer {
  name
  phone
  email
  party {
    ...EntityPartyFields
  }
}
    ${EntityPartyFieldsFragmentDoc}`;
export const EntityInvoiceFieldsFragmentDoc = gql`
    fragment EntityInvoiceFields on EntityInvoice {
  id
  externalDisplayId
  amount {
    ...EntityMonetaryValueFields
  }
  customer {
    ...EntityInvoiceCustomerFields
  }
  createdAt
  dueAt
}
    ${EntityMonetaryValueFieldsFragmentDoc}
${EntityInvoiceCustomerFieldsFragmentDoc}`;
export const SegmentFieldsFragmentDoc = gql`
    fragment SegmentFields on Segment {
  collectionId
  fileId
  sequence
  token
}
    `;
export const BillAccessFieldsFragmentDoc = gql`
    fragment BillAccessFields on BillAccess {
  objectId
  objectKind
  role
}
    `;
export const EntityBillFieldsFragmentDoc = gql`
    fragment EntityBillFields on EntityBill {
  id
  billStatus
  vendorName
  totalAmount {
    ...EntityMonetaryValueFields
  }
  dueDate {
    ...DateFields
  }
  billDescription
  format {
    id
    formatClass
    segments {
      ...SegmentFields
    }
  }
  approvalAccess {
    ...BillAccessFields
  }
}
    ${EntityMonetaryValueFieldsFragmentDoc}
${DateFieldsFragmentDoc}
${SegmentFieldsFragmentDoc}
${BillAccessFieldsFragmentDoc}`;
export const EntityIdentityVerificationFieldsFragmentDoc = gql`
    fragment EntityIdentityVerificationFields on EntityIdentityVerification {
  legalEntityId
  status
}
    `;
export const ObjectEntitiesFieldsFragmentDoc = gql`
    fragment ObjectEntitiesFields on ObjectEntities {
  __typename
  users {
    ...EntityUserFields
  }
  transactions {
    ...EntityTransactionFields
  }
  comments {
    ...EntityCommentFields
  }
  reports {
    ...EntityReportFields
  }
  reportPackages {
    ...EntityReportPackageFields
  }
  parties {
    ...EntityPartyFields
  }
  datasources {
    ...EntityDataSourceFields
  }
  categories {
    ...EntityCategoryFields
  }
  threads {
    ...EntityThreadFields
  }
  legalEntities {
    ...EntityLegalEntityFields
  }
  workItems {
    ...EntityWorkItemFields
  }
  invoices {
    ...EntityInvoiceFields
  }
  bills {
    ...EntityBillFields
  }
  identityVerifications {
    ...EntityIdentityVerificationFields
  }
  departments {
    ...EntityDepartmentFields
  }
  locations {
    ...EntityLocationFields
  }
}
    ${EntityUserFieldsFragmentDoc}
${EntityTransactionFieldsFragmentDoc}
${EntityCommentFieldsFragmentDoc}
${EntityReportFieldsFragmentDoc}
${EntityReportPackageFieldsFragmentDoc}
${EntityPartyFieldsFragmentDoc}
${EntityDataSourceFieldsFragmentDoc}
${EntityCategoryFieldsFragmentDoc}
${EntityThreadFieldsFragmentDoc}
${EntityLegalEntityFieldsFragmentDoc}
${EntityWorkItemFieldsFragmentDoc}
${EntityInvoiceFieldsFragmentDoc}
${EntityBillFieldsFragmentDoc}
${EntityIdentityVerificationFieldsFragmentDoc}
${EntityDepartmentFieldsFragmentDoc}
${EntityLocationFieldsFragmentDoc}`;
export const ThreadsFieldsFragmentDoc = gql`
    fragment ThreadsFields on Threads {
  threads {
    ...ThreadListFields
  }
  entities {
    ...ObjectEntitiesFields
  }
}
    ${ThreadListFieldsFragmentDoc}
${ObjectEntitiesFieldsFragmentDoc}`;
export const CommentFieldsFragmentDoc = gql`
    fragment CommentFields on Comment {
  __typename
  id
  authorId
  text
  timestamp
  editedAt
  deletedAt
  layoutAttachment {
    layoutId
    legalEntityId
  }
  reactions
}
    `;
export const ThreadFieldsFragmentDoc = gql`
    fragment ThreadFields on Thread {
  __typename
  id
  details {
    ...ThreadDetailsFields
  }
  comments {
    ...CommentFields
  }
}
    ${ThreadDetailsFieldsFragmentDoc}
${CommentFieldsFragmentDoc}`;
export const ThreadWithEntitiesFieldsFragmentDoc = gql`
    fragment ThreadWithEntitiesFields on ThreadWithEntities {
  __typename
  thread {
    ...ThreadFields
  }
  entities {
    ...ObjectEntitiesFields
  }
}
    ${ThreadFieldsFragmentDoc}
${ObjectEntitiesFieldsFragmentDoc}`;
export const BooksClosedDateFieldsFragmentDoc = gql`
    fragment BooksClosedDateFields on BooksClosedDate {
  legalEntityId
  latestBooksClosedDateFact
  viewBooksClosedDate
  user {
    ...EntityUserFields
  }
}
    ${EntityUserFieldsFragmentDoc}`;
export const UserInfoFieldsFragmentDoc = gql`
    fragment UserInfoFields on UserInfo {
  givenName
  familyName
  emailAddress
}
    `;
export const LegalEntityUserInfoFieldsFragmentDoc = gql`
    fragment LegalEntityUserInfoFields on LegalEntityUserInfo {
  legalEntity {
    ...LegalEntityFields
  }
  userInfo {
    ...UserInfoFields
  }
}
    ${LegalEntityFieldsFragmentDoc}
${UserInfoFieldsFragmentDoc}`;
export const OwnedSourceAccountFieldsFragmentDoc = gql`
    fragment OwnedSourceAccountFields on OwnedSourceAccount {
  sourceAccount {
    ...SourceAccountFields
  }
  owner {
    ...EmployeeFields
  }
}
    ${SourceAccountFieldsFragmentDoc}
${EmployeeFieldsFragmentDoc}`;
export const BooksClosedFactFieldsFragmentDoc = gql`
    fragment BooksClosedFactFields on BooksClosedFact {
  legalEntityId
  dataSourceId
  userId
  booksClosedDate
  createdAt
}
    `;
export const OrganizationPreviewFieldsFragmentDoc = gql`
    fragment OrganizationPreviewFields on OrganizationPreview {
  id
  slug
  name
  iconUrl
}
    `;
export const SummaryTotalFieldsFragmentDoc = gql`
    fragment SummaryTotalFields on SummaryTotal {
  total {
    ...MoneyFlowFields
  }
  count
}
    ${MoneyFlowFieldsFragmentDoc}`;
export const PeriodMonetaryValueFieldsFragmentDoc = gql`
    fragment PeriodMonetaryValueFields on PeriodMonetaryValue {
  value {
    ...MonetaryValueFields
  }
  moneyFlow {
    ...MoneyFlowFields
  }
  current {
    ...SummaryTotalFields
  }
  prior {
    ...SummaryTotalFields
  }
  transactionsCount
  deltaPrevious
  deltaYearAgo
  isFirstOccurrencePeriod
}
    ${MonetaryValueFieldsFragmentDoc}
${MoneyFlowFieldsFragmentDoc}
${SummaryTotalFieldsFragmentDoc}`;
export const TransactionSummaryFieldsFragmentDoc = gql`
    fragment TransactionSummaryFields on TransactionSummary {
  period {
    ...PeriodFields
  }
  total {
    ...PeriodMonetaryValueFields
  }
  isPeriodBookClosed
}
    ${PeriodFieldsFragmentDoc}
${PeriodMonetaryValueFieldsFragmentDoc}`;
export const DataSourceFieldsFragmentDoc = gql`
    fragment DataSourceFields on DataSource {
  id
  legalEntityId
  status
  statusReason
  institution {
    ...InstitutionFields
  }
  providerType
  accountAlias
  createdAt
  updatedAt
}
    ${InstitutionFieldsFragmentDoc}`;
export const RefreshProgressFieldsFragmentDoc = gql`
    fragment RefreshProgressFields on RefreshProgress {
  mostRecentFetchStartTime
  progressPercentage
}
    `;
export const EmployeeAffiliationsFieldsFragmentDoc = gql`
    fragment EmployeeAffiliationsFields on EmployeeAffiliations {
  employee {
    ...EmployeeFields
  }
  affiliations {
    organization {
      ...EntityOrganizationFields
    }
    legalEntity {
      ...EntityLegalEntityFields
    }
    role {
      id
    }
  }
}
    ${EmployeeFieldsFragmentDoc}
${EntityOrganizationFieldsFragmentDoc}
${EntityLegalEntityFieldsFragmentDoc}`;
export const LocationFieldsFragmentDoc = gql`
    fragment LocationFields on Location {
  id
  name
  parentId
  active
  ancestors {
    ...EntityLocationFields
  }
}
    ${EntityLocationFieldsFragmentDoc}`;
export const WorkItemTransactionFieldsFragmentDoc = gql`
    fragment WorkItemTransactionFields on WorkItemTransaction {
  externalTransactionId
  externalTransactionLineId
  supportType
  displayCategory {
    ...EntityCategoryFields
  }
  displayPartyRole
  displayEntry
  splitCategory {
    ...EntityCategoryFields
  }
  description
  name
  party {
    ...EntityPartyFields
  }
  creditValue {
    ...MonetaryValueFields
  }
  debitValue {
    ...MonetaryValueFields
  }
  displayValue {
    ...MonetaryValueFields
  }
  moneyFlow {
    ...MoneyFlowFields
  }
  occurredAt
  dataSourceId
  quickbooksAppName
  ledgerTransactionId
}
    ${EntityCategoryFieldsFragmentDoc}
${EntityPartyFieldsFragmentDoc}
${MonetaryValueFieldsFragmentDoc}
${MoneyFlowFieldsFragmentDoc}`;
export const WorkItemReviewFieldsFragmentDoc = gql`
    fragment WorkItemReviewFields on WorkItemReview {
  createdAt
  creator {
    ...EntityUserFields
  }
  updatedAt
  subject
}
    ${EntityUserFieldsFragmentDoc}`;
export const WorkItemFieldsFragmentDoc = gql`
    fragment WorkItemFields on WorkItem {
  legalEntityId
  workItemId
  time
  itemType
  itemState
  ledgerType
  createdAt
  updatedBy
  updatedAt
  actionable
  transactions {
    ...WorkItemTransactionFields
  }
  reviewState
  review {
    ...WorkItemReviewFields
  }
  generatedQuestions {
    ...WorkItemGeneratedQuestionFields
  }
  pushes {
    ...WorkItemPushFields
  }
}
    ${WorkItemTransactionFieldsFragmentDoc}
${WorkItemReviewFieldsFragmentDoc}
${WorkItemGeneratedQuestionFieldsFragmentDoc}
${WorkItemPushFieldsFragmentDoc}`;
export const InsightFieldsFragmentDoc = gql`
    fragment InsightFields on Insight {
  score
  globalScore
  subjectType
  subjectId
  subjectDisplayKey
  periodValue {
    ...MonetaryValueFields
  }
  absoluteDelta {
    ...MonetaryValueFields
  }
  comparisonPeriodValue {
    ...MonetaryValueFields
  }
  comparisonIntervalOffset
  ruleType
  sentence
  subjectIconUrl
  designation
  type
  productArea
}
    ${MonetaryValueFieldsFragmentDoc}`;
export const ArchiveIntervalOriginFieldsFragmentDoc = gql`
    fragment ArchiveIntervalOriginFields on ArchiveIntervalOrigin {
  interval
  year
  index
  intervalCount
}
    `;
export const TransactionEntryLineFieldsFragmentDoc = gql`
    fragment TransactionEntryLineFields on TransactionEntryLine {
  lineId
  value {
    ...MonetaryValueFields
  }
  category {
    ...CategoryFields
  }
  name
  description
  isCredit
  highlighted
}
    ${MonetaryValueFieldsFragmentDoc}
${CategoryFieldsFragmentDoc}`;
export const TransactionViewDetailFieldsFragmentDoc = gql`
    fragment TransactionViewDetailFields on TransactionViewDetail {
  id
  factId
  occurredAt
  externalCreatedAt
  externalId
  externalDisplayId
  quickbooksAppName
  name
  description
  creditAmount {
    ...MonetaryValueFields
  }
  debitAmount {
    ...MonetaryValueFields
  }
  creditCategoryHierarchy {
    ...CategoryFields
  }
  debitCategoryHierarchy {
    ...CategoryFields
  }
  entryLines {
    ...TransactionEntryLineFields
  }
  party {
    ...TransactionPartyFields
  }
  institution {
    ...InstitutionFields
  }
  sourceAccount {
    ...SourceAccountFields
  }
  recordType
}
    ${MonetaryValueFieldsFragmentDoc}
${CategoryFieldsFragmentDoc}
${TransactionEntryLineFieldsFragmentDoc}
${TransactionPartyFieldsFragmentDoc}
${InstitutionFieldsFragmentDoc}
${SourceAccountFieldsFragmentDoc}`;
export const TransactionViewDetailsFieldsFragmentDoc = gql`
    fragment TransactionViewDetailsFields on TransactionViewDetails {
  details {
    ...TransactionViewDetailFields
  }
}
    ${TransactionViewDetailFieldsFragmentDoc}`;
export const HighlightedTokenFieldsFragmentDoc = gql`
    fragment HighlightedTokenFields on HighlightedToken {
  token
  score
}
    `;
export const HighlightedTokensFieldsFragmentDoc = gql`
    fragment HighlightedTokensFields on HighlightedTokens {
  description {
    ...HighlightedTokenFields
  }
  name {
    ...HighlightedTokenFields
  }
}
    ${HighlightedTokenFieldsFragmentDoc}`;
export const EntityLedgerTransactionFieldsFragmentDoc = gql`
    fragment EntityLedgerTransactionFields on EntityLedgerTransaction {
  id
  timestamp
  factIds
}
    `;
export const ActivityObjectIdentifierFieldsFragmentDoc = gql`
    fragment ActivityObjectIdentifierFields on ActivityObjectIdentifier {
  kind
  id
  context
}
    `;
export const DataSourcePreferenceFieldsFragmentDoc = gql`
    fragment DataSourcePreferenceFields on DataSourcePreference {
  dataSourceId
  accountingBasis
  taxForm
  providerType
  departmentTerminology
}
    `;
export const CategoryMappingFieldsFragmentDoc = gql`
    fragment CategoryMappingFields on CategoryMapping {
  mappingValue
  mappingType
  product
  category {
    ...EntityCategoryFields
  }
}
    ${EntityCategoryFieldsFragmentDoc}`;
export const TriggerDataSourceClassificationDocument = gql`
    mutation TriggerDataSourceClassification($legalEntityId: String!, $dataSourceId: String!) {
  triggerDataSourceClassification(
    legalEntityId: $legalEntityId
    dataSourceId: $dataSourceId
  )
}
    `;
export type TriggerDataSourceClassificationMutationFn = Apollo.MutationFunction<TriggerDataSourceClassificationMutation, TriggerDataSourceClassificationMutationVariables>;

/**
 * __useTriggerDataSourceClassificationMutation__
 *
 * To run a mutation, you first call `useTriggerDataSourceClassificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTriggerDataSourceClassificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [triggerDataSourceClassificationMutation, { data, loading, error }] = useTriggerDataSourceClassificationMutation({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *      dataSourceId: // value for 'dataSourceId'
 *   },
 * });
 */
export function useTriggerDataSourceClassificationMutation(baseOptions?: Apollo.MutationHookOptions<TriggerDataSourceClassificationMutation, TriggerDataSourceClassificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TriggerDataSourceClassificationMutation, TriggerDataSourceClassificationMutationVariables>(TriggerDataSourceClassificationDocument, options);
      }
export type TriggerDataSourceClassificationMutationHookResult = ReturnType<typeof useTriggerDataSourceClassificationMutation>;
export type TriggerDataSourceClassificationMutationResult = Apollo.MutationResult<TriggerDataSourceClassificationMutation>;
export type TriggerDataSourceClassificationMutationOptions = Apollo.BaseMutationOptions<TriggerDataSourceClassificationMutation, TriggerDataSourceClassificationMutationVariables>;
export const DeleteAdminDataSourceDocument = gql`
    mutation DeleteAdminDataSource($legalEntityId: String!, $dataSourceId: String!, $providerType: ProviderType!) {
  deleteAdminDataSource(
    legalEntityId: $legalEntityId
    dataSourceId: $dataSourceId
    providerType: $providerType
  )
}
    `;
export type DeleteAdminDataSourceMutationFn = Apollo.MutationFunction<DeleteAdminDataSourceMutation, DeleteAdminDataSourceMutationVariables>;

/**
 * __useDeleteAdminDataSourceMutation__
 *
 * To run a mutation, you first call `useDeleteAdminDataSourceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAdminDataSourceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAdminDataSourceMutation, { data, loading, error }] = useDeleteAdminDataSourceMutation({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *      dataSourceId: // value for 'dataSourceId'
 *      providerType: // value for 'providerType'
 *   },
 * });
 */
export function useDeleteAdminDataSourceMutation(baseOptions?: Apollo.MutationHookOptions<DeleteAdminDataSourceMutation, DeleteAdminDataSourceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteAdminDataSourceMutation, DeleteAdminDataSourceMutationVariables>(DeleteAdminDataSourceDocument, options);
      }
export type DeleteAdminDataSourceMutationHookResult = ReturnType<typeof useDeleteAdminDataSourceMutation>;
export type DeleteAdminDataSourceMutationResult = Apollo.MutationResult<DeleteAdminDataSourceMutation>;
export type DeleteAdminDataSourceMutationOptions = Apollo.BaseMutationOptions<DeleteAdminDataSourceMutation, DeleteAdminDataSourceMutationVariables>;
export const ListAdminBooksClosedFactsDocument = gql`
    query ListAdminBooksClosedFacts($legalEntityId: String!) {
  listAdminBooksClosedFacts(legalEntityId: $legalEntityId) {
    ...BooksClosedFactFields
  }
}
    ${BooksClosedFactFieldsFragmentDoc}`;

/**
 * __useListAdminBooksClosedFactsQuery__
 *
 * To run a query within a React component, call `useListAdminBooksClosedFactsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListAdminBooksClosedFactsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListAdminBooksClosedFactsQuery({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *   },
 * });
 */
export function useListAdminBooksClosedFactsQuery(baseOptions: Apollo.QueryHookOptions<ListAdminBooksClosedFactsQuery, ListAdminBooksClosedFactsQueryVariables> & ({ variables: ListAdminBooksClosedFactsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListAdminBooksClosedFactsQuery, ListAdminBooksClosedFactsQueryVariables>(ListAdminBooksClosedFactsDocument, options);
      }
export function useListAdminBooksClosedFactsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListAdminBooksClosedFactsQuery, ListAdminBooksClosedFactsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListAdminBooksClosedFactsQuery, ListAdminBooksClosedFactsQueryVariables>(ListAdminBooksClosedFactsDocument, options);
        }
export function useListAdminBooksClosedFactsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ListAdminBooksClosedFactsQuery, ListAdminBooksClosedFactsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ListAdminBooksClosedFactsQuery, ListAdminBooksClosedFactsQueryVariables>(ListAdminBooksClosedFactsDocument, options);
        }
export type ListAdminBooksClosedFactsQueryHookResult = ReturnType<typeof useListAdminBooksClosedFactsQuery>;
export type ListAdminBooksClosedFactsLazyQueryHookResult = ReturnType<typeof useListAdminBooksClosedFactsLazyQuery>;
export type ListAdminBooksClosedFactsSuspenseQueryHookResult = ReturnType<typeof useListAdminBooksClosedFactsSuspenseQuery>;
export type ListAdminBooksClosedFactsQueryResult = Apollo.QueryResult<ListAdminBooksClosedFactsQuery, ListAdminBooksClosedFactsQueryVariables>;
export const ListAdminOwnedDataSourcesDocument = gql`
    query ListAdminOwnedDataSources($legalEntityId: String!) {
  listAdminOwnedDataSources(legalEntityId: $legalEntityId) {
    dataSource {
      ...EnrichedDataSourceFields
    }
    ownedSourceAccounts {
      ...OwnedSourceAccountFields
    }
    dataSourcePreference {
      ...DataSourcePreferenceFields
    }
  }
  listAdminPredictedDataSources(legalEntityId: $legalEntityId) {
    ...PredictedDataSourceFields
  }
}
    ${EnrichedDataSourceFieldsFragmentDoc}
${OwnedSourceAccountFieldsFragmentDoc}
${DataSourcePreferenceFieldsFragmentDoc}
${PredictedDataSourceFieldsFragmentDoc}`;

/**
 * __useListAdminOwnedDataSourcesQuery__
 *
 * To run a query within a React component, call `useListAdminOwnedDataSourcesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListAdminOwnedDataSourcesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListAdminOwnedDataSourcesQuery({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *   },
 * });
 */
export function useListAdminOwnedDataSourcesQuery(baseOptions: Apollo.QueryHookOptions<ListAdminOwnedDataSourcesQuery, ListAdminOwnedDataSourcesQueryVariables> & ({ variables: ListAdminOwnedDataSourcesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListAdminOwnedDataSourcesQuery, ListAdminOwnedDataSourcesQueryVariables>(ListAdminOwnedDataSourcesDocument, options);
      }
export function useListAdminOwnedDataSourcesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListAdminOwnedDataSourcesQuery, ListAdminOwnedDataSourcesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListAdminOwnedDataSourcesQuery, ListAdminOwnedDataSourcesQueryVariables>(ListAdminOwnedDataSourcesDocument, options);
        }
export function useListAdminOwnedDataSourcesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ListAdminOwnedDataSourcesQuery, ListAdminOwnedDataSourcesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ListAdminOwnedDataSourcesQuery, ListAdminOwnedDataSourcesQueryVariables>(ListAdminOwnedDataSourcesDocument, options);
        }
export type ListAdminOwnedDataSourcesQueryHookResult = ReturnType<typeof useListAdminOwnedDataSourcesQuery>;
export type ListAdminOwnedDataSourcesLazyQueryHookResult = ReturnType<typeof useListAdminOwnedDataSourcesLazyQuery>;
export type ListAdminOwnedDataSourcesSuspenseQueryHookResult = ReturnType<typeof useListAdminOwnedDataSourcesSuspenseQuery>;
export type ListAdminOwnedDataSourcesQueryResult = Apollo.QueryResult<ListAdminOwnedDataSourcesQuery, ListAdminOwnedDataSourcesQueryVariables>;
export const ReadAdminBooksClosedDateDocument = gql`
    query ReadAdminBooksClosedDate($legalEntityId: String!) {
  readAdminBooksClosedDate(legalEntityId: $legalEntityId) {
    ...BooksClosedDateFields
  }
}
    ${BooksClosedDateFieldsFragmentDoc}`;

/**
 * __useReadAdminBooksClosedDateQuery__
 *
 * To run a query within a React component, call `useReadAdminBooksClosedDateQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadAdminBooksClosedDateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadAdminBooksClosedDateQuery({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *   },
 * });
 */
export function useReadAdminBooksClosedDateQuery(baseOptions: Apollo.QueryHookOptions<ReadAdminBooksClosedDateQuery, ReadAdminBooksClosedDateQueryVariables> & ({ variables: ReadAdminBooksClosedDateQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadAdminBooksClosedDateQuery, ReadAdminBooksClosedDateQueryVariables>(ReadAdminBooksClosedDateDocument, options);
      }
export function useReadAdminBooksClosedDateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadAdminBooksClosedDateQuery, ReadAdminBooksClosedDateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadAdminBooksClosedDateQuery, ReadAdminBooksClosedDateQueryVariables>(ReadAdminBooksClosedDateDocument, options);
        }
export function useReadAdminBooksClosedDateSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ReadAdminBooksClosedDateQuery, ReadAdminBooksClosedDateQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ReadAdminBooksClosedDateQuery, ReadAdminBooksClosedDateQueryVariables>(ReadAdminBooksClosedDateDocument, options);
        }
export type ReadAdminBooksClosedDateQueryHookResult = ReturnType<typeof useReadAdminBooksClosedDateQuery>;
export type ReadAdminBooksClosedDateLazyQueryHookResult = ReturnType<typeof useReadAdminBooksClosedDateLazyQuery>;
export type ReadAdminBooksClosedDateSuspenseQueryHookResult = ReturnType<typeof useReadAdminBooksClosedDateSuspenseQuery>;
export type ReadAdminBooksClosedDateQueryResult = Apollo.QueryResult<ReadAdminBooksClosedDateQuery, ReadAdminBooksClosedDateQueryVariables>;
export const ListDataSourceJobErrorsDocument = gql`
    query ListDataSourceJobErrors($legalEntityId: String!, $requestObjectId: String) {
  listDataSourceJobErrors(
    legalEntityId: $legalEntityId
    requestObjectId: $requestObjectId
  ) {
    jobOwner {
      ...JobOwnerFields
    }
    jobMetadata {
      ...JobMetadataFields
    }
    jobType
    errorMessage
    createdAt
  }
}
    ${JobOwnerFieldsFragmentDoc}
${JobMetadataFieldsFragmentDoc}`;

/**
 * __useListDataSourceJobErrorsQuery__
 *
 * To run a query within a React component, call `useListDataSourceJobErrorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListDataSourceJobErrorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListDataSourceJobErrorsQuery({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *      requestObjectId: // value for 'requestObjectId'
 *   },
 * });
 */
export function useListDataSourceJobErrorsQuery(baseOptions: Apollo.QueryHookOptions<ListDataSourceJobErrorsQuery, ListDataSourceJobErrorsQueryVariables> & ({ variables: ListDataSourceJobErrorsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListDataSourceJobErrorsQuery, ListDataSourceJobErrorsQueryVariables>(ListDataSourceJobErrorsDocument, options);
      }
export function useListDataSourceJobErrorsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListDataSourceJobErrorsQuery, ListDataSourceJobErrorsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListDataSourceJobErrorsQuery, ListDataSourceJobErrorsQueryVariables>(ListDataSourceJobErrorsDocument, options);
        }
export function useListDataSourceJobErrorsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ListDataSourceJobErrorsQuery, ListDataSourceJobErrorsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ListDataSourceJobErrorsQuery, ListDataSourceJobErrorsQueryVariables>(ListDataSourceJobErrorsDocument, options);
        }
export type ListDataSourceJobErrorsQueryHookResult = ReturnType<typeof useListDataSourceJobErrorsQuery>;
export type ListDataSourceJobErrorsLazyQueryHookResult = ReturnType<typeof useListDataSourceJobErrorsLazyQuery>;
export type ListDataSourceJobErrorsSuspenseQueryHookResult = ReturnType<typeof useListDataSourceJobErrorsSuspenseQuery>;
export type ListDataSourceJobErrorsQueryResult = Apollo.QueryResult<ListDataSourceJobErrorsQuery, ListDataSourceJobErrorsQueryVariables>;
export const UpdateInstitutionDocument = gql`
    mutation UpdateInstitution($institution_id: String!, $name: String!, $url: String!, $routingNumber: String!) {
  updateInstitution(
    institution_id: $institution_id
    name: $name
    url: $url
    routingNumber: $routingNumber
  )
}
    `;
export type UpdateInstitutionMutationFn = Apollo.MutationFunction<UpdateInstitutionMutation, UpdateInstitutionMutationVariables>;

/**
 * __useUpdateInstitutionMutation__
 *
 * To run a mutation, you first call `useUpdateInstitutionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInstitutionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInstitutionMutation, { data, loading, error }] = useUpdateInstitutionMutation({
 *   variables: {
 *      institution_id: // value for 'institution_id'
 *      name: // value for 'name'
 *      url: // value for 'url'
 *      routingNumber: // value for 'routingNumber'
 *   },
 * });
 */
export function useUpdateInstitutionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateInstitutionMutation, UpdateInstitutionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateInstitutionMutation, UpdateInstitutionMutationVariables>(UpdateInstitutionDocument, options);
      }
export type UpdateInstitutionMutationHookResult = ReturnType<typeof useUpdateInstitutionMutation>;
export type UpdateInstitutionMutationResult = Apollo.MutationResult<UpdateInstitutionMutation>;
export type UpdateInstitutionMutationOptions = Apollo.BaseMutationOptions<UpdateInstitutionMutation, UpdateInstitutionMutationVariables>;
export const CreateInstitutionDocument = gql`
    mutation CreateInstitution($name: String!, $url: String!, $routingNumber: String!, $partyId: String!, $kind: InstitutionKind!) {
  createInstitution(
    name: $name
    url: $url
    routingNumber: $routingNumber
    partyId: $partyId
    kind: $kind
  ) {
    ...AdminInstitutionFields
  }
}
    ${AdminInstitutionFieldsFragmentDoc}`;
export type CreateInstitutionMutationFn = Apollo.MutationFunction<CreateInstitutionMutation, CreateInstitutionMutationVariables>;

/**
 * __useCreateInstitutionMutation__
 *
 * To run a mutation, you first call `useCreateInstitutionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInstitutionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInstitutionMutation, { data, loading, error }] = useCreateInstitutionMutation({
 *   variables: {
 *      name: // value for 'name'
 *      url: // value for 'url'
 *      routingNumber: // value for 'routingNumber'
 *      partyId: // value for 'partyId'
 *      kind: // value for 'kind'
 *   },
 * });
 */
export function useCreateInstitutionMutation(baseOptions?: Apollo.MutationHookOptions<CreateInstitutionMutation, CreateInstitutionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateInstitutionMutation, CreateInstitutionMutationVariables>(CreateInstitutionDocument, options);
      }
export type CreateInstitutionMutationHookResult = ReturnType<typeof useCreateInstitutionMutation>;
export type CreateInstitutionMutationResult = Apollo.MutationResult<CreateInstitutionMutation>;
export type CreateInstitutionMutationOptions = Apollo.BaseMutationOptions<CreateInstitutionMutation, CreateInstitutionMutationVariables>;
export const UpdateInstitutionProductsDocument = gql`
    mutation UpdateInstitutionProducts($institutionId: String!, $products: [InstitutionProduct!]!) {
  updateInstitutionProducts(institutionId: $institutionId, products: $products)
}
    `;
export type UpdateInstitutionProductsMutationFn = Apollo.MutationFunction<UpdateInstitutionProductsMutation, UpdateInstitutionProductsMutationVariables>;

/**
 * __useUpdateInstitutionProductsMutation__
 *
 * To run a mutation, you first call `useUpdateInstitutionProductsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInstitutionProductsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInstitutionProductsMutation, { data, loading, error }] = useUpdateInstitutionProductsMutation({
 *   variables: {
 *      institutionId: // value for 'institutionId'
 *      products: // value for 'products'
 *   },
 * });
 */
export function useUpdateInstitutionProductsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateInstitutionProductsMutation, UpdateInstitutionProductsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateInstitutionProductsMutation, UpdateInstitutionProductsMutationVariables>(UpdateInstitutionProductsDocument, options);
      }
export type UpdateInstitutionProductsMutationHookResult = ReturnType<typeof useUpdateInstitutionProductsMutation>;
export type UpdateInstitutionProductsMutationResult = Apollo.MutationResult<UpdateInstitutionProductsMutation>;
export type UpdateInstitutionProductsMutationOptions = Apollo.BaseMutationOptions<UpdateInstitutionProductsMutation, UpdateInstitutionProductsMutationVariables>;
export const UpdateInstitutionPartyDocument = gql`
    mutation UpdateInstitutionParty($institutionId: String!, $partyId: String!) {
  updateInstitutionParty(institutionId: $institutionId, partyId: $partyId)
}
    `;
export type UpdateInstitutionPartyMutationFn = Apollo.MutationFunction<UpdateInstitutionPartyMutation, UpdateInstitutionPartyMutationVariables>;

/**
 * __useUpdateInstitutionPartyMutation__
 *
 * To run a mutation, you first call `useUpdateInstitutionPartyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInstitutionPartyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInstitutionPartyMutation, { data, loading, error }] = useUpdateInstitutionPartyMutation({
 *   variables: {
 *      institutionId: // value for 'institutionId'
 *      partyId: // value for 'partyId'
 *   },
 * });
 */
export function useUpdateInstitutionPartyMutation(baseOptions?: Apollo.MutationHookOptions<UpdateInstitutionPartyMutation, UpdateInstitutionPartyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateInstitutionPartyMutation, UpdateInstitutionPartyMutationVariables>(UpdateInstitutionPartyDocument, options);
      }
export type UpdateInstitutionPartyMutationHookResult = ReturnType<typeof useUpdateInstitutionPartyMutation>;
export type UpdateInstitutionPartyMutationResult = Apollo.MutationResult<UpdateInstitutionPartyMutation>;
export type UpdateInstitutionPartyMutationOptions = Apollo.BaseMutationOptions<UpdateInstitutionPartyMutation, UpdateInstitutionPartyMutationVariables>;
export const ListInstitutionsDocument = gql`
    query ListInstitutions($pagination: Pagination!) {
  listInstitutions(pagination: $pagination) {
    ...AdminInstitutionFields
  }
}
    ${AdminInstitutionFieldsFragmentDoc}`;

/**
 * __useListInstitutionsQuery__
 *
 * To run a query within a React component, call `useListInstitutionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListInstitutionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListInstitutionsQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useListInstitutionsQuery(baseOptions: Apollo.QueryHookOptions<ListInstitutionsQuery, ListInstitutionsQueryVariables> & ({ variables: ListInstitutionsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListInstitutionsQuery, ListInstitutionsQueryVariables>(ListInstitutionsDocument, options);
      }
export function useListInstitutionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListInstitutionsQuery, ListInstitutionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListInstitutionsQuery, ListInstitutionsQueryVariables>(ListInstitutionsDocument, options);
        }
export function useListInstitutionsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ListInstitutionsQuery, ListInstitutionsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ListInstitutionsQuery, ListInstitutionsQueryVariables>(ListInstitutionsDocument, options);
        }
export type ListInstitutionsQueryHookResult = ReturnType<typeof useListInstitutionsQuery>;
export type ListInstitutionsLazyQueryHookResult = ReturnType<typeof useListInstitutionsLazyQuery>;
export type ListInstitutionsSuspenseQueryHookResult = ReturnType<typeof useListInstitutionsSuspenseQuery>;
export type ListInstitutionsQueryResult = Apollo.QueryResult<ListInstitutionsQuery, ListInstitutionsQueryVariables>;
export const GetInstitutionDocument = gql`
    query GetInstitution($institutionId: String!) {
  getInstitution(institutionId: $institutionId) {
    ...AdminInstitutionFields
  }
}
    ${AdminInstitutionFieldsFragmentDoc}`;

/**
 * __useGetInstitutionQuery__
 *
 * To run a query within a React component, call `useGetInstitutionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInstitutionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInstitutionQuery({
 *   variables: {
 *      institutionId: // value for 'institutionId'
 *   },
 * });
 */
export function useGetInstitutionQuery(baseOptions: Apollo.QueryHookOptions<GetInstitutionQuery, GetInstitutionQueryVariables> & ({ variables: GetInstitutionQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInstitutionQuery, GetInstitutionQueryVariables>(GetInstitutionDocument, options);
      }
export function useGetInstitutionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInstitutionQuery, GetInstitutionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInstitutionQuery, GetInstitutionQueryVariables>(GetInstitutionDocument, options);
        }
export function useGetInstitutionSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetInstitutionQuery, GetInstitutionQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetInstitutionQuery, GetInstitutionQueryVariables>(GetInstitutionDocument, options);
        }
export type GetInstitutionQueryHookResult = ReturnType<typeof useGetInstitutionQuery>;
export type GetInstitutionLazyQueryHookResult = ReturnType<typeof useGetInstitutionLazyQuery>;
export type GetInstitutionSuspenseQueryHookResult = ReturnType<typeof useGetInstitutionSuspenseQuery>;
export type GetInstitutionQueryResult = Apollo.QueryResult<GetInstitutionQuery, GetInstitutionQueryVariables>;
export const ListOrganizationsDocument = gql`
    query ListOrganizations($pagination: Pagination, $searchTerm: String, $affiliateType: AffiliateType, $organizationIds: [String!], $onlyWithEmployees: Boolean) {
  listOrganizations(
    pagination: $pagination
    searchTerm: $searchTerm
    affiliateType: $affiliateType
    organizationIds: $organizationIds
    onlyWithEmployees: $onlyWithEmployees
  ) {
    ...AdminOrganizationFields
  }
}
    ${AdminOrganizationFieldsFragmentDoc}`;

/**
 * __useListOrganizationsQuery__
 *
 * To run a query within a React component, call `useListOrganizationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListOrganizationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListOrganizationsQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      searchTerm: // value for 'searchTerm'
 *      affiliateType: // value for 'affiliateType'
 *      organizationIds: // value for 'organizationIds'
 *      onlyWithEmployees: // value for 'onlyWithEmployees'
 *   },
 * });
 */
export function useListOrganizationsQuery(baseOptions?: Apollo.QueryHookOptions<ListOrganizationsQuery, ListOrganizationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListOrganizationsQuery, ListOrganizationsQueryVariables>(ListOrganizationsDocument, options);
      }
export function useListOrganizationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListOrganizationsQuery, ListOrganizationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListOrganizationsQuery, ListOrganizationsQueryVariables>(ListOrganizationsDocument, options);
        }
export function useListOrganizationsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ListOrganizationsQuery, ListOrganizationsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ListOrganizationsQuery, ListOrganizationsQueryVariables>(ListOrganizationsDocument, options);
        }
export type ListOrganizationsQueryHookResult = ReturnType<typeof useListOrganizationsQuery>;
export type ListOrganizationsLazyQueryHookResult = ReturnType<typeof useListOrganizationsLazyQuery>;
export type ListOrganizationsSuspenseQueryHookResult = ReturnType<typeof useListOrganizationsSuspenseQuery>;
export type ListOrganizationsQueryResult = Apollo.QueryResult<ListOrganizationsQuery, ListOrganizationsQueryVariables>;
export const ListAffiliatedOrganizationsDocument = gql`
    query ListAffiliatedOrganizations($searchTerm: String) {
  listAffiliatedOrganizations(searchTerm: $searchTerm) {
    ...AdminOrganizationFields
  }
}
    ${AdminOrganizationFieldsFragmentDoc}`;

/**
 * __useListAffiliatedOrganizationsQuery__
 *
 * To run a query within a React component, call `useListAffiliatedOrganizationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListAffiliatedOrganizationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListAffiliatedOrganizationsQuery({
 *   variables: {
 *      searchTerm: // value for 'searchTerm'
 *   },
 * });
 */
export function useListAffiliatedOrganizationsQuery(baseOptions?: Apollo.QueryHookOptions<ListAffiliatedOrganizationsQuery, ListAffiliatedOrganizationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListAffiliatedOrganizationsQuery, ListAffiliatedOrganizationsQueryVariables>(ListAffiliatedOrganizationsDocument, options);
      }
export function useListAffiliatedOrganizationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListAffiliatedOrganizationsQuery, ListAffiliatedOrganizationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListAffiliatedOrganizationsQuery, ListAffiliatedOrganizationsQueryVariables>(ListAffiliatedOrganizationsDocument, options);
        }
export function useListAffiliatedOrganizationsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ListAffiliatedOrganizationsQuery, ListAffiliatedOrganizationsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ListAffiliatedOrganizationsQuery, ListAffiliatedOrganizationsQueryVariables>(ListAffiliatedOrganizationsDocument, options);
        }
export type ListAffiliatedOrganizationsQueryHookResult = ReturnType<typeof useListAffiliatedOrganizationsQuery>;
export type ListAffiliatedOrganizationsLazyQueryHookResult = ReturnType<typeof useListAffiliatedOrganizationsLazyQuery>;
export type ListAffiliatedOrganizationsSuspenseQueryHookResult = ReturnType<typeof useListAffiliatedOrganizationsSuspenseQuery>;
export type ListAffiliatedOrganizationsQueryResult = Apollo.QueryResult<ListAffiliatedOrganizationsQuery, ListAffiliatedOrganizationsQueryVariables>;
export const ListApprovalPriorityOrganizationsDocument = gql`
    query ListApprovalPriorityOrganizations($pagination: Pagination!) {
  listApprovalPriorityOrganizations(pagination: $pagination) {
    ...AdminOrganizationFields
  }
}
    ${AdminOrganizationFieldsFragmentDoc}`;

/**
 * __useListApprovalPriorityOrganizationsQuery__
 *
 * To run a query within a React component, call `useListApprovalPriorityOrganizationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListApprovalPriorityOrganizationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListApprovalPriorityOrganizationsQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useListApprovalPriorityOrganizationsQuery(baseOptions: Apollo.QueryHookOptions<ListApprovalPriorityOrganizationsQuery, ListApprovalPriorityOrganizationsQueryVariables> & ({ variables: ListApprovalPriorityOrganizationsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListApprovalPriorityOrganizationsQuery, ListApprovalPriorityOrganizationsQueryVariables>(ListApprovalPriorityOrganizationsDocument, options);
      }
export function useListApprovalPriorityOrganizationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListApprovalPriorityOrganizationsQuery, ListApprovalPriorityOrganizationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListApprovalPriorityOrganizationsQuery, ListApprovalPriorityOrganizationsQueryVariables>(ListApprovalPriorityOrganizationsDocument, options);
        }
export function useListApprovalPriorityOrganizationsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ListApprovalPriorityOrganizationsQuery, ListApprovalPriorityOrganizationsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ListApprovalPriorityOrganizationsQuery, ListApprovalPriorityOrganizationsQueryVariables>(ListApprovalPriorityOrganizationsDocument, options);
        }
export type ListApprovalPriorityOrganizationsQueryHookResult = ReturnType<typeof useListApprovalPriorityOrganizationsQuery>;
export type ListApprovalPriorityOrganizationsLazyQueryHookResult = ReturnType<typeof useListApprovalPriorityOrganizationsLazyQuery>;
export type ListApprovalPriorityOrganizationsSuspenseQueryHookResult = ReturnType<typeof useListApprovalPriorityOrganizationsSuspenseQuery>;
export type ListApprovalPriorityOrganizationsQueryResult = Apollo.QueryResult<ListApprovalPriorityOrganizationsQuery, ListApprovalPriorityOrganizationsQueryVariables>;
export const ReadOrganizationDocument = gql`
    query ReadOrganization($id: String!) {
  readOrganization(id: $id) {
    ...AdminOrganizationFields
  }
}
    ${AdminOrganizationFieldsFragmentDoc}`;

/**
 * __useReadOrganizationQuery__
 *
 * To run a query within a React component, call `useReadOrganizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadOrganizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadOrganizationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useReadOrganizationQuery(baseOptions: Apollo.QueryHookOptions<ReadOrganizationQuery, ReadOrganizationQueryVariables> & ({ variables: ReadOrganizationQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadOrganizationQuery, ReadOrganizationQueryVariables>(ReadOrganizationDocument, options);
      }
export function useReadOrganizationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadOrganizationQuery, ReadOrganizationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadOrganizationQuery, ReadOrganizationQueryVariables>(ReadOrganizationDocument, options);
        }
export function useReadOrganizationSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ReadOrganizationQuery, ReadOrganizationQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ReadOrganizationQuery, ReadOrganizationQueryVariables>(ReadOrganizationDocument, options);
        }
export type ReadOrganizationQueryHookResult = ReturnType<typeof useReadOrganizationQuery>;
export type ReadOrganizationLazyQueryHookResult = ReturnType<typeof useReadOrganizationLazyQuery>;
export type ReadOrganizationSuspenseQueryHookResult = ReturnType<typeof useReadOrganizationSuspenseQuery>;
export type ReadOrganizationQueryResult = Apollo.QueryResult<ReadOrganizationQuery, ReadOrganizationQueryVariables>;
export const ListOrganizationUsersDocument = gql`
    query ListOrganizationUsers($id: String!) {
  listOrganizationUsers(id: $id) {
    ...AdminUserFields
  }
}
    ${AdminUserFieldsFragmentDoc}`;

/**
 * __useListOrganizationUsersQuery__
 *
 * To run a query within a React component, call `useListOrganizationUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useListOrganizationUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListOrganizationUsersQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useListOrganizationUsersQuery(baseOptions: Apollo.QueryHookOptions<ListOrganizationUsersQuery, ListOrganizationUsersQueryVariables> & ({ variables: ListOrganizationUsersQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListOrganizationUsersQuery, ListOrganizationUsersQueryVariables>(ListOrganizationUsersDocument, options);
      }
export function useListOrganizationUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListOrganizationUsersQuery, ListOrganizationUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListOrganizationUsersQuery, ListOrganizationUsersQueryVariables>(ListOrganizationUsersDocument, options);
        }
export function useListOrganizationUsersSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ListOrganizationUsersQuery, ListOrganizationUsersQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ListOrganizationUsersQuery, ListOrganizationUsersQueryVariables>(ListOrganizationUsersDocument, options);
        }
export type ListOrganizationUsersQueryHookResult = ReturnType<typeof useListOrganizationUsersQuery>;
export type ListOrganizationUsersLazyQueryHookResult = ReturnType<typeof useListOrganizationUsersLazyQuery>;
export type ListOrganizationUsersSuspenseQueryHookResult = ReturnType<typeof useListOrganizationUsersSuspenseQuery>;
export type ListOrganizationUsersQueryResult = Apollo.QueryResult<ListOrganizationUsersQuery, ListOrganizationUsersQueryVariables>;
export const ReadAssociatedThreadsDocument = gql`
    query ReadAssociatedThreads($id: String!, $legalEntityId: String!, $kind: ObjectKind!, $allowResolved: Boolean = true) {
  response: readAssociatedThreads(
    id: $id
    legalEntityId: $legalEntityId
    kind: $kind
    allowResolved: $allowResolved
  ) {
    threads {
      ...ThreadFields
    }
    entities {
      ...ObjectEntitiesFields
    }
  }
}
    ${ThreadFieldsFragmentDoc}
${ObjectEntitiesFieldsFragmentDoc}`;

/**
 * __useReadAssociatedThreadsQuery__
 *
 * To run a query within a React component, call `useReadAssociatedThreadsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadAssociatedThreadsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadAssociatedThreadsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      legalEntityId: // value for 'legalEntityId'
 *      kind: // value for 'kind'
 *      allowResolved: // value for 'allowResolved'
 *   },
 * });
 */
export function useReadAssociatedThreadsQuery(baseOptions: Apollo.QueryHookOptions<ReadAssociatedThreadsQuery, ReadAssociatedThreadsQueryVariables> & ({ variables: ReadAssociatedThreadsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadAssociatedThreadsQuery, ReadAssociatedThreadsQueryVariables>(ReadAssociatedThreadsDocument, options);
      }
export function useReadAssociatedThreadsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadAssociatedThreadsQuery, ReadAssociatedThreadsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadAssociatedThreadsQuery, ReadAssociatedThreadsQueryVariables>(ReadAssociatedThreadsDocument, options);
        }
export function useReadAssociatedThreadsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ReadAssociatedThreadsQuery, ReadAssociatedThreadsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ReadAssociatedThreadsQuery, ReadAssociatedThreadsQueryVariables>(ReadAssociatedThreadsDocument, options);
        }
export type ReadAssociatedThreadsQueryHookResult = ReturnType<typeof useReadAssociatedThreadsQuery>;
export type ReadAssociatedThreadsLazyQueryHookResult = ReturnType<typeof useReadAssociatedThreadsLazyQuery>;
export type ReadAssociatedThreadsSuspenseQueryHookResult = ReturnType<typeof useReadAssociatedThreadsSuspenseQuery>;
export type ReadAssociatedThreadsQueryResult = Apollo.QueryResult<ReadAssociatedThreadsQuery, ReadAssociatedThreadsQueryVariables>;
export const ReadThreadDocument = gql`
    query ReadThread($legalEntityId: String!, $id: String!) {
  thread: readThread(legalEntityId: $legalEntityId, threadId: $id) {
    ...ThreadWithEntitiesFields
  }
}
    ${ThreadWithEntitiesFieldsFragmentDoc}`;

/**
 * __useReadThreadQuery__
 *
 * To run a query within a React component, call `useReadThreadQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadThreadQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadThreadQuery({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useReadThreadQuery(baseOptions: Apollo.QueryHookOptions<ReadThreadQuery, ReadThreadQueryVariables> & ({ variables: ReadThreadQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadThreadQuery, ReadThreadQueryVariables>(ReadThreadDocument, options);
      }
export function useReadThreadLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadThreadQuery, ReadThreadQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadThreadQuery, ReadThreadQueryVariables>(ReadThreadDocument, options);
        }
export function useReadThreadSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ReadThreadQuery, ReadThreadQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ReadThreadQuery, ReadThreadQueryVariables>(ReadThreadDocument, options);
        }
export type ReadThreadQueryHookResult = ReturnType<typeof useReadThreadQuery>;
export type ReadThreadLazyQueryHookResult = ReturnType<typeof useReadThreadLazyQuery>;
export type ReadThreadSuspenseQueryHookResult = ReturnType<typeof useReadThreadSuspenseQuery>;
export type ReadThreadQueryResult = Apollo.QueryResult<ReadThreadQuery, ReadThreadQueryVariables>;
export const RunMigrationPullRequestDocument = gql`
    mutation RunMigrationPullRequest($repo: String!, $prNumber: Int!, $filename: String!, $isPartitionableDML: Boolean!) {
  runMigrationPullRequest(
    repo: $repo
    prNumber: $prNumber
    filename: $filename
    isPartitionableDML: $isPartitionableDML
  )
}
    `;
export type RunMigrationPullRequestMutationFn = Apollo.MutationFunction<RunMigrationPullRequestMutation, RunMigrationPullRequestMutationVariables>;

/**
 * __useRunMigrationPullRequestMutation__
 *
 * To run a mutation, you first call `useRunMigrationPullRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRunMigrationPullRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [runMigrationPullRequestMutation, { data, loading, error }] = useRunMigrationPullRequestMutation({
 *   variables: {
 *      repo: // value for 'repo'
 *      prNumber: // value for 'prNumber'
 *      filename: // value for 'filename'
 *      isPartitionableDML: // value for 'isPartitionableDML'
 *   },
 * });
 */
export function useRunMigrationPullRequestMutation(baseOptions?: Apollo.MutationHookOptions<RunMigrationPullRequestMutation, RunMigrationPullRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RunMigrationPullRequestMutation, RunMigrationPullRequestMutationVariables>(RunMigrationPullRequestDocument, options);
      }
export type RunMigrationPullRequestMutationHookResult = ReturnType<typeof useRunMigrationPullRequestMutation>;
export type RunMigrationPullRequestMutationResult = Apollo.MutationResult<RunMigrationPullRequestMutation>;
export type RunMigrationPullRequestMutationOptions = Apollo.BaseMutationOptions<RunMigrationPullRequestMutation, RunMigrationPullRequestMutationVariables>;
export const MergeMigrationPullRequestDocument = gql`
    mutation MergeMigrationPullRequest($repo: String!, $prNumber: Int!) {
  mergeMigrationPullRequest(repo: $repo, prNumber: $prNumber)
}
    `;
export type MergeMigrationPullRequestMutationFn = Apollo.MutationFunction<MergeMigrationPullRequestMutation, MergeMigrationPullRequestMutationVariables>;

/**
 * __useMergeMigrationPullRequestMutation__
 *
 * To run a mutation, you first call `useMergeMigrationPullRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMergeMigrationPullRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mergeMigrationPullRequestMutation, { data, loading, error }] = useMergeMigrationPullRequestMutation({
 *   variables: {
 *      repo: // value for 'repo'
 *      prNumber: // value for 'prNumber'
 *   },
 * });
 */
export function useMergeMigrationPullRequestMutation(baseOptions?: Apollo.MutationHookOptions<MergeMigrationPullRequestMutation, MergeMigrationPullRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MergeMigrationPullRequestMutation, MergeMigrationPullRequestMutationVariables>(MergeMigrationPullRequestDocument, options);
      }
export type MergeMigrationPullRequestMutationHookResult = ReturnType<typeof useMergeMigrationPullRequestMutation>;
export type MergeMigrationPullRequestMutationResult = Apollo.MutationResult<MergeMigrationPullRequestMutation>;
export type MergeMigrationPullRequestMutationOptions = Apollo.BaseMutationOptions<MergeMigrationPullRequestMutation, MergeMigrationPullRequestMutationVariables>;
export const MigrateEmployeeToAffiliateDocument = gql`
    mutation MigrateEmployeeToAffiliate($userId: String!, $legalEntityId: String!, $organizationId: String, $organizationName: String) {
  migrateEmployeeToAffiliate(
    userId: $userId
    legalEntityId: $legalEntityId
    organizationId: $organizationId
    organizationName: $organizationName
  )
}
    `;
export type MigrateEmployeeToAffiliateMutationFn = Apollo.MutationFunction<MigrateEmployeeToAffiliateMutation, MigrateEmployeeToAffiliateMutationVariables>;

/**
 * __useMigrateEmployeeToAffiliateMutation__
 *
 * To run a mutation, you first call `useMigrateEmployeeToAffiliateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMigrateEmployeeToAffiliateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [migrateEmployeeToAffiliateMutation, { data, loading, error }] = useMigrateEmployeeToAffiliateMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      legalEntityId: // value for 'legalEntityId'
 *      organizationId: // value for 'organizationId'
 *      organizationName: // value for 'organizationName'
 *   },
 * });
 */
export function useMigrateEmployeeToAffiliateMutation(baseOptions?: Apollo.MutationHookOptions<MigrateEmployeeToAffiliateMutation, MigrateEmployeeToAffiliateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MigrateEmployeeToAffiliateMutation, MigrateEmployeeToAffiliateMutationVariables>(MigrateEmployeeToAffiliateDocument, options);
      }
export type MigrateEmployeeToAffiliateMutationHookResult = ReturnType<typeof useMigrateEmployeeToAffiliateMutation>;
export type MigrateEmployeeToAffiliateMutationResult = Apollo.MutationResult<MigrateEmployeeToAffiliateMutation>;
export type MigrateEmployeeToAffiliateMutationOptions = Apollo.BaseMutationOptions<MigrateEmployeeToAffiliateMutation, MigrateEmployeeToAffiliateMutationVariables>;
export const ReadMigrationPullRequestDocument = gql`
    query ReadMigrationPullRequest($repo: String!, $prNumber: Int!) {
  readMigrationPullRequest(repo: $repo, prNumber: $prNumber) {
    pullRequestSummary {
      pullRequest {
        ...PullRequestFields
      }
      reviewStatus
      mergeableStatus
      merged
    }
    fileSummaries {
      file {
        name
        contents
        url
      }
      hasRun
    }
  }
}
    ${PullRequestFieldsFragmentDoc}`;

/**
 * __useReadMigrationPullRequestQuery__
 *
 * To run a query within a React component, call `useReadMigrationPullRequestQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadMigrationPullRequestQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadMigrationPullRequestQuery({
 *   variables: {
 *      repo: // value for 'repo'
 *      prNumber: // value for 'prNumber'
 *   },
 * });
 */
export function useReadMigrationPullRequestQuery(baseOptions: Apollo.QueryHookOptions<ReadMigrationPullRequestQuery, ReadMigrationPullRequestQueryVariables> & ({ variables: ReadMigrationPullRequestQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadMigrationPullRequestQuery, ReadMigrationPullRequestQueryVariables>(ReadMigrationPullRequestDocument, options);
      }
export function useReadMigrationPullRequestLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadMigrationPullRequestQuery, ReadMigrationPullRequestQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadMigrationPullRequestQuery, ReadMigrationPullRequestQueryVariables>(ReadMigrationPullRequestDocument, options);
        }
export function useReadMigrationPullRequestSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ReadMigrationPullRequestQuery, ReadMigrationPullRequestQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ReadMigrationPullRequestQuery, ReadMigrationPullRequestQueryVariables>(ReadMigrationPullRequestDocument, options);
        }
export type ReadMigrationPullRequestQueryHookResult = ReturnType<typeof useReadMigrationPullRequestQuery>;
export type ReadMigrationPullRequestLazyQueryHookResult = ReturnType<typeof useReadMigrationPullRequestLazyQuery>;
export type ReadMigrationPullRequestSuspenseQueryHookResult = ReturnType<typeof useReadMigrationPullRequestSuspenseQuery>;
export type ReadMigrationPullRequestQueryResult = Apollo.QueryResult<ReadMigrationPullRequestQuery, ReadMigrationPullRequestQueryVariables>;
export const ListMigrationPullRequestsDocument = gql`
    query ListMigrationPullRequests($repo: String!, $anyLabel: Boolean, $prState: PullRequestFilterState) {
  listMigrationPullRequests(repo: $repo, anyLabel: $anyLabel, prState: $prState) {
    ...PullRequestFields
  }
}
    ${PullRequestFieldsFragmentDoc}`;

/**
 * __useListMigrationPullRequestsQuery__
 *
 * To run a query within a React component, call `useListMigrationPullRequestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListMigrationPullRequestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListMigrationPullRequestsQuery({
 *   variables: {
 *      repo: // value for 'repo'
 *      anyLabel: // value for 'anyLabel'
 *      prState: // value for 'prState'
 *   },
 * });
 */
export function useListMigrationPullRequestsQuery(baseOptions: Apollo.QueryHookOptions<ListMigrationPullRequestsQuery, ListMigrationPullRequestsQueryVariables> & ({ variables: ListMigrationPullRequestsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListMigrationPullRequestsQuery, ListMigrationPullRequestsQueryVariables>(ListMigrationPullRequestsDocument, options);
      }
export function useListMigrationPullRequestsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListMigrationPullRequestsQuery, ListMigrationPullRequestsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListMigrationPullRequestsQuery, ListMigrationPullRequestsQueryVariables>(ListMigrationPullRequestsDocument, options);
        }
export function useListMigrationPullRequestsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ListMigrationPullRequestsQuery, ListMigrationPullRequestsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ListMigrationPullRequestsQuery, ListMigrationPullRequestsQueryVariables>(ListMigrationPullRequestsDocument, options);
        }
export type ListMigrationPullRequestsQueryHookResult = ReturnType<typeof useListMigrationPullRequestsQuery>;
export type ListMigrationPullRequestsLazyQueryHookResult = ReturnType<typeof useListMigrationPullRequestsLazyQuery>;
export type ListMigrationPullRequestsSuspenseQueryHookResult = ReturnType<typeof useListMigrationPullRequestsSuspenseQuery>;
export type ListMigrationPullRequestsQueryResult = Apollo.QueryResult<ListMigrationPullRequestsQuery, ListMigrationPullRequestsQueryVariables>;
export const DeletePrefixMappingDocument = gql`
    mutation DeletePrefixMapping($prefix: String!, $partyId: String!) {
  deletePrefixMapping(prefix: $prefix, partyId: $partyId)
}
    `;
export type DeletePrefixMappingMutationFn = Apollo.MutationFunction<DeletePrefixMappingMutation, DeletePrefixMappingMutationVariables>;

/**
 * __useDeletePrefixMappingMutation__
 *
 * To run a mutation, you first call `useDeletePrefixMappingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePrefixMappingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePrefixMappingMutation, { data, loading, error }] = useDeletePrefixMappingMutation({
 *   variables: {
 *      prefix: // value for 'prefix'
 *      partyId: // value for 'partyId'
 *   },
 * });
 */
export function useDeletePrefixMappingMutation(baseOptions?: Apollo.MutationHookOptions<DeletePrefixMappingMutation, DeletePrefixMappingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletePrefixMappingMutation, DeletePrefixMappingMutationVariables>(DeletePrefixMappingDocument, options);
      }
export type DeletePrefixMappingMutationHookResult = ReturnType<typeof useDeletePrefixMappingMutation>;
export type DeletePrefixMappingMutationResult = Apollo.MutationResult<DeletePrefixMappingMutation>;
export type DeletePrefixMappingMutationOptions = Apollo.BaseMutationOptions<DeletePrefixMappingMutation, DeletePrefixMappingMutationVariables>;
export const IgnorePrefixDocument = gql`
    mutation IgnorePrefix($prefix: String!) {
  ignorePrefix(prefix: $prefix)
}
    `;
export type IgnorePrefixMutationFn = Apollo.MutationFunction<IgnorePrefixMutation, IgnorePrefixMutationVariables>;

/**
 * __useIgnorePrefixMutation__
 *
 * To run a mutation, you first call `useIgnorePrefixMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIgnorePrefixMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [ignorePrefixMutation, { data, loading, error }] = useIgnorePrefixMutation({
 *   variables: {
 *      prefix: // value for 'prefix'
 *   },
 * });
 */
export function useIgnorePrefixMutation(baseOptions?: Apollo.MutationHookOptions<IgnorePrefixMutation, IgnorePrefixMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IgnorePrefixMutation, IgnorePrefixMutationVariables>(IgnorePrefixDocument, options);
      }
export type IgnorePrefixMutationHookResult = ReturnType<typeof useIgnorePrefixMutation>;
export type IgnorePrefixMutationResult = Apollo.MutationResult<IgnorePrefixMutation>;
export type IgnorePrefixMutationOptions = Apollo.BaseMutationOptions<IgnorePrefixMutation, IgnorePrefixMutationVariables>;
export const UnignorePrefixDocument = gql`
    mutation UnignorePrefix($prefix: String!) {
  unignorePrefix(prefix: $prefix)
}
    `;
export type UnignorePrefixMutationFn = Apollo.MutationFunction<UnignorePrefixMutation, UnignorePrefixMutationVariables>;

/**
 * __useUnignorePrefixMutation__
 *
 * To run a mutation, you first call `useUnignorePrefixMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnignorePrefixMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unignorePrefixMutation, { data, loading, error }] = useUnignorePrefixMutation({
 *   variables: {
 *      prefix: // value for 'prefix'
 *   },
 * });
 */
export function useUnignorePrefixMutation(baseOptions?: Apollo.MutationHookOptions<UnignorePrefixMutation, UnignorePrefixMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnignorePrefixMutation, UnignorePrefixMutationVariables>(UnignorePrefixDocument, options);
      }
export type UnignorePrefixMutationHookResult = ReturnType<typeof useUnignorePrefixMutation>;
export type UnignorePrefixMutationResult = Apollo.MutationResult<UnignorePrefixMutation>;
export type UnignorePrefixMutationOptions = Apollo.BaseMutationOptions<UnignorePrefixMutation, UnignorePrefixMutationVariables>;
export const ListClusteredPrefixesDocument = gql`
    query ListClusteredPrefixes($legalEntityId: String) {
  listClusteredPrefixes(legalEntityId: $legalEntityId) {
    ...ClusteredPrefixFields
  }
}
    ${ClusteredPrefixFieldsFragmentDoc}`;

/**
 * __useListClusteredPrefixesQuery__
 *
 * To run a query within a React component, call `useListClusteredPrefixesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListClusteredPrefixesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListClusteredPrefixesQuery({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *   },
 * });
 */
export function useListClusteredPrefixesQuery(baseOptions?: Apollo.QueryHookOptions<ListClusteredPrefixesQuery, ListClusteredPrefixesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListClusteredPrefixesQuery, ListClusteredPrefixesQueryVariables>(ListClusteredPrefixesDocument, options);
      }
export function useListClusteredPrefixesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListClusteredPrefixesQuery, ListClusteredPrefixesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListClusteredPrefixesQuery, ListClusteredPrefixesQueryVariables>(ListClusteredPrefixesDocument, options);
        }
export function useListClusteredPrefixesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ListClusteredPrefixesQuery, ListClusteredPrefixesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ListClusteredPrefixesQuery, ListClusteredPrefixesQueryVariables>(ListClusteredPrefixesDocument, options);
        }
export type ListClusteredPrefixesQueryHookResult = ReturnType<typeof useListClusteredPrefixesQuery>;
export type ListClusteredPrefixesLazyQueryHookResult = ReturnType<typeof useListClusteredPrefixesLazyQuery>;
export type ListClusteredPrefixesSuspenseQueryHookResult = ReturnType<typeof useListClusteredPrefixesSuspenseQuery>;
export type ListClusteredPrefixesQueryResult = Apollo.QueryResult<ListClusteredPrefixesQuery, ListClusteredPrefixesQueryVariables>;
export const ListSanitizedDescriptionsDocument = gql`
    query ListSanitizedDescriptions($prefix: String!) {
  listSanitizedDescriptions(prefix: $prefix) {
    ...SanitizedDescriptionFields
  }
}
    ${SanitizedDescriptionFieldsFragmentDoc}`;

/**
 * __useListSanitizedDescriptionsQuery__
 *
 * To run a query within a React component, call `useListSanitizedDescriptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListSanitizedDescriptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListSanitizedDescriptionsQuery({
 *   variables: {
 *      prefix: // value for 'prefix'
 *   },
 * });
 */
export function useListSanitizedDescriptionsQuery(baseOptions: Apollo.QueryHookOptions<ListSanitizedDescriptionsQuery, ListSanitizedDescriptionsQueryVariables> & ({ variables: ListSanitizedDescriptionsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListSanitizedDescriptionsQuery, ListSanitizedDescriptionsQueryVariables>(ListSanitizedDescriptionsDocument, options);
      }
export function useListSanitizedDescriptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListSanitizedDescriptionsQuery, ListSanitizedDescriptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListSanitizedDescriptionsQuery, ListSanitizedDescriptionsQueryVariables>(ListSanitizedDescriptionsDocument, options);
        }
export function useListSanitizedDescriptionsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ListSanitizedDescriptionsQuery, ListSanitizedDescriptionsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ListSanitizedDescriptionsQuery, ListSanitizedDescriptionsQueryVariables>(ListSanitizedDescriptionsDocument, options);
        }
export type ListSanitizedDescriptionsQueryHookResult = ReturnType<typeof useListSanitizedDescriptionsQuery>;
export type ListSanitizedDescriptionsLazyQueryHookResult = ReturnType<typeof useListSanitizedDescriptionsLazyQuery>;
export type ListSanitizedDescriptionsSuspenseQueryHookResult = ReturnType<typeof useListSanitizedDescriptionsSuspenseQuery>;
export type ListSanitizedDescriptionsQueryResult = Apollo.QueryResult<ListSanitizedDescriptionsQuery, ListSanitizedDescriptionsQueryVariables>;
export const ListPrefixMappingsDocument = gql`
    query ListPrefixMappings($filter: PrefixFilter!) {
  listPrefixMappings(filter: $filter) {
    ...PrefixMappingSummaryFields
  }
}
    ${PrefixMappingSummaryFieldsFragmentDoc}`;

/**
 * __useListPrefixMappingsQuery__
 *
 * To run a query within a React component, call `useListPrefixMappingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListPrefixMappingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListPrefixMappingsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useListPrefixMappingsQuery(baseOptions: Apollo.QueryHookOptions<ListPrefixMappingsQuery, ListPrefixMappingsQueryVariables> & ({ variables: ListPrefixMappingsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListPrefixMappingsQuery, ListPrefixMappingsQueryVariables>(ListPrefixMappingsDocument, options);
      }
export function useListPrefixMappingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListPrefixMappingsQuery, ListPrefixMappingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListPrefixMappingsQuery, ListPrefixMappingsQueryVariables>(ListPrefixMappingsDocument, options);
        }
export function useListPrefixMappingsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ListPrefixMappingsQuery, ListPrefixMappingsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ListPrefixMappingsQuery, ListPrefixMappingsQueryVariables>(ListPrefixMappingsDocument, options);
        }
export type ListPrefixMappingsQueryHookResult = ReturnType<typeof useListPrefixMappingsQuery>;
export type ListPrefixMappingsLazyQueryHookResult = ReturnType<typeof useListPrefixMappingsLazyQuery>;
export type ListPrefixMappingsSuspenseQueryHookResult = ReturnType<typeof useListPrefixMappingsSuspenseQuery>;
export type ListPrefixMappingsQueryResult = Apollo.QueryResult<ListPrefixMappingsQuery, ListPrefixMappingsQueryVariables>;
export const ListIgnoredPrefixesDocument = gql`
    query ListIgnoredPrefixes {
  listIgnoredPrefixes
}
    `;

/**
 * __useListIgnoredPrefixesQuery__
 *
 * To run a query within a React component, call `useListIgnoredPrefixesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListIgnoredPrefixesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListIgnoredPrefixesQuery({
 *   variables: {
 *   },
 * });
 */
export function useListIgnoredPrefixesQuery(baseOptions?: Apollo.QueryHookOptions<ListIgnoredPrefixesQuery, ListIgnoredPrefixesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListIgnoredPrefixesQuery, ListIgnoredPrefixesQueryVariables>(ListIgnoredPrefixesDocument, options);
      }
export function useListIgnoredPrefixesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListIgnoredPrefixesQuery, ListIgnoredPrefixesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListIgnoredPrefixesQuery, ListIgnoredPrefixesQueryVariables>(ListIgnoredPrefixesDocument, options);
        }
export function useListIgnoredPrefixesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ListIgnoredPrefixesQuery, ListIgnoredPrefixesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ListIgnoredPrefixesQuery, ListIgnoredPrefixesQueryVariables>(ListIgnoredPrefixesDocument, options);
        }
export type ListIgnoredPrefixesQueryHookResult = ReturnType<typeof useListIgnoredPrefixesQuery>;
export type ListIgnoredPrefixesLazyQueryHookResult = ReturnType<typeof useListIgnoredPrefixesLazyQuery>;
export type ListIgnoredPrefixesSuspenseQueryHookResult = ReturnType<typeof useListIgnoredPrefixesSuspenseQuery>;
export type ListIgnoredPrefixesQueryResult = Apollo.QueryResult<ListIgnoredPrefixesQuery, ListIgnoredPrefixesQueryVariables>;
export const TriggerDenormalizedViewGenerationDocument = gql`
    mutation TriggerDenormalizedViewGeneration($legalEntityId: String!) {
  triggerDenormalizedViewGeneration(legalEntityId: $legalEntityId)
}
    `;
export type TriggerDenormalizedViewGenerationMutationFn = Apollo.MutationFunction<TriggerDenormalizedViewGenerationMutation, TriggerDenormalizedViewGenerationMutationVariables>;

/**
 * __useTriggerDenormalizedViewGenerationMutation__
 *
 * To run a mutation, you first call `useTriggerDenormalizedViewGenerationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTriggerDenormalizedViewGenerationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [triggerDenormalizedViewGenerationMutation, { data, loading, error }] = useTriggerDenormalizedViewGenerationMutation({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *   },
 * });
 */
export function useTriggerDenormalizedViewGenerationMutation(baseOptions?: Apollo.MutationHookOptions<TriggerDenormalizedViewGenerationMutation, TriggerDenormalizedViewGenerationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TriggerDenormalizedViewGenerationMutation, TriggerDenormalizedViewGenerationMutationVariables>(TriggerDenormalizedViewGenerationDocument, options);
      }
export type TriggerDenormalizedViewGenerationMutationHookResult = ReturnType<typeof useTriggerDenormalizedViewGenerationMutation>;
export type TriggerDenormalizedViewGenerationMutationResult = Apollo.MutationResult<TriggerDenormalizedViewGenerationMutation>;
export type TriggerDenormalizedViewGenerationMutationOptions = Apollo.BaseMutationOptions<TriggerDenormalizedViewGenerationMutation, TriggerDenormalizedViewGenerationMutationVariables>;
export const OnboardDocument = gql`
    mutation Onboard($legalEntityId: String!) {
  onboard(legalEntityId: $legalEntityId)
}
    `;
export type OnboardMutationFn = Apollo.MutationFunction<OnboardMutation, OnboardMutationVariables>;

/**
 * __useOnboardMutation__
 *
 * To run a mutation, you first call `useOnboardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOnboardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [onboardMutation, { data, loading, error }] = useOnboardMutation({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *   },
 * });
 */
export function useOnboardMutation(baseOptions?: Apollo.MutationHookOptions<OnboardMutation, OnboardMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<OnboardMutation, OnboardMutationVariables>(OnboardDocument, options);
      }
export type OnboardMutationHookResult = ReturnType<typeof useOnboardMutation>;
export type OnboardMutationResult = Apollo.MutationResult<OnboardMutation>;
export type OnboardMutationOptions = Apollo.BaseMutationOptions<OnboardMutation, OnboardMutationVariables>;
export const ReadEntityDataProcessingStatusesDocument = gql`
    query ReadEntityDataProcessingStatuses($legalEntityId: String!) {
  readEntityDataProcessingStatuses(legalEntityId: $legalEntityId) {
    entries {
      ...DenormalizedViewStatusFields
    }
  }
}
    ${DenormalizedViewStatusFieldsFragmentDoc}`;

/**
 * __useReadEntityDataProcessingStatusesQuery__
 *
 * To run a query within a React component, call `useReadEntityDataProcessingStatusesQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadEntityDataProcessingStatusesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadEntityDataProcessingStatusesQuery({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *   },
 * });
 */
export function useReadEntityDataProcessingStatusesQuery(baseOptions: Apollo.QueryHookOptions<ReadEntityDataProcessingStatusesQuery, ReadEntityDataProcessingStatusesQueryVariables> & ({ variables: ReadEntityDataProcessingStatusesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadEntityDataProcessingStatusesQuery, ReadEntityDataProcessingStatusesQueryVariables>(ReadEntityDataProcessingStatusesDocument, options);
      }
export function useReadEntityDataProcessingStatusesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadEntityDataProcessingStatusesQuery, ReadEntityDataProcessingStatusesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadEntityDataProcessingStatusesQuery, ReadEntityDataProcessingStatusesQueryVariables>(ReadEntityDataProcessingStatusesDocument, options);
        }
export function useReadEntityDataProcessingStatusesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ReadEntityDataProcessingStatusesQuery, ReadEntityDataProcessingStatusesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ReadEntityDataProcessingStatusesQuery, ReadEntityDataProcessingStatusesQueryVariables>(ReadEntityDataProcessingStatusesDocument, options);
        }
export type ReadEntityDataProcessingStatusesQueryHookResult = ReturnType<typeof useReadEntityDataProcessingStatusesQuery>;
export type ReadEntityDataProcessingStatusesLazyQueryHookResult = ReturnType<typeof useReadEntityDataProcessingStatusesLazyQuery>;
export type ReadEntityDataProcessingStatusesSuspenseQueryHookResult = ReturnType<typeof useReadEntityDataProcessingStatusesSuspenseQuery>;
export type ReadEntityDataProcessingStatusesQueryResult = Apollo.QueryResult<ReadEntityDataProcessingStatusesQuery, ReadEntityDataProcessingStatusesQueryVariables>;
export const ListAllDataProcessingStatusesDocument = gql`
    query ListAllDataProcessingStatuses {
  listAllDataProcessingStatuses {
    entries {
      ...DenormalizedViewStatusFields
    }
  }
}
    ${DenormalizedViewStatusFieldsFragmentDoc}`;

/**
 * __useListAllDataProcessingStatusesQuery__
 *
 * To run a query within a React component, call `useListAllDataProcessingStatusesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListAllDataProcessingStatusesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListAllDataProcessingStatusesQuery({
 *   variables: {
 *   },
 * });
 */
export function useListAllDataProcessingStatusesQuery(baseOptions?: Apollo.QueryHookOptions<ListAllDataProcessingStatusesQuery, ListAllDataProcessingStatusesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListAllDataProcessingStatusesQuery, ListAllDataProcessingStatusesQueryVariables>(ListAllDataProcessingStatusesDocument, options);
      }
export function useListAllDataProcessingStatusesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListAllDataProcessingStatusesQuery, ListAllDataProcessingStatusesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListAllDataProcessingStatusesQuery, ListAllDataProcessingStatusesQueryVariables>(ListAllDataProcessingStatusesDocument, options);
        }
export function useListAllDataProcessingStatusesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ListAllDataProcessingStatusesQuery, ListAllDataProcessingStatusesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ListAllDataProcessingStatusesQuery, ListAllDataProcessingStatusesQueryVariables>(ListAllDataProcessingStatusesDocument, options);
        }
export type ListAllDataProcessingStatusesQueryHookResult = ReturnType<typeof useListAllDataProcessingStatusesQuery>;
export type ListAllDataProcessingStatusesLazyQueryHookResult = ReturnType<typeof useListAllDataProcessingStatusesLazyQuery>;
export type ListAllDataProcessingStatusesSuspenseQueryHookResult = ReturnType<typeof useListAllDataProcessingStatusesSuspenseQuery>;
export type ListAllDataProcessingStatusesQueryResult = Apollo.QueryResult<ListAllDataProcessingStatusesQuery, ListAllDataProcessingStatusesQueryVariables>;
export const ListCsvImportsDocument = gql`
    query ListCsvImports($legalEntityId: String!, $pagination: Pagination!, $order: CsvImportSort) {
  listCsvImports(
    legalEntityId: $legalEntityId
    pagination: $pagination
    order: $order
  ) {
    csvImportId
    dataSourceId
    fileName
    transactionCount
    source
    createdAt
    deletedAt
  }
}
    `;

/**
 * __useListCsvImportsQuery__
 *
 * To run a query within a React component, call `useListCsvImportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListCsvImportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListCsvImportsQuery({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useListCsvImportsQuery(baseOptions: Apollo.QueryHookOptions<ListCsvImportsQuery, ListCsvImportsQueryVariables> & ({ variables: ListCsvImportsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListCsvImportsQuery, ListCsvImportsQueryVariables>(ListCsvImportsDocument, options);
      }
export function useListCsvImportsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListCsvImportsQuery, ListCsvImportsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListCsvImportsQuery, ListCsvImportsQueryVariables>(ListCsvImportsDocument, options);
        }
export function useListCsvImportsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ListCsvImportsQuery, ListCsvImportsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ListCsvImportsQuery, ListCsvImportsQueryVariables>(ListCsvImportsDocument, options);
        }
export type ListCsvImportsQueryHookResult = ReturnType<typeof useListCsvImportsQuery>;
export type ListCsvImportsLazyQueryHookResult = ReturnType<typeof useListCsvImportsLazyQuery>;
export type ListCsvImportsSuspenseQueryHookResult = ReturnType<typeof useListCsvImportsSuspenseQuery>;
export type ListCsvImportsQueryResult = Apollo.QueryResult<ListCsvImportsQuery, ListCsvImportsQueryVariables>;
export const ListCsvImportTransactionFactsDocument = gql`
    query ListCsvImportTransactionFacts($legalEntityId: String!, $csvImportId: String!, $pagination: Pagination) {
  listCsvImportTransactionFacts(
    legalEntityId: $legalEntityId
    csvImportId: $csvImportId
    pagination: $pagination
  )
}
    `;

/**
 * __useListCsvImportTransactionFactsQuery__
 *
 * To run a query within a React component, call `useListCsvImportTransactionFactsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListCsvImportTransactionFactsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListCsvImportTransactionFactsQuery({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *      csvImportId: // value for 'csvImportId'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useListCsvImportTransactionFactsQuery(baseOptions: Apollo.QueryHookOptions<ListCsvImportTransactionFactsQuery, ListCsvImportTransactionFactsQueryVariables> & ({ variables: ListCsvImportTransactionFactsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListCsvImportTransactionFactsQuery, ListCsvImportTransactionFactsQueryVariables>(ListCsvImportTransactionFactsDocument, options);
      }
export function useListCsvImportTransactionFactsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListCsvImportTransactionFactsQuery, ListCsvImportTransactionFactsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListCsvImportTransactionFactsQuery, ListCsvImportTransactionFactsQueryVariables>(ListCsvImportTransactionFactsDocument, options);
        }
export function useListCsvImportTransactionFactsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ListCsvImportTransactionFactsQuery, ListCsvImportTransactionFactsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ListCsvImportTransactionFactsQuery, ListCsvImportTransactionFactsQueryVariables>(ListCsvImportTransactionFactsDocument, options);
        }
export type ListCsvImportTransactionFactsQueryHookResult = ReturnType<typeof useListCsvImportTransactionFactsQuery>;
export type ListCsvImportTransactionFactsLazyQueryHookResult = ReturnType<typeof useListCsvImportTransactionFactsLazyQuery>;
export type ListCsvImportTransactionFactsSuspenseQueryHookResult = ReturnType<typeof useListCsvImportTransactionFactsSuspenseQuery>;
export type ListCsvImportTransactionFactsQueryResult = Apollo.QueryResult<ListCsvImportTransactionFactsQuery, ListCsvImportTransactionFactsQueryVariables>;
export const ResearchSalesLeadAgentDocument = gql`
    subscription ResearchSalesLeadAgent($value: String!) {
  researchSalesLeadAgent(value: $value) {
    agentEvent {
      runStatus
      message
    }
  }
}
    `;

/**
 * __useResearchSalesLeadAgentSubscription__
 *
 * To run a query within a React component, call `useResearchSalesLeadAgentSubscription` and pass it any options that fit your needs.
 * When your component renders, `useResearchSalesLeadAgentSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useResearchSalesLeadAgentSubscription({
 *   variables: {
 *      value: // value for 'value'
 *   },
 * });
 */
export function useResearchSalesLeadAgentSubscription(baseOptions: Apollo.SubscriptionHookOptions<ResearchSalesLeadAgentSubscription, ResearchSalesLeadAgentSubscriptionVariables> & ({ variables: ResearchSalesLeadAgentSubscriptionVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<ResearchSalesLeadAgentSubscription, ResearchSalesLeadAgentSubscriptionVariables>(ResearchSalesLeadAgentDocument, options);
      }
export type ResearchSalesLeadAgentSubscriptionHookResult = ReturnType<typeof useResearchSalesLeadAgentSubscription>;
export type ResearchSalesLeadAgentSubscriptionResult = Apollo.SubscriptionResult<ResearchSalesLeadAgentSubscription>;
export const HydratePartyAgentDocument = gql`
    subscription HydratePartyAgent($value: String!) {
  hydratePartyAgent(value: $value) {
    agentEvent {
      runStatus
      message
    }
  }
}
    `;

/**
 * __useHydratePartyAgentSubscription__
 *
 * To run a query within a React component, call `useHydratePartyAgentSubscription` and pass it any options that fit your needs.
 * When your component renders, `useHydratePartyAgentSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHydratePartyAgentSubscription({
 *   variables: {
 *      value: // value for 'value'
 *   },
 * });
 */
export function useHydratePartyAgentSubscription(baseOptions: Apollo.SubscriptionHookOptions<HydratePartyAgentSubscription, HydratePartyAgentSubscriptionVariables> & ({ variables: HydratePartyAgentSubscriptionVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<HydratePartyAgentSubscription, HydratePartyAgentSubscriptionVariables>(HydratePartyAgentDocument, options);
      }
export type HydratePartyAgentSubscriptionHookResult = ReturnType<typeof useHydratePartyAgentSubscription>;
export type HydratePartyAgentSubscriptionResult = Apollo.SubscriptionResult<HydratePartyAgentSubscription>;
export const ListAvailableSuperuserRolesDocument = gql`
    query ListAvailableSuperuserRoles {
  listAvailableSuperuserRoles {
    ...SuperuserRoleFields
  }
}
    ${SuperuserRoleFieldsFragmentDoc}`;

/**
 * __useListAvailableSuperuserRolesQuery__
 *
 * To run a query within a React component, call `useListAvailableSuperuserRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListAvailableSuperuserRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListAvailableSuperuserRolesQuery({
 *   variables: {
 *   },
 * });
 */
export function useListAvailableSuperuserRolesQuery(baseOptions?: Apollo.QueryHookOptions<ListAvailableSuperuserRolesQuery, ListAvailableSuperuserRolesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListAvailableSuperuserRolesQuery, ListAvailableSuperuserRolesQueryVariables>(ListAvailableSuperuserRolesDocument, options);
      }
export function useListAvailableSuperuserRolesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListAvailableSuperuserRolesQuery, ListAvailableSuperuserRolesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListAvailableSuperuserRolesQuery, ListAvailableSuperuserRolesQueryVariables>(ListAvailableSuperuserRolesDocument, options);
        }
export function useListAvailableSuperuserRolesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ListAvailableSuperuserRolesQuery, ListAvailableSuperuserRolesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ListAvailableSuperuserRolesQuery, ListAvailableSuperuserRolesQueryVariables>(ListAvailableSuperuserRolesDocument, options);
        }
export type ListAvailableSuperuserRolesQueryHookResult = ReturnType<typeof useListAvailableSuperuserRolesQuery>;
export type ListAvailableSuperuserRolesLazyQueryHookResult = ReturnType<typeof useListAvailableSuperuserRolesLazyQuery>;
export type ListAvailableSuperuserRolesSuspenseQueryHookResult = ReturnType<typeof useListAvailableSuperuserRolesSuspenseQuery>;
export type ListAvailableSuperuserRolesQueryResult = Apollo.QueryResult<ListAvailableSuperuserRolesQuery, ListAvailableSuperuserRolesQueryVariables>;
export const ListLineClassFactHierarchyDocument = gql`
    query ListLineClassFactHierarchy($legalEntityId: String!) {
  listLineClassFactHierarchy(legalEntityId: $legalEntityId) {
    ...ObjectMappingHierarchyNodeFields
  }
}
    ${ObjectMappingHierarchyNodeFieldsFragmentDoc}`;

/**
 * __useListLineClassFactHierarchyQuery__
 *
 * To run a query within a React component, call `useListLineClassFactHierarchyQuery` and pass it any options that fit your needs.
 * When your component renders, `useListLineClassFactHierarchyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListLineClassFactHierarchyQuery({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *   },
 * });
 */
export function useListLineClassFactHierarchyQuery(baseOptions: Apollo.QueryHookOptions<ListLineClassFactHierarchyQuery, ListLineClassFactHierarchyQueryVariables> & ({ variables: ListLineClassFactHierarchyQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListLineClassFactHierarchyQuery, ListLineClassFactHierarchyQueryVariables>(ListLineClassFactHierarchyDocument, options);
      }
export function useListLineClassFactHierarchyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListLineClassFactHierarchyQuery, ListLineClassFactHierarchyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListLineClassFactHierarchyQuery, ListLineClassFactHierarchyQueryVariables>(ListLineClassFactHierarchyDocument, options);
        }
export function useListLineClassFactHierarchySuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ListLineClassFactHierarchyQuery, ListLineClassFactHierarchyQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ListLineClassFactHierarchyQuery, ListLineClassFactHierarchyQueryVariables>(ListLineClassFactHierarchyDocument, options);
        }
export type ListLineClassFactHierarchyQueryHookResult = ReturnType<typeof useListLineClassFactHierarchyQuery>;
export type ListLineClassFactHierarchyLazyQueryHookResult = ReturnType<typeof useListLineClassFactHierarchyLazyQuery>;
export type ListLineClassFactHierarchySuspenseQueryHookResult = ReturnType<typeof useListLineClassFactHierarchySuspenseQuery>;
export type ListLineClassFactHierarchyQueryResult = Apollo.QueryResult<ListLineClassFactHierarchyQuery, ListLineClassFactHierarchyQueryVariables>;
export const ListDepartmentFactHierarchyDocument = gql`
    query ListDepartmentFactHierarchy($legalEntityId: String!) {
  listDepartmentFactHierarchy(legalEntityId: $legalEntityId) {
    ...ObjectMappingHierarchyNodeFields
  }
}
    ${ObjectMappingHierarchyNodeFieldsFragmentDoc}`;

/**
 * __useListDepartmentFactHierarchyQuery__
 *
 * To run a query within a React component, call `useListDepartmentFactHierarchyQuery` and pass it any options that fit your needs.
 * When your component renders, `useListDepartmentFactHierarchyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListDepartmentFactHierarchyQuery({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *   },
 * });
 */
export function useListDepartmentFactHierarchyQuery(baseOptions: Apollo.QueryHookOptions<ListDepartmentFactHierarchyQuery, ListDepartmentFactHierarchyQueryVariables> & ({ variables: ListDepartmentFactHierarchyQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListDepartmentFactHierarchyQuery, ListDepartmentFactHierarchyQueryVariables>(ListDepartmentFactHierarchyDocument, options);
      }
export function useListDepartmentFactHierarchyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListDepartmentFactHierarchyQuery, ListDepartmentFactHierarchyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListDepartmentFactHierarchyQuery, ListDepartmentFactHierarchyQueryVariables>(ListDepartmentFactHierarchyDocument, options);
        }
export function useListDepartmentFactHierarchySuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ListDepartmentFactHierarchyQuery, ListDepartmentFactHierarchyQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ListDepartmentFactHierarchyQuery, ListDepartmentFactHierarchyQueryVariables>(ListDepartmentFactHierarchyDocument, options);
        }
export type ListDepartmentFactHierarchyQueryHookResult = ReturnType<typeof useListDepartmentFactHierarchyQuery>;
export type ListDepartmentFactHierarchyLazyQueryHookResult = ReturnType<typeof useListDepartmentFactHierarchyLazyQuery>;
export type ListDepartmentFactHierarchySuspenseQueryHookResult = ReturnType<typeof useListDepartmentFactHierarchySuspenseQuery>;
export type ListDepartmentFactHierarchyQueryResult = Apollo.QueryResult<ListDepartmentFactHierarchyQuery, ListDepartmentFactHierarchyQueryVariables>;
export const TriggerDigitsLedgerReviewDocument = gql`
    mutation TriggerDigitsLedgerReview($legalEntityId: String!, $ledgerType: LedgerType!) {
  triggerDigitsLedgerReview(
    legalEntityId: $legalEntityId
    ledgerType: $ledgerType
  )
}
    `;
export type TriggerDigitsLedgerReviewMutationFn = Apollo.MutationFunction<TriggerDigitsLedgerReviewMutation, TriggerDigitsLedgerReviewMutationVariables>;

/**
 * __useTriggerDigitsLedgerReviewMutation__
 *
 * To run a mutation, you first call `useTriggerDigitsLedgerReviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTriggerDigitsLedgerReviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [triggerDigitsLedgerReviewMutation, { data, loading, error }] = useTriggerDigitsLedgerReviewMutation({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *      ledgerType: // value for 'ledgerType'
 *   },
 * });
 */
export function useTriggerDigitsLedgerReviewMutation(baseOptions?: Apollo.MutationHookOptions<TriggerDigitsLedgerReviewMutation, TriggerDigitsLedgerReviewMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TriggerDigitsLedgerReviewMutation, TriggerDigitsLedgerReviewMutationVariables>(TriggerDigitsLedgerReviewDocument, options);
      }
export type TriggerDigitsLedgerReviewMutationHookResult = ReturnType<typeof useTriggerDigitsLedgerReviewMutation>;
export type TriggerDigitsLedgerReviewMutationResult = Apollo.MutationResult<TriggerDigitsLedgerReviewMutation>;
export type TriggerDigitsLedgerReviewMutationOptions = Apollo.BaseMutationOptions<TriggerDigitsLedgerReviewMutation, TriggerDigitsLedgerReviewMutationVariables>;
export const ReadAdminVanityStatsDocument = gql`
    query ReadAdminVanityStats($legalEntityId: String!) {
  readVanityStats(legalEntityId: $legalEntityId) {
    ...AdminVanityStatsFields
  }
}
    ${AdminVanityStatsFieldsFragmentDoc}`;

/**
 * __useReadAdminVanityStatsQuery__
 *
 * To run a query within a React component, call `useReadAdminVanityStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadAdminVanityStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadAdminVanityStatsQuery({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *   },
 * });
 */
export function useReadAdminVanityStatsQuery(baseOptions: Apollo.QueryHookOptions<ReadAdminVanityStatsQuery, ReadAdminVanityStatsQueryVariables> & ({ variables: ReadAdminVanityStatsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadAdminVanityStatsQuery, ReadAdminVanityStatsQueryVariables>(ReadAdminVanityStatsDocument, options);
      }
export function useReadAdminVanityStatsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadAdminVanityStatsQuery, ReadAdminVanityStatsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadAdminVanityStatsQuery, ReadAdminVanityStatsQueryVariables>(ReadAdminVanityStatsDocument, options);
        }
export function useReadAdminVanityStatsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ReadAdminVanityStatsQuery, ReadAdminVanityStatsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ReadAdminVanityStatsQuery, ReadAdminVanityStatsQueryVariables>(ReadAdminVanityStatsDocument, options);
        }
export type ReadAdminVanityStatsQueryHookResult = ReturnType<typeof useReadAdminVanityStatsQuery>;
export type ReadAdminVanityStatsLazyQueryHookResult = ReturnType<typeof useReadAdminVanityStatsLazyQuery>;
export type ReadAdminVanityStatsSuspenseQueryHookResult = ReturnType<typeof useReadAdminVanityStatsSuspenseQuery>;
export type ReadAdminVanityStatsQueryResult = Apollo.QueryResult<ReadAdminVanityStatsQuery, ReadAdminVanityStatsQueryVariables>;
export const ReadAdminGlobalVanityStatsDocument = gql`
    query ReadAdminGlobalVanityStats {
  readGlobalVanityStats {
    ...AdminGlobalVanityStatsFields
  }
}
    ${AdminGlobalVanityStatsFieldsFragmentDoc}`;

/**
 * __useReadAdminGlobalVanityStatsQuery__
 *
 * To run a query within a React component, call `useReadAdminGlobalVanityStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadAdminGlobalVanityStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadAdminGlobalVanityStatsQuery({
 *   variables: {
 *   },
 * });
 */
export function useReadAdminGlobalVanityStatsQuery(baseOptions?: Apollo.QueryHookOptions<ReadAdminGlobalVanityStatsQuery, ReadAdminGlobalVanityStatsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadAdminGlobalVanityStatsQuery, ReadAdminGlobalVanityStatsQueryVariables>(ReadAdminGlobalVanityStatsDocument, options);
      }
export function useReadAdminGlobalVanityStatsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadAdminGlobalVanityStatsQuery, ReadAdminGlobalVanityStatsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadAdminGlobalVanityStatsQuery, ReadAdminGlobalVanityStatsQueryVariables>(ReadAdminGlobalVanityStatsDocument, options);
        }
export function useReadAdminGlobalVanityStatsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ReadAdminGlobalVanityStatsQuery, ReadAdminGlobalVanityStatsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ReadAdminGlobalVanityStatsQuery, ReadAdminGlobalVanityStatsQueryVariables>(ReadAdminGlobalVanityStatsDocument, options);
        }
export type ReadAdminGlobalVanityStatsQueryHookResult = ReturnType<typeof useReadAdminGlobalVanityStatsQuery>;
export type ReadAdminGlobalVanityStatsLazyQueryHookResult = ReturnType<typeof useReadAdminGlobalVanityStatsLazyQuery>;
export type ReadAdminGlobalVanityStatsSuspenseQueryHookResult = ReturnType<typeof useReadAdminGlobalVanityStatsSuspenseQuery>;
export type ReadAdminGlobalVanityStatsQueryResult = Apollo.QueryResult<ReadAdminGlobalVanityStatsQuery, ReadAdminGlobalVanityStatsQueryVariables>;
export const SearchDocument = gql`
    query Search($pagination: Pagination!, $searchTerm: String!) {
  organizations: listOrganizations(
    pagination: $pagination
    searchTerm: $searchTerm
  ) {
    ...AdminOrganizationFields
  }
  affiliations: listAffiliatedOrganizations(searchTerm: $searchTerm) {
    ...AdminOrganizationFields
  }
  legalEntities: listLegalEntities(
    pagination: $pagination
    searchTerm: $searchTerm
  ) {
    ...AdminLegalEntityFields
  }
  users: listUsers(pagination: $pagination, searchTerm: $searchTerm) {
    ...AdminUserFields
  }
  signups: listSignups(
    status: AllSignups
    pagination: $pagination
    searchTerm: $searchTerm
  ) {
    ...AdminSignupFields
  }
}
    ${AdminOrganizationFieldsFragmentDoc}
${AdminLegalEntityFieldsFragmentDoc}
${AdminUserFieldsFragmentDoc}
${AdminSignupFieldsFragmentDoc}`;

/**
 * __useSearchQuery__
 *
 * To run a query within a React component, call `useSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      searchTerm: // value for 'searchTerm'
 *   },
 * });
 */
export function useSearchQuery(baseOptions: Apollo.QueryHookOptions<SearchQuery, SearchQueryVariables> & ({ variables: SearchQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchQuery, SearchQueryVariables>(SearchDocument, options);
      }
export function useSearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchQuery, SearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchQuery, SearchQueryVariables>(SearchDocument, options);
        }
export function useSearchSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<SearchQuery, SearchQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SearchQuery, SearchQueryVariables>(SearchDocument, options);
        }
export type SearchQueryHookResult = ReturnType<typeof useSearchQuery>;
export type SearchLazyQueryHookResult = ReturnType<typeof useSearchLazyQuery>;
export type SearchSuspenseQueryHookResult = ReturnType<typeof useSearchSuspenseQuery>;
export type SearchQueryResult = Apollo.QueryResult<SearchQuery, SearchQueryVariables>;
export const CreateDoppelgangerPermitDocument = gql`
    mutation CreateDoppelgangerPermit($userId: String!, $justification: String) {
  createDoppelgangerPermit(targetUserId: $userId, justification: $justification) {
    ...AdminDoppelgangerPermitFields
  }
}
    ${AdminDoppelgangerPermitFieldsFragmentDoc}`;
export type CreateDoppelgangerPermitMutationFn = Apollo.MutationFunction<CreateDoppelgangerPermitMutation, CreateDoppelgangerPermitMutationVariables>;

/**
 * __useCreateDoppelgangerPermitMutation__
 *
 * To run a mutation, you first call `useCreateDoppelgangerPermitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDoppelgangerPermitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDoppelgangerPermitMutation, { data, loading, error }] = useCreateDoppelgangerPermitMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      justification: // value for 'justification'
 *   },
 * });
 */
export function useCreateDoppelgangerPermitMutation(baseOptions?: Apollo.MutationHookOptions<CreateDoppelgangerPermitMutation, CreateDoppelgangerPermitMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateDoppelgangerPermitMutation, CreateDoppelgangerPermitMutationVariables>(CreateDoppelgangerPermitDocument, options);
      }
export type CreateDoppelgangerPermitMutationHookResult = ReturnType<typeof useCreateDoppelgangerPermitMutation>;
export type CreateDoppelgangerPermitMutationResult = Apollo.MutationResult<CreateDoppelgangerPermitMutation>;
export type CreateDoppelgangerPermitMutationOptions = Apollo.BaseMutationOptions<CreateDoppelgangerPermitMutation, CreateDoppelgangerPermitMutationVariables>;
export const ListDoppelgangerPermitsDocument = gql`
    query ListDoppelgangerPermits {
  listDoppelgangerPermits {
    ...AdminDoppelgangerPermitFields
  }
}
    ${AdminDoppelgangerPermitFieldsFragmentDoc}`;

/**
 * __useListDoppelgangerPermitsQuery__
 *
 * To run a query within a React component, call `useListDoppelgangerPermitsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListDoppelgangerPermitsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListDoppelgangerPermitsQuery({
 *   variables: {
 *   },
 * });
 */
export function useListDoppelgangerPermitsQuery(baseOptions?: Apollo.QueryHookOptions<ListDoppelgangerPermitsQuery, ListDoppelgangerPermitsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListDoppelgangerPermitsQuery, ListDoppelgangerPermitsQueryVariables>(ListDoppelgangerPermitsDocument, options);
      }
export function useListDoppelgangerPermitsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListDoppelgangerPermitsQuery, ListDoppelgangerPermitsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListDoppelgangerPermitsQuery, ListDoppelgangerPermitsQueryVariables>(ListDoppelgangerPermitsDocument, options);
        }
export function useListDoppelgangerPermitsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ListDoppelgangerPermitsQuery, ListDoppelgangerPermitsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ListDoppelgangerPermitsQuery, ListDoppelgangerPermitsQueryVariables>(ListDoppelgangerPermitsDocument, options);
        }
export type ListDoppelgangerPermitsQueryHookResult = ReturnType<typeof useListDoppelgangerPermitsQuery>;
export type ListDoppelgangerPermitsLazyQueryHookResult = ReturnType<typeof useListDoppelgangerPermitsLazyQuery>;
export type ListDoppelgangerPermitsSuspenseQueryHookResult = ReturnType<typeof useListDoppelgangerPermitsSuspenseQuery>;
export type ListDoppelgangerPermitsQueryResult = Apollo.QueryResult<ListDoppelgangerPermitsQuery, ListDoppelgangerPermitsQueryVariables>;
export const TriggerSourceDataClassificationDocument = gql`
    mutation TriggerSourceDataClassification($legalEntityId: String!, $dataSourceId: String!, $externalId: String!) {
  triggerSourceDataClassification(
    legalEntityId: $legalEntityId
    dataSourceId: $dataSourceId
    externalId: $externalId
  )
}
    `;
export type TriggerSourceDataClassificationMutationFn = Apollo.MutationFunction<TriggerSourceDataClassificationMutation, TriggerSourceDataClassificationMutationVariables>;

/**
 * __useTriggerSourceDataClassificationMutation__
 *
 * To run a mutation, you first call `useTriggerSourceDataClassificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTriggerSourceDataClassificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [triggerSourceDataClassificationMutation, { data, loading, error }] = useTriggerSourceDataClassificationMutation({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *      dataSourceId: // value for 'dataSourceId'
 *      externalId: // value for 'externalId'
 *   },
 * });
 */
export function useTriggerSourceDataClassificationMutation(baseOptions?: Apollo.MutationHookOptions<TriggerSourceDataClassificationMutation, TriggerSourceDataClassificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TriggerSourceDataClassificationMutation, TriggerSourceDataClassificationMutationVariables>(TriggerSourceDataClassificationDocument, options);
      }
export type TriggerSourceDataClassificationMutationHookResult = ReturnType<typeof useTriggerSourceDataClassificationMutation>;
export type TriggerSourceDataClassificationMutationResult = Apollo.MutationResult<TriggerSourceDataClassificationMutation>;
export type TriggerSourceDataClassificationMutationOptions = Apollo.BaseMutationOptions<TriggerSourceDataClassificationMutation, TriggerSourceDataClassificationMutationVariables>;
export const DeleteCsvImportTransactionsDocument = gql`
    mutation DeleteCsvImportTransactions($legalEntityId: String!, $csvImportId: String!) {
  deleteCsvImportTransactions(
    legalEntityId: $legalEntityId
    csvImportId: $csvImportId
  )
}
    `;
export type DeleteCsvImportTransactionsMutationFn = Apollo.MutationFunction<DeleteCsvImportTransactionsMutation, DeleteCsvImportTransactionsMutationVariables>;

/**
 * __useDeleteCsvImportTransactionsMutation__
 *
 * To run a mutation, you first call `useDeleteCsvImportTransactionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCsvImportTransactionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCsvImportTransactionsMutation, { data, loading, error }] = useDeleteCsvImportTransactionsMutation({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *      csvImportId: // value for 'csvImportId'
 *   },
 * });
 */
export function useDeleteCsvImportTransactionsMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCsvImportTransactionsMutation, DeleteCsvImportTransactionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCsvImportTransactionsMutation, DeleteCsvImportTransactionsMutationVariables>(DeleteCsvImportTransactionsDocument, options);
      }
export type DeleteCsvImportTransactionsMutationHookResult = ReturnType<typeof useDeleteCsvImportTransactionsMutation>;
export type DeleteCsvImportTransactionsMutationResult = Apollo.MutationResult<DeleteCsvImportTransactionsMutation>;
export type DeleteCsvImportTransactionsMutationOptions = Apollo.BaseMutationOptions<DeleteCsvImportTransactionsMutation, DeleteCsvImportTransactionsMutationVariables>;
export const CheckAllCsvImportFactsDeletedDocument = gql`
    query CheckAllCsvImportFactsDeleted($legalEntityId: String!, $csvImportId: String!) {
  checkAllCsvImportFactsDeleted(
    legalEntityId: $legalEntityId
    csvImportId: $csvImportId
  ) {
    allDeleted
  }
}
    `;

/**
 * __useCheckAllCsvImportFactsDeletedQuery__
 *
 * To run a query within a React component, call `useCheckAllCsvImportFactsDeletedQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckAllCsvImportFactsDeletedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckAllCsvImportFactsDeletedQuery({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *      csvImportId: // value for 'csvImportId'
 *   },
 * });
 */
export function useCheckAllCsvImportFactsDeletedQuery(baseOptions: Apollo.QueryHookOptions<CheckAllCsvImportFactsDeletedQuery, CheckAllCsvImportFactsDeletedQueryVariables> & ({ variables: CheckAllCsvImportFactsDeletedQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CheckAllCsvImportFactsDeletedQuery, CheckAllCsvImportFactsDeletedQueryVariables>(CheckAllCsvImportFactsDeletedDocument, options);
      }
export function useCheckAllCsvImportFactsDeletedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CheckAllCsvImportFactsDeletedQuery, CheckAllCsvImportFactsDeletedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CheckAllCsvImportFactsDeletedQuery, CheckAllCsvImportFactsDeletedQueryVariables>(CheckAllCsvImportFactsDeletedDocument, options);
        }
export function useCheckAllCsvImportFactsDeletedSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<CheckAllCsvImportFactsDeletedQuery, CheckAllCsvImportFactsDeletedQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CheckAllCsvImportFactsDeletedQuery, CheckAllCsvImportFactsDeletedQueryVariables>(CheckAllCsvImportFactsDeletedDocument, options);
        }
export type CheckAllCsvImportFactsDeletedQueryHookResult = ReturnType<typeof useCheckAllCsvImportFactsDeletedQuery>;
export type CheckAllCsvImportFactsDeletedLazyQueryHookResult = ReturnType<typeof useCheckAllCsvImportFactsDeletedLazyQuery>;
export type CheckAllCsvImportFactsDeletedSuspenseQueryHookResult = ReturnType<typeof useCheckAllCsvImportFactsDeletedSuspenseQuery>;
export type CheckAllCsvImportFactsDeletedQueryResult = Apollo.QueryResult<CheckAllCsvImportFactsDeletedQuery, CheckAllCsvImportFactsDeletedQueryVariables>;
export const ListAssociatedTransactionFactsDocument = gql`
    query ListAssociatedTransactionFacts($dataSourceId: String!, $externalId: String!) {
  listAssociatedTransactionFacts(
    dataSourceId: $dataSourceId
    externalId: $externalId
  ) {
    ...AdminTransactionFactFields
  }
}
    ${AdminTransactionFactFieldsFragmentDoc}`;

/**
 * __useListAssociatedTransactionFactsQuery__
 *
 * To run a query within a React component, call `useListAssociatedTransactionFactsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListAssociatedTransactionFactsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListAssociatedTransactionFactsQuery({
 *   variables: {
 *      dataSourceId: // value for 'dataSourceId'
 *      externalId: // value for 'externalId'
 *   },
 * });
 */
export function useListAssociatedTransactionFactsQuery(baseOptions: Apollo.QueryHookOptions<ListAssociatedTransactionFactsQuery, ListAssociatedTransactionFactsQueryVariables> & ({ variables: ListAssociatedTransactionFactsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListAssociatedTransactionFactsQuery, ListAssociatedTransactionFactsQueryVariables>(ListAssociatedTransactionFactsDocument, options);
      }
export function useListAssociatedTransactionFactsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListAssociatedTransactionFactsQuery, ListAssociatedTransactionFactsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListAssociatedTransactionFactsQuery, ListAssociatedTransactionFactsQueryVariables>(ListAssociatedTransactionFactsDocument, options);
        }
export function useListAssociatedTransactionFactsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ListAssociatedTransactionFactsQuery, ListAssociatedTransactionFactsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ListAssociatedTransactionFactsQuery, ListAssociatedTransactionFactsQueryVariables>(ListAssociatedTransactionFactsDocument, options);
        }
export type ListAssociatedTransactionFactsQueryHookResult = ReturnType<typeof useListAssociatedTransactionFactsQuery>;
export type ListAssociatedTransactionFactsLazyQueryHookResult = ReturnType<typeof useListAssociatedTransactionFactsLazyQuery>;
export type ListAssociatedTransactionFactsSuspenseQueryHookResult = ReturnType<typeof useListAssociatedTransactionFactsSuspenseQuery>;
export type ListAssociatedTransactionFactsQueryResult = Apollo.QueryResult<ListAssociatedTransactionFactsQuery, ListAssociatedTransactionFactsQueryVariables>;
export const ReadAdminTransactionFactDocument = gql`
    query ReadAdminTransactionFact($transactionFactId: String!) {
  readTransactionFact(transactionFactId: $transactionFactId) {
    ...AdminTransactionFactFields
  }
}
    ${AdminTransactionFactFieldsFragmentDoc}`;

/**
 * __useReadAdminTransactionFactQuery__
 *
 * To run a query within a React component, call `useReadAdminTransactionFactQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadAdminTransactionFactQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadAdminTransactionFactQuery({
 *   variables: {
 *      transactionFactId: // value for 'transactionFactId'
 *   },
 * });
 */
export function useReadAdminTransactionFactQuery(baseOptions: Apollo.QueryHookOptions<ReadAdminTransactionFactQuery, ReadAdminTransactionFactQueryVariables> & ({ variables: ReadAdminTransactionFactQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadAdminTransactionFactQuery, ReadAdminTransactionFactQueryVariables>(ReadAdminTransactionFactDocument, options);
      }
export function useReadAdminTransactionFactLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadAdminTransactionFactQuery, ReadAdminTransactionFactQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadAdminTransactionFactQuery, ReadAdminTransactionFactQueryVariables>(ReadAdminTransactionFactDocument, options);
        }
export function useReadAdminTransactionFactSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ReadAdminTransactionFactQuery, ReadAdminTransactionFactQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ReadAdminTransactionFactQuery, ReadAdminTransactionFactQueryVariables>(ReadAdminTransactionFactDocument, options);
        }
export type ReadAdminTransactionFactQueryHookResult = ReturnType<typeof useReadAdminTransactionFactQuery>;
export type ReadAdminTransactionFactLazyQueryHookResult = ReturnType<typeof useReadAdminTransactionFactLazyQuery>;
export type ReadAdminTransactionFactSuspenseQueryHookResult = ReturnType<typeof useReadAdminTransactionFactSuspenseQuery>;
export type ReadAdminTransactionFactQueryResult = Apollo.QueryResult<ReadAdminTransactionFactQuery, ReadAdminTransactionFactQueryVariables>;
export const AllTransactionFactMetadataDocument = gql`
    query AllTransactionFactMetadata($transactionFactId: String!) {
  readTransactionFact(transactionFactId: $transactionFactId) {
    ...AdminTransactionFactFields
  }
  readEntityExtractions(transactionFactId: $transactionFactId) {
    ...AdminTransactionEntityExtractionFields
  }
  listTransactionFactPartyClassifications(transactionFactId: $transactionFactId) {
    party {
      ...PartyFields
    }
    classification {
      ...AdminClassificationFields
    }
    prefix
    prefixDescription
    role
    overlayName
    viewPartyName
    viewPartyReason
  }
  listTransactionFactCategoryClassifications(
    transactionFactId: $transactionFactId
  ) {
    category {
      ...CategoryFields
    }
    entryType
    transactionFactId
    classification {
      ...AdminClassificationFields
    }
  }
  listTransactionFactLedgerPartyClassifications(
    transactionFactId: $transactionFactId
  ) {
    party {
      ...PartyFields
    }
    classification {
      ...AdminClassificationFields
    }
    prefix
    prefixDescription
    role
    overlayName
    viewPartyName
    viewPartyReason
  }
}
    ${AdminTransactionFactFieldsFragmentDoc}
${AdminTransactionEntityExtractionFieldsFragmentDoc}
${PartyFieldsFragmentDoc}
${AdminClassificationFieldsFragmentDoc}
${CategoryFieldsFragmentDoc}`;

/**
 * __useAllTransactionFactMetadataQuery__
 *
 * To run a query within a React component, call `useAllTransactionFactMetadataQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllTransactionFactMetadataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllTransactionFactMetadataQuery({
 *   variables: {
 *      transactionFactId: // value for 'transactionFactId'
 *   },
 * });
 */
export function useAllTransactionFactMetadataQuery(baseOptions: Apollo.QueryHookOptions<AllTransactionFactMetadataQuery, AllTransactionFactMetadataQueryVariables> & ({ variables: AllTransactionFactMetadataQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllTransactionFactMetadataQuery, AllTransactionFactMetadataQueryVariables>(AllTransactionFactMetadataDocument, options);
      }
export function useAllTransactionFactMetadataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllTransactionFactMetadataQuery, AllTransactionFactMetadataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllTransactionFactMetadataQuery, AllTransactionFactMetadataQueryVariables>(AllTransactionFactMetadataDocument, options);
        }
export function useAllTransactionFactMetadataSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<AllTransactionFactMetadataQuery, AllTransactionFactMetadataQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AllTransactionFactMetadataQuery, AllTransactionFactMetadataQueryVariables>(AllTransactionFactMetadataDocument, options);
        }
export type AllTransactionFactMetadataQueryHookResult = ReturnType<typeof useAllTransactionFactMetadataQuery>;
export type AllTransactionFactMetadataLazyQueryHookResult = ReturnType<typeof useAllTransactionFactMetadataLazyQuery>;
export type AllTransactionFactMetadataSuspenseQueryHookResult = ReturnType<typeof useAllTransactionFactMetadataSuspenseQuery>;
export type AllTransactionFactMetadataQueryResult = Apollo.QueryResult<AllTransactionFactMetadataQuery, AllTransactionFactMetadataQueryVariables>;
export const ListAdminChartsOfAccountsDocument = gql`
    query ListAdminChartsOfAccounts($global: Boolean, $legalEntityId: String, $accountingBasis: AccountingBasis) {
  listChartsOfAccounts(
    filter: {global: $global, legalEntityId: $legalEntityId, accountingBasis: $accountingBasis}
  ) {
    ...ChartOfAccountsListEntryFields
  }
}
    ${ChartOfAccountsListEntryFieldsFragmentDoc}`;

/**
 * __useListAdminChartsOfAccountsQuery__
 *
 * To run a query within a React component, call `useListAdminChartsOfAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListAdminChartsOfAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListAdminChartsOfAccountsQuery({
 *   variables: {
 *      global: // value for 'global'
 *      legalEntityId: // value for 'legalEntityId'
 *      accountingBasis: // value for 'accountingBasis'
 *   },
 * });
 */
export function useListAdminChartsOfAccountsQuery(baseOptions?: Apollo.QueryHookOptions<ListAdminChartsOfAccountsQuery, ListAdminChartsOfAccountsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListAdminChartsOfAccountsQuery, ListAdminChartsOfAccountsQueryVariables>(ListAdminChartsOfAccountsDocument, options);
      }
export function useListAdminChartsOfAccountsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListAdminChartsOfAccountsQuery, ListAdminChartsOfAccountsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListAdminChartsOfAccountsQuery, ListAdminChartsOfAccountsQueryVariables>(ListAdminChartsOfAccountsDocument, options);
        }
export function useListAdminChartsOfAccountsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ListAdminChartsOfAccountsQuery, ListAdminChartsOfAccountsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ListAdminChartsOfAccountsQuery, ListAdminChartsOfAccountsQueryVariables>(ListAdminChartsOfAccountsDocument, options);
        }
export type ListAdminChartsOfAccountsQueryHookResult = ReturnType<typeof useListAdminChartsOfAccountsQuery>;
export type ListAdminChartsOfAccountsLazyQueryHookResult = ReturnType<typeof useListAdminChartsOfAccountsLazyQuery>;
export type ListAdminChartsOfAccountsSuspenseQueryHookResult = ReturnType<typeof useListAdminChartsOfAccountsSuspenseQuery>;
export type ListAdminChartsOfAccountsQueryResult = Apollo.QueryResult<ListAdminChartsOfAccountsQuery, ListAdminChartsOfAccountsQueryVariables>;
export const ReadAdminChartOfAccountsWithMappingDocument = gql`
    query ReadAdminChartOfAccountsWithMapping($fromId: String!, $toId: String!, $viewType: ViewType!) {
  readMappedChartOfAccounts(fromId: $fromId, toId: $toId, viewType: $viewType) {
    ...MappedChartOfAccountsFields
  }
}
    ${MappedChartOfAccountsFieldsFragmentDoc}`;

/**
 * __useReadAdminChartOfAccountsWithMappingQuery__
 *
 * To run a query within a React component, call `useReadAdminChartOfAccountsWithMappingQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadAdminChartOfAccountsWithMappingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadAdminChartOfAccountsWithMappingQuery({
 *   variables: {
 *      fromId: // value for 'fromId'
 *      toId: // value for 'toId'
 *      viewType: // value for 'viewType'
 *   },
 * });
 */
export function useReadAdminChartOfAccountsWithMappingQuery(baseOptions: Apollo.QueryHookOptions<ReadAdminChartOfAccountsWithMappingQuery, ReadAdminChartOfAccountsWithMappingQueryVariables> & ({ variables: ReadAdminChartOfAccountsWithMappingQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadAdminChartOfAccountsWithMappingQuery, ReadAdminChartOfAccountsWithMappingQueryVariables>(ReadAdminChartOfAccountsWithMappingDocument, options);
      }
export function useReadAdminChartOfAccountsWithMappingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadAdminChartOfAccountsWithMappingQuery, ReadAdminChartOfAccountsWithMappingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadAdminChartOfAccountsWithMappingQuery, ReadAdminChartOfAccountsWithMappingQueryVariables>(ReadAdminChartOfAccountsWithMappingDocument, options);
        }
export function useReadAdminChartOfAccountsWithMappingSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ReadAdminChartOfAccountsWithMappingQuery, ReadAdminChartOfAccountsWithMappingQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ReadAdminChartOfAccountsWithMappingQuery, ReadAdminChartOfAccountsWithMappingQueryVariables>(ReadAdminChartOfAccountsWithMappingDocument, options);
        }
export type ReadAdminChartOfAccountsWithMappingQueryHookResult = ReturnType<typeof useReadAdminChartOfAccountsWithMappingQuery>;
export type ReadAdminChartOfAccountsWithMappingLazyQueryHookResult = ReturnType<typeof useReadAdminChartOfAccountsWithMappingLazyQuery>;
export type ReadAdminChartOfAccountsWithMappingSuspenseQueryHookResult = ReturnType<typeof useReadAdminChartOfAccountsWithMappingSuspenseQuery>;
export type ReadAdminChartOfAccountsWithMappingQueryResult = Apollo.QueryResult<ReadAdminChartOfAccountsWithMappingQuery, ReadAdminChartOfAccountsWithMappingQueryVariables>;
export const ReadAdminChartOfAccountsDocument = gql`
    query ReadAdminChartOfAccounts($id: String!) {
  readChartOfAccounts(id: $id) {
    ...AdminChartOfAccountsFields
  }
}
    ${AdminChartOfAccountsFieldsFragmentDoc}`;

/**
 * __useReadAdminChartOfAccountsQuery__
 *
 * To run a query within a React component, call `useReadAdminChartOfAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadAdminChartOfAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadAdminChartOfAccountsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useReadAdminChartOfAccountsQuery(baseOptions: Apollo.QueryHookOptions<ReadAdminChartOfAccountsQuery, ReadAdminChartOfAccountsQueryVariables> & ({ variables: ReadAdminChartOfAccountsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadAdminChartOfAccountsQuery, ReadAdminChartOfAccountsQueryVariables>(ReadAdminChartOfAccountsDocument, options);
      }
export function useReadAdminChartOfAccountsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadAdminChartOfAccountsQuery, ReadAdminChartOfAccountsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadAdminChartOfAccountsQuery, ReadAdminChartOfAccountsQueryVariables>(ReadAdminChartOfAccountsDocument, options);
        }
export function useReadAdminChartOfAccountsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ReadAdminChartOfAccountsQuery, ReadAdminChartOfAccountsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ReadAdminChartOfAccountsQuery, ReadAdminChartOfAccountsQueryVariables>(ReadAdminChartOfAccountsDocument, options);
        }
export type ReadAdminChartOfAccountsQueryHookResult = ReturnType<typeof useReadAdminChartOfAccountsQuery>;
export type ReadAdminChartOfAccountsLazyQueryHookResult = ReturnType<typeof useReadAdminChartOfAccountsLazyQuery>;
export type ReadAdminChartOfAccountsSuspenseQueryHookResult = ReturnType<typeof useReadAdminChartOfAccountsSuspenseQuery>;
export type ReadAdminChartOfAccountsQueryResult = Apollo.QueryResult<ReadAdminChartOfAccountsQuery, ReadAdminChartOfAccountsQueryVariables>;
export const RemoveSuperuserRoleDocument = gql`
    mutation RemoveSuperuserRole($superuserId: String!, $roleIdentifier: String!) {
  removeSuperuserRole(superuserId: $superuserId, roleIdentifier: $roleIdentifier)
}
    `;
export type RemoveSuperuserRoleMutationFn = Apollo.MutationFunction<RemoveSuperuserRoleMutation, RemoveSuperuserRoleMutationVariables>;

/**
 * __useRemoveSuperuserRoleMutation__
 *
 * To run a mutation, you first call `useRemoveSuperuserRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveSuperuserRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeSuperuserRoleMutation, { data, loading, error }] = useRemoveSuperuserRoleMutation({
 *   variables: {
 *      superuserId: // value for 'superuserId'
 *      roleIdentifier: // value for 'roleIdentifier'
 *   },
 * });
 */
export function useRemoveSuperuserRoleMutation(baseOptions?: Apollo.MutationHookOptions<RemoveSuperuserRoleMutation, RemoveSuperuserRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveSuperuserRoleMutation, RemoveSuperuserRoleMutationVariables>(RemoveSuperuserRoleDocument, options);
      }
export type RemoveSuperuserRoleMutationHookResult = ReturnType<typeof useRemoveSuperuserRoleMutation>;
export type RemoveSuperuserRoleMutationResult = Apollo.MutationResult<RemoveSuperuserRoleMutation>;
export type RemoveSuperuserRoleMutationOptions = Apollo.BaseMutationOptions<RemoveSuperuserRoleMutation, RemoveSuperuserRoleMutationVariables>;
export const SendUserLegalEntityApprovalDocument = gql`
    mutation SendUserLegalEntityApproval($userId: String!, $legalEntityId: String!) {
  sendUserLegalEntityApproval(userId: $userId, legalEntityId: $legalEntityId)
}
    `;
export type SendUserLegalEntityApprovalMutationFn = Apollo.MutationFunction<SendUserLegalEntityApprovalMutation, SendUserLegalEntityApprovalMutationVariables>;

/**
 * __useSendUserLegalEntityApprovalMutation__
 *
 * To run a mutation, you first call `useSendUserLegalEntityApprovalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendUserLegalEntityApprovalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendUserLegalEntityApprovalMutation, { data, loading, error }] = useSendUserLegalEntityApprovalMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      legalEntityId: // value for 'legalEntityId'
 *   },
 * });
 */
export function useSendUserLegalEntityApprovalMutation(baseOptions?: Apollo.MutationHookOptions<SendUserLegalEntityApprovalMutation, SendUserLegalEntityApprovalMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendUserLegalEntityApprovalMutation, SendUserLegalEntityApprovalMutationVariables>(SendUserLegalEntityApprovalDocument, options);
      }
export type SendUserLegalEntityApprovalMutationHookResult = ReturnType<typeof useSendUserLegalEntityApprovalMutation>;
export type SendUserLegalEntityApprovalMutationResult = Apollo.MutationResult<SendUserLegalEntityApprovalMutation>;
export type SendUserLegalEntityApprovalMutationOptions = Apollo.BaseMutationOptions<SendUserLegalEntityApprovalMutation, SendUserLegalEntityApprovalMutationVariables>;
export const AddSuperuserRoleDocument = gql`
    mutation AddSuperuserRole($superuserId: String!, $roleIdentifier: String!) {
  addSuperuserRole(superuserId: $superuserId, roleIdentifier: $roleIdentifier) {
    ...SuperuserFields
  }
}
    ${SuperuserFieldsFragmentDoc}`;
export type AddSuperuserRoleMutationFn = Apollo.MutationFunction<AddSuperuserRoleMutation, AddSuperuserRoleMutationVariables>;

/**
 * __useAddSuperuserRoleMutation__
 *
 * To run a mutation, you first call `useAddSuperuserRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddSuperuserRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addSuperuserRoleMutation, { data, loading, error }] = useAddSuperuserRoleMutation({
 *   variables: {
 *      superuserId: // value for 'superuserId'
 *      roleIdentifier: // value for 'roleIdentifier'
 *   },
 * });
 */
export function useAddSuperuserRoleMutation(baseOptions?: Apollo.MutationHookOptions<AddSuperuserRoleMutation, AddSuperuserRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddSuperuserRoleMutation, AddSuperuserRoleMutationVariables>(AddSuperuserRoleDocument, options);
      }
export type AddSuperuserRoleMutationHookResult = ReturnType<typeof useAddSuperuserRoleMutation>;
export type AddSuperuserRoleMutationResult = Apollo.MutationResult<AddSuperuserRoleMutation>;
export type AddSuperuserRoleMutationOptions = Apollo.BaseMutationOptions<AddSuperuserRoleMutation, AddSuperuserRoleMutationVariables>;
export const DeleteSuperuserDocument = gql`
    mutation DeleteSuperuser($id: String!) {
  deleteSuperuser(id: $id)
}
    `;
export type DeleteSuperuserMutationFn = Apollo.MutationFunction<DeleteSuperuserMutation, DeleteSuperuserMutationVariables>;

/**
 * __useDeleteSuperuserMutation__
 *
 * To run a mutation, you first call `useDeleteSuperuserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSuperuserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSuperuserMutation, { data, loading, error }] = useDeleteSuperuserMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteSuperuserMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSuperuserMutation, DeleteSuperuserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteSuperuserMutation, DeleteSuperuserMutationVariables>(DeleteSuperuserDocument, options);
      }
export type DeleteSuperuserMutationHookResult = ReturnType<typeof useDeleteSuperuserMutation>;
export type DeleteSuperuserMutationResult = Apollo.MutationResult<DeleteSuperuserMutation>;
export type DeleteSuperuserMutationOptions = Apollo.BaseMutationOptions<DeleteSuperuserMutation, DeleteSuperuserMutationVariables>;
export const UnlockUserDocument = gql`
    mutation UnlockUser($userId: String!) {
  unlockUser(id: $userId)
}
    `;
export type UnlockUserMutationFn = Apollo.MutationFunction<UnlockUserMutation, UnlockUserMutationVariables>;

/**
 * __useUnlockUserMutation__
 *
 * To run a mutation, you first call `useUnlockUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnlockUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unlockUserMutation, { data, loading, error }] = useUnlockUserMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUnlockUserMutation(baseOptions?: Apollo.MutationHookOptions<UnlockUserMutation, UnlockUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnlockUserMutation, UnlockUserMutationVariables>(UnlockUserDocument, options);
      }
export type UnlockUserMutationHookResult = ReturnType<typeof useUnlockUserMutation>;
export type UnlockUserMutationResult = Apollo.MutationResult<UnlockUserMutation>;
export type UnlockUserMutationOptions = Apollo.BaseMutationOptions<UnlockUserMutation, UnlockUserMutationVariables>;
export const CreateSuperuserDocument = gql`
    mutation CreateSuperuser($emailAddress: String!, $roleIdentifiers: [String!]!, $givenName: String!, $familyName: String!) {
  createSuperuser(
    emailAddress: $emailAddress
    roleIdentifiers: $roleIdentifiers
    givenName: $givenName
    familyName: $familyName
  ) {
    ...SuperuserFields
  }
}
    ${SuperuserFieldsFragmentDoc}`;
export type CreateSuperuserMutationFn = Apollo.MutationFunction<CreateSuperuserMutation, CreateSuperuserMutationVariables>;

/**
 * __useCreateSuperuserMutation__
 *
 * To run a mutation, you first call `useCreateSuperuserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSuperuserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSuperuserMutation, { data, loading, error }] = useCreateSuperuserMutation({
 *   variables: {
 *      emailAddress: // value for 'emailAddress'
 *      roleIdentifiers: // value for 'roleIdentifiers'
 *      givenName: // value for 'givenName'
 *      familyName: // value for 'familyName'
 *   },
 * });
 */
export function useCreateSuperuserMutation(baseOptions?: Apollo.MutationHookOptions<CreateSuperuserMutation, CreateSuperuserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSuperuserMutation, CreateSuperuserMutationVariables>(CreateSuperuserDocument, options);
      }
export type CreateSuperuserMutationHookResult = ReturnType<typeof useCreateSuperuserMutation>;
export type CreateSuperuserMutationResult = Apollo.MutationResult<CreateSuperuserMutation>;
export type CreateSuperuserMutationOptions = Apollo.BaseMutationOptions<CreateSuperuserMutation, CreateSuperuserMutationVariables>;
export const ListSuperusersDocument = gql`
    query ListSuperusers {
  listSuperusers {
    ...SuperuserFields
  }
}
    ${SuperuserFieldsFragmentDoc}`;

/**
 * __useListSuperusersQuery__
 *
 * To run a query within a React component, call `useListSuperusersQuery` and pass it any options that fit your needs.
 * When your component renders, `useListSuperusersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListSuperusersQuery({
 *   variables: {
 *   },
 * });
 */
export function useListSuperusersQuery(baseOptions?: Apollo.QueryHookOptions<ListSuperusersQuery, ListSuperusersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListSuperusersQuery, ListSuperusersQueryVariables>(ListSuperusersDocument, options);
      }
export function useListSuperusersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListSuperusersQuery, ListSuperusersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListSuperusersQuery, ListSuperusersQueryVariables>(ListSuperusersDocument, options);
        }
export function useListSuperusersSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ListSuperusersQuery, ListSuperusersQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ListSuperusersQuery, ListSuperusersQueryVariables>(ListSuperusersDocument, options);
        }
export type ListSuperusersQueryHookResult = ReturnType<typeof useListSuperusersQuery>;
export type ListSuperusersLazyQueryHookResult = ReturnType<typeof useListSuperusersLazyQuery>;
export type ListSuperusersSuspenseQueryHookResult = ReturnType<typeof useListSuperusersSuspenseQuery>;
export type ListSuperusersQueryResult = Apollo.QueryResult<ListSuperusersQuery, ListSuperusersQueryVariables>;
export const ListUsersDocument = gql`
    query ListUsers($pagination: Pagination!, $searchTerm: String, $ids: [String!]) {
  listUsers(pagination: $pagination, searchTerm: $searchTerm, ids: $ids) {
    ...AdminUserFields
  }
}
    ${AdminUserFieldsFragmentDoc}`;

/**
 * __useListUsersQuery__
 *
 * To run a query within a React component, call `useListUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useListUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListUsersQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      searchTerm: // value for 'searchTerm'
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useListUsersQuery(baseOptions: Apollo.QueryHookOptions<ListUsersQuery, ListUsersQueryVariables> & ({ variables: ListUsersQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListUsersQuery, ListUsersQueryVariables>(ListUsersDocument, options);
      }
export function useListUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListUsersQuery, ListUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListUsersQuery, ListUsersQueryVariables>(ListUsersDocument, options);
        }
export function useListUsersSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ListUsersQuery, ListUsersQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ListUsersQuery, ListUsersQueryVariables>(ListUsersDocument, options);
        }
export type ListUsersQueryHookResult = ReturnType<typeof useListUsersQuery>;
export type ListUsersLazyQueryHookResult = ReturnType<typeof useListUsersLazyQuery>;
export type ListUsersSuspenseQueryHookResult = ReturnType<typeof useListUsersSuspenseQuery>;
export type ListUsersQueryResult = Apollo.QueryResult<ListUsersQuery, ListUsersQueryVariables>;
export const ReadUserDocument = gql`
    query ReadUser($id: String!) {
  readUser(id: $id) {
    ...AdminUserFields
  }
}
    ${AdminUserFieldsFragmentDoc}`;

/**
 * __useReadUserQuery__
 *
 * To run a query within a React component, call `useReadUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useReadUserQuery(baseOptions: Apollo.QueryHookOptions<ReadUserQuery, ReadUserQueryVariables> & ({ variables: ReadUserQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadUserQuery, ReadUserQueryVariables>(ReadUserDocument, options);
      }
export function useReadUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadUserQuery, ReadUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadUserQuery, ReadUserQueryVariables>(ReadUserDocument, options);
        }
export function useReadUserSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ReadUserQuery, ReadUserQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ReadUserQuery, ReadUserQueryVariables>(ReadUserDocument, options);
        }
export type ReadUserQueryHookResult = ReturnType<typeof useReadUserQuery>;
export type ReadUserLazyQueryHookResult = ReturnType<typeof useReadUserLazyQuery>;
export type ReadUserSuspenseQueryHookResult = ReturnType<typeof useReadUserSuspenseQuery>;
export type ReadUserQueryResult = Apollo.QueryResult<ReadUserQuery, ReadUserQueryVariables>;
export const ListEmployedUsersDocument = gql`
    query ListEmployedUsers {
  listEmployedUsers {
    ...UserFields
  }
}
    ${UserFieldsFragmentDoc}`;

/**
 * __useListEmployedUsersQuery__
 *
 * To run a query within a React component, call `useListEmployedUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useListEmployedUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListEmployedUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useListEmployedUsersQuery(baseOptions?: Apollo.QueryHookOptions<ListEmployedUsersQuery, ListEmployedUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListEmployedUsersQuery, ListEmployedUsersQueryVariables>(ListEmployedUsersDocument, options);
      }
export function useListEmployedUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListEmployedUsersQuery, ListEmployedUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListEmployedUsersQuery, ListEmployedUsersQueryVariables>(ListEmployedUsersDocument, options);
        }
export function useListEmployedUsersSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ListEmployedUsersQuery, ListEmployedUsersQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ListEmployedUsersQuery, ListEmployedUsersQueryVariables>(ListEmployedUsersDocument, options);
        }
export type ListEmployedUsersQueryHookResult = ReturnType<typeof useListEmployedUsersQuery>;
export type ListEmployedUsersLazyQueryHookResult = ReturnType<typeof useListEmployedUsersLazyQuery>;
export type ListEmployedUsersSuspenseQueryHookResult = ReturnType<typeof useListEmployedUsersSuspenseQuery>;
export type ListEmployedUsersQueryResult = Apollo.QueryResult<ListEmployedUsersQuery, ListEmployedUsersQueryVariables>;
export const UpdateSignupStatusDocument = gql`
    mutation UpdateSignupStatus($id: String!, $status: SignupStatus!, $statusReason: String) {
  updateSignupStatus(id: $id, status: $status, statusReason: $statusReason)
}
    `;
export type UpdateSignupStatusMutationFn = Apollo.MutationFunction<UpdateSignupStatusMutation, UpdateSignupStatusMutationVariables>;

/**
 * __useUpdateSignupStatusMutation__
 *
 * To run a mutation, you first call `useUpdateSignupStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSignupStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSignupStatusMutation, { data, loading, error }] = useUpdateSignupStatusMutation({
 *   variables: {
 *      id: // value for 'id'
 *      status: // value for 'status'
 *      statusReason: // value for 'statusReason'
 *   },
 * });
 */
export function useUpdateSignupStatusMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSignupStatusMutation, UpdateSignupStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSignupStatusMutation, UpdateSignupStatusMutationVariables>(UpdateSignupStatusDocument, options);
      }
export type UpdateSignupStatusMutationHookResult = ReturnType<typeof useUpdateSignupStatusMutation>;
export type UpdateSignupStatusMutationResult = Apollo.MutationResult<UpdateSignupStatusMutation>;
export type UpdateSignupStatusMutationOptions = Apollo.BaseMutationOptions<UpdateSignupStatusMutation, UpdateSignupStatusMutationVariables>;
export const ReadSignupDocument = gql`
    query ReadSignup($id: String!) {
  readSignup(id: $id) {
    ...AdminSignupFields
  }
}
    ${AdminSignupFieldsFragmentDoc}`;

/**
 * __useReadSignupQuery__
 *
 * To run a query within a React component, call `useReadSignupQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadSignupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadSignupQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useReadSignupQuery(baseOptions: Apollo.QueryHookOptions<ReadSignupQuery, ReadSignupQueryVariables> & ({ variables: ReadSignupQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadSignupQuery, ReadSignupQueryVariables>(ReadSignupDocument, options);
      }
export function useReadSignupLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadSignupQuery, ReadSignupQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadSignupQuery, ReadSignupQueryVariables>(ReadSignupDocument, options);
        }
export function useReadSignupSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ReadSignupQuery, ReadSignupQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ReadSignupQuery, ReadSignupQueryVariables>(ReadSignupDocument, options);
        }
export type ReadSignupQueryHookResult = ReturnType<typeof useReadSignupQuery>;
export type ReadSignupLazyQueryHookResult = ReturnType<typeof useReadSignupLazyQuery>;
export type ReadSignupSuspenseQueryHookResult = ReturnType<typeof useReadSignupSuspenseQuery>;
export type ReadSignupQueryResult = Apollo.QueryResult<ReadSignupQuery, ReadSignupQueryVariables>;
export const ListSignupsDocument = gql`
    query ListSignups($status: ListSignupRequestStatus!, $pagination: Pagination!) {
  listSignups(status: $status, pagination: $pagination) {
    ...AdminSignupFields
  }
}
    ${AdminSignupFieldsFragmentDoc}`;

/**
 * __useListSignupsQuery__
 *
 * To run a query within a React component, call `useListSignupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListSignupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListSignupsQuery({
 *   variables: {
 *      status: // value for 'status'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useListSignupsQuery(baseOptions: Apollo.QueryHookOptions<ListSignupsQuery, ListSignupsQueryVariables> & ({ variables: ListSignupsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListSignupsQuery, ListSignupsQueryVariables>(ListSignupsDocument, options);
      }
export function useListSignupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListSignupsQuery, ListSignupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListSignupsQuery, ListSignupsQueryVariables>(ListSignupsDocument, options);
        }
export function useListSignupsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ListSignupsQuery, ListSignupsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ListSignupsQuery, ListSignupsQueryVariables>(ListSignupsDocument, options);
        }
export type ListSignupsQueryHookResult = ReturnType<typeof useListSignupsQuery>;
export type ListSignupsLazyQueryHookResult = ReturnType<typeof useListSignupsLazyQuery>;
export type ListSignupsSuspenseQueryHookResult = ReturnType<typeof useListSignupsSuspenseQuery>;
export type ListSignupsQueryResult = Apollo.QueryResult<ListSignupsQuery, ListSignupsQueryVariables>;
export const ResendActivationEmailDocument = gql`
    mutation ResendActivationEmail($legalEntityId: String!, $recipientId: String!) {
  resendActivationEmail(legalEntityId: $legalEntityId, recipientId: $recipientId)
}
    `;
export type ResendActivationEmailMutationFn = Apollo.MutationFunction<ResendActivationEmailMutation, ResendActivationEmailMutationVariables>;

/**
 * __useResendActivationEmailMutation__
 *
 * To run a mutation, you first call `useResendActivationEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendActivationEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendActivationEmailMutation, { data, loading, error }] = useResendActivationEmailMutation({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *      recipientId: // value for 'recipientId'
 *   },
 * });
 */
export function useResendActivationEmailMutation(baseOptions?: Apollo.MutationHookOptions<ResendActivationEmailMutation, ResendActivationEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResendActivationEmailMutation, ResendActivationEmailMutationVariables>(ResendActivationEmailDocument, options);
      }
export type ResendActivationEmailMutationHookResult = ReturnType<typeof useResendActivationEmailMutation>;
export type ResendActivationEmailMutationResult = Apollo.MutationResult<ResendActivationEmailMutation>;
export type ResendActivationEmailMutationOptions = Apollo.BaseMutationOptions<ResendActivationEmailMutation, ResendActivationEmailMutationVariables>;
export const UpdateLegalEntityStatusDocument = gql`
    mutation UpdateLegalEntityStatus($id: String!, $status: LegalEntityStatus!) {
  updateLegalEntityStatus(id: $id, status: $status)
}
    `;
export type UpdateLegalEntityStatusMutationFn = Apollo.MutationFunction<UpdateLegalEntityStatusMutation, UpdateLegalEntityStatusMutationVariables>;

/**
 * __useUpdateLegalEntityStatusMutation__
 *
 * To run a mutation, you first call `useUpdateLegalEntityStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLegalEntityStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLegalEntityStatusMutation, { data, loading, error }] = useUpdateLegalEntityStatusMutation({
 *   variables: {
 *      id: // value for 'id'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useUpdateLegalEntityStatusMutation(baseOptions?: Apollo.MutationHookOptions<UpdateLegalEntityStatusMutation, UpdateLegalEntityStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateLegalEntityStatusMutation, UpdateLegalEntityStatusMutationVariables>(UpdateLegalEntityStatusDocument, options);
      }
export type UpdateLegalEntityStatusMutationHookResult = ReturnType<typeof useUpdateLegalEntityStatusMutation>;
export type UpdateLegalEntityStatusMutationResult = Apollo.MutationResult<UpdateLegalEntityStatusMutation>;
export type UpdateLegalEntityStatusMutationOptions = Apollo.BaseMutationOptions<UpdateLegalEntityStatusMutation, UpdateLegalEntityStatusMutationVariables>;
export const ListLegalEntitiesDocument = gql`
    query ListLegalEntities($pagination: Pagination!, $searchTerm: String, $includedStatuses: [LegalEntityStatus!], $excludedStatuses: [LegalEntityStatus!], $legalEntityIds: [String!]) {
  listLegalEntities(
    pagination: $pagination
    searchTerm: $searchTerm
    includedStatuses: $includedStatuses
    excludedStatuses: $excludedStatuses
    legalEntityIds: $legalEntityIds
  ) {
    ...AdminLegalEntityFields
  }
}
    ${AdminLegalEntityFieldsFragmentDoc}`;

/**
 * __useListLegalEntitiesQuery__
 *
 * To run a query within a React component, call `useListLegalEntitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListLegalEntitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListLegalEntitiesQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      searchTerm: // value for 'searchTerm'
 *      includedStatuses: // value for 'includedStatuses'
 *      excludedStatuses: // value for 'excludedStatuses'
 *      legalEntityIds: // value for 'legalEntityIds'
 *   },
 * });
 */
export function useListLegalEntitiesQuery(baseOptions: Apollo.QueryHookOptions<ListLegalEntitiesQuery, ListLegalEntitiesQueryVariables> & ({ variables: ListLegalEntitiesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListLegalEntitiesQuery, ListLegalEntitiesQueryVariables>(ListLegalEntitiesDocument, options);
      }
export function useListLegalEntitiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListLegalEntitiesQuery, ListLegalEntitiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListLegalEntitiesQuery, ListLegalEntitiesQueryVariables>(ListLegalEntitiesDocument, options);
        }
export function useListLegalEntitiesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ListLegalEntitiesQuery, ListLegalEntitiesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ListLegalEntitiesQuery, ListLegalEntitiesQueryVariables>(ListLegalEntitiesDocument, options);
        }
export type ListLegalEntitiesQueryHookResult = ReturnType<typeof useListLegalEntitiesQuery>;
export type ListLegalEntitiesLazyQueryHookResult = ReturnType<typeof useListLegalEntitiesLazyQuery>;
export type ListLegalEntitiesSuspenseQueryHookResult = ReturnType<typeof useListLegalEntitiesSuspenseQuery>;
export type ListLegalEntitiesQueryResult = Apollo.QueryResult<ListLegalEntitiesQuery, ListLegalEntitiesQueryVariables>;
export const ListLegalEntityUsersDocument = gql`
    query ListLegalEntityUsers($id: String!) {
  listLegalEntityUsers(id: $id) {
    ...AdminUserFields
  }
}
    ${AdminUserFieldsFragmentDoc}`;

/**
 * __useListLegalEntityUsersQuery__
 *
 * To run a query within a React component, call `useListLegalEntityUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useListLegalEntityUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListLegalEntityUsersQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useListLegalEntityUsersQuery(baseOptions: Apollo.QueryHookOptions<ListLegalEntityUsersQuery, ListLegalEntityUsersQueryVariables> & ({ variables: ListLegalEntityUsersQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListLegalEntityUsersQuery, ListLegalEntityUsersQueryVariables>(ListLegalEntityUsersDocument, options);
      }
export function useListLegalEntityUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListLegalEntityUsersQuery, ListLegalEntityUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListLegalEntityUsersQuery, ListLegalEntityUsersQueryVariables>(ListLegalEntityUsersDocument, options);
        }
export function useListLegalEntityUsersSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ListLegalEntityUsersQuery, ListLegalEntityUsersQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ListLegalEntityUsersQuery, ListLegalEntityUsersQueryVariables>(ListLegalEntityUsersDocument, options);
        }
export type ListLegalEntityUsersQueryHookResult = ReturnType<typeof useListLegalEntityUsersQuery>;
export type ListLegalEntityUsersLazyQueryHookResult = ReturnType<typeof useListLegalEntityUsersLazyQuery>;
export type ListLegalEntityUsersSuspenseQueryHookResult = ReturnType<typeof useListLegalEntityUsersSuspenseQuery>;
export type ListLegalEntityUsersQueryResult = Apollo.QueryResult<ListLegalEntityUsersQuery, ListLegalEntityUsersQueryVariables>;
export const ReadLegalEntityDocument = gql`
    query ReadLegalEntity($id: String!) {
  readLegalEntity(id: $id) {
    ...AdminLegalEntityFields
  }
}
    ${AdminLegalEntityFieldsFragmentDoc}`;

/**
 * __useReadLegalEntityQuery__
 *
 * To run a query within a React component, call `useReadLegalEntityQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadLegalEntityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadLegalEntityQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useReadLegalEntityQuery(baseOptions: Apollo.QueryHookOptions<ReadLegalEntityQuery, ReadLegalEntityQueryVariables> & ({ variables: ReadLegalEntityQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadLegalEntityQuery, ReadLegalEntityQueryVariables>(ReadLegalEntityDocument, options);
      }
export function useReadLegalEntityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadLegalEntityQuery, ReadLegalEntityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadLegalEntityQuery, ReadLegalEntityQueryVariables>(ReadLegalEntityDocument, options);
        }
export function useReadLegalEntitySuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ReadLegalEntityQuery, ReadLegalEntityQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ReadLegalEntityQuery, ReadLegalEntityQueryVariables>(ReadLegalEntityDocument, options);
        }
export type ReadLegalEntityQueryHookResult = ReturnType<typeof useReadLegalEntityQuery>;
export type ReadLegalEntityLazyQueryHookResult = ReturnType<typeof useReadLegalEntityLazyQuery>;
export type ReadLegalEntitySuspenseQueryHookResult = ReturnType<typeof useReadLegalEntitySuspenseQuery>;
export type ReadLegalEntityQueryResult = Apollo.QueryResult<ReadLegalEntityQuery, ReadLegalEntityQueryVariables>;
export const ListUserLegalEntitiesDocument = gql`
    query ListUserLegalEntities($userId: String!) {
  listUserLegalEntities(userId: $userId) {
    ...LegalEntityFields
  }
}
    ${LegalEntityFieldsFragmentDoc}`;

/**
 * __useListUserLegalEntitiesQuery__
 *
 * To run a query within a React component, call `useListUserLegalEntitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListUserLegalEntitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListUserLegalEntitiesQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useListUserLegalEntitiesQuery(baseOptions: Apollo.QueryHookOptions<ListUserLegalEntitiesQuery, ListUserLegalEntitiesQueryVariables> & ({ variables: ListUserLegalEntitiesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListUserLegalEntitiesQuery, ListUserLegalEntitiesQueryVariables>(ListUserLegalEntitiesDocument, options);
      }
export function useListUserLegalEntitiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListUserLegalEntitiesQuery, ListUserLegalEntitiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListUserLegalEntitiesQuery, ListUserLegalEntitiesQueryVariables>(ListUserLegalEntitiesDocument, options);
        }
export function useListUserLegalEntitiesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ListUserLegalEntitiesQuery, ListUserLegalEntitiesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ListUserLegalEntitiesQuery, ListUserLegalEntitiesQueryVariables>(ListUserLegalEntitiesDocument, options);
        }
export type ListUserLegalEntitiesQueryHookResult = ReturnType<typeof useListUserLegalEntitiesQuery>;
export type ListUserLegalEntitiesLazyQueryHookResult = ReturnType<typeof useListUserLegalEntitiesLazyQuery>;
export type ListUserLegalEntitiesSuspenseQueryHookResult = ReturnType<typeof useListUserLegalEntitiesSuspenseQuery>;
export type ListUserLegalEntitiesQueryResult = Apollo.QueryResult<ListUserLegalEntitiesQuery, ListUserLegalEntitiesQueryVariables>;
export const ListApprovalEligibilityDocument = gql`
    query ListApprovalEligibility($legalEntityIds: [String!]!) {
  listApprovalEligibility(legalEntityIds: $legalEntityIds) {
    legalEntityId
    statuses
  }
}
    `;

/**
 * __useListApprovalEligibilityQuery__
 *
 * To run a query within a React component, call `useListApprovalEligibilityQuery` and pass it any options that fit your needs.
 * When your component renders, `useListApprovalEligibilityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListApprovalEligibilityQuery({
 *   variables: {
 *      legalEntityIds: // value for 'legalEntityIds'
 *   },
 * });
 */
export function useListApprovalEligibilityQuery(baseOptions: Apollo.QueryHookOptions<ListApprovalEligibilityQuery, ListApprovalEligibilityQueryVariables> & ({ variables: ListApprovalEligibilityQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListApprovalEligibilityQuery, ListApprovalEligibilityQueryVariables>(ListApprovalEligibilityDocument, options);
      }
export function useListApprovalEligibilityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListApprovalEligibilityQuery, ListApprovalEligibilityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListApprovalEligibilityQuery, ListApprovalEligibilityQueryVariables>(ListApprovalEligibilityDocument, options);
        }
export function useListApprovalEligibilitySuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ListApprovalEligibilityQuery, ListApprovalEligibilityQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ListApprovalEligibilityQuery, ListApprovalEligibilityQueryVariables>(ListApprovalEligibilityDocument, options);
        }
export type ListApprovalEligibilityQueryHookResult = ReturnType<typeof useListApprovalEligibilityQuery>;
export type ListApprovalEligibilityLazyQueryHookResult = ReturnType<typeof useListApprovalEligibilityLazyQuery>;
export type ListApprovalEligibilitySuspenseQueryHookResult = ReturnType<typeof useListApprovalEligibilitySuspenseQuery>;
export type ListApprovalEligibilityQueryResult = Apollo.QueryResult<ListApprovalEligibilityQuery, ListApprovalEligibilityQueryVariables>;
export const ReadLegalEntityPreferencesDocument = gql`
    query ReadLegalEntityPreferences($legalEntityId: String!) {
  readLegalEntityPreferences(legalEntityId: $legalEntityId) {
    legalEntityId
    isCogsInExpenses
    lineClassDisplayName
    lineClassPluralDisplayName
    bookkeeperAiCutoverAt
  }
}
    `;

/**
 * __useReadLegalEntityPreferencesQuery__
 *
 * To run a query within a React component, call `useReadLegalEntityPreferencesQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadLegalEntityPreferencesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadLegalEntityPreferencesQuery({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *   },
 * });
 */
export function useReadLegalEntityPreferencesQuery(baseOptions: Apollo.QueryHookOptions<ReadLegalEntityPreferencesQuery, ReadLegalEntityPreferencesQueryVariables> & ({ variables: ReadLegalEntityPreferencesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadLegalEntityPreferencesQuery, ReadLegalEntityPreferencesQueryVariables>(ReadLegalEntityPreferencesDocument, options);
      }
export function useReadLegalEntityPreferencesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadLegalEntityPreferencesQuery, ReadLegalEntityPreferencesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadLegalEntityPreferencesQuery, ReadLegalEntityPreferencesQueryVariables>(ReadLegalEntityPreferencesDocument, options);
        }
export function useReadLegalEntityPreferencesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ReadLegalEntityPreferencesQuery, ReadLegalEntityPreferencesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ReadLegalEntityPreferencesQuery, ReadLegalEntityPreferencesQueryVariables>(ReadLegalEntityPreferencesDocument, options);
        }
export type ReadLegalEntityPreferencesQueryHookResult = ReturnType<typeof useReadLegalEntityPreferencesQuery>;
export type ReadLegalEntityPreferencesLazyQueryHookResult = ReturnType<typeof useReadLegalEntityPreferencesLazyQuery>;
export type ReadLegalEntityPreferencesSuspenseQueryHookResult = ReturnType<typeof useReadLegalEntityPreferencesSuspenseQuery>;
export type ReadLegalEntityPreferencesQueryResult = Apollo.QueryResult<ReadLegalEntityPreferencesQuery, ReadLegalEntityPreferencesQueryVariables>;
export const CreateAliasDocument = gql`
    mutation CreateAlias($partyId: String!, $alias: String!) {
  createAlias(partyId: $partyId, alias: $alias) {
    partyId
    alias
  }
}
    `;
export type CreateAliasMutationFn = Apollo.MutationFunction<CreateAliasMutation, CreateAliasMutationVariables>;

/**
 * __useCreateAliasMutation__
 *
 * To run a mutation, you first call `useCreateAliasMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAliasMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAliasMutation, { data, loading, error }] = useCreateAliasMutation({
 *   variables: {
 *      partyId: // value for 'partyId'
 *      alias: // value for 'alias'
 *   },
 * });
 */
export function useCreateAliasMutation(baseOptions?: Apollo.MutationHookOptions<CreateAliasMutation, CreateAliasMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAliasMutation, CreateAliasMutationVariables>(CreateAliasDocument, options);
      }
export type CreateAliasMutationHookResult = ReturnType<typeof useCreateAliasMutation>;
export type CreateAliasMutationResult = Apollo.MutationResult<CreateAliasMutation>;
export type CreateAliasMutationOptions = Apollo.BaseMutationOptions<CreateAliasMutation, CreateAliasMutationVariables>;
export const DeleteGlobalPartyDocument = gql`
    mutation DeleteGlobalParty($partyId: String!) {
  deleteGlobalParty(partyId: $partyId)
}
    `;
export type DeleteGlobalPartyMutationFn = Apollo.MutationFunction<DeleteGlobalPartyMutation, DeleteGlobalPartyMutationVariables>;

/**
 * __useDeleteGlobalPartyMutation__
 *
 * To run a mutation, you first call `useDeleteGlobalPartyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteGlobalPartyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteGlobalPartyMutation, { data, loading, error }] = useDeleteGlobalPartyMutation({
 *   variables: {
 *      partyId: // value for 'partyId'
 *   },
 * });
 */
export function useDeleteGlobalPartyMutation(baseOptions?: Apollo.MutationHookOptions<DeleteGlobalPartyMutation, DeleteGlobalPartyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteGlobalPartyMutation, DeleteGlobalPartyMutationVariables>(DeleteGlobalPartyDocument, options);
      }
export type DeleteGlobalPartyMutationHookResult = ReturnType<typeof useDeleteGlobalPartyMutation>;
export type DeleteGlobalPartyMutationResult = Apollo.MutationResult<DeleteGlobalPartyMutation>;
export type DeleteGlobalPartyMutationOptions = Apollo.BaseMutationOptions<DeleteGlobalPartyMutation, DeleteGlobalPartyMutationVariables>;
export const CreateGlobalPartyDocument = gql`
    mutation CreateGlobalParty($fields: NewParty!, $aliases: [String!]!, $prefixes: [String!]!) {
  createGlobalParty(fields: $fields, aliases: $aliases, prefixes: $prefixes) {
    ...AdminPartyFields
  }
}
    ${AdminPartyFieldsFragmentDoc}`;
export type CreateGlobalPartyMutationFn = Apollo.MutationFunction<CreateGlobalPartyMutation, CreateGlobalPartyMutationVariables>;

/**
 * __useCreateGlobalPartyMutation__
 *
 * To run a mutation, you first call `useCreateGlobalPartyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateGlobalPartyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createGlobalPartyMutation, { data, loading, error }] = useCreateGlobalPartyMutation({
 *   variables: {
 *      fields: // value for 'fields'
 *      aliases: // value for 'aliases'
 *      prefixes: // value for 'prefixes'
 *   },
 * });
 */
export function useCreateGlobalPartyMutation(baseOptions?: Apollo.MutationHookOptions<CreateGlobalPartyMutation, CreateGlobalPartyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateGlobalPartyMutation, CreateGlobalPartyMutationVariables>(CreateGlobalPartyDocument, options);
      }
export type CreateGlobalPartyMutationHookResult = ReturnType<typeof useCreateGlobalPartyMutation>;
export type CreateGlobalPartyMutationResult = Apollo.MutationResult<CreateGlobalPartyMutation>;
export type CreateGlobalPartyMutationOptions = Apollo.BaseMutationOptions<CreateGlobalPartyMutation, CreateGlobalPartyMutationVariables>;
export const CreatePrefixMappingDocument = gql`
    mutation CreatePrefixMapping($prefix: String!, $partyId: String!) {
  createPrefixMapping(prefix: $prefix, partyId: $partyId) {
    ...PrefixMappingFields
  }
}
    ${PrefixMappingFieldsFragmentDoc}`;
export type CreatePrefixMappingMutationFn = Apollo.MutationFunction<CreatePrefixMappingMutation, CreatePrefixMappingMutationVariables>;

/**
 * __useCreatePrefixMappingMutation__
 *
 * To run a mutation, you first call `useCreatePrefixMappingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePrefixMappingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPrefixMappingMutation, { data, loading, error }] = useCreatePrefixMappingMutation({
 *   variables: {
 *      prefix: // value for 'prefix'
 *      partyId: // value for 'partyId'
 *   },
 * });
 */
export function useCreatePrefixMappingMutation(baseOptions?: Apollo.MutationHookOptions<CreatePrefixMappingMutation, CreatePrefixMappingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePrefixMappingMutation, CreatePrefixMappingMutationVariables>(CreatePrefixMappingDocument, options);
      }
export type CreatePrefixMappingMutationHookResult = ReturnType<typeof useCreatePrefixMappingMutation>;
export type CreatePrefixMappingMutationResult = Apollo.MutationResult<CreatePrefixMappingMutation>;
export type CreatePrefixMappingMutationOptions = Apollo.BaseMutationOptions<CreatePrefixMappingMutation, CreatePrefixMappingMutationVariables>;
export const UpdatePartyDocument = gql`
    mutation UpdateParty($legalEntityId: String!, $partyId: String!, $details: NewParty!) {
  updateParty(legalEntityId: $legalEntityId, partyId: $partyId, details: $details)
}
    `;
export type UpdatePartyMutationFn = Apollo.MutationFunction<UpdatePartyMutation, UpdatePartyMutationVariables>;

/**
 * __useUpdatePartyMutation__
 *
 * To run a mutation, you first call `useUpdatePartyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePartyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePartyMutation, { data, loading, error }] = useUpdatePartyMutation({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *      partyId: // value for 'partyId'
 *      details: // value for 'details'
 *   },
 * });
 */
export function useUpdatePartyMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePartyMutation, UpdatePartyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePartyMutation, UpdatePartyMutationVariables>(UpdatePartyDocument, options);
      }
export type UpdatePartyMutationHookResult = ReturnType<typeof useUpdatePartyMutation>;
export type UpdatePartyMutationResult = Apollo.MutationResult<UpdatePartyMutation>;
export type UpdatePartyMutationOptions = Apollo.BaseMutationOptions<UpdatePartyMutation, UpdatePartyMutationVariables>;
export const DeleteAliasDocument = gql`
    mutation DeleteAlias($partyId: String!, $alias: String!) {
  deleteAlias(partyId: $partyId, alias: $alias)
}
    `;
export type DeleteAliasMutationFn = Apollo.MutationFunction<DeleteAliasMutation, DeleteAliasMutationVariables>;

/**
 * __useDeleteAliasMutation__
 *
 * To run a mutation, you first call `useDeleteAliasMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAliasMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAliasMutation, { data, loading, error }] = useDeleteAliasMutation({
 *   variables: {
 *      partyId: // value for 'partyId'
 *      alias: // value for 'alias'
 *   },
 * });
 */
export function useDeleteAliasMutation(baseOptions?: Apollo.MutationHookOptions<DeleteAliasMutation, DeleteAliasMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteAliasMutation, DeleteAliasMutationVariables>(DeleteAliasDocument, options);
      }
export type DeleteAliasMutationHookResult = ReturnType<typeof useDeleteAliasMutation>;
export type DeleteAliasMutationResult = Apollo.MutationResult<DeleteAliasMutation>;
export type DeleteAliasMutationOptions = Apollo.BaseMutationOptions<DeleteAliasMutation, DeleteAliasMutationVariables>;
export const DesignatePartyDocument = gql`
    mutation DesignateParty($partyId: String!, $legalEntityId: String!, $designation: PartyDesignation!) {
  designateParty(
    partyId: $partyId
    legalEntityId: $legalEntityId
    designation: $designation
  )
}
    `;
export type DesignatePartyMutationFn = Apollo.MutationFunction<DesignatePartyMutation, DesignatePartyMutationVariables>;

/**
 * __useDesignatePartyMutation__
 *
 * To run a mutation, you first call `useDesignatePartyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDesignatePartyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [designatePartyMutation, { data, loading, error }] = useDesignatePartyMutation({
 *   variables: {
 *      partyId: // value for 'partyId'
 *      legalEntityId: // value for 'legalEntityId'
 *      designation: // value for 'designation'
 *   },
 * });
 */
export function useDesignatePartyMutation(baseOptions?: Apollo.MutationHookOptions<DesignatePartyMutation, DesignatePartyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DesignatePartyMutation, DesignatePartyMutationVariables>(DesignatePartyDocument, options);
      }
export type DesignatePartyMutationHookResult = ReturnType<typeof useDesignatePartyMutation>;
export type DesignatePartyMutationResult = Apollo.MutationResult<DesignatePartyMutation>;
export type DesignatePartyMutationOptions = Apollo.BaseMutationOptions<DesignatePartyMutation, DesignatePartyMutationVariables>;
export const DeletePartyExtractionDocument = gql`
    mutation DeletePartyExtraction($legalEntityId: String!, $partyExtractionId: String!) {
  deletePartyExtraction(
    legalEntityId: $legalEntityId
    partyExtractionId: $partyExtractionId
  )
}
    `;
export type DeletePartyExtractionMutationFn = Apollo.MutationFunction<DeletePartyExtractionMutation, DeletePartyExtractionMutationVariables>;

/**
 * __useDeletePartyExtractionMutation__
 *
 * To run a mutation, you first call `useDeletePartyExtractionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePartyExtractionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePartyExtractionMutation, { data, loading, error }] = useDeletePartyExtractionMutation({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *      partyExtractionId: // value for 'partyExtractionId'
 *   },
 * });
 */
export function useDeletePartyExtractionMutation(baseOptions?: Apollo.MutationHookOptions<DeletePartyExtractionMutation, DeletePartyExtractionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletePartyExtractionMutation, DeletePartyExtractionMutationVariables>(DeletePartyExtractionDocument, options);
      }
export type DeletePartyExtractionMutationHookResult = ReturnType<typeof useDeletePartyExtractionMutation>;
export type DeletePartyExtractionMutationResult = Apollo.MutationResult<DeletePartyExtractionMutation>;
export type DeletePartyExtractionMutationOptions = Apollo.BaseMutationOptions<DeletePartyExtractionMutation, DeletePartyExtractionMutationVariables>;
export const UpdateGlobalPartyDocument = gql`
    mutation UpdateGlobalParty($partyId: String!, $fields: NewParty!) {
  updateGlobalParty(partyId: $partyId, fields: $fields)
}
    `;
export type UpdateGlobalPartyMutationFn = Apollo.MutationFunction<UpdateGlobalPartyMutation, UpdateGlobalPartyMutationVariables>;

/**
 * __useUpdateGlobalPartyMutation__
 *
 * To run a mutation, you first call `useUpdateGlobalPartyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateGlobalPartyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateGlobalPartyMutation, { data, loading, error }] = useUpdateGlobalPartyMutation({
 *   variables: {
 *      partyId: // value for 'partyId'
 *      fields: // value for 'fields'
 *   },
 * });
 */
export function useUpdateGlobalPartyMutation(baseOptions?: Apollo.MutationHookOptions<UpdateGlobalPartyMutation, UpdateGlobalPartyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateGlobalPartyMutation, UpdateGlobalPartyMutationVariables>(UpdateGlobalPartyDocument, options);
      }
export type UpdateGlobalPartyMutationHookResult = ReturnType<typeof useUpdateGlobalPartyMutation>;
export type UpdateGlobalPartyMutationResult = Apollo.MutationResult<UpdateGlobalPartyMutation>;
export type UpdateGlobalPartyMutationOptions = Apollo.BaseMutationOptions<UpdateGlobalPartyMutation, UpdateGlobalPartyMutationVariables>;
export const ListGlobalPartiesWithSummariesDocument = gql`
    query ListGlobalPartiesWithSummaries($filter: GlobalPartyFilter, $pagination: Pagination!, $order: GlobalPartySort) {
  listGlobalPartiesWithSummaries(
    filter: $filter
    pagination: $pagination
    order: $order
  ) {
    ...AdminGlobalPartyFields
  }
}
    ${AdminGlobalPartyFieldsFragmentDoc}`;

/**
 * __useListGlobalPartiesWithSummariesQuery__
 *
 * To run a query within a React component, call `useListGlobalPartiesWithSummariesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListGlobalPartiesWithSummariesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListGlobalPartiesWithSummariesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useListGlobalPartiesWithSummariesQuery(baseOptions: Apollo.QueryHookOptions<ListGlobalPartiesWithSummariesQuery, ListGlobalPartiesWithSummariesQueryVariables> & ({ variables: ListGlobalPartiesWithSummariesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListGlobalPartiesWithSummariesQuery, ListGlobalPartiesWithSummariesQueryVariables>(ListGlobalPartiesWithSummariesDocument, options);
      }
export function useListGlobalPartiesWithSummariesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListGlobalPartiesWithSummariesQuery, ListGlobalPartiesWithSummariesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListGlobalPartiesWithSummariesQuery, ListGlobalPartiesWithSummariesQueryVariables>(ListGlobalPartiesWithSummariesDocument, options);
        }
export function useListGlobalPartiesWithSummariesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ListGlobalPartiesWithSummariesQuery, ListGlobalPartiesWithSummariesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ListGlobalPartiesWithSummariesQuery, ListGlobalPartiesWithSummariesQueryVariables>(ListGlobalPartiesWithSummariesDocument, options);
        }
export type ListGlobalPartiesWithSummariesQueryHookResult = ReturnType<typeof useListGlobalPartiesWithSummariesQuery>;
export type ListGlobalPartiesWithSummariesLazyQueryHookResult = ReturnType<typeof useListGlobalPartiesWithSummariesLazyQuery>;
export type ListGlobalPartiesWithSummariesSuspenseQueryHookResult = ReturnType<typeof useListGlobalPartiesWithSummariesSuspenseQuery>;
export type ListGlobalPartiesWithSummariesQueryResult = Apollo.QueryResult<ListGlobalPartiesWithSummariesQuery, ListGlobalPartiesWithSummariesQueryVariables>;
export const ListPartyExtractionsDocument = gql`
    query ListPartyExtractions($legalEntityId: String!, $pagination: Pagination!) {
  listPartyExtractions(legalEntityId: $legalEntityId, pagination: $pagination) {
    legalEntityId
    partyExtractionId
    partyExtractionKey
    transactionFactId
    party {
      ...PartyFields
    }
  }
}
    ${PartyFieldsFragmentDoc}`;

/**
 * __useListPartyExtractionsQuery__
 *
 * To run a query within a React component, call `useListPartyExtractionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListPartyExtractionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListPartyExtractionsQuery({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useListPartyExtractionsQuery(baseOptions: Apollo.QueryHookOptions<ListPartyExtractionsQuery, ListPartyExtractionsQueryVariables> & ({ variables: ListPartyExtractionsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListPartyExtractionsQuery, ListPartyExtractionsQueryVariables>(ListPartyExtractionsDocument, options);
      }
export function useListPartyExtractionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListPartyExtractionsQuery, ListPartyExtractionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListPartyExtractionsQuery, ListPartyExtractionsQueryVariables>(ListPartyExtractionsDocument, options);
        }
export function useListPartyExtractionsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ListPartyExtractionsQuery, ListPartyExtractionsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ListPartyExtractionsQuery, ListPartyExtractionsQueryVariables>(ListPartyExtractionsDocument, options);
        }
export type ListPartyExtractionsQueryHookResult = ReturnType<typeof useListPartyExtractionsQuery>;
export type ListPartyExtractionsLazyQueryHookResult = ReturnType<typeof useListPartyExtractionsLazyQuery>;
export type ListPartyExtractionsSuspenseQueryHookResult = ReturnType<typeof useListPartyExtractionsSuspenseQuery>;
export type ListPartyExtractionsQueryResult = Apollo.QueryResult<ListPartyExtractionsQuery, ListPartyExtractionsQueryVariables>;
export const ListAgentPartiesDocument = gql`
    query ListAgentParties($legalEntityId: String!, $pagination: Pagination!) {
  listAgentParties(legalEntityId: $legalEntityId, pagination: $pagination) {
    ...PartyFields
  }
}
    ${PartyFieldsFragmentDoc}`;

/**
 * __useListAgentPartiesQuery__
 *
 * To run a query within a React component, call `useListAgentPartiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListAgentPartiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListAgentPartiesQuery({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useListAgentPartiesQuery(baseOptions: Apollo.QueryHookOptions<ListAgentPartiesQuery, ListAgentPartiesQueryVariables> & ({ variables: ListAgentPartiesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListAgentPartiesQuery, ListAgentPartiesQueryVariables>(ListAgentPartiesDocument, options);
      }
export function useListAgentPartiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListAgentPartiesQuery, ListAgentPartiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListAgentPartiesQuery, ListAgentPartiesQueryVariables>(ListAgentPartiesDocument, options);
        }
export function useListAgentPartiesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ListAgentPartiesQuery, ListAgentPartiesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ListAgentPartiesQuery, ListAgentPartiesQueryVariables>(ListAgentPartiesDocument, options);
        }
export type ListAgentPartiesQueryHookResult = ReturnType<typeof useListAgentPartiesQuery>;
export type ListAgentPartiesLazyQueryHookResult = ReturnType<typeof useListAgentPartiesLazyQuery>;
export type ListAgentPartiesSuspenseQueryHookResult = ReturnType<typeof useListAgentPartiesSuspenseQuery>;
export type ListAgentPartiesQueryResult = Apollo.QueryResult<ListAgentPartiesQuery, ListAgentPartiesQueryVariables>;
export const ListPartiesDocument = gql`
    query ListParties($legalEntityId: String!, $pagination: Pagination!, $viewType: ViewType!, $partyType: PartyType, $designation: PartyDesignation) {
  listParties(
    legalEntityId: $legalEntityId
    pagination: $pagination
    viewType: $viewType
    partyType: $partyType
    designation: $designation
  ) {
    ...AdminPartyFields
  }
}
    ${AdminPartyFieldsFragmentDoc}`;

/**
 * __useListPartiesQuery__
 *
 * To run a query within a React component, call `useListPartiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListPartiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListPartiesQuery({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *      pagination: // value for 'pagination'
 *      viewType: // value for 'viewType'
 *      partyType: // value for 'partyType'
 *      designation: // value for 'designation'
 *   },
 * });
 */
export function useListPartiesQuery(baseOptions: Apollo.QueryHookOptions<ListPartiesQuery, ListPartiesQueryVariables> & ({ variables: ListPartiesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListPartiesQuery, ListPartiesQueryVariables>(ListPartiesDocument, options);
      }
export function useListPartiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListPartiesQuery, ListPartiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListPartiesQuery, ListPartiesQueryVariables>(ListPartiesDocument, options);
        }
export function useListPartiesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ListPartiesQuery, ListPartiesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ListPartiesQuery, ListPartiesQueryVariables>(ListPartiesDocument, options);
        }
export type ListPartiesQueryHookResult = ReturnType<typeof useListPartiesQuery>;
export type ListPartiesLazyQueryHookResult = ReturnType<typeof useListPartiesLazyQuery>;
export type ListPartiesSuspenseQueryHookResult = ReturnType<typeof useListPartiesSuspenseQuery>;
export type ListPartiesQueryResult = Apollo.QueryResult<ListPartiesQuery, ListPartiesQueryVariables>;
export const ListGlobalPartiesDocument = gql`
    query ListGlobalParties($filter: GlobalPartyFilter, $pagination: Pagination!, $order: GlobalPartySort) {
  listGlobalParties(filter: $filter, pagination: $pagination, order: $order) {
    ...AdminGlobalPartyFields
  }
}
    ${AdminGlobalPartyFieldsFragmentDoc}`;

/**
 * __useListGlobalPartiesQuery__
 *
 * To run a query within a React component, call `useListGlobalPartiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListGlobalPartiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListGlobalPartiesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      pagination: // value for 'pagination'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useListGlobalPartiesQuery(baseOptions: Apollo.QueryHookOptions<ListGlobalPartiesQuery, ListGlobalPartiesQueryVariables> & ({ variables: ListGlobalPartiesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListGlobalPartiesQuery, ListGlobalPartiesQueryVariables>(ListGlobalPartiesDocument, options);
      }
export function useListGlobalPartiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListGlobalPartiesQuery, ListGlobalPartiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListGlobalPartiesQuery, ListGlobalPartiesQueryVariables>(ListGlobalPartiesDocument, options);
        }
export function useListGlobalPartiesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ListGlobalPartiesQuery, ListGlobalPartiesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ListGlobalPartiesQuery, ListGlobalPartiesQueryVariables>(ListGlobalPartiesDocument, options);
        }
export type ListGlobalPartiesQueryHookResult = ReturnType<typeof useListGlobalPartiesQuery>;
export type ListGlobalPartiesLazyQueryHookResult = ReturnType<typeof useListGlobalPartiesLazyQuery>;
export type ListGlobalPartiesSuspenseQueryHookResult = ReturnType<typeof useListGlobalPartiesSuspenseQuery>;
export type ListGlobalPartiesQueryResult = Apollo.QueryResult<ListGlobalPartiesQuery, ListGlobalPartiesQueryVariables>;
export const ListPayrollPartiesDocument = gql`
    query ListPayrollParties($legalEntityId: String!) {
  listPayrollParties(legalEntityId: $legalEntityId) {
    ...PayrollProviderFields
  }
}
    ${PayrollProviderFieldsFragmentDoc}`;

/**
 * __useListPayrollPartiesQuery__
 *
 * To run a query within a React component, call `useListPayrollPartiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListPayrollPartiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListPayrollPartiesQuery({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *   },
 * });
 */
export function useListPayrollPartiesQuery(baseOptions: Apollo.QueryHookOptions<ListPayrollPartiesQuery, ListPayrollPartiesQueryVariables> & ({ variables: ListPayrollPartiesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListPayrollPartiesQuery, ListPayrollPartiesQueryVariables>(ListPayrollPartiesDocument, options);
      }
export function useListPayrollPartiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListPayrollPartiesQuery, ListPayrollPartiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListPayrollPartiesQuery, ListPayrollPartiesQueryVariables>(ListPayrollPartiesDocument, options);
        }
export function useListPayrollPartiesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ListPayrollPartiesQuery, ListPayrollPartiesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ListPayrollPartiesQuery, ListPayrollPartiesQueryVariables>(ListPayrollPartiesDocument, options);
        }
export type ListPayrollPartiesQueryHookResult = ReturnType<typeof useListPayrollPartiesQuery>;
export type ListPayrollPartiesLazyQueryHookResult = ReturnType<typeof useListPayrollPartiesLazyQuery>;
export type ListPayrollPartiesSuspenseQueryHookResult = ReturnType<typeof useListPayrollPartiesSuspenseQuery>;
export type ListPayrollPartiesQueryResult = Apollo.QueryResult<ListPayrollPartiesQuery, ListPayrollPartiesQueryVariables>;
export const ReadSourceRecordedMatchSummaryDocument = gql`
    query ReadSourceRecordedMatchSummary($legalEntityId: String!) {
  readSourceRecordedMatchSummary(legalEntityId: $legalEntityId) {
    ...SourceRecordedMatchSummaryFields
  }
}
    ${SourceRecordedMatchSummaryFieldsFragmentDoc}`;

/**
 * __useReadSourceRecordedMatchSummaryQuery__
 *
 * To run a query within a React component, call `useReadSourceRecordedMatchSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadSourceRecordedMatchSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadSourceRecordedMatchSummaryQuery({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *   },
 * });
 */
export function useReadSourceRecordedMatchSummaryQuery(baseOptions: Apollo.QueryHookOptions<ReadSourceRecordedMatchSummaryQuery, ReadSourceRecordedMatchSummaryQueryVariables> & ({ variables: ReadSourceRecordedMatchSummaryQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadSourceRecordedMatchSummaryQuery, ReadSourceRecordedMatchSummaryQueryVariables>(ReadSourceRecordedMatchSummaryDocument, options);
      }
export function useReadSourceRecordedMatchSummaryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadSourceRecordedMatchSummaryQuery, ReadSourceRecordedMatchSummaryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadSourceRecordedMatchSummaryQuery, ReadSourceRecordedMatchSummaryQueryVariables>(ReadSourceRecordedMatchSummaryDocument, options);
        }
export function useReadSourceRecordedMatchSummarySuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ReadSourceRecordedMatchSummaryQuery, ReadSourceRecordedMatchSummaryQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ReadSourceRecordedMatchSummaryQuery, ReadSourceRecordedMatchSummaryQueryVariables>(ReadSourceRecordedMatchSummaryDocument, options);
        }
export type ReadSourceRecordedMatchSummaryQueryHookResult = ReturnType<typeof useReadSourceRecordedMatchSummaryQuery>;
export type ReadSourceRecordedMatchSummaryLazyQueryHookResult = ReturnType<typeof useReadSourceRecordedMatchSummaryLazyQuery>;
export type ReadSourceRecordedMatchSummarySuspenseQueryHookResult = ReturnType<typeof useReadSourceRecordedMatchSummarySuspenseQuery>;
export type ReadSourceRecordedMatchSummaryQueryResult = Apollo.QueryResult<ReadSourceRecordedMatchSummaryQuery, ReadSourceRecordedMatchSummaryQueryVariables>;
export const SoftDeleteLegalEntityDocument = gql`
    mutation SoftDeleteLegalEntity($legalEntityId: String!) {
  softDeleteLegalEntity(id: $legalEntityId)
}
    `;
export type SoftDeleteLegalEntityMutationFn = Apollo.MutationFunction<SoftDeleteLegalEntityMutation, SoftDeleteLegalEntityMutationVariables>;

/**
 * __useSoftDeleteLegalEntityMutation__
 *
 * To run a mutation, you first call `useSoftDeleteLegalEntityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSoftDeleteLegalEntityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [softDeleteLegalEntityMutation, { data, loading, error }] = useSoftDeleteLegalEntityMutation({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *   },
 * });
 */
export function useSoftDeleteLegalEntityMutation(baseOptions?: Apollo.MutationHookOptions<SoftDeleteLegalEntityMutation, SoftDeleteLegalEntityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SoftDeleteLegalEntityMutation, SoftDeleteLegalEntityMutationVariables>(SoftDeleteLegalEntityDocument, options);
      }
export type SoftDeleteLegalEntityMutationHookResult = ReturnType<typeof useSoftDeleteLegalEntityMutation>;
export type SoftDeleteLegalEntityMutationResult = Apollo.MutationResult<SoftDeleteLegalEntityMutation>;
export type SoftDeleteLegalEntityMutationOptions = Apollo.BaseMutationOptions<SoftDeleteLegalEntityMutation, SoftDeleteLegalEntityMutationVariables>;
export const SoftDeleteOrganizationDocument = gql`
    mutation SoftDeleteOrganization($organizationId: String!, $deleteUsers: Boolean!) {
  softDeleteOrganization(id: $organizationId, deleteUsers: $deleteUsers)
}
    `;
export type SoftDeleteOrganizationMutationFn = Apollo.MutationFunction<SoftDeleteOrganizationMutation, SoftDeleteOrganizationMutationVariables>;

/**
 * __useSoftDeleteOrganizationMutation__
 *
 * To run a mutation, you first call `useSoftDeleteOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSoftDeleteOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [softDeleteOrganizationMutation, { data, loading, error }] = useSoftDeleteOrganizationMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      deleteUsers: // value for 'deleteUsers'
 *   },
 * });
 */
export function useSoftDeleteOrganizationMutation(baseOptions?: Apollo.MutationHookOptions<SoftDeleteOrganizationMutation, SoftDeleteOrganizationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SoftDeleteOrganizationMutation, SoftDeleteOrganizationMutationVariables>(SoftDeleteOrganizationDocument, options);
      }
export type SoftDeleteOrganizationMutationHookResult = ReturnType<typeof useSoftDeleteOrganizationMutation>;
export type SoftDeleteOrganizationMutationResult = Apollo.MutationResult<SoftDeleteOrganizationMutation>;
export type SoftDeleteOrganizationMutationOptions = Apollo.BaseMutationOptions<SoftDeleteOrganizationMutation, SoftDeleteOrganizationMutationVariables>;
export const UpdateClassificationValidationExpectationDocument = gql`
    mutation UpdateClassificationValidationExpectation($legalEntityId: String!, $facts: [NewClassificationValidationExpectationFact!]!, $partyName: String, $partyRole: String, $partyIconObjectName: String, $debitCategoryName: String, $creditCategoryName: String, $employeeOwnerName: String, $expectationId: String!) {
  updateClassificationValidationExpectation(
    legalEntityId: $legalEntityId
    facts: $facts
    partyName: $partyName
    partyRole: $partyRole
    partyIconObjectName: $partyIconObjectName
    debitCategoryName: $debitCategoryName
    creditCategoryName: $creditCategoryName
    employeeOwnerName: $employeeOwnerName
    expectationId: $expectationId
  ) {
    ...ClassificationValidationFields
  }
}
    ${ClassificationValidationFieldsFragmentDoc}`;
export type UpdateClassificationValidationExpectationMutationFn = Apollo.MutationFunction<UpdateClassificationValidationExpectationMutation, UpdateClassificationValidationExpectationMutationVariables>;

/**
 * __useUpdateClassificationValidationExpectationMutation__
 *
 * To run a mutation, you first call `useUpdateClassificationValidationExpectationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClassificationValidationExpectationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClassificationValidationExpectationMutation, { data, loading, error }] = useUpdateClassificationValidationExpectationMutation({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *      facts: // value for 'facts'
 *      partyName: // value for 'partyName'
 *      partyRole: // value for 'partyRole'
 *      partyIconObjectName: // value for 'partyIconObjectName'
 *      debitCategoryName: // value for 'debitCategoryName'
 *      creditCategoryName: // value for 'creditCategoryName'
 *      employeeOwnerName: // value for 'employeeOwnerName'
 *      expectationId: // value for 'expectationId'
 *   },
 * });
 */
export function useUpdateClassificationValidationExpectationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateClassificationValidationExpectationMutation, UpdateClassificationValidationExpectationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateClassificationValidationExpectationMutation, UpdateClassificationValidationExpectationMutationVariables>(UpdateClassificationValidationExpectationDocument, options);
      }
export type UpdateClassificationValidationExpectationMutationHookResult = ReturnType<typeof useUpdateClassificationValidationExpectationMutation>;
export type UpdateClassificationValidationExpectationMutationResult = Apollo.MutationResult<UpdateClassificationValidationExpectationMutation>;
export type UpdateClassificationValidationExpectationMutationOptions = Apollo.BaseMutationOptions<UpdateClassificationValidationExpectationMutation, UpdateClassificationValidationExpectationMutationVariables>;
export const CreateClassificationValidationExpectationDocument = gql`
    mutation CreateClassificationValidationExpectation($legalEntityId: String!, $facts: [NewClassificationValidationExpectationFact!]!, $partyName: String, $partyRole: String, $partyIconObjectName: String, $debitCategoryName: String, $creditCategoryName: String, $employeeOwnerName: String) {
  createClassificationValidationExpectation(
    legalEntityId: $legalEntityId
    facts: $facts
    partyName: $partyName
    partyRole: $partyRole
    partyIconObjectName: $partyIconObjectName
    debitCategoryName: $debitCategoryName
    creditCategoryName: $creditCategoryName
    employeeOwnerName: $employeeOwnerName
  ) {
    ...ClassificationValidationFields
  }
}
    ${ClassificationValidationFieldsFragmentDoc}`;
export type CreateClassificationValidationExpectationMutationFn = Apollo.MutationFunction<CreateClassificationValidationExpectationMutation, CreateClassificationValidationExpectationMutationVariables>;

/**
 * __useCreateClassificationValidationExpectationMutation__
 *
 * To run a mutation, you first call `useCreateClassificationValidationExpectationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateClassificationValidationExpectationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createClassificationValidationExpectationMutation, { data, loading, error }] = useCreateClassificationValidationExpectationMutation({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *      facts: // value for 'facts'
 *      partyName: // value for 'partyName'
 *      partyRole: // value for 'partyRole'
 *      partyIconObjectName: // value for 'partyIconObjectName'
 *      debitCategoryName: // value for 'debitCategoryName'
 *      creditCategoryName: // value for 'creditCategoryName'
 *      employeeOwnerName: // value for 'employeeOwnerName'
 *   },
 * });
 */
export function useCreateClassificationValidationExpectationMutation(baseOptions?: Apollo.MutationHookOptions<CreateClassificationValidationExpectationMutation, CreateClassificationValidationExpectationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateClassificationValidationExpectationMutation, CreateClassificationValidationExpectationMutationVariables>(CreateClassificationValidationExpectationDocument, options);
      }
export type CreateClassificationValidationExpectationMutationHookResult = ReturnType<typeof useCreateClassificationValidationExpectationMutation>;
export type CreateClassificationValidationExpectationMutationResult = Apollo.MutationResult<CreateClassificationValidationExpectationMutation>;
export type CreateClassificationValidationExpectationMutationOptions = Apollo.BaseMutationOptions<CreateClassificationValidationExpectationMutation, CreateClassificationValidationExpectationMutationVariables>;
export const ListMismatchedQuickbooksBalanceSheetCategoriesDocument = gql`
    query ListMismatchedQuickbooksBalanceSheetCategories($legalEntityId: String!) {
  listMismatchedQuickbooksBalanceSheetCategories(legalEntityId: $legalEntityId) {
    ...MismatchedQuickbooksBalanceSheetCategoryFields
  }
}
    ${MismatchedQuickbooksBalanceSheetCategoryFieldsFragmentDoc}`;

/**
 * __useListMismatchedQuickbooksBalanceSheetCategoriesQuery__
 *
 * To run a query within a React component, call `useListMismatchedQuickbooksBalanceSheetCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListMismatchedQuickbooksBalanceSheetCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListMismatchedQuickbooksBalanceSheetCategoriesQuery({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *   },
 * });
 */
export function useListMismatchedQuickbooksBalanceSheetCategoriesQuery(baseOptions: Apollo.QueryHookOptions<ListMismatchedQuickbooksBalanceSheetCategoriesQuery, ListMismatchedQuickbooksBalanceSheetCategoriesQueryVariables> & ({ variables: ListMismatchedQuickbooksBalanceSheetCategoriesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListMismatchedQuickbooksBalanceSheetCategoriesQuery, ListMismatchedQuickbooksBalanceSheetCategoriesQueryVariables>(ListMismatchedQuickbooksBalanceSheetCategoriesDocument, options);
      }
export function useListMismatchedQuickbooksBalanceSheetCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListMismatchedQuickbooksBalanceSheetCategoriesQuery, ListMismatchedQuickbooksBalanceSheetCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListMismatchedQuickbooksBalanceSheetCategoriesQuery, ListMismatchedQuickbooksBalanceSheetCategoriesQueryVariables>(ListMismatchedQuickbooksBalanceSheetCategoriesDocument, options);
        }
export function useListMismatchedQuickbooksBalanceSheetCategoriesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ListMismatchedQuickbooksBalanceSheetCategoriesQuery, ListMismatchedQuickbooksBalanceSheetCategoriesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ListMismatchedQuickbooksBalanceSheetCategoriesQuery, ListMismatchedQuickbooksBalanceSheetCategoriesQueryVariables>(ListMismatchedQuickbooksBalanceSheetCategoriesDocument, options);
        }
export type ListMismatchedQuickbooksBalanceSheetCategoriesQueryHookResult = ReturnType<typeof useListMismatchedQuickbooksBalanceSheetCategoriesQuery>;
export type ListMismatchedQuickbooksBalanceSheetCategoriesLazyQueryHookResult = ReturnType<typeof useListMismatchedQuickbooksBalanceSheetCategoriesLazyQuery>;
export type ListMismatchedQuickbooksBalanceSheetCategoriesSuspenseQueryHookResult = ReturnType<typeof useListMismatchedQuickbooksBalanceSheetCategoriesSuspenseQuery>;
export type ListMismatchedQuickbooksBalanceSheetCategoriesQueryResult = Apollo.QueryResult<ListMismatchedQuickbooksBalanceSheetCategoriesQuery, ListMismatchedQuickbooksBalanceSheetCategoriesQueryVariables>;
export const ListClassificationValidationExpectationsDocument = gql`
    query ListClassificationValidationExpectations($legalEntityId: String, $factIds: [String!]) {
  listClassificationValidationExpectations(
    legalEntityId: $legalEntityId
    factIds: $factIds
  ) {
    ...ClassificationValidationFields
  }
}
    ${ClassificationValidationFieldsFragmentDoc}`;

/**
 * __useListClassificationValidationExpectationsQuery__
 *
 * To run a query within a React component, call `useListClassificationValidationExpectationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListClassificationValidationExpectationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListClassificationValidationExpectationsQuery({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *      factIds: // value for 'factIds'
 *   },
 * });
 */
export function useListClassificationValidationExpectationsQuery(baseOptions?: Apollo.QueryHookOptions<ListClassificationValidationExpectationsQuery, ListClassificationValidationExpectationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListClassificationValidationExpectationsQuery, ListClassificationValidationExpectationsQueryVariables>(ListClassificationValidationExpectationsDocument, options);
      }
export function useListClassificationValidationExpectationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListClassificationValidationExpectationsQuery, ListClassificationValidationExpectationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListClassificationValidationExpectationsQuery, ListClassificationValidationExpectationsQueryVariables>(ListClassificationValidationExpectationsDocument, options);
        }
export function useListClassificationValidationExpectationsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ListClassificationValidationExpectationsQuery, ListClassificationValidationExpectationsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ListClassificationValidationExpectationsQuery, ListClassificationValidationExpectationsQueryVariables>(ListClassificationValidationExpectationsDocument, options);
        }
export type ListClassificationValidationExpectationsQueryHookResult = ReturnType<typeof useListClassificationValidationExpectationsQuery>;
export type ListClassificationValidationExpectationsLazyQueryHookResult = ReturnType<typeof useListClassificationValidationExpectationsLazyQuery>;
export type ListClassificationValidationExpectationsSuspenseQueryHookResult = ReturnType<typeof useListClassificationValidationExpectationsSuspenseQuery>;
export type ListClassificationValidationExpectationsQueryResult = Apollo.QueryResult<ListClassificationValidationExpectationsQuery, ListClassificationValidationExpectationsQueryVariables>;
export const ListMismatchedQuickbooksCategoriesDocument = gql`
    query ListMismatchedQuickbooksCategories($legalEntityId: String!) {
  listMismatchedQuickbooksCategories(legalEntityId: $legalEntityId) {
    ...MismatchedQuickbooksCategoryFields
  }
}
    ${MismatchedQuickbooksCategoryFieldsFragmentDoc}`;

/**
 * __useListMismatchedQuickbooksCategoriesQuery__
 *
 * To run a query within a React component, call `useListMismatchedQuickbooksCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListMismatchedQuickbooksCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListMismatchedQuickbooksCategoriesQuery({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *   },
 * });
 */
export function useListMismatchedQuickbooksCategoriesQuery(baseOptions: Apollo.QueryHookOptions<ListMismatchedQuickbooksCategoriesQuery, ListMismatchedQuickbooksCategoriesQueryVariables> & ({ variables: ListMismatchedQuickbooksCategoriesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListMismatchedQuickbooksCategoriesQuery, ListMismatchedQuickbooksCategoriesQueryVariables>(ListMismatchedQuickbooksCategoriesDocument, options);
      }
export function useListMismatchedQuickbooksCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListMismatchedQuickbooksCategoriesQuery, ListMismatchedQuickbooksCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListMismatchedQuickbooksCategoriesQuery, ListMismatchedQuickbooksCategoriesQueryVariables>(ListMismatchedQuickbooksCategoriesDocument, options);
        }
export function useListMismatchedQuickbooksCategoriesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ListMismatchedQuickbooksCategoriesQuery, ListMismatchedQuickbooksCategoriesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ListMismatchedQuickbooksCategoriesQuery, ListMismatchedQuickbooksCategoriesQueryVariables>(ListMismatchedQuickbooksCategoriesDocument, options);
        }
export type ListMismatchedQuickbooksCategoriesQueryHookResult = ReturnType<typeof useListMismatchedQuickbooksCategoriesQuery>;
export type ListMismatchedQuickbooksCategoriesLazyQueryHookResult = ReturnType<typeof useListMismatchedQuickbooksCategoriesLazyQuery>;
export type ListMismatchedQuickbooksCategoriesSuspenseQueryHookResult = ReturnType<typeof useListMismatchedQuickbooksCategoriesSuspenseQuery>;
export type ListMismatchedQuickbooksCategoriesQueryResult = Apollo.QueryResult<ListMismatchedQuickbooksCategoriesQuery, ListMismatchedQuickbooksCategoriesQueryVariables>;
export const SummarizeDataSourceFetchesDocument = gql`
    query SummarizeDataSourceFetches {
  summarizeDataSourceFetches {
    ...SummarizeDataSourceFetchesFields
  }
}
    ${SummarizeDataSourceFetchesFieldsFragmentDoc}`;

/**
 * __useSummarizeDataSourceFetchesQuery__
 *
 * To run a query within a React component, call `useSummarizeDataSourceFetchesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSummarizeDataSourceFetchesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSummarizeDataSourceFetchesQuery({
 *   variables: {
 *   },
 * });
 */
export function useSummarizeDataSourceFetchesQuery(baseOptions?: Apollo.QueryHookOptions<SummarizeDataSourceFetchesQuery, SummarizeDataSourceFetchesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SummarizeDataSourceFetchesQuery, SummarizeDataSourceFetchesQueryVariables>(SummarizeDataSourceFetchesDocument, options);
      }
export function useSummarizeDataSourceFetchesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SummarizeDataSourceFetchesQuery, SummarizeDataSourceFetchesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SummarizeDataSourceFetchesQuery, SummarizeDataSourceFetchesQueryVariables>(SummarizeDataSourceFetchesDocument, options);
        }
export function useSummarizeDataSourceFetchesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<SummarizeDataSourceFetchesQuery, SummarizeDataSourceFetchesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SummarizeDataSourceFetchesQuery, SummarizeDataSourceFetchesQueryVariables>(SummarizeDataSourceFetchesDocument, options);
        }
export type SummarizeDataSourceFetchesQueryHookResult = ReturnType<typeof useSummarizeDataSourceFetchesQuery>;
export type SummarizeDataSourceFetchesLazyQueryHookResult = ReturnType<typeof useSummarizeDataSourceFetchesLazyQuery>;
export type SummarizeDataSourceFetchesSuspenseQueryHookResult = ReturnType<typeof useSummarizeDataSourceFetchesSuspenseQuery>;
export type SummarizeDataSourceFetchesQueryResult = Apollo.QueryResult<SummarizeDataSourceFetchesQuery, SummarizeDataSourceFetchesQueryVariables>;
export const ListFailingClassificationValidationsDocument = gql`
    query ListFailingClassificationValidations {
  listFailingClassificationValidations {
    ...ClassificationValidationFailureFields
  }
}
    ${ClassificationValidationFailureFieldsFragmentDoc}`;

/**
 * __useListFailingClassificationValidationsQuery__
 *
 * To run a query within a React component, call `useListFailingClassificationValidationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListFailingClassificationValidationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListFailingClassificationValidationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useListFailingClassificationValidationsQuery(baseOptions?: Apollo.QueryHookOptions<ListFailingClassificationValidationsQuery, ListFailingClassificationValidationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListFailingClassificationValidationsQuery, ListFailingClassificationValidationsQueryVariables>(ListFailingClassificationValidationsDocument, options);
      }
export function useListFailingClassificationValidationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListFailingClassificationValidationsQuery, ListFailingClassificationValidationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListFailingClassificationValidationsQuery, ListFailingClassificationValidationsQueryVariables>(ListFailingClassificationValidationsDocument, options);
        }
export function useListFailingClassificationValidationsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ListFailingClassificationValidationsQuery, ListFailingClassificationValidationsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ListFailingClassificationValidationsQuery, ListFailingClassificationValidationsQueryVariables>(ListFailingClassificationValidationsDocument, options);
        }
export type ListFailingClassificationValidationsQueryHookResult = ReturnType<typeof useListFailingClassificationValidationsQuery>;
export type ListFailingClassificationValidationsLazyQueryHookResult = ReturnType<typeof useListFailingClassificationValidationsLazyQuery>;
export type ListFailingClassificationValidationsSuspenseQueryHookResult = ReturnType<typeof useListFailingClassificationValidationsSuspenseQuery>;
export type ListFailingClassificationValidationsQueryResult = Apollo.QueryResult<ListFailingClassificationValidationsQuery, ListFailingClassificationValidationsQueryVariables>;
export const SummarizeClassificationValidationsDocument = gql`
    query SummarizeClassificationValidations {
  summarizeClassificationValidations {
    ...ClassificationValidationSummaryFields
  }
}
    ${ClassificationValidationSummaryFieldsFragmentDoc}`;

/**
 * __useSummarizeClassificationValidationsQuery__
 *
 * To run a query within a React component, call `useSummarizeClassificationValidationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSummarizeClassificationValidationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSummarizeClassificationValidationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useSummarizeClassificationValidationsQuery(baseOptions?: Apollo.QueryHookOptions<SummarizeClassificationValidationsQuery, SummarizeClassificationValidationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SummarizeClassificationValidationsQuery, SummarizeClassificationValidationsQueryVariables>(SummarizeClassificationValidationsDocument, options);
      }
export function useSummarizeClassificationValidationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SummarizeClassificationValidationsQuery, SummarizeClassificationValidationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SummarizeClassificationValidationsQuery, SummarizeClassificationValidationsQueryVariables>(SummarizeClassificationValidationsDocument, options);
        }
export function useSummarizeClassificationValidationsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<SummarizeClassificationValidationsQuery, SummarizeClassificationValidationsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SummarizeClassificationValidationsQuery, SummarizeClassificationValidationsQueryVariables>(SummarizeClassificationValidationsDocument, options);
        }
export type SummarizeClassificationValidationsQueryHookResult = ReturnType<typeof useSummarizeClassificationValidationsQuery>;
export type SummarizeClassificationValidationsLazyQueryHookResult = ReturnType<typeof useSummarizeClassificationValidationsLazyQuery>;
export type SummarizeClassificationValidationsSuspenseQueryHookResult = ReturnType<typeof useSummarizeClassificationValidationsSuspenseQuery>;
export type SummarizeClassificationValidationsQueryResult = Apollo.QueryResult<SummarizeClassificationValidationsQuery, SummarizeClassificationValidationsQueryVariables>;
export const ReadValidationSummaryDocument = gql`
    query ReadValidationSummary($legalEntityId: String!) {
  readValidationSummary(legalEntityId: $legalEntityId) {
    ...ValidationSummaryFields
  }
}
    ${ValidationSummaryFieldsFragmentDoc}`;

/**
 * __useReadValidationSummaryQuery__
 *
 * To run a query within a React component, call `useReadValidationSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadValidationSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadValidationSummaryQuery({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *   },
 * });
 */
export function useReadValidationSummaryQuery(baseOptions: Apollo.QueryHookOptions<ReadValidationSummaryQuery, ReadValidationSummaryQueryVariables> & ({ variables: ReadValidationSummaryQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadValidationSummaryQuery, ReadValidationSummaryQueryVariables>(ReadValidationSummaryDocument, options);
      }
export function useReadValidationSummaryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadValidationSummaryQuery, ReadValidationSummaryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadValidationSummaryQuery, ReadValidationSummaryQueryVariables>(ReadValidationSummaryDocument, options);
        }
export function useReadValidationSummarySuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ReadValidationSummaryQuery, ReadValidationSummaryQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ReadValidationSummaryQuery, ReadValidationSummaryQueryVariables>(ReadValidationSummaryDocument, options);
        }
export type ReadValidationSummaryQueryHookResult = ReturnType<typeof useReadValidationSummaryQuery>;
export type ReadValidationSummaryLazyQueryHookResult = ReturnType<typeof useReadValidationSummaryLazyQuery>;
export type ReadValidationSummarySuspenseQueryHookResult = ReturnType<typeof useReadValidationSummarySuspenseQuery>;
export type ReadValidationSummaryQueryResult = Apollo.QueryResult<ReadValidationSummaryQuery, ReadValidationSummaryQueryVariables>;
export const SummarizeViewGenerationDocument = gql`
    query SummarizeViewGeneration {
  summarizeViewGeneration {
    ...SummarizeViewGenerationFields
  }
}
    ${SummarizeViewGenerationFieldsFragmentDoc}`;

/**
 * __useSummarizeViewGenerationQuery__
 *
 * To run a query within a React component, call `useSummarizeViewGenerationQuery` and pass it any options that fit your needs.
 * When your component renders, `useSummarizeViewGenerationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSummarizeViewGenerationQuery({
 *   variables: {
 *   },
 * });
 */
export function useSummarizeViewGenerationQuery(baseOptions?: Apollo.QueryHookOptions<SummarizeViewGenerationQuery, SummarizeViewGenerationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SummarizeViewGenerationQuery, SummarizeViewGenerationQueryVariables>(SummarizeViewGenerationDocument, options);
      }
export function useSummarizeViewGenerationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SummarizeViewGenerationQuery, SummarizeViewGenerationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SummarizeViewGenerationQuery, SummarizeViewGenerationQueryVariables>(SummarizeViewGenerationDocument, options);
        }
export function useSummarizeViewGenerationSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<SummarizeViewGenerationQuery, SummarizeViewGenerationQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SummarizeViewGenerationQuery, SummarizeViewGenerationQueryVariables>(SummarizeViewGenerationDocument, options);
        }
export type SummarizeViewGenerationQueryHookResult = ReturnType<typeof useSummarizeViewGenerationQuery>;
export type SummarizeViewGenerationLazyQueryHookResult = ReturnType<typeof useSummarizeViewGenerationLazyQuery>;
export type SummarizeViewGenerationSuspenseQueryHookResult = ReturnType<typeof useSummarizeViewGenerationSuspenseQuery>;
export type SummarizeViewGenerationQueryResult = Apollo.QueryResult<SummarizeViewGenerationQuery, SummarizeViewGenerationQueryVariables>;
export const ListUnsupportedTransactionsDocument = gql`
    query ListUnsupportedTransactions($legalEntityId: String!) {
  listUnsupportedTransactions(legalEntityId: $legalEntityId) {
    ...UnsupportedTransactionsFields
  }
}
    ${UnsupportedTransactionsFieldsFragmentDoc}`;

/**
 * __useListUnsupportedTransactionsQuery__
 *
 * To run a query within a React component, call `useListUnsupportedTransactionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListUnsupportedTransactionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListUnsupportedTransactionsQuery({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *   },
 * });
 */
export function useListUnsupportedTransactionsQuery(baseOptions: Apollo.QueryHookOptions<ListUnsupportedTransactionsQuery, ListUnsupportedTransactionsQueryVariables> & ({ variables: ListUnsupportedTransactionsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListUnsupportedTransactionsQuery, ListUnsupportedTransactionsQueryVariables>(ListUnsupportedTransactionsDocument, options);
      }
export function useListUnsupportedTransactionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListUnsupportedTransactionsQuery, ListUnsupportedTransactionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListUnsupportedTransactionsQuery, ListUnsupportedTransactionsQueryVariables>(ListUnsupportedTransactionsDocument, options);
        }
export function useListUnsupportedTransactionsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ListUnsupportedTransactionsQuery, ListUnsupportedTransactionsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ListUnsupportedTransactionsQuery, ListUnsupportedTransactionsQueryVariables>(ListUnsupportedTransactionsDocument, options);
        }
export type ListUnsupportedTransactionsQueryHookResult = ReturnType<typeof useListUnsupportedTransactionsQuery>;
export type ListUnsupportedTransactionsLazyQueryHookResult = ReturnType<typeof useListUnsupportedTransactionsLazyQuery>;
export type ListUnsupportedTransactionsSuspenseQueryHookResult = ReturnType<typeof useListUnsupportedTransactionsSuspenseQuery>;
export type ListUnsupportedTransactionsQueryResult = Apollo.QueryResult<ListUnsupportedTransactionsQuery, ListUnsupportedTransactionsQueryVariables>;
export const DisableConnectionDocument = gql`
    mutation DisableConnection($id: String!) {
  disableConnection(id: $id)
}
    `;
export type DisableConnectionMutationFn = Apollo.MutationFunction<DisableConnectionMutation, DisableConnectionMutationVariables>;

/**
 * __useDisableConnectionMutation__
 *
 * To run a mutation, you first call `useDisableConnectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDisableConnectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [disableConnectionMutation, { data, loading, error }] = useDisableConnectionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDisableConnectionMutation(baseOptions?: Apollo.MutationHookOptions<DisableConnectionMutation, DisableConnectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DisableConnectionMutation, DisableConnectionMutationVariables>(DisableConnectionDocument, options);
      }
export type DisableConnectionMutationHookResult = ReturnType<typeof useDisableConnectionMutation>;
export type DisableConnectionMutationResult = Apollo.MutationResult<DisableConnectionMutation>;
export type DisableConnectionMutationOptions = Apollo.BaseMutationOptions<DisableConnectionMutation, DisableConnectionMutationVariables>;
export const SyncConnectionDocument = gql`
    mutation SyncConnection($id: String!) {
  syncConnection(id: $id)
}
    `;
export type SyncConnectionMutationFn = Apollo.MutationFunction<SyncConnectionMutation, SyncConnectionMutationVariables>;

/**
 * __useSyncConnectionMutation__
 *
 * To run a mutation, you first call `useSyncConnectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSyncConnectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [syncConnectionMutation, { data, loading, error }] = useSyncConnectionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSyncConnectionMutation(baseOptions?: Apollo.MutationHookOptions<SyncConnectionMutation, SyncConnectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SyncConnectionMutation, SyncConnectionMutationVariables>(SyncConnectionDocument, options);
      }
export type SyncConnectionMutationHookResult = ReturnType<typeof useSyncConnectionMutation>;
export type SyncConnectionMutationResult = Apollo.MutationResult<SyncConnectionMutation>;
export type SyncConnectionMutationOptions = Apollo.BaseMutationOptions<SyncConnectionMutation, SyncConnectionMutationVariables>;
export const DeleteConnectionDocument = gql`
    mutation DeleteConnection($id: String!) {
  deleteConnection(id: $id)
}
    `;
export type DeleteConnectionMutationFn = Apollo.MutationFunction<DeleteConnectionMutation, DeleteConnectionMutationVariables>;

/**
 * __useDeleteConnectionMutation__
 *
 * To run a mutation, you first call `useDeleteConnectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteConnectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteConnectionMutation, { data, loading, error }] = useDeleteConnectionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteConnectionMutation(baseOptions?: Apollo.MutationHookOptions<DeleteConnectionMutation, DeleteConnectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteConnectionMutation, DeleteConnectionMutationVariables>(DeleteConnectionDocument, options);
      }
export type DeleteConnectionMutationHookResult = ReturnType<typeof useDeleteConnectionMutation>;
export type DeleteConnectionMutationResult = Apollo.MutationResult<DeleteConnectionMutation>;
export type DeleteConnectionMutationOptions = Apollo.BaseMutationOptions<DeleteConnectionMutation, DeleteConnectionMutationVariables>;
export const CreateConnectionTemplateDocument = gql`
    mutation CreateConnectionTemplate($connectorId: String!, $institutionId: String!) {
  createConnectionTemplate(
    connectorId: $connectorId
    institutionId: $institutionId
  )
}
    `;
export type CreateConnectionTemplateMutationFn = Apollo.MutationFunction<CreateConnectionTemplateMutation, CreateConnectionTemplateMutationVariables>;

/**
 * __useCreateConnectionTemplateMutation__
 *
 * To run a mutation, you first call `useCreateConnectionTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateConnectionTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createConnectionTemplateMutation, { data, loading, error }] = useCreateConnectionTemplateMutation({
 *   variables: {
 *      connectorId: // value for 'connectorId'
 *      institutionId: // value for 'institutionId'
 *   },
 * });
 */
export function useCreateConnectionTemplateMutation(baseOptions?: Apollo.MutationHookOptions<CreateConnectionTemplateMutation, CreateConnectionTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateConnectionTemplateMutation, CreateConnectionTemplateMutationVariables>(CreateConnectionTemplateDocument, options);
      }
export type CreateConnectionTemplateMutationHookResult = ReturnType<typeof useCreateConnectionTemplateMutation>;
export type CreateConnectionTemplateMutationResult = Apollo.MutationResult<CreateConnectionTemplateMutation>;
export type CreateConnectionTemplateMutationOptions = Apollo.BaseMutationOptions<CreateConnectionTemplateMutation, CreateConnectionTemplateMutationVariables>;
export const ResetConnectionDocument = gql`
    mutation ResetConnection($id: String!) {
  resetConnection(id: $id)
}
    `;
export type ResetConnectionMutationFn = Apollo.MutationFunction<ResetConnectionMutation, ResetConnectionMutationVariables>;

/**
 * __useResetConnectionMutation__
 *
 * To run a mutation, you first call `useResetConnectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetConnectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetConnectionMutation, { data, loading, error }] = useResetConnectionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useResetConnectionMutation(baseOptions?: Apollo.MutationHookOptions<ResetConnectionMutation, ResetConnectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetConnectionMutation, ResetConnectionMutationVariables>(ResetConnectionDocument, options);
      }
export type ResetConnectionMutationHookResult = ReturnType<typeof useResetConnectionMutation>;
export type ResetConnectionMutationResult = Apollo.MutationResult<ResetConnectionMutation>;
export type ResetConnectionMutationOptions = Apollo.BaseMutationOptions<ResetConnectionMutation, ResetConnectionMutationVariables>;
export const EnableConnectionDocument = gql`
    mutation EnableConnection($id: String!) {
  enableConnection(id: $id)
}
    `;
export type EnableConnectionMutationFn = Apollo.MutationFunction<EnableConnectionMutation, EnableConnectionMutationVariables>;

/**
 * __useEnableConnectionMutation__
 *
 * To run a mutation, you first call `useEnableConnectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEnableConnectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [enableConnectionMutation, { data, loading, error }] = useEnableConnectionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEnableConnectionMutation(baseOptions?: Apollo.MutationHookOptions<EnableConnectionMutation, EnableConnectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EnableConnectionMutation, EnableConnectionMutationVariables>(EnableConnectionDocument, options);
      }
export type EnableConnectionMutationHookResult = ReturnType<typeof useEnableConnectionMutation>;
export type EnableConnectionMutationResult = Apollo.MutationResult<EnableConnectionMutation>;
export type EnableConnectionMutationOptions = Apollo.BaseMutationOptions<EnableConnectionMutation, EnableConnectionMutationVariables>;
export const DeleteConnectionTemplateDocument = gql`
    mutation DeleteConnectionTemplate($connectorId: String!, $institutionId: String!) {
  deleteConnectionTemplate(
    connectorId: $connectorId
    institutionId: $institutionId
  )
}
    `;
export type DeleteConnectionTemplateMutationFn = Apollo.MutationFunction<DeleteConnectionTemplateMutation, DeleteConnectionTemplateMutationVariables>;

/**
 * __useDeleteConnectionTemplateMutation__
 *
 * To run a mutation, you first call `useDeleteConnectionTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteConnectionTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteConnectionTemplateMutation, { data, loading, error }] = useDeleteConnectionTemplateMutation({
 *   variables: {
 *      connectorId: // value for 'connectorId'
 *      institutionId: // value for 'institutionId'
 *   },
 * });
 */
export function useDeleteConnectionTemplateMutation(baseOptions?: Apollo.MutationHookOptions<DeleteConnectionTemplateMutation, DeleteConnectionTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteConnectionTemplateMutation, DeleteConnectionTemplateMutationVariables>(DeleteConnectionTemplateDocument, options);
      }
export type DeleteConnectionTemplateMutationHookResult = ReturnType<typeof useDeleteConnectionTemplateMutation>;
export type DeleteConnectionTemplateMutationResult = Apollo.MutationResult<DeleteConnectionTemplateMutation>;
export type DeleteConnectionTemplateMutationOptions = Apollo.BaseMutationOptions<DeleteConnectionTemplateMutation, DeleteConnectionTemplateMutationVariables>;
export const BrokenConnectionsDocument = gql`
    query BrokenConnections {
  brokenConnections {
    connection {
      ...AdminConnectionFields
    }
    dataUpdatedAt
  }
}
    ${AdminConnectionFieldsFragmentDoc}`;

/**
 * __useBrokenConnectionsQuery__
 *
 * To run a query within a React component, call `useBrokenConnectionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBrokenConnectionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBrokenConnectionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useBrokenConnectionsQuery(baseOptions?: Apollo.QueryHookOptions<BrokenConnectionsQuery, BrokenConnectionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BrokenConnectionsQuery, BrokenConnectionsQueryVariables>(BrokenConnectionsDocument, options);
      }
export function useBrokenConnectionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BrokenConnectionsQuery, BrokenConnectionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BrokenConnectionsQuery, BrokenConnectionsQueryVariables>(BrokenConnectionsDocument, options);
        }
export function useBrokenConnectionsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<BrokenConnectionsQuery, BrokenConnectionsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<BrokenConnectionsQuery, BrokenConnectionsQueryVariables>(BrokenConnectionsDocument, options);
        }
export type BrokenConnectionsQueryHookResult = ReturnType<typeof useBrokenConnectionsQuery>;
export type BrokenConnectionsLazyQueryHookResult = ReturnType<typeof useBrokenConnectionsLazyQuery>;
export type BrokenConnectionsSuspenseQueryHookResult = ReturnType<typeof useBrokenConnectionsSuspenseQuery>;
export type BrokenConnectionsQueryResult = Apollo.QueryResult<BrokenConnectionsQuery, BrokenConnectionsQueryVariables>;
export const ListConnectionsDocument = gql`
    query ListConnections($legalEntityId: String!) {
  listConnections(legalEntityId: $legalEntityId) {
    ...AdminConnectionListFields
  }
}
    ${AdminConnectionListFieldsFragmentDoc}`;

/**
 * __useListConnectionsQuery__
 *
 * To run a query within a React component, call `useListConnectionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListConnectionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListConnectionsQuery({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *   },
 * });
 */
export function useListConnectionsQuery(baseOptions: Apollo.QueryHookOptions<ListConnectionsQuery, ListConnectionsQueryVariables> & ({ variables: ListConnectionsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListConnectionsQuery, ListConnectionsQueryVariables>(ListConnectionsDocument, options);
      }
export function useListConnectionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListConnectionsQuery, ListConnectionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListConnectionsQuery, ListConnectionsQueryVariables>(ListConnectionsDocument, options);
        }
export function useListConnectionsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ListConnectionsQuery, ListConnectionsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ListConnectionsQuery, ListConnectionsQueryVariables>(ListConnectionsDocument, options);
        }
export type ListConnectionsQueryHookResult = ReturnType<typeof useListConnectionsQuery>;
export type ListConnectionsLazyQueryHookResult = ReturnType<typeof useListConnectionsLazyQuery>;
export type ListConnectionsSuspenseQueryHookResult = ReturnType<typeof useListConnectionsSuspenseQuery>;
export type ListConnectionsQueryResult = Apollo.QueryResult<ListConnectionsQuery, ListConnectionsQueryVariables>;
export const ListConnectorsDocument = gql`
    query ListConnectors($institutionId: String) {
  listConnectors(institutionId: $institutionId) {
    ...ConnectorFields
  }
}
    ${ConnectorFieldsFragmentDoc}`;

/**
 * __useListConnectorsQuery__
 *
 * To run a query within a React component, call `useListConnectorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListConnectorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListConnectorsQuery({
 *   variables: {
 *      institutionId: // value for 'institutionId'
 *   },
 * });
 */
export function useListConnectorsQuery(baseOptions?: Apollo.QueryHookOptions<ListConnectorsQuery, ListConnectorsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListConnectorsQuery, ListConnectorsQueryVariables>(ListConnectorsDocument, options);
      }
export function useListConnectorsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListConnectorsQuery, ListConnectorsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListConnectorsQuery, ListConnectorsQueryVariables>(ListConnectorsDocument, options);
        }
export function useListConnectorsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ListConnectorsQuery, ListConnectorsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ListConnectorsQuery, ListConnectorsQueryVariables>(ListConnectorsDocument, options);
        }
export type ListConnectorsQueryHookResult = ReturnType<typeof useListConnectorsQuery>;
export type ListConnectorsLazyQueryHookResult = ReturnType<typeof useListConnectorsLazyQuery>;
export type ListConnectorsSuspenseQueryHookResult = ReturnType<typeof useListConnectorsSuspenseQuery>;
export type ListConnectorsQueryResult = Apollo.QueryResult<ListConnectorsQuery, ListConnectorsQueryVariables>;
export const ConnectionEventsDocument = gql`
    query ConnectionEvents($connectionId: String!) {
  connectionEvents(id: $connectionId) {
    ...AdminConnectionEventFields
  }
}
    ${AdminConnectionEventFieldsFragmentDoc}`;

/**
 * __useConnectionEventsQuery__
 *
 * To run a query within a React component, call `useConnectionEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useConnectionEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConnectionEventsQuery({
 *   variables: {
 *      connectionId: // value for 'connectionId'
 *   },
 * });
 */
export function useConnectionEventsQuery(baseOptions: Apollo.QueryHookOptions<ConnectionEventsQuery, ConnectionEventsQueryVariables> & ({ variables: ConnectionEventsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ConnectionEventsQuery, ConnectionEventsQueryVariables>(ConnectionEventsDocument, options);
      }
export function useConnectionEventsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ConnectionEventsQuery, ConnectionEventsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ConnectionEventsQuery, ConnectionEventsQueryVariables>(ConnectionEventsDocument, options);
        }
export function useConnectionEventsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ConnectionEventsQuery, ConnectionEventsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ConnectionEventsQuery, ConnectionEventsQueryVariables>(ConnectionEventsDocument, options);
        }
export type ConnectionEventsQueryHookResult = ReturnType<typeof useConnectionEventsQuery>;
export type ConnectionEventsLazyQueryHookResult = ReturnType<typeof useConnectionEventsLazyQuery>;
export type ConnectionEventsSuspenseQueryHookResult = ReturnType<typeof useConnectionEventsSuspenseQuery>;
export type ConnectionEventsQueryResult = Apollo.QueryResult<ConnectionEventsQuery, ConnectionEventsQueryVariables>;
export const ReadConnectionDocument = gql`
    query ReadConnection($connectionId: String!) {
  readConnection(id: $connectionId) {
    ...AdminConnectionFields
  }
}
    ${AdminConnectionFieldsFragmentDoc}`;

/**
 * __useReadConnectionQuery__
 *
 * To run a query within a React component, call `useReadConnectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadConnectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadConnectionQuery({
 *   variables: {
 *      connectionId: // value for 'connectionId'
 *   },
 * });
 */
export function useReadConnectionQuery(baseOptions: Apollo.QueryHookOptions<ReadConnectionQuery, ReadConnectionQueryVariables> & ({ variables: ReadConnectionQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadConnectionQuery, ReadConnectionQueryVariables>(ReadConnectionDocument, options);
      }
export function useReadConnectionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadConnectionQuery, ReadConnectionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadConnectionQuery, ReadConnectionQueryVariables>(ReadConnectionDocument, options);
        }
export function useReadConnectionSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ReadConnectionQuery, ReadConnectionQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ReadConnectionQuery, ReadConnectionQueryVariables>(ReadConnectionDocument, options);
        }
export type ReadConnectionQueryHookResult = ReturnType<typeof useReadConnectionQuery>;
export type ReadConnectionLazyQueryHookResult = ReturnType<typeof useReadConnectionLazyQuery>;
export type ReadConnectionSuspenseQueryHookResult = ReturnType<typeof useReadConnectionSuspenseQuery>;
export type ReadConnectionQueryResult = Apollo.QueryResult<ReadConnectionQuery, ReadConnectionQueryVariables>;
export const ReadConnectRecordingDocument = gql`
    query ReadConnectRecording($connectionId: String!, $eventId: String!) {
  readConnectRecording(connectionId: $connectionId, eventId: $eventId) {
    responses {
      rawBody
      metadata {
        request_id
        url
        status
        status_code
      }
    }
  }
}
    `;

/**
 * __useReadConnectRecordingQuery__
 *
 * To run a query within a React component, call `useReadConnectRecordingQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadConnectRecordingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadConnectRecordingQuery({
 *   variables: {
 *      connectionId: // value for 'connectionId'
 *      eventId: // value for 'eventId'
 *   },
 * });
 */
export function useReadConnectRecordingQuery(baseOptions: Apollo.QueryHookOptions<ReadConnectRecordingQuery, ReadConnectRecordingQueryVariables> & ({ variables: ReadConnectRecordingQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadConnectRecordingQuery, ReadConnectRecordingQueryVariables>(ReadConnectRecordingDocument, options);
      }
export function useReadConnectRecordingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadConnectRecordingQuery, ReadConnectRecordingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadConnectRecordingQuery, ReadConnectRecordingQueryVariables>(ReadConnectRecordingDocument, options);
        }
export function useReadConnectRecordingSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ReadConnectRecordingQuery, ReadConnectRecordingQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ReadConnectRecordingQuery, ReadConnectRecordingQueryVariables>(ReadConnectRecordingDocument, options);
        }
export type ReadConnectRecordingQueryHookResult = ReturnType<typeof useReadConnectRecordingQuery>;
export type ReadConnectRecordingLazyQueryHookResult = ReturnType<typeof useReadConnectRecordingLazyQuery>;
export type ReadConnectRecordingSuspenseQueryHookResult = ReturnType<typeof useReadConnectRecordingSuspenseQuery>;
export type ReadConnectRecordingQueryResult = Apollo.QueryResult<ReadConnectRecordingQuery, ReadConnectRecordingQueryVariables>;
export const UpdateAspectStatusDocument = gql`
    mutation UpdateAspectStatus($legalEntityId: String!, $aspectId: String!, $status: AspectStatusValue!) {
  updateAspectStatus(
    legalEntityId: $legalEntityId
    aspectId: $aspectId
    status: $status
  )
}
    `;
export type UpdateAspectStatusMutationFn = Apollo.MutationFunction<UpdateAspectStatusMutation, UpdateAspectStatusMutationVariables>;

/**
 * __useUpdateAspectStatusMutation__
 *
 * To run a mutation, you first call `useUpdateAspectStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAspectStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAspectStatusMutation, { data, loading, error }] = useUpdateAspectStatusMutation({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *      aspectId: // value for 'aspectId'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useUpdateAspectStatusMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAspectStatusMutation, UpdateAspectStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAspectStatusMutation, UpdateAspectStatusMutationVariables>(UpdateAspectStatusDocument, options);
      }
export type UpdateAspectStatusMutationHookResult = ReturnType<typeof useUpdateAspectStatusMutation>;
export type UpdateAspectStatusMutationResult = Apollo.MutationResult<UpdateAspectStatusMutation>;
export type UpdateAspectStatusMutationOptions = Apollo.BaseMutationOptions<UpdateAspectStatusMutation, UpdateAspectStatusMutationVariables>;
export const ApproveRequestDocument = gql`
    mutation ApproveRequest($id: String!) {
  approveRequest(id: $id)
}
    `;
export type ApproveRequestMutationFn = Apollo.MutationFunction<ApproveRequestMutation, ApproveRequestMutationVariables>;

/**
 * __useApproveRequestMutation__
 *
 * To run a mutation, you first call `useApproveRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApproveRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approveRequestMutation, { data, loading, error }] = useApproveRequestMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useApproveRequestMutation(baseOptions?: Apollo.MutationHookOptions<ApproveRequestMutation, ApproveRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApproveRequestMutation, ApproveRequestMutationVariables>(ApproveRequestDocument, options);
      }
export type ApproveRequestMutationHookResult = ReturnType<typeof useApproveRequestMutation>;
export type ApproveRequestMutationResult = Apollo.MutationResult<ApproveRequestMutation>;
export type ApproveRequestMutationOptions = Apollo.BaseMutationOptions<ApproveRequestMutation, ApproveRequestMutationVariables>;
export const ListAspectRequestsDocument = gql`
    query ListAspectRequests($legalEntityId: String!) {
  listAspectRequests(legalEntityId: $legalEntityId) {
    ...AspectRequestFields
  }
}
    ${AspectRequestFieldsFragmentDoc}`;

/**
 * __useListAspectRequestsQuery__
 *
 * To run a query within a React component, call `useListAspectRequestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListAspectRequestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListAspectRequestsQuery({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *   },
 * });
 */
export function useListAspectRequestsQuery(baseOptions: Apollo.QueryHookOptions<ListAspectRequestsQuery, ListAspectRequestsQueryVariables> & ({ variables: ListAspectRequestsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListAspectRequestsQuery, ListAspectRequestsQueryVariables>(ListAspectRequestsDocument, options);
      }
export function useListAspectRequestsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListAspectRequestsQuery, ListAspectRequestsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListAspectRequestsQuery, ListAspectRequestsQueryVariables>(ListAspectRequestsDocument, options);
        }
export function useListAspectRequestsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ListAspectRequestsQuery, ListAspectRequestsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ListAspectRequestsQuery, ListAspectRequestsQueryVariables>(ListAspectRequestsDocument, options);
        }
export type ListAspectRequestsQueryHookResult = ReturnType<typeof useListAspectRequestsQuery>;
export type ListAspectRequestsLazyQueryHookResult = ReturnType<typeof useListAspectRequestsLazyQuery>;
export type ListAspectRequestsSuspenseQueryHookResult = ReturnType<typeof useListAspectRequestsSuspenseQuery>;
export type ListAspectRequestsQueryResult = Apollo.QueryResult<ListAspectRequestsQuery, ListAspectRequestsQueryVariables>;
export const ListAspectStatusesDocument = gql`
    query ListAspectStatuses($legalEntityId: String!) {
  listAspectStatuses(legalEntityId: $legalEntityId) {
    ...LegalEntityAspectStatusFields
  }
}
    ${LegalEntityAspectStatusFieldsFragmentDoc}`;

/**
 * __useListAspectStatusesQuery__
 *
 * To run a query within a React component, call `useListAspectStatusesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListAspectStatusesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListAspectStatusesQuery({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *   },
 * });
 */
export function useListAspectStatusesQuery(baseOptions: Apollo.QueryHookOptions<ListAspectStatusesQuery, ListAspectStatusesQueryVariables> & ({ variables: ListAspectStatusesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListAspectStatusesQuery, ListAspectStatusesQueryVariables>(ListAspectStatusesDocument, options);
      }
export function useListAspectStatusesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListAspectStatusesQuery, ListAspectStatusesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListAspectStatusesQuery, ListAspectStatusesQueryVariables>(ListAspectStatusesDocument, options);
        }
export function useListAspectStatusesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ListAspectStatusesQuery, ListAspectStatusesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ListAspectStatusesQuery, ListAspectStatusesQueryVariables>(ListAspectStatusesDocument, options);
        }
export type ListAspectStatusesQueryHookResult = ReturnType<typeof useListAspectStatusesQuery>;
export type ListAspectStatusesLazyQueryHookResult = ReturnType<typeof useListAspectStatusesLazyQuery>;
export type ListAspectStatusesSuspenseQueryHookResult = ReturnType<typeof useListAspectStatusesSuspenseQuery>;
export type ListAspectStatusesQueryResult = Apollo.QueryResult<ListAspectStatusesQuery, ListAspectStatusesQueryVariables>;
export const ReadEmailHtmlDocument = gql`
    query ReadEmailHTML($eventType: EventType!) {
  readEmailHTML(eventType: $eventType)
}
    `;

/**
 * __useReadEmailHtmlQuery__
 *
 * To run a query within a React component, call `useReadEmailHtmlQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadEmailHtmlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadEmailHtmlQuery({
 *   variables: {
 *      eventType: // value for 'eventType'
 *   },
 * });
 */
export function useReadEmailHtmlQuery(baseOptions: Apollo.QueryHookOptions<ReadEmailHtmlQuery, ReadEmailHtmlQueryVariables> & ({ variables: ReadEmailHtmlQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadEmailHtmlQuery, ReadEmailHtmlQueryVariables>(ReadEmailHtmlDocument, options);
      }
export function useReadEmailHtmlLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadEmailHtmlQuery, ReadEmailHtmlQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadEmailHtmlQuery, ReadEmailHtmlQueryVariables>(ReadEmailHtmlDocument, options);
        }
export function useReadEmailHtmlSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ReadEmailHtmlQuery, ReadEmailHtmlQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ReadEmailHtmlQuery, ReadEmailHtmlQueryVariables>(ReadEmailHtmlDocument, options);
        }
export type ReadEmailHtmlQueryHookResult = ReturnType<typeof useReadEmailHtmlQuery>;
export type ReadEmailHtmlLazyQueryHookResult = ReturnType<typeof useReadEmailHtmlLazyQuery>;
export type ReadEmailHtmlSuspenseQueryHookResult = ReturnType<typeof useReadEmailHtmlSuspenseQuery>;
export type ReadEmailHtmlQueryResult = Apollo.QueryResult<ReadEmailHtmlQuery, ReadEmailHtmlQueryVariables>;
export const ReadBillingSubscriptionHistoryDocument = gql`
    query ReadBillingSubscriptionHistory($organizationId: String!) {
  readBillingSubscriptionHistory(organizationId: $organizationId) {
    createdAt
    paymentIntentStatus
    amount {
      amount
      currencyMultiplier
      iso4217CurrencyCode
    }
    interval
    productName
  }
}
    `;

/**
 * __useReadBillingSubscriptionHistoryQuery__
 *
 * To run a query within a React component, call `useReadBillingSubscriptionHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadBillingSubscriptionHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadBillingSubscriptionHistoryQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useReadBillingSubscriptionHistoryQuery(baseOptions: Apollo.QueryHookOptions<ReadBillingSubscriptionHistoryQuery, ReadBillingSubscriptionHistoryQueryVariables> & ({ variables: ReadBillingSubscriptionHistoryQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadBillingSubscriptionHistoryQuery, ReadBillingSubscriptionHistoryQueryVariables>(ReadBillingSubscriptionHistoryDocument, options);
      }
export function useReadBillingSubscriptionHistoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadBillingSubscriptionHistoryQuery, ReadBillingSubscriptionHistoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadBillingSubscriptionHistoryQuery, ReadBillingSubscriptionHistoryQueryVariables>(ReadBillingSubscriptionHistoryDocument, options);
        }
export function useReadBillingSubscriptionHistorySuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ReadBillingSubscriptionHistoryQuery, ReadBillingSubscriptionHistoryQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ReadBillingSubscriptionHistoryQuery, ReadBillingSubscriptionHistoryQueryVariables>(ReadBillingSubscriptionHistoryDocument, options);
        }
export type ReadBillingSubscriptionHistoryQueryHookResult = ReturnType<typeof useReadBillingSubscriptionHistoryQuery>;
export type ReadBillingSubscriptionHistoryLazyQueryHookResult = ReturnType<typeof useReadBillingSubscriptionHistoryLazyQuery>;
export type ReadBillingSubscriptionHistorySuspenseQueryHookResult = ReturnType<typeof useReadBillingSubscriptionHistorySuspenseQuery>;
export type ReadBillingSubscriptionHistoryQueryResult = Apollo.QueryResult<ReadBillingSubscriptionHistoryQuery, ReadBillingSubscriptionHistoryQueryVariables>;
export const ReadOrganizationBillingPlanDocument = gql`
    query ReadOrganizationBillingPlan($organizationId: String!) {
  readOrganizationBillingPlan(organizationId: $organizationId) {
    product {
      ...BillingProductFields
    }
    price {
      ...BillingPriceFields
    }
    legalEntityLimit {
      ...BillingLegalEntityLimitFields
    }
  }
}
    ${BillingProductFieldsFragmentDoc}
${BillingPriceFieldsFragmentDoc}
${BillingLegalEntityLimitFieldsFragmentDoc}`;

/**
 * __useReadOrganizationBillingPlanQuery__
 *
 * To run a query within a React component, call `useReadOrganizationBillingPlanQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadOrganizationBillingPlanQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadOrganizationBillingPlanQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useReadOrganizationBillingPlanQuery(baseOptions: Apollo.QueryHookOptions<ReadOrganizationBillingPlanQuery, ReadOrganizationBillingPlanQueryVariables> & ({ variables: ReadOrganizationBillingPlanQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadOrganizationBillingPlanQuery, ReadOrganizationBillingPlanQueryVariables>(ReadOrganizationBillingPlanDocument, options);
      }
export function useReadOrganizationBillingPlanLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadOrganizationBillingPlanQuery, ReadOrganizationBillingPlanQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadOrganizationBillingPlanQuery, ReadOrganizationBillingPlanQueryVariables>(ReadOrganizationBillingPlanDocument, options);
        }
export function useReadOrganizationBillingPlanSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ReadOrganizationBillingPlanQuery, ReadOrganizationBillingPlanQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ReadOrganizationBillingPlanQuery, ReadOrganizationBillingPlanQueryVariables>(ReadOrganizationBillingPlanDocument, options);
        }
export type ReadOrganizationBillingPlanQueryHookResult = ReturnType<typeof useReadOrganizationBillingPlanQuery>;
export type ReadOrganizationBillingPlanLazyQueryHookResult = ReturnType<typeof useReadOrganizationBillingPlanLazyQuery>;
export type ReadOrganizationBillingPlanSuspenseQueryHookResult = ReturnType<typeof useReadOrganizationBillingPlanSuspenseQuery>;
export type ReadOrganizationBillingPlanQueryResult = Apollo.QueryResult<ReadOrganizationBillingPlanQuery, ReadOrganizationBillingPlanQueryVariables>;
export const ResetWorkItemDocument = gql`
    mutation ResetWorkItem($workItemId: String!) {
  resetWorkItem(workItemId: $workItemId)
}
    `;
export type ResetWorkItemMutationFn = Apollo.MutationFunction<ResetWorkItemMutation, ResetWorkItemMutationVariables>;

/**
 * __useResetWorkItemMutation__
 *
 * To run a mutation, you first call `useResetWorkItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetWorkItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetWorkItemMutation, { data, loading, error }] = useResetWorkItemMutation({
 *   variables: {
 *      workItemId: // value for 'workItemId'
 *   },
 * });
 */
export function useResetWorkItemMutation(baseOptions?: Apollo.MutationHookOptions<ResetWorkItemMutation, ResetWorkItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetWorkItemMutation, ResetWorkItemMutationVariables>(ResetWorkItemDocument, options);
      }
export type ResetWorkItemMutationHookResult = ReturnType<typeof useResetWorkItemMutation>;
export type ResetWorkItemMutationResult = Apollo.MutationResult<ResetWorkItemMutation>;
export type ResetWorkItemMutationOptions = Apollo.BaseMutationOptions<ResetWorkItemMutation, ResetWorkItemMutationVariables>;
export const TriggerTransactionAuditCheckDocument = gql`
    mutation TriggerTransactionAuditCheck($transactionFactId: String!) {
  triggerTransactionAuditCheck(transactionFactId: $transactionFactId)
}
    `;
export type TriggerTransactionAuditCheckMutationFn = Apollo.MutationFunction<TriggerTransactionAuditCheckMutation, TriggerTransactionAuditCheckMutationVariables>;

/**
 * __useTriggerTransactionAuditCheckMutation__
 *
 * To run a mutation, you first call `useTriggerTransactionAuditCheckMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTriggerTransactionAuditCheckMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [triggerTransactionAuditCheckMutation, { data, loading, error }] = useTriggerTransactionAuditCheckMutation({
 *   variables: {
 *      transactionFactId: // value for 'transactionFactId'
 *   },
 * });
 */
export function useTriggerTransactionAuditCheckMutation(baseOptions?: Apollo.MutationHookOptions<TriggerTransactionAuditCheckMutation, TriggerTransactionAuditCheckMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TriggerTransactionAuditCheckMutation, TriggerTransactionAuditCheckMutationVariables>(TriggerTransactionAuditCheckDocument, options);
      }
export type TriggerTransactionAuditCheckMutationHookResult = ReturnType<typeof useTriggerTransactionAuditCheckMutation>;
export type TriggerTransactionAuditCheckMutationResult = Apollo.MutationResult<TriggerTransactionAuditCheckMutation>;
export type TriggerTransactionAuditCheckMutationOptions = Apollo.BaseMutationOptions<TriggerTransactionAuditCheckMutation, TriggerTransactionAuditCheckMutationVariables>;
export const ReadWorkItemDocument = gql`
    query ReadWorkItem($workItemId: String!) {
  readWorkItem(workItemId: $workItemId) {
    ...AdminWorkItemFields
  }
}
    ${AdminWorkItemFieldsFragmentDoc}`;

/**
 * __useReadWorkItemQuery__
 *
 * To run a query within a React component, call `useReadWorkItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadWorkItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadWorkItemQuery({
 *   variables: {
 *      workItemId: // value for 'workItemId'
 *   },
 * });
 */
export function useReadWorkItemQuery(baseOptions: Apollo.QueryHookOptions<ReadWorkItemQuery, ReadWorkItemQueryVariables> & ({ variables: ReadWorkItemQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadWorkItemQuery, ReadWorkItemQueryVariables>(ReadWorkItemDocument, options);
      }
export function useReadWorkItemLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadWorkItemQuery, ReadWorkItemQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadWorkItemQuery, ReadWorkItemQueryVariables>(ReadWorkItemDocument, options);
        }
export function useReadWorkItemSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ReadWorkItemQuery, ReadWorkItemQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ReadWorkItemQuery, ReadWorkItemQueryVariables>(ReadWorkItemDocument, options);
        }
export type ReadWorkItemQueryHookResult = ReturnType<typeof useReadWorkItemQuery>;
export type ReadWorkItemLazyQueryHookResult = ReturnType<typeof useReadWorkItemLazyQuery>;
export type ReadWorkItemSuspenseQueryHookResult = ReturnType<typeof useReadWorkItemSuspenseQuery>;
export type ReadWorkItemQueryResult = Apollo.QueryResult<ReadWorkItemQuery, ReadWorkItemQueryVariables>;
export const GetEmbeddingDocument = gql`
    query GetEmbedding($transactionFactId: String!, $partyPrefix: String) {
  getEmbedding(transactionFactId: $transactionFactId, partyPrefix: $partyPrefix) {
    embeddingValue
    deployedIndexId
  }
}
    `;

/**
 * __useGetEmbeddingQuery__
 *
 * To run a query within a React component, call `useGetEmbeddingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmbeddingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmbeddingQuery({
 *   variables: {
 *      transactionFactId: // value for 'transactionFactId'
 *      partyPrefix: // value for 'partyPrefix'
 *   },
 * });
 */
export function useGetEmbeddingQuery(baseOptions: Apollo.QueryHookOptions<GetEmbeddingQuery, GetEmbeddingQueryVariables> & ({ variables: GetEmbeddingQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEmbeddingQuery, GetEmbeddingQueryVariables>(GetEmbeddingDocument, options);
      }
export function useGetEmbeddingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEmbeddingQuery, GetEmbeddingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEmbeddingQuery, GetEmbeddingQueryVariables>(GetEmbeddingDocument, options);
        }
export function useGetEmbeddingSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetEmbeddingQuery, GetEmbeddingQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetEmbeddingQuery, GetEmbeddingQueryVariables>(GetEmbeddingDocument, options);
        }
export type GetEmbeddingQueryHookResult = ReturnType<typeof useGetEmbeddingQuery>;
export type GetEmbeddingLazyQueryHookResult = ReturnType<typeof useGetEmbeddingLazyQuery>;
export type GetEmbeddingSuspenseQueryHookResult = ReturnType<typeof useGetEmbeddingSuspenseQuery>;
export type GetEmbeddingQueryResult = Apollo.QueryResult<GetEmbeddingQuery, GetEmbeddingQueryVariables>;
export const HighlightWorkItemDocument = gql`
    query HighlightWorkItem($similarityType: SimilarityType!, $description: String, $name: String) {
  highlightWorkItem(
    similarityType: $similarityType
    description: $description
    name: $name
  ) {
    ...HighlightedTokensFields
  }
}
    ${HighlightedTokensFieldsFragmentDoc}`;

/**
 * __useHighlightWorkItemQuery__
 *
 * To run a query within a React component, call `useHighlightWorkItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useHighlightWorkItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHighlightWorkItemQuery({
 *   variables: {
 *      similarityType: // value for 'similarityType'
 *      description: // value for 'description'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useHighlightWorkItemQuery(baseOptions: Apollo.QueryHookOptions<HighlightWorkItemQuery, HighlightWorkItemQueryVariables> & ({ variables: HighlightWorkItemQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HighlightWorkItemQuery, HighlightWorkItemQueryVariables>(HighlightWorkItemDocument, options);
      }
export function useHighlightWorkItemLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HighlightWorkItemQuery, HighlightWorkItemQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HighlightWorkItemQuery, HighlightWorkItemQueryVariables>(HighlightWorkItemDocument, options);
        }
export function useHighlightWorkItemSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<HighlightWorkItemQuery, HighlightWorkItemQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<HighlightWorkItemQuery, HighlightWorkItemQueryVariables>(HighlightWorkItemDocument, options);
        }
export type HighlightWorkItemQueryHookResult = ReturnType<typeof useHighlightWorkItemQuery>;
export type HighlightWorkItemLazyQueryHookResult = ReturnType<typeof useHighlightWorkItemLazyQuery>;
export type HighlightWorkItemSuspenseQueryHookResult = ReturnType<typeof useHighlightWorkItemSuspenseQuery>;
export type HighlightWorkItemQueryResult = Apollo.QueryResult<HighlightWorkItemQuery, HighlightWorkItemQueryVariables>;
export const GetNeighborsDocument = gql`
    query GetNeighbors($deployedIndexId: String!, $floatVal: [Float!]!, $numNeighbors: Int, $restricts: [Namespace!], $perCrowdingAttributeNumNeighbors: Int, $approxNumNeighbors: Int, $leafNodesToSearchPercentOverride: Int) {
  getNeighbors(
    deployedIndexId: $deployedIndexId
    floatVal: $floatVal
    numNeighbors: $numNeighbors
    restricts: $restricts
    perCrowdingAttributeNumNeighbors: $perCrowdingAttributeNumNeighbors
    approxNumNeighbors: $approxNumNeighbors
    leafNodesToSearchPercentOverride: $leafNodesToSearchPercentOverride
  ) {
    neighbor {
      id
      distance
    }
  }
}
    `;

/**
 * __useGetNeighborsQuery__
 *
 * To run a query within a React component, call `useGetNeighborsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNeighborsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNeighborsQuery({
 *   variables: {
 *      deployedIndexId: // value for 'deployedIndexId'
 *      floatVal: // value for 'floatVal'
 *      numNeighbors: // value for 'numNeighbors'
 *      restricts: // value for 'restricts'
 *      perCrowdingAttributeNumNeighbors: // value for 'perCrowdingAttributeNumNeighbors'
 *      approxNumNeighbors: // value for 'approxNumNeighbors'
 *      leafNodesToSearchPercentOverride: // value for 'leafNodesToSearchPercentOverride'
 *   },
 * });
 */
export function useGetNeighborsQuery(baseOptions: Apollo.QueryHookOptions<GetNeighborsQuery, GetNeighborsQueryVariables> & ({ variables: GetNeighborsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetNeighborsQuery, GetNeighborsQueryVariables>(GetNeighborsDocument, options);
      }
export function useGetNeighborsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNeighborsQuery, GetNeighborsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetNeighborsQuery, GetNeighborsQueryVariables>(GetNeighborsDocument, options);
        }
export function useGetNeighborsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetNeighborsQuery, GetNeighborsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetNeighborsQuery, GetNeighborsQueryVariables>(GetNeighborsDocument, options);
        }
export type GetNeighborsQueryHookResult = ReturnType<typeof useGetNeighborsQuery>;
export type GetNeighborsLazyQueryHookResult = ReturnType<typeof useGetNeighborsLazyQuery>;
export type GetNeighborsSuspenseQueryHookResult = ReturnType<typeof useGetNeighborsSuspenseQuery>;
export type GetNeighborsQueryResult = Apollo.QueryResult<GetNeighborsQuery, GetNeighborsQueryVariables>;
export const ReadWorkItemValidationDocument = gql`
    query ReadWorkItemValidation($legalEntityId: String!) {
  readWorkItemValidation(legalEntityId: $legalEntityId) {
    status
    workItemCount
  }
}
    `;

/**
 * __useReadWorkItemValidationQuery__
 *
 * To run a query within a React component, call `useReadWorkItemValidationQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadWorkItemValidationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadWorkItemValidationQuery({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *   },
 * });
 */
export function useReadWorkItemValidationQuery(baseOptions: Apollo.QueryHookOptions<ReadWorkItemValidationQuery, ReadWorkItemValidationQueryVariables> & ({ variables: ReadWorkItemValidationQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadWorkItemValidationQuery, ReadWorkItemValidationQueryVariables>(ReadWorkItemValidationDocument, options);
      }
export function useReadWorkItemValidationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadWorkItemValidationQuery, ReadWorkItemValidationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadWorkItemValidationQuery, ReadWorkItemValidationQueryVariables>(ReadWorkItemValidationDocument, options);
        }
export function useReadWorkItemValidationSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ReadWorkItemValidationQuery, ReadWorkItemValidationQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ReadWorkItemValidationQuery, ReadWorkItemValidationQueryVariables>(ReadWorkItemValidationDocument, options);
        }
export type ReadWorkItemValidationQueryHookResult = ReturnType<typeof useReadWorkItemValidationQuery>;
export type ReadWorkItemValidationLazyQueryHookResult = ReturnType<typeof useReadWorkItemValidationLazyQuery>;
export type ReadWorkItemValidationSuspenseQueryHookResult = ReturnType<typeof useReadWorkItemValidationSuspenseQuery>;
export type ReadWorkItemValidationQueryResult = Apollo.QueryResult<ReadWorkItemValidationQuery, ReadWorkItemValidationQueryVariables>;
export const DeleteReportPackageDocument = gql`
    mutation DeleteReportPackage($packageId: String!, $legalEntityId: String!) {
  deleteReportPackage(packageId: $packageId, legalEntityId: $legalEntityId)
}
    `;
export type DeleteReportPackageMutationFn = Apollo.MutationFunction<DeleteReportPackageMutation, DeleteReportPackageMutationVariables>;

/**
 * __useDeleteReportPackageMutation__
 *
 * To run a mutation, you first call `useDeleteReportPackageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteReportPackageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteReportPackageMutation, { data, loading, error }] = useDeleteReportPackageMutation({
 *   variables: {
 *      packageId: // value for 'packageId'
 *      legalEntityId: // value for 'legalEntityId'
 *   },
 * });
 */
export function useDeleteReportPackageMutation(baseOptions?: Apollo.MutationHookOptions<DeleteReportPackageMutation, DeleteReportPackageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteReportPackageMutation, DeleteReportPackageMutationVariables>(DeleteReportPackageDocument, options);
      }
export type DeleteReportPackageMutationHookResult = ReturnType<typeof useDeleteReportPackageMutation>;
export type DeleteReportPackageMutationResult = Apollo.MutationResult<DeleteReportPackageMutation>;
export type DeleteReportPackageMutationOptions = Apollo.BaseMutationOptions<DeleteReportPackageMutation, DeleteReportPackageMutationVariables>;
export const CreateReportPackageDocument = gql`
    mutation CreateReportPackage($viewKey: ViewKey!, $title: String!, $periodStartAt: DateInput!, $periodEndAt: DateInput!, $interval: Interval!, $attachments: NewReportAttachments!) {
  createReportPackage(
    viewKey: $viewKey
    title: $title
    periodStartAt: $periodStartAt
    periodEndAt: $periodEndAt
    interval: $interval
    attachments: $attachments
  ) {
    ...ReportPackageMetadataFields
  }
}
    ${ReportPackageMetadataFieldsFragmentDoc}`;
export type CreateReportPackageMutationFn = Apollo.MutationFunction<CreateReportPackageMutation, CreateReportPackageMutationVariables>;

/**
 * __useCreateReportPackageMutation__
 *
 * To run a mutation, you first call `useCreateReportPackageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateReportPackageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createReportPackageMutation, { data, loading, error }] = useCreateReportPackageMutation({
 *   variables: {
 *      viewKey: // value for 'viewKey'
 *      title: // value for 'title'
 *      periodStartAt: // value for 'periodStartAt'
 *      periodEndAt: // value for 'periodEndAt'
 *      interval: // value for 'interval'
 *      attachments: // value for 'attachments'
 *   },
 * });
 */
export function useCreateReportPackageMutation(baseOptions?: Apollo.MutationHookOptions<CreateReportPackageMutation, CreateReportPackageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateReportPackageMutation, CreateReportPackageMutationVariables>(CreateReportPackageDocument, options);
      }
export type CreateReportPackageMutationHookResult = ReturnType<typeof useCreateReportPackageMutation>;
export type CreateReportPackageMutationResult = Apollo.MutationResult<CreateReportPackageMutation>;
export type CreateReportPackageMutationOptions = Apollo.BaseMutationOptions<CreateReportPackageMutation, CreateReportPackageMutationVariables>;
export const ReadReportValidationArchiveDocument = gql`
    query ReadReportValidationArchive($legalEntityId: String!, $reportId: String!, $versionId: String!) {
  readReportValidationArchive(
    legalEntityId: $legalEntityId
    reportId: $reportId
    versionId: $versionId
  ) {
    quickbooks
    digits
    avs
  }
}
    `;

/**
 * __useReadReportValidationArchiveQuery__
 *
 * To run a query within a React component, call `useReadReportValidationArchiveQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadReportValidationArchiveQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadReportValidationArchiveQuery({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *      reportId: // value for 'reportId'
 *      versionId: // value for 'versionId'
 *   },
 * });
 */
export function useReadReportValidationArchiveQuery(baseOptions: Apollo.QueryHookOptions<ReadReportValidationArchiveQuery, ReadReportValidationArchiveQueryVariables> & ({ variables: ReadReportValidationArchiveQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadReportValidationArchiveQuery, ReadReportValidationArchiveQueryVariables>(ReadReportValidationArchiveDocument, options);
      }
export function useReadReportValidationArchiveLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadReportValidationArchiveQuery, ReadReportValidationArchiveQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadReportValidationArchiveQuery, ReadReportValidationArchiveQueryVariables>(ReadReportValidationArchiveDocument, options);
        }
export function useReadReportValidationArchiveSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ReadReportValidationArchiveQuery, ReadReportValidationArchiveQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ReadReportValidationArchiveQuery, ReadReportValidationArchiveQueryVariables>(ReadReportValidationArchiveDocument, options);
        }
export type ReadReportValidationArchiveQueryHookResult = ReturnType<typeof useReadReportValidationArchiveQuery>;
export type ReadReportValidationArchiveLazyQueryHookResult = ReturnType<typeof useReadReportValidationArchiveLazyQuery>;
export type ReadReportValidationArchiveSuspenseQueryHookResult = ReturnType<typeof useReadReportValidationArchiveSuspenseQuery>;
export type ReadReportValidationArchiveQueryResult = Apollo.QueryResult<ReadReportValidationArchiveQuery, ReadReportValidationArchiveQueryVariables>;
export const ListReportValidationHistoriesDocument = gql`
    query ListReportValidationHistories($legalEntityIds: [String!]!) {
  listReportValidationHistories(legalEntityIds: $legalEntityIds) {
    legalEntityId
    periodHistories {
      ...ReportKindValidationPeriodHistoryFields
    }
  }
}
    ${ReportKindValidationPeriodHistoryFieldsFragmentDoc}`;

/**
 * __useListReportValidationHistoriesQuery__
 *
 * To run a query within a React component, call `useListReportValidationHistoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListReportValidationHistoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListReportValidationHistoriesQuery({
 *   variables: {
 *      legalEntityIds: // value for 'legalEntityIds'
 *   },
 * });
 */
export function useListReportValidationHistoriesQuery(baseOptions: Apollo.QueryHookOptions<ListReportValidationHistoriesQuery, ListReportValidationHistoriesQueryVariables> & ({ variables: ListReportValidationHistoriesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListReportValidationHistoriesQuery, ListReportValidationHistoriesQueryVariables>(ListReportValidationHistoriesDocument, options);
      }
export function useListReportValidationHistoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListReportValidationHistoriesQuery, ListReportValidationHistoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListReportValidationHistoriesQuery, ListReportValidationHistoriesQueryVariables>(ListReportValidationHistoriesDocument, options);
        }
export function useListReportValidationHistoriesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ListReportValidationHistoriesQuery, ListReportValidationHistoriesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ListReportValidationHistoriesQuery, ListReportValidationHistoriesQueryVariables>(ListReportValidationHistoriesDocument, options);
        }
export type ListReportValidationHistoriesQueryHookResult = ReturnType<typeof useListReportValidationHistoriesQuery>;
export type ListReportValidationHistoriesLazyQueryHookResult = ReturnType<typeof useListReportValidationHistoriesLazyQuery>;
export type ListReportValidationHistoriesSuspenseQueryHookResult = ReturnType<typeof useListReportValidationHistoriesSuspenseQuery>;
export type ListReportValidationHistoriesQueryResult = Apollo.QueryResult<ListReportValidationHistoriesQuery, ListReportValidationHistoriesQueryVariables>;
export const ListReportPackagesDocument = gql`
    query ListReportPackages($legalEntityId: String!, $interval: Interval!, $intervalCount: Int, $year: Int!, $index: Int!, $paginationLimit: Int!, $paginationOffset: Int!) {
  listReportPackages(
    legalEntityId: $legalEntityId
    origin: {interval: $interval, year: $year, index: $index, intervalCount: $intervalCount}
    pagination: {limit: $paginationLimit, offset: $paginationOffset}
  ) {
    ...AdminReportPackageMetadataFields
  }
}
    ${AdminReportPackageMetadataFieldsFragmentDoc}`;

/**
 * __useListReportPackagesQuery__
 *
 * To run a query within a React component, call `useListReportPackagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListReportPackagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListReportPackagesQuery({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *      interval: // value for 'interval'
 *      intervalCount: // value for 'intervalCount'
 *      year: // value for 'year'
 *      index: // value for 'index'
 *      paginationLimit: // value for 'paginationLimit'
 *      paginationOffset: // value for 'paginationOffset'
 *   },
 * });
 */
export function useListReportPackagesQuery(baseOptions: Apollo.QueryHookOptions<ListReportPackagesQuery, ListReportPackagesQueryVariables> & ({ variables: ListReportPackagesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListReportPackagesQuery, ListReportPackagesQueryVariables>(ListReportPackagesDocument, options);
      }
export function useListReportPackagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListReportPackagesQuery, ListReportPackagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListReportPackagesQuery, ListReportPackagesQueryVariables>(ListReportPackagesDocument, options);
        }
export function useListReportPackagesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ListReportPackagesQuery, ListReportPackagesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ListReportPackagesQuery, ListReportPackagesQueryVariables>(ListReportPackagesDocument, options);
        }
export type ListReportPackagesQueryHookResult = ReturnType<typeof useListReportPackagesQuery>;
export type ListReportPackagesLazyQueryHookResult = ReturnType<typeof useListReportPackagesLazyQuery>;
export type ListReportPackagesSuspenseQueryHookResult = ReturnType<typeof useListReportPackagesSuspenseQuery>;
export type ListReportPackagesQueryResult = Apollo.QueryResult<ListReportPackagesQuery, ListReportPackagesQueryVariables>;
export const ReadValidationHistoryDocument = gql`
    query ReadValidationHistory($legalEntityId: String!) {
  readValidationHistory(legalEntityId: $legalEntityId) {
    ...ValidationHistoryFields
  }
}
    ${ValidationHistoryFieldsFragmentDoc}`;

/**
 * __useReadValidationHistoryQuery__
 *
 * To run a query within a React component, call `useReadValidationHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadValidationHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadValidationHistoryQuery({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *   },
 * });
 */
export function useReadValidationHistoryQuery(baseOptions: Apollo.QueryHookOptions<ReadValidationHistoryQuery, ReadValidationHistoryQueryVariables> & ({ variables: ReadValidationHistoryQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadValidationHistoryQuery, ReadValidationHistoryQueryVariables>(ReadValidationHistoryDocument, options);
      }
export function useReadValidationHistoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadValidationHistoryQuery, ReadValidationHistoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadValidationHistoryQuery, ReadValidationHistoryQueryVariables>(ReadValidationHistoryDocument, options);
        }
export function useReadValidationHistorySuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ReadValidationHistoryQuery, ReadValidationHistoryQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ReadValidationHistoryQuery, ReadValidationHistoryQueryVariables>(ReadValidationHistoryDocument, options);
        }
export type ReadValidationHistoryQueryHookResult = ReturnType<typeof useReadValidationHistoryQuery>;
export type ReadValidationHistoryLazyQueryHookResult = ReturnType<typeof useReadValidationHistoryLazyQuery>;
export type ReadValidationHistorySuspenseQueryHookResult = ReturnType<typeof useReadValidationHistorySuspenseQuery>;
export type ReadValidationHistoryQueryResult = Apollo.QueryResult<ReadValidationHistoryQuery, ReadValidationHistoryQueryVariables>;
export const ReadDataValidationDocument = gql`
    query ReadDataValidation($legalEntityId: String!) {
  readDataHealth(legalEntityId: $legalEntityId) {
    ...DataHealthFields
  }
}
    ${DataHealthFieldsFragmentDoc}`;

/**
 * __useReadDataValidationQuery__
 *
 * To run a query within a React component, call `useReadDataValidationQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadDataValidationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadDataValidationQuery({
 *   variables: {
 *      legalEntityId: // value for 'legalEntityId'
 *   },
 * });
 */
export function useReadDataValidationQuery(baseOptions: Apollo.QueryHookOptions<ReadDataValidationQuery, ReadDataValidationQueryVariables> & ({ variables: ReadDataValidationQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadDataValidationQuery, ReadDataValidationQueryVariables>(ReadDataValidationDocument, options);
      }
export function useReadDataValidationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadDataValidationQuery, ReadDataValidationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadDataValidationQuery, ReadDataValidationQueryVariables>(ReadDataValidationDocument, options);
        }
export function useReadDataValidationSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ReadDataValidationQuery, ReadDataValidationQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ReadDataValidationQuery, ReadDataValidationQueryVariables>(ReadDataValidationDocument, options);
        }
export type ReadDataValidationQueryHookResult = ReturnType<typeof useReadDataValidationQuery>;
export type ReadDataValidationLazyQueryHookResult = ReturnType<typeof useReadDataValidationLazyQuery>;
export type ReadDataValidationSuspenseQueryHookResult = ReturnType<typeof useReadDataValidationSuspenseQuery>;
export type ReadDataValidationQueryResult = Apollo.QueryResult<ReadDataValidationQuery, ReadDataValidationQueryVariables>;
export const UpdateEmployeeStatusDocument = gql`
    mutation UpdateEmployeeStatus($organizationId: String!, $employeeId: String!, $status: EmployeeStatus!) {
  updateEmployeeStatus(
    organizationId: $organizationId
    employeeId: $employeeId
    status: $status
  ) {
    ...AdminEmployeeFields
  }
}
    ${AdminEmployeeFieldsFragmentDoc}`;
export type UpdateEmployeeStatusMutationFn = Apollo.MutationFunction<UpdateEmployeeStatusMutation, UpdateEmployeeStatusMutationVariables>;

/**
 * __useUpdateEmployeeStatusMutation__
 *
 * To run a mutation, you first call `useUpdateEmployeeStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEmployeeStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEmployeeStatusMutation, { data, loading, error }] = useUpdateEmployeeStatusMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      employeeId: // value for 'employeeId'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useUpdateEmployeeStatusMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEmployeeStatusMutation, UpdateEmployeeStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateEmployeeStatusMutation, UpdateEmployeeStatusMutationVariables>(UpdateEmployeeStatusDocument, options);
      }
export type UpdateEmployeeStatusMutationHookResult = ReturnType<typeof useUpdateEmployeeStatusMutation>;
export type UpdateEmployeeStatusMutationResult = Apollo.MutationResult<UpdateEmployeeStatusMutation>;
export type UpdateEmployeeStatusMutationOptions = Apollo.BaseMutationOptions<UpdateEmployeeStatusMutation, UpdateEmployeeStatusMutationVariables>;
export const UpdateEmployeeHiddenDocument = gql`
    mutation UpdateEmployeeHidden($organizationId: String!, $employeeId: String!, $isHidden: Boolean!) {
  updateEmployeeHidden(
    organizationId: $organizationId
    employeeId: $employeeId
    isHidden: $isHidden
  )
}
    `;
export type UpdateEmployeeHiddenMutationFn = Apollo.MutationFunction<UpdateEmployeeHiddenMutation, UpdateEmployeeHiddenMutationVariables>;

/**
 * __useUpdateEmployeeHiddenMutation__
 *
 * To run a mutation, you first call `useUpdateEmployeeHiddenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEmployeeHiddenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEmployeeHiddenMutation, { data, loading, error }] = useUpdateEmployeeHiddenMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      employeeId: // value for 'employeeId'
 *      isHidden: // value for 'isHidden'
 *   },
 * });
 */
export function useUpdateEmployeeHiddenMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEmployeeHiddenMutation, UpdateEmployeeHiddenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateEmployeeHiddenMutation, UpdateEmployeeHiddenMutationVariables>(UpdateEmployeeHiddenDocument, options);
      }
export type UpdateEmployeeHiddenMutationHookResult = ReturnType<typeof useUpdateEmployeeHiddenMutation>;
export type UpdateEmployeeHiddenMutationResult = Apollo.MutationResult<UpdateEmployeeHiddenMutation>;
export type UpdateEmployeeHiddenMutationOptions = Apollo.BaseMutationOptions<UpdateEmployeeHiddenMutation, UpdateEmployeeHiddenMutationVariables>;
export const ListEmployeesDocument = gql`
    query ListEmployees($filter: EmployeeFilter!, $pagination: Pagination!) {
  listEmployees(filter: $filter, pagination: $pagination) {
    ...AdminEmployeeFields
  }
}
    ${AdminEmployeeFieldsFragmentDoc}`;

/**
 * __useListEmployeesQuery__
 *
 * To run a query within a React component, call `useListEmployeesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListEmployeesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListEmployeesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useListEmployeesQuery(baseOptions: Apollo.QueryHookOptions<ListEmployeesQuery, ListEmployeesQueryVariables> & ({ variables: ListEmployeesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListEmployeesQuery, ListEmployeesQueryVariables>(ListEmployeesDocument, options);
      }
export function useListEmployeesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListEmployeesQuery, ListEmployeesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListEmployeesQuery, ListEmployeesQueryVariables>(ListEmployeesDocument, options);
        }
export function useListEmployeesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ListEmployeesQuery, ListEmployeesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ListEmployeesQuery, ListEmployeesQueryVariables>(ListEmployeesDocument, options);
        }
export type ListEmployeesQueryHookResult = ReturnType<typeof useListEmployeesQuery>;
export type ListEmployeesLazyQueryHookResult = ReturnType<typeof useListEmployeesLazyQuery>;
export type ListEmployeesSuspenseQueryHookResult = ReturnType<typeof useListEmployeesSuspenseQuery>;
export type ListEmployeesQueryResult = Apollo.QueryResult<ListEmployeesQuery, ListEmployeesQueryVariables>;
export const ResendInvitationDocument = gql`
    mutation ResendInvitation($token: String!) {
  resendInvitation(token: $token)
}
    `;
export type ResendInvitationMutationFn = Apollo.MutationFunction<ResendInvitationMutation, ResendInvitationMutationVariables>;

/**
 * __useResendInvitationMutation__
 *
 * To run a mutation, you first call `useResendInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendInvitationMutation, { data, loading, error }] = useResendInvitationMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useResendInvitationMutation(baseOptions?: Apollo.MutationHookOptions<ResendInvitationMutation, ResendInvitationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResendInvitationMutation, ResendInvitationMutationVariables>(ResendInvitationDocument, options);
      }
export type ResendInvitationMutationHookResult = ReturnType<typeof useResendInvitationMutation>;
export type ResendInvitationMutationResult = Apollo.MutationResult<ResendInvitationMutation>;
export type ResendInvitationMutationOptions = Apollo.BaseMutationOptions<ResendInvitationMutation, ResendInvitationMutationVariables>;
export const SendManagedOperatorInvitationDocument = gql`
    mutation SendManagedOperatorInvitation($organizationId: String!, $affiliateUserId: String!, $affiliateOrganizationId: String!, $recipient: ContactInput!, $legalEntityId: String!, $message: String, $redirectPath: String) {
  sendManagedOperatorInvitation(
    organizationId: $organizationId
    affiliateUserId: $affiliateUserId
    affiliateOrganizationId: $affiliateOrganizationId
    recipient: $recipient
    legalEntityId: $legalEntityId
    message: $message
    redirectPath: $redirectPath
  )
}
    `;
export type SendManagedOperatorInvitationMutationFn = Apollo.MutationFunction<SendManagedOperatorInvitationMutation, SendManagedOperatorInvitationMutationVariables>;

/**
 * __useSendManagedOperatorInvitationMutation__
 *
 * To run a mutation, you first call `useSendManagedOperatorInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendManagedOperatorInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendManagedOperatorInvitationMutation, { data, loading, error }] = useSendManagedOperatorInvitationMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      affiliateUserId: // value for 'affiliateUserId'
 *      affiliateOrganizationId: // value for 'affiliateOrganizationId'
 *      recipient: // value for 'recipient'
 *      legalEntityId: // value for 'legalEntityId'
 *      message: // value for 'message'
 *      redirectPath: // value for 'redirectPath'
 *   },
 * });
 */
export function useSendManagedOperatorInvitationMutation(baseOptions?: Apollo.MutationHookOptions<SendManagedOperatorInvitationMutation, SendManagedOperatorInvitationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendManagedOperatorInvitationMutation, SendManagedOperatorInvitationMutationVariables>(SendManagedOperatorInvitationDocument, options);
      }
export type SendManagedOperatorInvitationMutationHookResult = ReturnType<typeof useSendManagedOperatorInvitationMutation>;
export type SendManagedOperatorInvitationMutationResult = Apollo.MutationResult<SendManagedOperatorInvitationMutation>;
export type SendManagedOperatorInvitationMutationOptions = Apollo.BaseMutationOptions<SendManagedOperatorInvitationMutation, SendManagedOperatorInvitationMutationVariables>;
export const CancelInvitationDocument = gql`
    mutation CancelInvitation($token: String!) {
  cancelInvitation(token: $token)
}
    `;
export type CancelInvitationMutationFn = Apollo.MutationFunction<CancelInvitationMutation, CancelInvitationMutationVariables>;

/**
 * __useCancelInvitationMutation__
 *
 * To run a mutation, you first call `useCancelInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelInvitationMutation, { data, loading, error }] = useCancelInvitationMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useCancelInvitationMutation(baseOptions?: Apollo.MutationHookOptions<CancelInvitationMutation, CancelInvitationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CancelInvitationMutation, CancelInvitationMutationVariables>(CancelInvitationDocument, options);
      }
export type CancelInvitationMutationHookResult = ReturnType<typeof useCancelInvitationMutation>;
export type CancelInvitationMutationResult = Apollo.MutationResult<CancelInvitationMutation>;
export type CancelInvitationMutationOptions = Apollo.BaseMutationOptions<CancelInvitationMutation, CancelInvitationMutationVariables>;
export const SendInvitationDocument = gql`
    mutation SendInvitation($givenName: String, $familyName: String, $emailAddress: String!, $signupId: String, $destinationOrganizationId: String, $destinationOrganizationRole: String, $legalEntityId: String, $invitationType: String!, $newOrganizationName: String, $newOrganizationIcon: String, $newOrganizationWebsiteUrl: String, $proposalType: String) {
  sendInvitation(
    recipient: {givenName: $givenName, familyName: $familyName, emailAddress: $emailAddress}
    signupId: $signupId
    destinationOrganizationId: $destinationOrganizationId
    destinationOrganizationRole: $destinationOrganizationRole
    legalEntityId: $legalEntityId
    invitationType: $invitationType
    newOrganizationName: $newOrganizationName
    newOrganizationIcon: $newOrganizationIcon
    newOrganizationWebsiteUrl: $newOrganizationWebsiteUrl
    proposalType: $proposalType
  )
}
    `;
export type SendInvitationMutationFn = Apollo.MutationFunction<SendInvitationMutation, SendInvitationMutationVariables>;

/**
 * __useSendInvitationMutation__
 *
 * To run a mutation, you first call `useSendInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendInvitationMutation, { data, loading, error }] = useSendInvitationMutation({
 *   variables: {
 *      givenName: // value for 'givenName'
 *      familyName: // value for 'familyName'
 *      emailAddress: // value for 'emailAddress'
 *      signupId: // value for 'signupId'
 *      destinationOrganizationId: // value for 'destinationOrganizationId'
 *      destinationOrganizationRole: // value for 'destinationOrganizationRole'
 *      legalEntityId: // value for 'legalEntityId'
 *      invitationType: // value for 'invitationType'
 *      newOrganizationName: // value for 'newOrganizationName'
 *      newOrganizationIcon: // value for 'newOrganizationIcon'
 *      newOrganizationWebsiteUrl: // value for 'newOrganizationWebsiteUrl'
 *      proposalType: // value for 'proposalType'
 *   },
 * });
 */
export function useSendInvitationMutation(baseOptions?: Apollo.MutationHookOptions<SendInvitationMutation, SendInvitationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendInvitationMutation, SendInvitationMutationVariables>(SendInvitationDocument, options);
      }
export type SendInvitationMutationHookResult = ReturnType<typeof useSendInvitationMutation>;
export type SendInvitationMutationResult = Apollo.MutationResult<SendInvitationMutation>;
export type SendInvitationMutationOptions = Apollo.BaseMutationOptions<SendInvitationMutation, SendInvitationMutationVariables>;
export const ReadInvitationDocument = gql`
    query ReadInvitation($token: String!) {
  readInvitation(token: $token) {
    ...AdminInvitationFields
  }
}
    ${AdminInvitationFieldsFragmentDoc}`;

/**
 * __useReadInvitationQuery__
 *
 * To run a query within a React component, call `useReadInvitationQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadInvitationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadInvitationQuery({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useReadInvitationQuery(baseOptions: Apollo.QueryHookOptions<ReadInvitationQuery, ReadInvitationQueryVariables> & ({ variables: ReadInvitationQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadInvitationQuery, ReadInvitationQueryVariables>(ReadInvitationDocument, options);
      }
export function useReadInvitationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadInvitationQuery, ReadInvitationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadInvitationQuery, ReadInvitationQueryVariables>(ReadInvitationDocument, options);
        }
export function useReadInvitationSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ReadInvitationQuery, ReadInvitationQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ReadInvitationQuery, ReadInvitationQueryVariables>(ReadInvitationDocument, options);
        }
export type ReadInvitationQueryHookResult = ReturnType<typeof useReadInvitationQuery>;
export type ReadInvitationLazyQueryHookResult = ReturnType<typeof useReadInvitationLazyQuery>;
export type ReadInvitationSuspenseQueryHookResult = ReturnType<typeof useReadInvitationSuspenseQuery>;
export type ReadInvitationQueryResult = Apollo.QueryResult<ReadInvitationQuery, ReadInvitationQueryVariables>;
export const ReadInvitationBySignupDocument = gql`
    query ReadInvitationBySignup($signupId: String!) {
  readInvitationBySignup(signupId: $signupId) {
    ...AdminInvitationFields
  }
}
    ${AdminInvitationFieldsFragmentDoc}`;

/**
 * __useReadInvitationBySignupQuery__
 *
 * To run a query within a React component, call `useReadInvitationBySignupQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadInvitationBySignupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadInvitationBySignupQuery({
 *   variables: {
 *      signupId: // value for 'signupId'
 *   },
 * });
 */
export function useReadInvitationBySignupQuery(baseOptions: Apollo.QueryHookOptions<ReadInvitationBySignupQuery, ReadInvitationBySignupQueryVariables> & ({ variables: ReadInvitationBySignupQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReadInvitationBySignupQuery, ReadInvitationBySignupQueryVariables>(ReadInvitationBySignupDocument, options);
      }
export function useReadInvitationBySignupLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReadInvitationBySignupQuery, ReadInvitationBySignupQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReadInvitationBySignupQuery, ReadInvitationBySignupQueryVariables>(ReadInvitationBySignupDocument, options);
        }
export function useReadInvitationBySignupSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ReadInvitationBySignupQuery, ReadInvitationBySignupQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ReadInvitationBySignupQuery, ReadInvitationBySignupQueryVariables>(ReadInvitationBySignupDocument, options);
        }
export type ReadInvitationBySignupQueryHookResult = ReturnType<typeof useReadInvitationBySignupQuery>;
export type ReadInvitationBySignupLazyQueryHookResult = ReturnType<typeof useReadInvitationBySignupLazyQuery>;
export type ReadInvitationBySignupSuspenseQueryHookResult = ReturnType<typeof useReadInvitationBySignupSuspenseQuery>;
export type ReadInvitationBySignupQueryResult = Apollo.QueryResult<ReadInvitationBySignupQuery, ReadInvitationBySignupQueryVariables>;
export const ListInvitationsDocument = gql`
    query ListInvitations {
  listInvitations {
    ...AdminInvitationFields
  }
}
    ${AdminInvitationFieldsFragmentDoc}`;

/**
 * __useListInvitationsQuery__
 *
 * To run a query within a React component, call `useListInvitationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListInvitationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListInvitationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useListInvitationsQuery(baseOptions?: Apollo.QueryHookOptions<ListInvitationsQuery, ListInvitationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListInvitationsQuery, ListInvitationsQueryVariables>(ListInvitationsDocument, options);
      }
export function useListInvitationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListInvitationsQuery, ListInvitationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListInvitationsQuery, ListInvitationsQueryVariables>(ListInvitationsDocument, options);
        }
export function useListInvitationsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ListInvitationsQuery, ListInvitationsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ListInvitationsQuery, ListInvitationsQueryVariables>(ListInvitationsDocument, options);
        }
export type ListInvitationsQueryHookResult = ReturnType<typeof useListInvitationsQuery>;
export type ListInvitationsLazyQueryHookResult = ReturnType<typeof useListInvitationsLazyQuery>;
export type ListInvitationsSuspenseQueryHookResult = ReturnType<typeof useListInvitationsSuspenseQuery>;
export type ListInvitationsQueryResult = Apollo.QueryResult<ListInvitationsQuery, ListInvitationsQueryVariables>;